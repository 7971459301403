@import "react-quill/dist/quill.snow.css";
button {
  text-transform: unset !important;
}
button:disabled {
  opacity: 0.5;
}

.btn-outlined,
.btn-contained {
  font-size: 16px !important;
  border-radius: 8px !important;
  padding: 8px 84px !important;
}

.btn-text {
  font-size: 14px !important;
  min-width: unset !important;
  min-height: unset !important;
  text-transform: unset !important;
  padding: 0 !important;
  line-height: 1.5 !important;
  border-radius: 0 !important;
}
.btn-text:hover {
  background-color: transparent !important;
}

.ecn-input {
  width: 100%;
  border: none;
}
.ecn-input .MuiInputBase-root {
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #212121;
}
.ecn-input .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 16px !important;
}
.ecn-input .MuiInputBase-root .MuiInputBase-input::placeholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3);
}
.ecn-input .MuiInputBase-root:focus, .ecn-input .MuiInputBase-root:active {
  outline: none;
}
.ecn-input .MuiFormHelperText-root {
  margin-left: 2px;
}

.ecn-input-area {
  width: 100%;
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}
.ecn-input-area::placeholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3) !important;
}
.ecn-input-area:focus, .ecn-input-area:active {
  outline: none;
  color: rgba(0, 0, 0, 0.3) !important;
}

.ecn-underline-input {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  line-height: 1.5;
}
.ecn-underline-input .MuiInputLabel-root {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
}
.ecn-underline-input .MuiInput-input {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
}
.ecn-underline-input::placeholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3);
}
.ecn-underline-input:focus, .ecn-underline-input:active {
  outline: none;
}

.ecn-underline-input-error .MuiInputLabel-root {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6) !important;
  font-weight: 400;
}

.ecn-breadcrumb {
  margin-top: 20px !important;
  margin-bottom: 25px !important;
}
.ecn-breadcrumb .MuiBreadcrumbs-ol .MuiBreadcrumbs-li {
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
  cursor: pointer;
}
.ecn-breadcrumb .MuiBreadcrumbs-ol .MuiBreadcrumbs-li:last-child {
  color: #212121;
  cursor: default;
}
.ecn-breadcrumb .MuiBreadcrumbs-ol .MuiBreadcrumbs-separator {
  margin-left: 7px;
  margin-right: 7px;
}

.ecn-tabs {
  margin-top: 20px;
  margin-bottom: 10px;
  position: relative;
  z-index: 33;
}
.ecn-tabs .MuiTabs-scroller {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.ecn-tabs .MuiTabs-scroller .MuiTabs-flexContainer {
  display: flex;
  align-items: center;
  padding: 0px 0px;
  gap: 20px;
}
.ecn-tabs .MuiTabs-scroller .MuiTabs-flexContainer button {
  font-size: 18px;
  color: rgba(33, 33, 33, 0.6);
  font-weight: 500;
  text-transform: capitalize;
  max-width: none !important;
  padding: 1px 20px;
  border-radius: 7px 7px 0px 0px;
  min-height: 41px !important;
  margin-top: 5px !important;
}
.ecn-tabs .MuiTabs-scroller .MuiTabs-flexContainer button:hover {
  background-color: rgba(0, 52, 187, 0.05);
}
.ecn-tabs .MuiTabs-scroller .MuiTabs-flexContainer .Mui-selected {
  color: rgb(0, 52, 187);
}
.ecn-tabs .MuiTabs-scroller .MuiTabs-indicator {
  border-bottom: 4px solid rgb(0, 52, 187) !important;
  font-weight: 700;
}

.ecn-chip {
  border-radius: 8px !important;
  padding: 4px 10px !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
}

.ecn-checkbox {
  stroke-width: 0.3px !important;
}

.ecn-select-empty {
  color: rgba(0, 0, 0, 0.3) !important;
}
.ecn-select-empty .MuiSelect-select {
  font-size: 14px !important;
  color: #000000;
}

.ecn-input-v2 {
  width: 100%;
  min-width: 275px;
}
.ecn-input-v2 .MuiFormHelperText-root {
  color: #ba0000 !important;
  font-size: 14px;
  font-weight: 400;
  border: none !important;
}
.ecn-input-v2 .MuiInputBase-root {
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #212121;
}
.ecn-input-v2 .MuiInputBase-root .Mui-disabled {
  color: rgba(33, 33, 33, 0.6) !important;
  font-size: 16px;
  font-weight: 400;
}
.ecn-input-v2 .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 16px;
}
.ecn-input-v2 .MuiInputBase-root .MuiInputBase-input::placeholder {
  font-size: 14px;
  font-weight: 400;
  opacity: unset;
  color: rgba(33, 33, 33, 0.3);
}
.ecn-input-v2 .MuiFormHelperText-root {
  margin-left: 2px;
}

.btn-contained-v2 {
  background: #0034bb !important;
  color: #ffffff !important;
  font-weight: 500;
  box-shadow: unset !important;
  border-radius: 7px !important;
}
.btn-contained-v2:disabled {
  background: #0034bb !important;
  color: #ffffff !important;
  opacity: 0.5 !important;
}

.btn-outlined-v2 {
  color: #212121 !important;
  border: 1px solid rgba(33, 33, 33, 0.2) !important;
  border-radius: 7px !important;
}
.btn-outlined-v2:hover {
  background: rgba(0, 52, 187, 0.05) !important;
}

.ecn-input-v2 {
  width: 100%;
  min-width: 275px;
}
.ecn-input-v2 .MuiFormHelperText-root {
  color: #ba0000 !important;
  font-size: 14px;
  font-weight: 400;
  border: none !important;
}
.ecn-input-v2 .MuiInputBase-root {
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #212121;
}
.ecn-input-v2 .MuiInputBase-root .Mui-disabled {
  color: rgba(33, 33, 33, 0.6) !important;
  font-size: 16px;
  font-weight: 400;
}
.ecn-input-v2 .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 16px;
}
.ecn-input-v2 .MuiInputBase-root .MuiInputBase-input::placeholder {
  font-size: 14px;
  font-weight: 400;
  opacity: unset;
  color: rgba(33, 33, 33, 0.3);
}
.ecn-input-v2 .MuiFormHelperText-root {
  margin-left: 2px;
}

.ecn-select-v2 .MuiFormControl-root {
  width: 100%;
  min-width: 277px;
}
.ecn-select-v2 .MuiFormControl-root .placeholder {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.3);
  text-transform: none;
}
.ecn-select-v2 .MuiFormControl-root .MuiInputBase-root {
  margin-top: 0px !important;
}
.ecn-select-v2 .MuiFormControl-root .MuiInputBase-root .MuiSvgIcon-root {
  margin-right: 15px;
  width: 24px;
  height: 24px;
  color: rgba(33, 33, 33, 0.8);
}
.ecn-select-v2 .MuiFormControl-root .MuiInputBase-root .MuiSelect-select {
  text-transform: capitalize;
}
.ecn-select-v2 .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 16px;
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  background: transparent;
}

.ecn-datagrid {
  border-radius: 0px 0px 0px 0px !important;
  border: none !important;
  padding-inline: 30px;
  padding-bottom: 75px;
}
.ecn-datagrid .MuiDataGrid-actionsCell .show-disabled {
  min-width: 30px;
}
.ecn-datagrid .MuiDataGrid-actionsCell .hide-disabled {
  display: none;
}
.ecn-datagrid .MuiDataGrid-columnHeaderCheckbox {
  min-width: 37px !important;
  width: unset !important;
}
.ecn-datagrid .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: flex-end;
}
.ecn-datagrid .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer .MuiCheckbox-indeterminate {
  width: 38px;
  height: 38px;
}
.ecn-datagrid .MuiDataGrid-cellCheckbox {
  justify-content: flex-end;
  min-width: 39px !important;
  width: unset !important;
}
.ecn-datagrid .three-dot-icon {
  color: rgba(33, 33, 33, 0.4);
}
.ecn-datagrid .user-round-icon {
  color: rgba(33, 33, 33, 0.7);
}
.ecn-datagrid .Mui-selected {
  background-color: unset !important;
}
.ecn-datagrid .Mui-hovered {
  background-color: unset !important;
}
.ecn-datagrid .MuiDataGrid-row:hover {
  background-color: unset !important;
}
.ecn-datagrid .MuiChip-root {
  font-size: 14px;
  font-weight: 400;
  height: unset;
  min-height: 28px;
}
.ecn-datagrid .MuiChip-root .MuiChip-label {
  padding-left: 10px;
  padding-right: 10px;
}
.ecn-datagrid .success-chip {
  background-color: rgba(2, 122, 72, 0.05);
  color: #027a48;
}
.ecn-datagrid .pending-chip {
  background: rgba(33, 33, 33, 0.05);
  color: #212121;
}
.ecn-datagrid .MuiDataGrid-row:last-child .MuiDataGrid-cell {
  border-bottom: none !important;
}

.ecn-datagrid-updated {
  border-radius: 0px 0px 0px 0px !important;
  border: none !important;
  padding-bottom: 29px;
}
.ecn-datagrid-updated .MuiDataGrid-actionsCell .show-disabled {
  min-width: 30px;
}
.ecn-datagrid-updated .MuiDataGrid-actionsCell .hide-disabled {
  display: none;
}
.ecn-datagrid-updated .MuiDataGrid-columnHeaderCheckbox {
  min-width: 37px !important;
  width: unset !important;
}
.ecn-datagrid-updated .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: flex-end;
}
.ecn-datagrid-updated .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer .MuiCheckbox-indeterminate {
  width: 38px;
  height: 38px;
}
.ecn-datagrid-updated .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer .MuiCheckbox-indeterminate .MuiSvgIcon-root {
  width: 28px;
  height: 28px;
}
.ecn-datagrid-updated .MuiDataGrid-cellCheckbox {
  justify-content: flex-end;
  min-width: 39px !important;
  width: unset !important;
}
.ecn-datagrid-updated .three-dot-icon {
  color: rgba(33, 33, 33, 0.4);
}
.ecn-datagrid-updated .user-round-icon {
  color: rgba(33, 33, 33, 0.7);
}
.ecn-datagrid-updated .MuiDataGrid-row .MuiDataGrid-cellCheckbox {
  padding-left: 10px;
}
.ecn-datagrid-updated .Mui-selected {
  background-color: unset !important;
  border-radius: 10px;
}
.ecn-datagrid-updated .Mui-selected .MuiDataGrid-cell {
  background-color: rgba(0, 52, 187, 0.05);
}
.ecn-datagrid-updated .Mui-selected .MuiDataGrid-cell:first-child {
  border-radius: 10px 0px 0px 10px;
}
.ecn-datagrid-updated .Mui-selected .MuiDataGrid-cell:last-child {
  border-radius: 0px 10px 10px 0px;
}
.ecn-datagrid-updated .Mui-hovered {
  background-color: unset !important;
  border-radius: 10px;
}
.ecn-datagrid-updated .Mui-hovered .MuiDataGrid-cell {
  background-color: rgba(0, 52, 187, 0.05);
}
.ecn-datagrid-updated .Mui-hovered .MuiDataGrid-cell:first-child {
  border-radius: 10px 0px 0px 10px;
}
.ecn-datagrid-updated .Mui-hovered .MuiDataGrid-cell:last-child {
  border-radius: 0px 10px 10px 0px;
}
.ecn-datagrid-updated .MuiDataGrid-row:hover {
  background-color: unset !important;
  border-radius: 10px;
}
.ecn-datagrid-updated .MuiDataGrid-row:hover .MuiDataGrid-cell {
  background-color: rgba(0, 52, 187, 0.05);
}
.ecn-datagrid-updated .MuiDataGrid-row:hover .MuiDataGrid-cell:first-child {
  border-radius: 10px 0px 0px 10px;
}
.ecn-datagrid-updated .MuiDataGrid-row:hover .MuiDataGrid-cell:last-child {
  border-radius: 0px 10px 10px 0px;
}
.ecn-datagrid-updated .header-sort-icon {
  width: 16px;
  height: 16px;
}
.ecn-datagrid-updated .MuiChip-root {
  font-size: 14px;
  font-weight: 400;
  height: unset;
  min-height: 28px;
}
.ecn-datagrid-updated .MuiChip-root .MuiChip-label {
  padding-left: 10px;
  padding-right: 10px;
}
.ecn-datagrid-updated .success-chip {
  background-color: rgba(2, 122, 72, 0.05);
  color: #027a48;
}
.ecn-datagrid-updated .pending-chip {
  background: rgba(33, 33, 33, 0.05);
  color: #212121;
}
.ecn-datagrid-updated .MuiDataGrid-row:last-child .MuiDataGrid-cell {
  border-bottom: none !important;
}

.MuiDataGrid-menu .MuiPaper-root {
  border-radius: 6px;
  color: rgba(33, 33, 33, 0.7);
  box-shadow: 0px 7px 30px 7px rgba(0, 0, 0, 0.1);
  padding-inline: 11px;
}
.MuiDataGrid-menu .MuiPaper-root .MuiMenuItem-root {
  padding: 14px 29px 14px 31px;
  border-radius: 10px;
}
.MuiDataGrid-menu .MuiPaper-root .MuiMenuItem-root .MuiListItemIcon-root {
  min-width: 33px;
}
.MuiDataGrid-menu .MuiPaper-root .MuiList-root {
  padding-top: 11px;
  padding-bottom: 11px;
}
.MuiDataGrid-menu .MuiPaper-root .MuiList-root .MuiButtonBase-root:hover {
  background: rgba(0, 52, 187, 0.05);
  color: #0034bb;
}
.MuiDataGrid-menu .MuiPaper-root .MuiList-root .MuiButtonBase-root:hover .MuiTypography-root {
  color: #0034bb;
}
.MuiDataGrid-menu .MuiPaper-root .MuiList-root .MuiButtonBase-root:hover .MuiListItemIcon-root {
  color: #0034bb;
}
.MuiDataGrid-menu .MuiPaper-root .MuiList-root .MuiButtonBase-root .MuiTypography-root {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.7);
  opacity: unset;
}
.MuiDataGrid-menu .MuiPaper-root .MuiList-root .Mui-selected {
  background-color: rgba(0, 52, 187, 0.05) !important;
}
.MuiDataGrid-menu .MuiPaper-root .MuiList-root .Mui-selected:hover {
  background-color: rgba(0, 52, 187, 0.05) !important;
}
.MuiDataGrid-menu .MuiPaper-root .MuiList-root .Mui-selected .MuiTypography-root {
  color: #0034bb;
}

.MuiDataGrid-panelHeader .Mui-focused {
  color: #0034bb !important;
}
.MuiDataGrid-panelHeader .MuiInput-root::after {
  border-bottom: 2px solid #0034bb !important;
}

.MuiDataGrid-panelFooter .MuiButton-root {
  color: #0034bb !important;
}
.MuiDataGrid-panelFooter .Mui-focused {
  color: #0034bb !important;
}

.MuiDataGrid-filterForm .Mui-focused {
  color: #0034bb !important;
}
.MuiDataGrid-filterForm .MuiInput-root::after {
  border-bottom: 2px solid #0034bb !important;
}

.MuiDataGrid-columnHeaders {
  border-top: none !important;
  border-radius: 10px;
  padding-left: 10px;
}

.MuiDataGrid-columnsPanelRow .MuiSwitch-switchBase {
  color: #0034bb !important;
}
.MuiDataGrid-columnsPanelRow .MuiSwitch-track {
  background-color: #0034bb !important;
}

.ecn-chip-cross-v2 {
  border-radius: 16px !important;
  background: rgba(0, 52, 187, 0.13) !important;
  color: #212121 !important;
  font-size: 14px !important;
  font-weight: 400;
  padding: 6px 2px 6px 0px !important;
}
.ecn-chip-cross-v2 .MuiChip-label {
  padding-left: 10px;
}
.ecn-chip-cross-v2 .icon {
  color: #212121 !important;
}

.ecn-chip-delete-v2 {
  border-radius: 32px !important;
  background: rgba(0, 52, 187, 0.05) !important;
  font-size: 14px !important;
  height: unset !important;
  font-weight: 400;
  padding: 8px 6px 8px 1px !important;
  color: #212121;
}
.ecn-chip-delete-v2 .MuiChip-label {
  padding-right: 23px;
}
.ecn-chip-delete-v2 .MuiAvatar-root {
  width: 38px !important;
  height: 38px !important;
  border: 3px solid #ffffff;
}
.ecn-chip-delete-v2 .initials-avatar {
  background: rgba(0, 52, 187, 0.2);
  border-radius: 50px;
  border: 3px solid #ffffff;
  width: 38px !important;
  height: 38px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ecn-chip-delete-v2 .initials-avatar .initials {
  color: #0034bb;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}

.ecn-radio-v2 {
  gap: 21px;
}
.ecn-radio-v2 .Mui-checked {
  color: #0034bb !important;
}
.ecn-radio-v2 .MuiFormControlLabel-root {
  gap: 15px;
  justify-content: flex-start;
  align-items: center;
}
.ecn-radio-v2 .MuiFormControlLabel-root .MuiButtonBase-root {
  color: rgba(33, 33, 33, 0.2);
  padding-top: unset !important;
  padding-bottom: unset !important;
  padding-right: unset !important;
}
.ecn-radio-v2 .MuiFormControlLabel-root .MuiTypography-root {
  font-size: 14px;
  color: #212121;
}
.ecn-radio-v2 .MuiFormControlLabel-root .MuiTypography-root .radio-label {
  margin-bottom: unset !important;
}

.search-bar .search {
  padding: 9px 14px;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  width: 54px;
  min-height: 44px;
}
.search-bar .search:hover {
  background: rgba(0, 52, 187, 0.05);
}
.search-bar .hide-icon {
  min-height: 46px;
}
.search-bar .hide-icon .icon {
  display: none;
}
.search-bar .active {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 20vw;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 9px 18px;
  border-radius: 7px;
  min-height: 44px;
  transition: width 0.5s;
  background-color: #ffffff !important;
  cursor: auto;
}
.search-bar .active .icon {
  cursor: pointer;
  flex-shrink: 0;
}
.search-bar .active:focus-within, .search-bar .active:focus {
  border: 1px solid rgba(0, 52, 187, 0.7) !important;
  outline: 1px solid rgba(0, 52, 187, 0.7);
}
.search-bar .active:hover {
  border: 1px solid rgba(0, 52, 187, 0.7) !important;
  outline: 1px solid rgba(0, 52, 187, 0.7);
  background: unset;
}
.search-bar .active input {
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  background-color: #ffffff;
}
.search-bar .active ::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
  opacity: unset;
}
.search-bar .active .search-cross {
  cursor: pointer;
  width: 24px !important;
  height: 24px !important;
  color: #000000;
}

.fallback-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.fallback-screen img {
  width: auto;
  max-height: 300px;
}
.fallback-screen .fallback-text {
  color: rgba(33, 33, 33, 0.8);
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 25px;
  max-width: 894px;
  margin-inline: auto;
  text-align: center;
  margin-top: -4px;
}
.fallback-screen .btn-contained-v2 {
  padding: 10px 86px;
  margin-bottom: 70px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 7px;
}

.MuiMenu-root .MuiMenu-paper {
  padding-inline: 11px;
}
.MuiMenu-root .MuiMenu-paper .MuiList-root {
  padding-bottom: 11px;
}
.MuiMenu-root .MuiMenu-paper .MuiList-root .checkbox-list-item {
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 10px;
}
.MuiMenu-root .MuiMenu-paper .MuiList-root .large-list-item {
  padding-top: 9px;
  padding-bottom: 9px;
  border-radius: 10px;
}
.MuiMenu-root .MuiMenu-paper .MuiList-root .small-list-item {
  border-radius: 8px;
}
.MuiMenu-root .MuiMenu-paper .MuiList-root .MuiButtonBase-root {
  margin-bottom: 3px;
  font-weight: 500;
}
.MuiMenu-root .MuiMenu-paper .MuiList-root .MuiButtonBase-root:hover {
  background: rgba(0, 52, 187, 0.05);
}
.MuiMenu-root .MuiMenu-paper .MuiList-root .MuiButtonBase-root:hover .MuiTypography-root {
  color: #0034bb;
}
.MuiMenu-root .MuiMenu-paper .MuiList-root .MuiButtonBase-root .MuiTypography-root {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.7);
  opacity: unset;
}
.MuiMenu-root .MuiMenu-paper .MuiList-root .Mui-selected {
  background-color: rgba(0, 52, 187, 0.05) !important;
}
.MuiMenu-root .MuiMenu-paper .MuiList-root .Mui-selected:hover {
  background-color: rgba(0, 52, 187, 0.05) !important;
}
.MuiMenu-root .MuiMenu-paper .MuiList-root .Mui-selected .MuiTypography-root {
  color: #0034bb;
}

.ecn-number-input-v2 .input-icon {
  display: flex;
  font-size: 10px;
  font-weight: 400;
  justify-content: space-between;
  width: 170px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 9px 17px 9px 17px;
}
.ecn-number-input-v2 .input-icon input::-webkit-outer-spin-button,
.ecn-number-input-v2 .input-icon input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ecn-number-input-v2 .input-icon:focus-within, .ecn-number-input-v2 .input-icon:focus {
  border: 1px solid rgba(0, 52, 187, 0.7) !important;
  outline: 1px solid rgba(0, 52, 187, 0.7);
}
.ecn-number-input-v2 .input-icon:hover {
  border: 1px solid rgba(0, 52, 187, 0.7) !important;
  outline: 1px solid rgba(0, 52, 187, 0.7);
  background: unset;
}
.ecn-number-input-v2 .input-icon input {
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: rgb(33, 33, 33);
}
.ecn-number-input-v2 .input-icon ::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.3);
}
.ecn-number-input-v2 .input-icon input[type=number] {
  -moz-appearance: textfield;
}
.ecn-number-input-v2 .input-icon .arrows {
  display: flex;
  flex-direction: column;
}
.ecn-number-input-v2 .input-icon .arrows .icon {
  width: 13px;
  color: #212121;
  height: auto;
}
.ecn-number-input-v2 .error {
  border: 1px solid #c53838;
}
.ecn-number-input-v2 .error:focus-within, .ecn-number-input-v2 .error:focus {
  border: 1px solid #c53838 !important;
  outline: 1px solid #c53838;
}
.ecn-number-input-v2 .error:hover {
  border: 1px solid #c53838 !important;
  outline: 1px solid #c53838;
  background: unset;
}

.screen-btn {
  --h-button: 48px;
  --w-button: 102px;
  --round: 0.5rem;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.25s ease;
  background: radial-gradient(65.28% 65.28% at 50% 100%, rgba(223, 113, 255, 0.8) 0%, rgba(223, 113, 255, 0) 100%), linear-gradient(0deg, #7a5af8, #7a5af8);
  border-radius: var(--round);
  border: none;
  outline: none;
  padding: 8px 12px;
}
.screen-btn::before, .screen-btn::after {
  content: "";
  position: absolute;
  inset: 1px;
  transition: all 0.5s ease-in-out;
  border-radius: calc(var(--round) - 1px);
  z-index: 0;
}
.screen-btn::before {
  background: linear-gradient(177.95deg, rgba(255, 255, 255, 0.19) 0%, rgba(255, 255, 255, 0) 100%);
}
.screen-btn::after {
  background: radial-gradient(65.28% 65.28% at 50% 100%, rgba(223, 113, 255, 0.8) 0%, rgba(223, 113, 255, 0) 100%), linear-gradient(0deg, #7a5af8, #7a5af8);
}
.screen-btn:hover svg.icon {
  fill: transparent;
  animation: dasharray 1s linear forwards, filled 0.1s linear forwards 0.95s;
}
.screen-btn:active {
  transform: scale(0.95);
}
.screen-btn:focus svg.icon {
  fill: white;
}
.screen-btn .fold {
  position: absolute;
  top: -1rem;
  right: -1rem;
  height: 1rem;
  width: 1rem;
  background: radial-gradient(100% 75% at 55%, rgba(223, 113, 255, 0.8) 0%, rgba(223, 113, 255, 0) 100%);
  box-shadow: 0 0 3px black;
  border-bottom-left-radius: 0.5rem;
  border-top-right-radius: var(--round);
  transition: all 0.5s ease-in-out;
  z-index: 1;
}
.screen-btn .fold::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 150%;
  height: 150%;
  transform: rotate(45deg) translateX(0%) translateY(-18px);
  background-color: #e8e8e8;
  pointer-events: none;
}
.screen-btn:hover .fold {
  top: 0;
  right: 0;
}
.screen-btn .points_wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  z-index: 1;
}
.screen-btn .points_wrapper .point {
  bottom: -10px;
  position: absolute;
  animation: floating-points infinite ease-in-out;
  pointer-events: none;
  width: 2px;
  height: 2px;
  background-color: #fff;
  border-radius: 9999px;
}
.screen-btn .points_wrapper .point:nth-child(1) {
  left: 10%;
  opacity: 1;
  animation-duration: 2.35s;
  animation-delay: 0.2s;
}
.screen-btn .points_wrapper .point:nth-child(2) {
  left: 30%;
  opacity: 0.7;
  animation-duration: 2.5s;
  animation-delay: 0.5s;
}
.screen-btn .points_wrapper .point:nth-child(3) {
  left: 25%;
  opacity: 0.8;
  animation-duration: 2.2s;
  animation-delay: 0.1s;
}
.screen-btn .points_wrapper .point:nth-child(4) {
  left: 44%;
  opacity: 0.6;
  animation-duration: 2.05s;
}
.screen-btn .points_wrapper .point:nth-child(5) {
  left: 50%;
  opacity: 1;
  animation-duration: 1.9s;
}
.screen-btn .points_wrapper .point:nth-child(6) {
  left: 75%;
  opacity: 0.5;
  animation-duration: 1.5s;
  animation-delay: 1.5s;
}
.screen-btn .points_wrapper .point:nth-child(7) {
  left: 88%;
  opacity: 0.9;
  animation-duration: 2.2s;
  animation-delay: 0.2s;
}
.screen-btn .points_wrapper .point:nth-child(8) {
  left: 58%;
  opacity: 0.8;
  animation-duration: 2.25s;
  animation-delay: 0.2s;
}
.screen-btn .points_wrapper .point:nth-child(9) {
  left: 98%;
  opacity: 0.6;
  animation-duration: 2.6s;
  animation-delay: 0.1s;
}
.screen-btn .points_wrapper .point:nth-child(10) {
  left: 65%;
  opacity: 1;
  animation-duration: 2.5s;
  animation-delay: 0.2s;
}
.screen-btn .inner {
  z-index: 2;
  position: relative;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.75;
  gap: 6px;
}
.screen-btn .inner svg.icon {
  width: 18px;
  height: 18px;
  transition: fill 0.1s linear;
}

@keyframes floating-points {
  0% {
    transform: translateY(0);
  }
  85% {
    opacity: 0;
  }
  100% {
    transform: translateY(-55px);
    opacity: 0;
  }
}
@keyframes dasharray {
  from {
    stroke-dasharray: 0 0 0 0;
  }
  to {
    stroke-dasharray: 68 68 0 0;
  }
}
@keyframes filled {
  to {
    fill: white;
  }
}
.add-file .drop-zone .container {
  border: 1px dashed rgba(0, 0, 0, 0.2);
  min-height: 160px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.add-file .drop-zone .container .filename {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: auto;
}
.add-file .drop-zone .container .filename .name {
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  gap: 4px;
  font-weight: 500;
  font-size: 20px;
  width: 150px;
  height: 80px;
  margin: auto;
}
.add-file .drop-zone .container .filename .name img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 6px;
}
.add-file .drop-zone .container .filename .buttons {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  margin-bottom: 0px;
  gap: 20px;
  text-align: center;
  justify-content: center;
}
.add-file .drop-zone .container .filename .buttons .upload {
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 38px;
  border-radius: 8px;
  width: auto !important;
  text-align: center;
}
.add-file .drop-zone .container .filename .buttons .upload .button {
  text-align: center;
}
.add-file .drop-zone .container .filename .buttons .upload .button button {
  margin: auto;
  border: none;
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  cursor: pointer;
}
.add-file .drop-zone .container .filename .buttons .upload-button {
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: none;
  background: #0034bb;
  text-align: center;
  padding: 7px 20px;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  min-width: 120px;
}
.add-file .drop-zone .container .filename .buttons .view-image-button {
  background: transparent;
  color: #0034bb;
}
.add-file .drop-zone .container .centeral-part {
  text-align: center;
  margin: auto;
  margin-top: -10px;
}
.add-file .drop-zone .container .centeral-part .button button {
  background: transparent;
  outline: none;
  padding: 8px 25px;
  color: #121212;
  border-radius: 5px;
  font-weight: 600;
  border: none;
}
.add-file .drop-zone .container .centeral-part .button button .icon {
  font-size: 30px;
}
.add-file .drop-zone .container .centeral-part .text .sub-text {
  font-size: 16px;
  font-weight: 500;
  color: #1f2937;
}
.add-file .drop-zone .container .centeral-part .text .sub-text span {
  font-size: 16px;
}
.add-file .drop-zone .container .centeral-part .text .support {
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
  margin-left: 40px;
  margin: auto;
}

.add-file-v2 {
  min-height: 80%;
}
.add-file-v2 .wrapper .error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 124px;
  margin-bottom: 124px;
}
.add-file-v2 .wrapper .error img {
  width: 325px;
  height: 325px;
  border-radius: 167px;
  border: 8px solid rgba(33, 33, 33, 0.6);
  margin-bottom: 40px;
}
.add-file-v2 .wrapper .error h3 {
  color: #ba0000;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
}
.add-file-v2 .wrapper .error .btn-oulined {
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  color: #212121;
  min-width: 190px;
  padding: 6px 19px;
  font-size: 18px;
  font-weight: 500;
}
.add-file-v2 .wrapper .progress {
  border: 1px solid rgba(33, 33, 33, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px 40px;
}
.add-file-v2 .wrapper .progress .image-loader {
  width: 325px;
  height: 325px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-file-v2 .wrapper .progress .image-loader img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 8px solid rgba(33, 33, 33, 0.6);
}
.add-file-v2 .wrapper .progress .image-loader .circular-progress {
  color: #0034bb;
  position: absolute;
}
.add-file-v2 .wrapper .progress h3 {
  color: #212121;
  font-size: 24px;
  font-weight: 600;
}
.add-file-v2 .wrapper .progress .in-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.add-file-v2 .wrapper .progress .in-progress p {
  color: #212121;
  font-size: 18px;
  font-weight: 400;
}
.add-file-v2 .wrapper .progress .in-progress .icon {
  width: 34px;
  height: 34px;
  color: #0034bb;
}
.add-file-v2 .wrapper .filename {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  border: 1px solid rgba(33, 33, 33, 0.2);
  border-radius: 8px;
  width: 100%;
}
.add-file-v2 .wrapper .filename .container {
  border-radius: 9px;
  padding: 40px 97px 30px 98px;
}
.add-file-v2 .wrapper .filename .container .cropper {
  position: relative;
  height: 310px;
  width: 310px;
  margin: auto;
  background: linear-gradient(to right, #a6a6a6 5px, transparent 5px) 0 0, linear-gradient(to right, #a6a6a6 5px, transparent 5px) 0 100%, linear-gradient(to left, #a6a6a6 5px, transparent 5px) 100% 0, linear-gradient(to left, #a6a6a6 5px, transparent 5px) 100% 100%, linear-gradient(to bottom, #a6a6a6 5px, transparent 5px) 0 0, linear-gradient(to bottom, #a6a6a6 5px, transparent 5px) 100% 0, linear-gradient(to top, #a6a6a6 5px, transparent 5px) 0 100%, linear-gradient(to top, #a6a6a6 5px, transparent 5px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 50px 50px;
}
.add-file-v2 .wrapper .filename .container .zoom-buttons {
  gap: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 43px;
}
.add-file-v2 .wrapper .filename .container .zoom-buttons .btn-text {
  color: #212121;
  font-size: 18px;
  font-weight: 500;
}
.add-file-v2 .wrapper .filename .container .zoom-buttons .btn-text .icon {
  width: 24px;
  height: 24px;
}
.add-file-v2 .wrapper .filename .container .cancel-and-replace {
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  gap: 50px;
}
.add-file-v2 .wrapper .filename .container .cancel-and-replace .cancel {
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 7px 25px;
  border-radius: 8px;
  width: 130px;
}
.add-file-v2 .wrapper .filename .container .cancel-and-replace .replace {
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #0034bb;
  padding: 7px 20px;
  color: #ffffff;
  cursor: pointer;
  width: 130px;
  text-align: center;
}
.add-file-v2 .wrapper .filename .buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  gap: 35px;
}
.add-file-v2 .wrapper .filename .buttons .btn-oulined {
  gap: 10px;
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  color: #212121;
  min-width: 120px;
  padding: 5px 1px;
  font-size: 16px;
  font-weight: 500;
}
.add-file-v2 .wrapper .filename .buttons .btn-oulined .icon {
  width: 20px;
  height: 20px;
}
.add-file-v2 .wrapper .filename .buttons .btn-contained {
  border-radius: 9px;
  padding: 8px 19px;
  border: 1px solid #0034bb;
  background: #0034bb;
  color: #ffffff;
  min-width: 363px;
  box-shadow: none;
}
.add-file-v2 .wrapper .filename .buttons .save-changes {
  font-size: 16px;
  font-weight: 500;
  padding: 5px 1px;
  min-width: 140px;
  border-radius: 9px;
  background: #0034bb;
  border: 1px solid #0034bb;
  color: #ffffff;
  box-shadow: none;
}

.wrapper-upload-v2 {
  width: 100%;
  background-image: repeating-linear-gradient(4deg, rgba(33, 33, 33, 0.2), rgba(33, 33, 33, 0.2) 13px, transparent 13px, transparent 26px, rgba(33, 33, 33, 0.2) 26px), repeating-linear-gradient(94deg, rgba(33, 33, 33, 0.2), rgba(33, 33, 33, 0.2) 13px, transparent 13px, transparent 26px, rgba(33, 33, 33, 0.2) 26px), repeating-linear-gradient(184deg, rgba(33, 33, 33, 0.2), rgba(33, 33, 33, 0.2) 13px, transparent 13px, transparent 26px, rgba(33, 33, 33, 0.2) 26px), repeating-linear-gradient(274deg, rgba(33, 33, 33, 0.2), rgba(33, 33, 33, 0.2) 13px, transparent 13px, transparent 26px, rgba(33, 33, 33, 0.2) 26px);
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}
.wrapper-upload-v2 .centeral-part {
  text-align: center;
  margin: auto;
  padding-top: 43px;
  padding-bottom: 48px;
}
.wrapper-upload-v2 .centeral-part .button button {
  background: transparent;
  outline: none;
  padding: 8px 25px;
  color: rgba(33, 33, 33, 0.6);
  border-radius: 5px;
  font-weight: 600;
  border: none;
}
.wrapper-upload-v2 .centeral-part .button button .icon {
  min-height: 65px;
  min-width: 65px;
}
.wrapper-upload-v2 .centeral-part .text .sub-text {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  margin-top: 23px;
  margin-bottom: 14px;
}
.wrapper-upload-v2 .centeral-part .text .sub-text-2 {
  color: rgba(33, 33, 33, 0.4);
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 13px;
}
.wrapper-upload-v2 .centeral-part .btn-outlined {
  gap: 10px;
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 7px 1px;
  color: #212121;
  width: 226px !important;
  font-size: 18px;
  font-weight: 500;
  padding: 9px 20px !important;
}
.wrapper-upload-v2 .centeral-part .btn-outlined icon {
  color: #212121;
  min-width: 20px;
}
.wrapper-upload-v2 .centeral-part .btn-outlined:hover {
  background: rgba(0, 52, 187, 0.1);
}

.bottom {
  border: 1px solid #eaecf0;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: 20px;
}
.bottom .MuiPagination-root ul li .Mui-selected {
  background: rgba(127, 86, 217, 0.1);
  color: #0034bb;
}
.bottom .MuiPagination-root ul li:first-child {
  position: absolute;
  left: 20px;
  text-align: center;
}
.bottom .MuiPagination-root ul li:first-child .MuiSvgIcon-root {
  display: none;
}
.bottom .MuiPagination-root ul li:first-child .MuiButtonBase-root {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 18px 15px 18px 21px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.bottom .MuiPagination-root ul li:first-child .MuiButtonBase-root::after {
  background: url("https://api.iconify.design/mdi/arrow-up-thin.svg?width=32&height=32&rotate=270deg") no-repeat center center/contain;
  background-repeat: no-repeat;
  background-position: -5px;
  content: "Previous";
  cursor: pointer;
  width: 90px;
}
.bottom .MuiPagination-root ul li:last-child {
  position: absolute;
  right: 20px;
}
.bottom .MuiPagination-root ul li:last-child .MuiSvgIcon-root {
  display: none;
}
.bottom .MuiPagination-root ul li:last-child .MuiButtonBase-root {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: 18px;
  padding-right: 10px;
  padding-left: 0px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.bottom .MuiPagination-root ul li:last-child .MuiButtonBase-root::after {
  background: url("https://api.iconify.design/mdi/arrow-up-thin.svg?width=40&height=40&rotate=270deg&flip=horizontal%2Cvertical") no-repeat center center/contain;
  background-repeat: no-repeat;
  background-position: 55px;
  content: " Next";
  cursor: pointer;
  width: 75px;
}

.upload-file {
  margin-left: 15px;
  margin-right: 15px;
}
.upload-file .drop-zone .container {
  background-image: repeating-linear-gradient(4deg, rgba(33, 33, 33, 0.2), rgba(33, 33, 33, 0.2) 13px, transparent 13px, transparent 26px, rgba(33, 33, 33, 0.2) 26px), repeating-linear-gradient(94deg, rgba(33, 33, 33, 0.2), rgba(33, 33, 33, 0.2) 13px, transparent 13px, transparent 26px, rgba(33, 33, 33, 0.2) 26px), repeating-linear-gradient(184deg, rgba(33, 33, 33, 0.2), rgba(33, 33, 33, 0.2) 13px, transparent 13px, transparent 26px, rgba(33, 33, 33, 0.2) 26px), repeating-linear-gradient(274deg, rgba(33, 33, 33, 0.2), rgba(33, 33, 33, 0.2) 13px, transparent 13px, transparent 26px, rgba(33, 33, 33, 0.2) 26px);
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  min-height: 160px;
  border-radius: 10px;
  padding-bottom: 30px;
}
.upload-file .drop-zone .container .filename .filename-top {
  text-align: center;
  padding-top: 33px;
}
.upload-file .drop-zone .container .filename .filename-top p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
  margin: 10px 0px;
  margin-bottom: 20px;
}
.upload-file .drop-zone .container .filename .all-files-preview {
  width: 90%;
  height: auto;
  margin: auto;
  border-radius: 6px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  padding: 30px;
}
.upload-file .drop-zone .container .filename .all-files-preview .file-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
}
.upload-file .drop-zone .container .filename .all-files-preview .file-name .file-name-left {
  display: flex;
  align-items: center;
  gap: 10px;
}
.upload-file .drop-zone .container .filename .all-files-preview .file-name .file-name-left p {
  font-size: 16px;
  font-weight: 500;
  color: rgb(33, 33, 33);
}
.upload-file .drop-zone .container .filename .all-files-preview .error {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  padding-left: 15px;
  margin-top: 10px;
}
.upload-file .drop-zone .container .filename .all-files-preview .error .item {
  font-size: 14px;
  font-weight: 500;
  color: rgb(33, 33, 33);
}
.upload-file .drop-zone .container .filename .all-files-preview .error p {
  font-size: 12px;
  font-weight: 500;
  color: rgb(186, 0, 0);
}
.upload-file .drop-zone .container .filename .buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 45px;
  margin-bottom: 0px;
  gap: 20px;
  text-align: center;
}
.upload-file .drop-zone .container .filename .buttons .upload {
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 44px;
  border-radius: 8px;
  width: auto !important;
  text-align: center;
  margin: unset !important;
}
.upload-file .drop-zone .container .filename .buttons .upload:hover {
  background: rgba(0, 52, 187, 0.05);
}
.upload-file .drop-zone .container .filename .buttons .upload .button {
  margin: unset;
}
.upload-file .drop-zone .container .filename .buttons .upload .button button {
  margin: unset;
  border: none;
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  color: rgb(0, 52, 187);
  cursor: pointer;
}
.upload-file .drop-zone .container .filename .buttons .upload-file {
  border-radius: 7px;
  padding: 7px 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  background: rgb(0, 52, 187);
  color: #fff;
}
.upload-file .drop-zone .container .centeral-part {
  text-align: center;
  margin: auto;
}
.upload-file .drop-zone .container .centeral-part .button {
  margin-top: 30px;
}
.upload-file .drop-zone .container .centeral-part .button button {
  background: transparent;
  outline: none;
  padding: 8px 25px;
  color: #121212;
  border-radius: 5px;
  font-weight: 600;
  border: none;
}
.upload-file .drop-zone .container .centeral-part .button button .icon {
  font-size: 30px;
}
.upload-file .drop-zone .container .centeral-part .text .sub-text {
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  display: flex;
  align-content: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 500;
  color: rgb(33, 33, 33);
  margin-top: 10px;
  padding: 7px 25px;
  margin-bottom: 10px;
}
.upload-file .drop-zone .container .centeral-part .text .drag-file {
  font-size: 16px;
  font-weight: 500;
  color: rgb(33, 33, 33);
  margin-top: 25px;
  margin-bottom: 10px;
}
.upload-file .drop-zone .container .centeral-part .text .or {
  font-size: 18px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.4);
}
.upload-file .drop-zone .container .centeral-part .text .support {
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
  margin-left: 40px;
  margin: auto;
}

.breadcrumb {
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 15px;
}
.breadcrumb .dots {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: rgba(33, 33, 33, 0.6);
}
.breadcrumb p {
  padding: 5px 10px;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
  margin-top: 3px;
}
.breadcrumb p:last-child {
  color: #212121;
}
.breadcrumb p:last-child:hover {
  background: none;
}
.breadcrumb p:hover {
  background: rgba(0, 52, 187, 0.05);
}

.signIn .container {
  display: flex;
  padding: 20px;
  height: 100vh;
}
.signIn .container .wrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 100px;
  padding-right: 80px;
}
.signIn .container .wrapper .main_logo {
  height: auto;
  max-width: 240px;
}
.signIn .container .wrapper .login-detail {
  margin: auto;
}
.signIn .container .wrapper .login-detail h1 {
  font-size: 44px;
  font-weight: 700;
  color: #000000;
}
.signIn .container .wrapper .login-detail .title {
  margin-bottom: 20px;
}
.signIn .container .wrapper .login-detail .input-text {
  margin-bottom: 30px;
}
.signIn .container .wrapper .login-detail .input-text p {
  font-size: 13px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 10px;
}
.signIn .container .wrapper .login-detail .input-text .outline-input {
  border-radius: 8px;
  margin-bottom: 5px;
  height: 40px;
  width: 100%;
}
.signIn .container .right {
  background: #000000;
  overflow-x: hidden;
  width: 50%;
}
.signIn .container .right img {
  height: 88%;
  margin-top: 5%;
  margin-left: 10%;
  border-radius: 20px;
}

.job {
  padding-top: 30px;
}
.job .title {
  font-size: 30px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 30px;
}
.job .cards {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding-bottom: 30px;
}
.job .cards .job-card {
  border: 1px solid #ccc;
  width: 31.5%;
  min-height: 220px;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.job .cards .job-card .job-posted-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.job .cards .job-card .job-posted-card-content p {
  font-size: 20px;
  font-weight: 600;
  color: #212121;
}
.job .cards .fallback-screen {
  text-align: center;
  margin: auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  width: 55vw;
  padding: 30px;
}
.job .cards .fallback-screen .image {
  width: 30vw;
  height: auto;
  margin: auto;
}
.job .cards .fallback-screen .image img {
  max-width: 100%;
  max-height: 100%;
}
.job .cards .fallback-screen .fallback-screen-content h1 {
  font-size: 35px;
  font-weight: 600;
  color: #212121;
}
.job .cards .fallback-screen .fallback-screen-content p {
  font-size: 22px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.71);
  width: 70%;
  margin: 15px auto;
}
.job .cards .fallback-screen .fallback-screen-content .btn-contained {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin: auto;
}
.job .cards .job-posted-card {
  border: 1px solid #ccc;
  width: 31.5%;
  min-height: 220px;
  border-radius: 12px;
  padding: 20px;
}
.job .cards .job-posted-card .job-posted-card-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
}
.job .cards .job-posted-card .job-posted-card-content div h1 {
  font-size: 20px;
  font-weight: 700;
  color: #212121;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.job .cards .job-posted-card .job-posted-card-content div .firm {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 10px 0px;
}
.job .cards .job-posted-card .job-posted-card-content div .firm p {
  font-size: 14px;
  font-weight: 400;
  color: #4b5563;
}
.job .cards .job-posted-card .job-posted-card-content div .firm .dotes {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: #212121;
}
.job .cards .job-posted-card .job-posted-card-content div .button {
  margin: 25px 0px;
  margin-top: 5px;
  display: flex;
  gap: 15px;
}
.job .cards .job-posted-card .job-posted-card-content .btn-contained {
  padding: 10px !important;
}
.job .cards .job-posted-card:hover {
  box-shadow: 0 4px 22px rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 1000px) and (max-width: 1250px) {
  .job .cards .job-card {
    width: 30.5%;
  }
  .job .cards .job-posted-card {
    width: 30.5%;
  }
}
.job__posting {
  padding: 25px 50px;
  margin: auto;
}
.job__posting .title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.job__posting .title-container .title {
  display: flex;
  align-items: center;
  font-size: 30px;
  font-weight: 500;
  color: rgb(16, 24, 40);
  cursor: pointer;
}
.job__posting .title-container img {
  max-width: 107px;
}
.job__posting .job__posting--body {
  display: flex;
  align-items: flex-start;
  margin-top: 50px;
}
.job__posting .job__posting--body .job__posting--body-left {
  display: flex;
  flex-direction: column;
  gap: 50px;
  position: relative;
  width: 27%;
}
.job__posting .job__posting--body .job__posting--body-left .left-menu {
  display: flex;
  align-items: center;
  gap: 14px;
}
.job__posting .job__posting--body .job__posting--body-left .left-menu .menu-icon {
  display: flex;
  align-items: center;
  justify-items: center;
  justify-items: center;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.job__posting .job__posting--body .job__posting--body-left .left-menu .menu-icon .icon {
  margin: auto;
  padding: auto;
  width: 25px;
  height: 25px;
  color: rgb(0, 0, 0);
}
.job__posting .job__posting--body .job__posting--body-left .left-menu .menu-text h2 {
  font-size: 20px;
  font-weight: 600;
  color: #101828;
}
.job__posting .job__posting--body .job__posting--body-left .left-menu .menu-text p {
  font-size: 14px;
  font-weight: 400;
  color: #101828;
}
.job__posting .job__posting--body .job__posting--body-left .active .menu-icon {
  border: 1px solid #0034bb;
}
.job__posting .job__posting--body .job__posting--body-left .active .menu-icon .icon {
  color: #0034bb;
}
.job__posting .job__posting--body .job__posting--body-left .active .menu-text h2 {
  color: #0034bb;
}
.job__posting .job__posting--body .job__posting--body-left .line {
  position: absolute;
  height: 60px;
  width: 1px;
  background: rgba(0, 0, 0, 0.2);
  top: 50px;
  left: 21px;
}
.job__posting .job__posting--body .job__posting--body-left .line-bottom {
  position: absolute;
  height: 70px;
  width: 1px;
  background: rgba(0, 0, 0, 0.2);
  bottom: 60px;
  left: 21px;
}
.job__posting .job__posting--body .job__posting--body-right {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 73%;
  border-radius: 8px;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container {
  min-height: 250px;
  height: auto;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container {
  min-height: 250px;
  height: auto;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .chip-container {
  display: flex;
  flex-direction: column;
  gap: 11px;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .chip-container p {
  font-size: 13px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .chip-container .chip-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin: 10px 0 30px 0;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .chip-container .chip-wrapper .skill-chip {
  padding: 10px !important;
  height: unset;
  color: rgba(33, 33, 33, 0.6);
  font-weight: 500;
  border-radius: 10px !important;
  border-color: rgba(33, 33, 33, 0.2);
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .chip-container .chip-wrapper .skill-chip.selected {
  color: #0034bb;
  border-color: #0034bb;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .chip-container .chip-wrapper .add-chip {
  border: 0;
  color: #000000;
  padding: 20px 10px !important;
  font-weight: 500;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .chip-container .button {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 48px;
  overflow-y: auto;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .chip-container .button .MuiChip-root {
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 15px !important;
  padding: 18px 20px !important;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .chip-container .button .MuiChip-root span {
  font-size: 16px;
  font-weight: 500;
  color: #0034bb;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .chip-container .button .add-chip {
  border: 1px solid #0034bb !important;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .skill-input {
  margin: 0px 0 60px 0;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .description-top {
  margin-bottom: unset !important;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .description-top .question-title {
  max-width: 509px;
  font-size: 14px;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 9px;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .top .question-title {
  font-size: 30px;
  font-weight: 600;
  color: #212121;
  width: 70%;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .generate-ai-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: -47px;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .generate-ai-btn .btn-contained {
  color: #ffffff;
  font-size: 17px;
  font-weight: 600;
  display: flex;
  min-width: 200px;
  padding: 11px 2px !important;
  gap: 8px;
  border-radius: 8px;
  background: radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(94deg, #0034bb 1.69%, #0034bb 99.83%);
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .generate-ai-btn .check-draft {
  min-width: 168px;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .sub-title {
  font-size: 13px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 10px;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .sub-title-error {
  color: #ba0000;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .group {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .description-quill .ql-toolbar {
  margin-bottom: 9px !important;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .description-quill .ql-container {
  margin-top: 10px;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .description-quill .ql-container .ql-editor {
  padding: unset;
  padding-bottom: 12px;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .description-quill .ql-container .ql-editor.ql-blank::before {
  left: 0px;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .quill {
  display: flex;
  flex-direction: column-reverse;
  white-space: pre-wrap;
  min-height: 20px;
  height: 220px;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .quill .ql-toolbar {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background: rgb(238, 238, 238);
  margin-bottom: 20px;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .quill .ql-container {
  border: none;
  min-height: 50px;
  max-height: 180px;
  overflow-y: scroll;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .quill .ql-container .ql-editor {
  height: auto;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .quill .ql-container .ql-editor.ql-blank::before {
  font-size: 14px;
  font-style: normal;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .quill .ql-picker-options {
  position: absolute;
  top: -150px;
  z-index: 2;
  height: 145px;
  overflow-y: scroll;
  border-radius: 5px;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .quill-error .ql-toolbar {
  border: none;
  border-bottom: 2px solid #ba0000;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .MuiSlider-root {
  margin-left: 20px;
  margin-top: 10px;
  width: 90%;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .MuiSlider-root .MuiSlider-rail {
  color: #f3f4f6 !important;
  height: 10px;
  opacity: 1;
  width: 100%;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .MuiSlider-root .MuiSlider-track {
  height: 10px;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .MuiSlider-root .MuiSlider-mark {
  display: none;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .MuiSlider-root .MuiSlider-thumb {
  background: #ffffff;
  border: 1px solid #a503f1;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .MuiAutocomplete-root .MuiFormLabel-root {
  font-size: 14px;
  font-weight: 400;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .MuiAutocomplete-root .Mui-focused {
  color: #212121;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .MuiAutocomplete-root .MuiInputBase-root {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .MuiAutocomplete-root .MuiInputBase-root .Mui-focused {
  color: #212121;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .MuiAutocomplete-root .MuiInputBase-root .MuiChip-root {
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 16px 15px;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .MuiAutocomplete-root .MuiInputBase-root .MuiChip-root span {
  font-size: 16px;
  font-weight: 500;
  color: #0034bb;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .MuiAutocomplete-root .MuiInputBase-root .MuiChip-root .MuiSvgIcon-root {
  fill: transparent;
  background: url("https://api.iconify.design/ic/baseline-clear.svg?color=%239ca3af&width=18") no-repeat center center/contain;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .questions-container .image__upload {
  max-height: 280px;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .image__upload {
  min-height: 285px;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .swipeable-container {
  overflow: hidden;
}
.job__posting .job__posting--body .job__posting--body-right .job__posting--questions-container .swipeable-container .swipeable-wrapper {
  display: flex;
  transition: transform 0.5s ease;
  /* Adjust the duration and easing as needed */
}
.job__posting .job__posting--body .job__posting--body-right .skill-buttons {
  margin-top: -45px !important;
}
.job__posting .job__posting--body .job__posting--body-right .buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 0px !important;
}
.job__posting .job__posting--body .job__posting--body-right .buttons .left {
  display: flex;
  align-items: center;
  gap: 20px;
}
.job__posting .job__posting--body .job__posting--body-right .buttons .left .btn-outlined {
  padding: 7px 44px !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.job__posting .job__posting--body .job__posting--body-right .buttons .left .btn-cancel {
  padding: 7px 34px !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: #212121;
}
.job__posting .job__posting--body .job__posting--body-right .buttons .right {
  display: flex;
  align-items: center;
  gap: 20px;
}
.job__posting .job__posting--body .job__posting--body-right .buttons .right .btn-outlined {
  padding: 8px 24px !important;
  border: none;
}
.job__posting .job__posting--body .job__posting--body-right .buttons .right .btn-contained {
  padding: 8px 54px !important;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .question-title {
  font-size: 20px;
  font-weight: 600;
  color: #374151;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .question-para {
  font-size: 14px;
  font-weight: 400;
  color: #6b7280;
  margin-bottom: 20px;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .questions {
  max-height: 60vh;
  overflow-y: scroll;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .questions .ques-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border-bottom: 5px solid #0034bb;
  margin-bottom: 20px;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .questions .ques-wrapper .ques .container {
  display: flex;
  gap: 20px;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 30px;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .questions .ques-wrapper .ques .container .question-answer {
  width: 60%;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .questions .ques-wrapper .ques .container .question-answer .MuiFormControl-root {
  width: 100%;
  border-radius: 8px;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .questions .ques-wrapper .ques .container .question-answer .MuiFormControl-root .MuiInputBase-root {
  border: none;
  height: 43px;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .questions .ques-wrapper .ques .container .question-answer .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  padding: 15px;
  font-size: 14px;
  background: transparent;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .questions .ques-wrapper .ques .container .question-answer .radio-container {
  margin-top: 10px;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .questions .ques-wrapper .ques .container .question-answer .radio-container .radio-div {
  margin-bottom: -5px;
  display: flex;
  align-items: center;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .questions .ques-wrapper .ques .container .question-answer .radio-container .radio-div input {
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 16px;
  font-weight: 500;
  padding: 8px 0px;
  padding-bottom: 3px;
  margin-left: -15px;
  width: 100%;
  background: transparent;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .questions .ques-wrapper .ques .container .question-answer .radio-container .add-closed {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
  justify-content: right;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .questions .ques-wrapper .ques .container .select {
  width: 40%;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .questions .ques-wrapper .ques .container .select .MuiFormControl-root {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .questions .ques-wrapper .ques .container .select .MuiFormControl-root .MuiInputBase-root {
  border: none;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .questions .ques-wrapper .ques .container .select .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  padding: 9px;
  font-size: 14px;
  background: transparent;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .questions .ques-wrapper .ques .container .select .item {
  display: flex;
  gap: 40px;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .questions .ques-wrapper .state-btns {
  width: 100%;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .questions .ques-wrapper .state-btns .button {
  color: #000000;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .questions .ques-wrapper .state-btns .btn .button {
  color: #000000;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .questions::-webkit-scrollbar {
  width: 0px;
  width: none;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .buttons .right,
.job__posting .job__posting--body .job__posting--body-right .question-list .buttons .left {
  display: flex;
  align-items: center;
  gap: 30px;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .buttons .right .btn-outlined,
.job__posting .job__posting--body .job__posting--body-right .question-list .buttons .left .btn-outlined {
  padding: 8px 24px !important;
  min-width: 150px;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .buttons .right .btn-contained,
.job__posting .job__posting--body .job__posting--body-right .question-list .buttons .left .btn-contained {
  padding: 8px 54px !important;
}
.job__posting .job__posting--body .job__posting--body-right .question-list .buttons .right .btn-outlined-background,
.job__posting .job__posting--body .job__posting--body-right .question-list .buttons .left .btn-outlined-background {
  background: rgba(127, 0, 187, 0.2);
  padding: 8px 54px !important;
}
.job__posting .job__posting--body .job__posting--body-right .job-details {
  max-height: 76vh;
  overflow-y: scroll;
  margin: auto 10px;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .title {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .sub-p {
  font-size: 14px;
  font-weight: 400;
  color: #374151;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .job-card {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-right: 0px;
  padding-bottom: 20px;
  padding-top: 10px;
  padding-left: 0px;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .job-card .card-body {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .job-card .card-body .card-body-left {
  display: flex;
  gap: 10px;
  padding-left: 5px;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .job-card .card-body .card-body-left .img {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .job-card .card-body .card-body-left .img img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .job-card .card-body .card-body-left .detail h3 {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .job-card .card-body .card-body-left .detail .location-title {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 8px;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .job-card .card-body .card-body-left .detail .location-title p {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .job-card .card-body .card-body-left .detail .location-title .dots {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: #000000;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .job-card .card-body .card-body-left .detail .chip {
  display: flex;
  align-items: center;
  gap: 5px;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .job-card .card-body .card-body-left .detail .chip button {
  border: none;
  outline: none;
  border-radius: 6px;
  border: 1px solid rgba(22, 13, 58, 0.2);
  background: linear-gradient(0deg, rgba(32, 87, 138, 0.12) 0%, rgba(32, 87, 138, 0.12) 100%), #ffffff;
  font-size: 14px;
  font-weight: 400;
  color: rgb(85, 85, 85);
  padding: 4px 10px;
  margin-top: 8px;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .job-card .card-body .card-body-right {
  text-align: right;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .job-card .card-body .card-body-right .buttons {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: unset;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .job-card .card-body .card-body-right .buttons button {
  border: none;
  outline: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  min-width: 100px;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .job-card .card-body .card-body-right .buttons .save {
  padding: 7px 15px;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.12);
  min-width: 50px;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .job-card .card-body .card-body-right .buttons .apply {
  color: #ffffff;
  background: #313131;
  padding: 7px 10px;
  padding-left: 20px;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .text-detail {
  border-radius: 8px;
  padding: 20px 0px;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .text-detail h2 {
  font-size: 20px;
  font-weight: 700;
  color: #212121;
  margin-bottom: 16px;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .text-detail .experience {
  font-size: 14px;
  font-weight: 400;
  color: #374151;
  margin-bottom: 20px;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .text-detail .markdown-data {
  margin-bottom: 20px;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .text-detail .markdown-data p {
  font-size: 14px;
  font-weight: 400;
  color: #374151;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .text-detail .markdown-data ul,
.job__posting .job__posting--body .job__posting--body-right .job-details .text-detail .markdown-data ol {
  margin-left: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #374151;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .buttons {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .buttons .right,
.job__posting .job__posting--body .job__posting--body-right .job-details .buttons .left {
  display: flex;
  align-items: center;
  gap: 30px;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .buttons .right .btn-outlined,
.job__posting .job__posting--body .job__posting--body-right .job-details .buttons .left .btn-outlined {
  padding: 8px 24px !important;
  min-width: 150px;
}
.job__posting .job__posting--body .job__posting--body-right .job-details .buttons .right .btn-contained,
.job__posting .job__posting--body .job__posting--body-right .job-details .buttons .left .btn-contained {
  padding: 8px 34px !important;
}
.job__posting .job__posting--body .job__posting--body-right .job-details::-webkit-scrollbar {
  width: 0px;
  width: none;
}

.confirm-dialog .MuiDialogContent-root {
  text-align: center;
}
.confirm-dialog .MuiDialogContent-root h3 {
  color: #212121;
  font-size: 20px;
  font-weight: 600;
  margin-top: 13px;
}
.confirm-dialog .MuiDialogContent-root p {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  margin: auto;
  max-width: 84%;
}
.confirm-dialog .MuiDialogContent-root .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-bottom: 15px;
}
.confirm-dialog .MuiDialogContent-root .button-container .cancel {
  border-radius: 9px;
  border: 1px solid #0034bb;
  color: #0034bb;
  padding: 8px 35px;
}
.confirm-dialog .MuiDialogContent-root .button-container .confirm {
  border-radius: 9px;
  border: 0.151px solid rgba(0, 0, 0, 0.12);
  background: #0034bb;
  padding: 8px 35px;
}
.confirm-dialog .MuiDialogContent-root img {
  height: auto;
  margin-top: 10px;
  max-width: 120px;
  margin-bottom: 30px;
  margin-left: 25px;
}

.ai-job-description .MuiDialog-container {
  margin-inline: 38px;
}
.ai-job-description .MuiDialog-container .heading {
  padding: 0px 19px;
}
.ai-job-description .MuiDialog-container .heading .cross {
  float: right;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 3px;
  width: 25px;
  stroke-width: 1.5px;
  stroke: #212121;
  height: 26px;
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root {
  padding: 2px 52px 68px 21px;
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body {
  display: flex;
  align-items: flex-start;
  height: 100%;
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body .left-body {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 24px;
  padding-top: 68px;
  gap: 14px;
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body .left-body .card-description {
  text-align: center;
  max-width: 282px;
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body .left-body .card-description .elevate-ai {
  max-height: 29px;
  margin-bottom: 8px;
  max-width: 140px;
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body .left-body .card-description h3 {
  color: #212121;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 12px;
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body .left-body .card-description p {
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
  margin-bottom: 3px;
  text-underline-offset: 2px;
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body .left-body .active {
  box-shadow: unset !important;
  border: 1px solid #0034bb;
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body .left-body .active .top-section {
  display: block !important;
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body .left-body .active .bottom-section {
  padding: 8px 12px 8px 12px !important;
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body .left-body .active .bottom-section h3 {
  color: #0034bb;
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body .left-body .card {
  border-radius: 15px;
  max-width: 280px;
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.05);
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body .left-body .card .top-section {
  display: none;
  background-color: #0034bb;
  border-radius: 15px 15px 0px 0px;
  min-height: 13px;
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body .left-body .card .bottom-section {
  min-height: 94px;
  border-radius: 15.476px;
  background-color: #ffffff;
  padding: 8px 12px 8px 13px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body .left-body .card .bottom-section h3 {
  font-size: 13px;
  font-weight: 600;
  margin-left: 2px;
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body .left-body .card .bottom-section p {
  color: #1f2937;
  font-size: 12px;
  font-weight: 400;
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body .left-body .card .bottom-section .words {
  color: #4b5563;
  font-size: 10px;
  font-weight: 600;
  margin-top: 4px;
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body .right-body {
  width: 75%;
  height: 100%;
  padding-right: 18px;
  padding-bottom: 18px;
  border-radius: 18px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body .right-body .container {
  padding: 30px 26px 0px 28px;
  overflow-y: auto;
  font-size: 14px;
  font-weight: 400;
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body .right-body .container .markdown-data {
  color: #000000;
  white-space: pre-wrap;
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body .right-body .container .markdown-data p {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body .right-body .container .markdown-data ul,
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body .right-body .container .markdown-data ol {
  margin-left: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  white-space: normal;
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body .right-body .markdown-bottom {
  text-align: right;
}
.ai-job-description .MuiDialog-container .MuiDialogContent-root .body .right-body .markdown-bottom .btn-contained {
  padding: 8px 30px !important;
  border-radius: 8px;
  background: #0034bb;
  font-size: 17px !important;
  font-weight: 500;
  margin-right: 20px;
}

@media only screen and (min-width: 1014px) and (max-width: 1280px) {
  .ai-job-description .MuiDialog-container .MuiDialogContent-root .body .left-body {
    padding-inline: 20px;
    padding-top: 52px;
    gap: 12px;
  }
  .ai-job-description .MuiDialog-container .MuiDialogContent-root .body .left-body .card-description h3 {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .ai-job-description .MuiDialog-container .MuiDialogContent-root .body .left-body .card-description p {
    font-size: 11px;
    margin-bottom: 2px;
  }
  .ai-job-description .MuiDialog-container .MuiDialogContent-root .body .left-body .card .top-section {
    min-height: 11px;
  }
  .ai-job-description .MuiDialog-container .MuiDialogContent-root .body .left-body .card .bottom-section {
    padding: 6px 10px 7px 11px;
  }
  .ai-job-description .MuiDialog-container .MuiDialogContent-root .body .left-body .card .bottom-section h3 {
    font-size: 12px;
  }
  .ai-job-description .MuiDialog-container .MuiDialogContent-root .body .left-body .card .bottom-section p {
    font-size: 11px;
  }
  .ai-job-description .MuiDialog-container .MuiDialogContent-root .body .left-body .card .bottom-section .words {
    font-size: 9px;
    margin-top: 3px;
  }
}
.group {
  padding: 30px 30px;
  padding-bottom: 0px;
}
.group .search {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 40%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 8px 10px;
  height: 43px;
}
.group .search input {
  width: 100%;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}
.group .search .cross {
  cursor: pointer;
}
.group .MuiBox-root .MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-pinnedColumnHeaders {
  box-shadow: none !important;
}
.group .MuiBox-root .MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-virtualScroller .MuiDataGrid-pinnedColumns {
  box-shadow: none !important;
}
.group .create-group .MuiMenu-paper {
  max-height: 200px !important;
}
.group .create-group h1 {
  font-size: 30px;
  font-weight: 500;
  color: #101828;
  margin-bottom: 10px;
}
.group .create-group .container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 80vh;
}
.group .create-group .container .top .row {
  margin-bottom: 20px;
  width: 45%;
}
.group .create-group .container .top .row p {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 5px;
}
.group .create-group .container .top .row .description {
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
  margin-left: 5px;
}
.group .create-group .container .top .row .text-field {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 10px 14px;
}
.group .create-group .container .top .row .select {
  margin-top: -15px;
}
.group .create-group .container .top .row .select .MuiFormControl-root {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 4px 0;
  margin-top: 20px;
}
.group .create-group .container .top .row .select .MuiFormControl-root .MuiInputBase-root {
  border: none;
}
.group .create-group .container .top .row .select .MuiFormControl-root .MuiInputBase-root .MuiSvgIcon-root {
  margin-right: 10px;
}
.group .create-group .container .top .row .select .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  padding: 5px 20px;
  font-size: 14px;
  background: transparent;
}
.group .create-group .container .top .row .select .MuiInputLabel-root {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.3);
  opacity: 0.4;
}
.group .create-group .container .top .row .select .MuiInputLabel-shrink {
  visibility: hidden;
}
.group .create-group .container .top .row .btn-text {
  text-decoration: underline;
  text-underline-offset: 7px;
  font-size: 14px;
  font-weight: 600;
}
.group .create-group .container .top .row .btn-text_:hover {
  text-decoration: none;
}
.group .create-group .container .top .row .avatar {
  width: 30px;
  height: 30px;
  background: #f3f4f6;
  color: #0034bb;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}
.group .table {
  position: relative;
}
.group .table .title {
  font-size: 30px;
  font-weight: 500;
  color: #101828;
  margin-bottom: 10px;
}
.group .table .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.group .table .top .button-group {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
}
.group .table .top .button-group .button {
  text-transform: capitalize;
  color: #6b7280;
  font-size: 14px;
  font-weight: 500;
  border: 0px;
  border-radius: 8px;
  padding: 9px 20px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.group .table .top .button-group .active {
  background: #0034bb;
  color: #ffffff;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.group .table .top .button-group .active2 {
  background: #0034bb;
  color: #ffffff;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.group .table .top .button-group .button:first-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.group .table .top .button-group .button:nth-child(1) {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.group .table .top .right {
  display: flex;
  align-items: center;
  gap: 30px;
}
.group .table .top .right .btn-outlined,
.group .table .top .right .btn-contained {
  padding: 7px 40px !important;
}
.group .table .MuiDataGrid-root {
  border: 1px solid #f3f4f6;
  border-bottom: none;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.group .table .no-result {
  width: 60vw;
  margin: auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  text-align: center;
  padding: 30px;
}
.group .table .no-result img {
  max-width: 450px;
}
.group .table .no-result h2 {
  font-size: 35px;
  font-weight: 600;
  color: rgb(33, 33, 33);
}
.group .table .no-result p {
  font-size: 22px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.71);
  margin: 15px 0px;
}
.group .table .no-result .btn-contained {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  gap: 10px;
  margin: auto;
}
.group .member-list {
  position: relative;
}
.group .member-list .top-card h1 {
  font-size: 30px;
  font-weight: 500;
  color: #6b7280;
  display: flex;
  align-items: center;
  margin: 30px 0px;
  margin-top: 10px;
}
.group .member-list .top-card h1 span {
  color: #000000;
}
.group .member-list .top-card h1 h2 {
  font-size: 30px;
  font-weight: 500;
  color: #6b7280;
  cursor: pointer;
}
.group .member-list .top-card .container {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  gap: 10px;
}
.group .member-list .top-card .container .card {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 25px 15px;
  border-radius: 12px;
  width: 20%;
  height: 150px;
}
.group .member-list .top-card .container .card h3 {
  font-size: 16px;
  font-weight: 600;
  color: #6b7280;
}
.group .member-list .top-card .container .card h1 {
  font-size: 32px;
  font-weight: 700;
  color: #101828;
}
.group .member-list .top-card .container .card .details {
  display: flex;
  align-content: center;
  gap: 10px;
  margin-top: 10px;
}
.group .member-list .top-card .container .card .details .avatar {
  width: 40px;
  height: 40px;
}
.group .member-list .top-card .container .card .details .name h5 {
  font-size: 12px;
  font-weight: 500;
  color: #101828;
}
.group .member-list .top-card .container .card .details .name p {
  font-size: 12px;
  font-weight: 500;
  color: #6b7280;
}
.group .member-list .top-card .container .large-card {
  width: 37%;
  height: 150px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 25px 15px;
  border-radius: 12px;
}
.group .member-list .top-card .container .large-card h3 {
  font-size: 16px;
  font-weight: 600;
  color: #4b5563;
  margin-bottom: 10px;
}
.group .member-list .top-card .container .large-card p {
  font-size: 14px;
  font-weight: 400;
  color: #313131;
}
.group .member-list .search-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.group .member-list .search-buttons .right {
  display: flex;
  gap: 30px;
  padding-right: 20px;
}
.group .member-list .search-buttons .right .btn-outlined {
  padding: 8px 35px !important;
}
.group .member-list .MuiDataGrid-root {
  border: 1px solid #f3f4f6;
  border-bottom: none;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.candidates {
  padding: 30px 30px;
  padding-bottom: 0px;
}
.candidates .top-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.candidates .top-section h1 {
  font-size: 30px;
  font-weight: 500;
  color: #000000;
  display: flex;
  align-items: center;
}
.candidates .top-section h1 span {
  color: #9ca3af;
}
.candidates .top-section .btn-outlined {
  min-width: 150px;
  padding: 8px 25px !important;
}
.candidates .default-text {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin-top: 15%;
}
.candidates .default-text div {
  text-align: center;
}
.candidates .default-text div p {
  font-size: 16px;
  font-weight: 500;
  color: #9ca3af;
}
.candidates .select-job .select {
  width: 45%;
}
.candidates .select-job .select .MuiFormControl-root {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-top: 20px;
}
.candidates .select-job .select .MuiFormControl-root .MuiInputBase-root {
  border: none;
}
.candidates .select-job .select .MuiFormControl-root .MuiInputBase-root .MuiSvgIcon-root {
  margin-right: 10px;
}
.candidates .select-job .select .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  font-size: 16px;
  background: transparent;
  padding: 10px 16px !important;
  font-weight: 600;
  color: #000000;
}
.candidates .select-job .select .item {
  display: flex;
  gap: 40px;
}
.candidates .table {
  position: relative;
}
.candidates .table .no-result {
  text-align: center;
  max-width: 50vw;
  margin: auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  padding: 30px;
  margin-top: 50px;
}
.candidates .table .no-result img {
  max-width: 40vw;
}
.candidates .table .search-bar {
  margin-top: 30px;
  margin-bottom: -61.5px;
  position: relative;
  z-index: 1;
}
.candidates .table .search-bar .search {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 22%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 8px 10px;
  height: 43px;
}
.candidates .table .search-bar .search input {
  width: 100%;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}
.candidates .table .search-bar .search .cross {
  cursor: pointer;
}
.candidates .table .all-resume {
  text-decoration: none;
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 8px 15px;
  border-radius: 8px;
  gap: 7px;
  font-weight: 500;
  font-size: 14px;
}
.candidates .table .all-resume .icon {
  font-size: 15px;
}
.candidates .table .MuiBox-root .MuiDataGrid-root {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-radius: 8px;
}
.candidates .table .MuiBox-root .MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-virtualScroller .MuiDataGrid-virtualScrollerContent .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:first-child .MuiDataGrid-cellContent {
  font-size: 14px;
  font-weight: 500;
  color: #101828;
}
.candidates .table .MuiBox-root .MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-virtualScroller .MuiDataGrid-virtualScrollerContent .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell .MuiDataGrid-cellContent {
  font-size: 14px;
  font-weight: 400;
  color: rgb(102, 112, 133);
}
.candidates .table .MuiBox-root .MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-virtualScroller .MuiDataGrid-virtualScrollerContent .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) .MuiDataGrid-cellContent {
  font-size: 14px;
  font-weight: 500;
  color: rgb(16, 24, 40);
}
.candidates .table .bottom {
  border: 1px solid #d1d5db;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: 20px;
}
.candidates .table .bottom .MuiPagination-root ul li .Mui-selected {
  background: rgba(127, 86, 217, 0.1);
  color: #0034bb;
}
.candidates .table .bottom .MuiPagination-root ul li:first-child {
  position: absolute;
  left: 10px;
  text-align: center;
}
.candidates .table .bottom .MuiPagination-root ul li:first-child .MuiSvgIcon-root {
  display: none;
}
.candidates .table .bottom .MuiPagination-root ul li:first-child .MuiButtonBase-root {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding-right: 0px;
  padding-left: 10px;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 18px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.candidates .table .bottom .MuiPagination-root ul li:first-child .MuiButtonBase-root::after {
  background: url("https://api.iconify.design/mdi/arrow-up-thin.svg?width=32&height=32&rotate=270deg") no-repeat center center/contain;
  background-repeat: no-repeat;
  background-position: -5px;
  content: "Previous";
  cursor: pointer;
  width: 90px;
}
.candidates .table .bottom .MuiPagination-root ul li:last-child {
  position: absolute;
  right: 30px;
}
.candidates .table .bottom .MuiPagination-root ul li:last-child .MuiSvgIcon-root {
  display: none;
}
.candidates .table .bottom .MuiPagination-root ul li:last-child .MuiButtonBase-root {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: 18px;
  padding-right: 10px;
  padding-left: 0px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.candidates .table .bottom .MuiPagination-root ul li:last-child .MuiButtonBase-root::after {
  background: url("https://api.iconify.design/mdi/arrow-up-thin.svg?width=40&height=40&rotate=270deg&flip=horizontal%2Cvertical") no-repeat center center/contain;
  background-repeat: no-repeat;
  background-position: 55px;
  content: " Next";
  cursor: pointer;
  width: 75px;
}
.candidates .table .MuiDataGrid-panel {
  margin-left: 130px;
}
.candidates .candidates-details {
  position: relative;
  margin-bottom: 120px;
}
.candidates .candidates-details .top-header h1 {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  font-size: 30px;
  font-weight: 500;
  color: #101828;
}
.candidates .candidates-details .body {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
  gap: 20px;
  margin-bottom: 30px;
}
.candidates .candidates-details .body .left {
  width: 70%;
}
.candidates .candidates-details .body .left .user-details {
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #ffffff;
  width: 100%;
}
.candidates .candidates-details .body .left .user-details .stats-data {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 10px;
  background: rgba(33, 33, 33, 0.03);
  padding: 20px;
  padding-right: 0px;
}
.candidates .candidates-details .body .left .user-details .stats-data .data {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 30px;
  text-align: center;
}
.candidates .candidates-details .body .left .user-details .stats-data .data:last-child {
  border-right: none;
}
.candidates .candidates-details .body .left .user-details .stats-data .data p {
  font-size: 14px;
  font-weight: 500;
  color: #0034bb;
}
.candidates .candidates-details .body .left .user-details .stats-data .data p:last-child {
  font-size: 18px;
  font-weight: 600;
  color: #1f2937;
}
.candidates .candidates-details .body .left .user-details .details {
  margin-top: -40px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.candidates .candidates-details .body .left .user-details .details .img-details {
  display: flex;
  align-items: flex-end;
  gap: 15px;
}
.candidates .candidates-details .body .left .user-details .details .img-details .image {
  width: 135px;
  height: 135px;
  border-radius: 100%;
  overflow: hidden;
}
.candidates .candidates-details .body .left .user-details .details .img-details .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}
.candidates .candidates-details .body .left .user-details .details .img-details .name h1 {
  font-size: 20px;
  font-weight: 700;
  color: #101828;
}
.candidates .candidates-details .body .left .user-details .details .img-details .name h2 {
  font-size: 18px;
  font-weight: 500;
  color: #101828;
}
.candidates .candidates-details .body .left .user-details .details .img-details .name p {
  font-size: 18px;
  font-weight: 500;
  color: rgba(16, 24, 40, 0.6);
}
.candidates .candidates-details .body .left .user-details .details .download {
  display: flex;
  align-items: center;
  gap: 20px;
}
.candidates .candidates-details .body .left .user-details .details .download .btn-outlined {
  min-width: 120px !important;
  padding: 7px 20px !important;
  display: flex;
  align-items: center;
  gap: 10px;
}
.candidates .candidates-details .body .left .education {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 6px;
  margin-top: 20px;
  padding-top: 0px;
  width: 100%;
}
.candidates .candidates-details .body .left .education .edu {
  display: flex;
  align-items: flex-start;
  gap: 100px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 25px;
}
.candidates .candidates-details .body .left .education .edu:last-child {
  border-bottom: none;
}
.candidates .candidates-details .body .left .education .edu h2 {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
  width: 15%;
}
.candidates .candidates-details .body .left .education .edu .edu-container {
  width: 85%;
}
.candidates .candidates-details .body .left .education .edu .edu-container .wrapper {
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 15px;
}
.candidates .candidates-details .body .left .education .edu .edu-container .wrapper:last-child {
  border-bottom: none;
}
.candidates .candidates-details .body .left .education .edu .edu-container .wrapper h1 {
  font-size: 20px;
  font-weight: 700;
  color: #101828;
}
.candidates .candidates-details .body .left .education .edu .edu-container .wrapper p {
  font-size: 16px;
  font-weight: 500;
  color: #101828;
}
.candidates .candidates-details .body .left .education .edu .edu-container .wrapper .location {
  display: flex;
  align-items: center;
  gap: 10px;
}
.candidates .candidates-details .body .left .education .edu .edu-container .wrapper .location p {
  color: #0034bb;
}
.candidates .candidates-details .body .left .education .edu .edu-container .wrapper .location .dots {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: #000000;
}
.candidates .candidates-details .body .left .question {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  margin-top: 20px;
}
.candidates .candidates-details .body .left .question h2 {
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
}
.candidates .candidates-details .body .left .question .ques .q {
  font-size: 16px;
  font-weight: 400;
  color: #1f2937;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
}
.candidates .candidates-details .body .left .question .ques .q .dots {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: #000000;
}
.candidates .candidates-details .body .left .question .ques .answer {
  font-size: 18px;
  font-weight: 500;
  color: #101828;
}
.candidates .candidates-details .body .right {
  width: 30%;
}
.candidates .candidates-details .body .right .ai__rating {
  border: 1px solid #0034bb;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 20px;
  padding-bottom: 0;
  padding-right: 0px;
  overflow: hidden;
}
.candidates .candidates-details .body .right .ai__rating .ai__rating--title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}
.candidates .candidates-details .body .right .ai__rating .ai__rating--title-container .ai__rating-title {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 19px;
  font-weight: 500;
  color: #101828;
}
.candidates .candidates-details .body .right .ai__rating .ai__rating--title-container .retry {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.7);
  font-weight: 600;
}
.candidates .candidates-details .body .right .ai__rating .ai__rating--score {
  display: flex;
  justify-content: space-between;
  margin-bottom: -25px;
  position: relative;
}
.candidates .candidates-details .body .right .ai__rating .ai__rating--score .ai__rating--score-left h1 {
  margin-top: 20px;
  font-size: 42px;
  font-weight: 700;
  color: #0034bb;
}
.candidates .candidates-details .body .right .ai__rating .ai__rating--score .ai__rating--score-left .ai__rating--score-unable {
  position: absolute;
  width: 50%;
  margin-top: 10px;
}
.candidates .candidates-details .body .right .ai__rating .ai__rating--score .ai__rating--score-left .ai__rating--score-unable p {
  font-size: 11px;
  font-weight: 600;
  color: #0034bb;
}
.candidates .candidates-details .body .right .ai__rating .ai__rating--score .ai__rating--score-left .ai__rating--score-unable .btn-text {
  margin-top: 10px;
  text-decoration: underline;
  text-underline-offset: 5px;
}
.candidates .candidates-details .body .right .ai__rating .ai__rating--score .ai__rating--score-right {
  margin-top: 10px;
  margin-right: -30px;
}
.candidates .candidates-details .body .right .ai__rating .ai__rating--score .ai__rating--score-right-move-left {
  margin-left: -50px;
  padding-right: 5px;
}
.candidates .candidates-details .body .right .ai__rating .ai__rating--score .ai__rating--score-right-blur {
  filter: blur(2px);
  position: relative;
  z-index: 1;
}
.candidates .candidates-details .body .right .ai__rating .feedback-container {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 15px;
  margin: auto;
  padding-top: 14px;
  margin-right: 15px;
}
.candidates .candidates-details .body .right .ai__rating .feedback-container .heading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  gap: 5px;
}
.candidates .candidates-details .body .right .ai__rating .feedback-container .heading .btn-text {
  color: rgba(33, 33, 33, 0.8);
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 5px;
}
.candidates .candidates-details .body .right .ai__rating .feedback-container .heading .btn-text:hover {
  text-decoration: none !important;
}
.candidates .candidates-details .body .right .ai__rating .feedback-container .sub-container {
  text-align: center;
}
.candidates .candidates-details .body .right .ai__rating .feedback-container .sub-container .rating {
  margin-bottom: 32px;
}
.candidates .candidates-details .body .right .ai__rating .feedback-container .sub-container .rating span {
  padding-left: 7px;
}
.candidates .candidates-details .body .right .ai__rating .feedback-container .sub-container .btn-outlined {
  font-size: 16px !important;
  border-radius: 8px !important;
  padding: 12px 53px !important;
  font-weight: 500 !important;
  margin: 0px auto 19px 10px;
}
.candidates .candidates-details .body .right .status {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 6px;
}
.candidates .candidates-details .body .right .status h1 {
  font-size: 16px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
  margin-bottom: 15px;
}
.candidates .candidates-details .body .right .status .select {
  width: 100%;
}
.candidates .candidates-details .body .right .status .select .MuiFormControl-root {
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(127, 0, 187, 0.6);
  background: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(127, 0, 187, 0.3);
}
.candidates .candidates-details .body .right .status .select .MuiFormControl-root .MuiInputBase-root {
  border: none;
}
.candidates .candidates-details .body .right .status .select .MuiFormControl-root .MuiInputBase-root .MuiSvgIcon-root {
  margin-right: 10px;
}
.candidates .candidates-details .body .right .status .select .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  font-size: 14px;
  background: transparent;
  padding: 7px 16px !important;
  font-weight: 600;
  color: #1f2937;
}
.candidates .candidates-details .body .right .status .select .item {
  display: flex;
  gap: 40px;
}
.candidates .candidates-details .body .right .score-card {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-top: 20px;
  border-radius: 6px;
}
.candidates .candidates-details .body .right .score-card .scores .top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.candidates .candidates-details .body .right .score-card .scores .top-bar h2 {
  font-size: 16px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
}
.candidates .candidates-details .body .right .score-card .scores .top-bar .edit {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.candidates .candidates-details .body .right .score-card .scores .top-bar .edit p {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
}
.candidates .candidates-details .body .right .score-card .scores .top-bar .edit .icon {
  font-size: 16px;
}
.candidates .candidates-details .body .right .score-card .scores .rating {
  margin-bottom: 10px;
}
.candidates .candidates-details .body .right .score-card .scores .rating .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
}
.candidates .candidates-details .body .right .score-card .scores .rating .title .name {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  color: #101828;
}
.candidates .candidates-details .body .right .score-card .total-card {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.candidates .candidates-details .body .right .score-card .total-card .total {
  margin-top: 25px;
}
.candidates .candidates-details .body .right .score-card .total-card .total p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(22, 13, 58, 0.6);
}
.candidates .candidates-details .body .right .score-card .total-card .total h2 {
  font-size: 16px;
  font-weight: 500;
  color: rgba(22, 13, 58, 0.7);
}
.candidates .candidates-details .body .right .score-card .total-card .total h2 span {
  font-size: 20px;
  font-weight: 700;
  color: #2bba1d;
}
.candidates .candidates-details .bottom {
  background: #ffffff;
  box-shadow: 0px -4px 22px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 20px 30px;
  padding-right: 110px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 81px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.candidates .candidates-details .bottom .btn-contained,
.candidates .candidates-details .bottom .btn-outlined {
  min-width: 250px !important;
}
.candidates .score-modifier {
  position: relative;
}
.candidates .score-modifier .title {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 30px;
  font-weight: 500;
  color: #101828;
  margin-bottom: 20px;
  cursor: pointer;
}
.candidates .score-modifier .chips {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  width: 90%;
}
.candidates .score-modifier .chips .chip {
  display: flex;
  flex-direction: row-reverse;
  border-radius: 6px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: 500;
  padding: 18px 12px !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #ffffff;
}
.candidates .score-modifier .chips .chip .MuiSvgIcon-root {
  display: none;
}
.candidates .score-modifier .chips .filled {
  border: 1px solid #0034bb;
  background: rgba(0, 52, 187, 0.15);
  color: #0034bb;
}
.candidates .score-modifier .container {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 140px;
}
.candidates .score-modifier .container .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 40px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;
}
.candidates .score-modifier .container .top h3 {
  font-size: 20px;
  font-weight: 700;
  color: rgba(22, 13, 58, 0.6);
}
.candidates .score-modifier .container .add-chip {
  border-radius: 6px;
  padding: 18px 12px;
  border: 1px solid #212121;
  background: #ffffff;
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  margin-left: 17px;
}
.candidates .score-modifier .container .custom-chip {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 30px;
}
.candidates .score-modifier .container .custom-chip .text-container {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.candidates .score-modifier .container .custom-chip .text-container .text-box {
  width: 70%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.candidates .score-modifier .container .custom-chip .text-container .text-box .text {
  width: 100%;
  margin-bottom: -5px;
  font-size: 18px;
  font-weight: 500;
}
.candidates .score-modifier .container .custom-chip .text-container .icon {
  display: flex;
  align-items: center;
  gap: 20px;
}
.candidates .score-modifier .container .right {
  display: flex;
  align-items: center;
  justify-content: right;
}
.candidates .score-modifier .container .right .wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.candidates .score-modifier .container .right .wrapper h2 {
  font-size: 20px;
  font-weight: 500;
  color: #101828;
}
.candidates .score-modifier .container .right .wrapper h2 span {
  color: #2bba1d;
  font-size: 38px;
  font-weight: 700;
}
.candidates .score-modifier .btns {
  background: #ffffff;
  box-shadow: 0px -4px 22px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  margin-left: -30px;
  padding: 20px 35px;
  padding-right: 120px;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-style-class {
  display: block;
}
.filter-style-class .MuiPaper-root {
  border-radius: 8px;
  padding: 10px 15px;
  margin-top: -70px;
  margin-left: 50vw;
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-columnsPanel .MuiDataGrid-columnsPanelRow {
  margin: 10px 0px;
}

@media only screen and (min-width: 1500px) and (max-width: 3000px) {
  .filter-style-class {
    display: block;
  }
  .filter-style-class .MuiPaper-root {
    margin-left: 65vw;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1139px) {
  .candidates .table .search-bar .search {
    width: 16%;
  }
  .candidates .table .all-resume {
    padding: 8px 10px;
    gap: 0px;
  }
  .candidates .candidates-details .body .right .ai__rating .feedback-container .sub-container .rating .MuiRating-icon {
    width: unset;
  }
  .candidates .candidates-details .body .right .ai__rating .feedback-container .sub-container .rating .MuiSvgIcon-root {
    font-size: 35px;
  }
}
.setting {
  padding-left: 40px;
  padding-top: 40px;
}
.setting .title {
  font-size: 28px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 20px;
}
.setting .body {
  display: flex;
  align-items: flex-start;
  gap: 30px;
}
.setting .body .setting-left {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px 10px;
  border-radius: 6px;
  width: 27%;
}
.setting .body .setting-left .left-side-bar ul {
  list-style: none;
}
.setting .body .setting-left .left-side-bar ul .li {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 17px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
  margin-bottom: 15px;
  padding: 9px 10px;
  height: 48px;
  cursor: pointer;
}
.setting .body .setting-left .left-side-bar ul .li:last-child {
  margin-bottom: 0px;
}
.setting .body .setting-left .left-side-bar ul .li:hover {
  border-radius: 6px;
  background: rgba(209, 213, 219, 0.5);
}
.setting .body .setting-left .left-side-bar ul .li .active {
  border-radius: 6px;
  background: rgba(127, 0, 187, 0.14);
  color: #0034bb;
}
.setting .body .setting-left .left-side-bar ul .active {
  border-radius: 6px;
  background: rgba(127, 0, 187, 0.14);
  color: #0034bb;
}
.setting .body .setting-right {
  width: 68%;
  padding-bottom: 72px;
}
.setting .body .setting-right .setting-notification,
.setting .body .setting-right .security {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  width: 100%;
  min-height: 500px;
  padding: 20px 25px;
}
.setting .body .setting-right .setting-notification h1,
.setting .body .setting-right .security h1 {
  font-size: 25px;
  font-weight: 600;
  color: #101828;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
}
.setting .body .setting-right .setting-notification .list,
.setting .body .setting-right .security .list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
}
.setting .body .setting-right .setting-notification .list:last-child,
.setting .body .setting-right .security .list:last-child {
  border-bottom: none;
}
.setting .body .setting-right .setting-notification .list h4,
.setting .body .setting-right .security .list h4 {
  font-size: 20px;
  font-weight: 500;
  color: #101828;
}
.setting .body .setting-right .reset-password {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  width: 100%;
  min-height: 500px;
  padding: 20px 25px;
}
.setting .body .setting-right .reset-password h1 {
  display: flex;
  align-items: center;
  font-size: 25px;
  font-weight: 600;
  color: #101828;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
  cursor: pointer;
}
.setting .body .setting-right .reset-password h1 span {
  display: flex;
  align-items: center;
  color: #9ca3af;
  margin-right: 5px;
}
.setting .body .setting-right .reset-password .field {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
}
.setting .body .setting-right .reset-password .field label {
  font-size: 16px;
  font-weight: 600;
  color: #6b7280;
  margin-bottom: 5px;
}
.setting .body .setting-right .reset-password .field .MuiOutlinedInput-root {
  border-radius: 8px !important;
}
.setting .body .setting-right .reset-password .btn-contained {
  margin-top: 30px;
  min-width: 200px;
}
.setting .body .setting-right .password-request {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  width: 100%;
  min-height: 500px;
  padding: 20px 25px;
}
.setting .body .setting-right .password-request h1 {
  display: flex;
  align-items: center;
  font-size: 25px;
  font-weight: 600;
  color: #101828;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
  cursor: pointer;
}
.setting .body .setting-right .password-request h1 span {
  display: flex;
  align-items: center;
  color: #9ca3af;
  margin-right: 5px;
}
.setting .body .setting-right .password-request p {
  font-size: 20px;
  font-weight: 500;
  color: rgba(16, 24, 40, 0.6);
  margin-top: 25px;
  margin-bottom: 20px;
}
.setting .body .setting-right .verify-email {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  width: 100%;
  min-height: 500px;
  padding: 20px 25px;
}
.setting .body .setting-right .verify-email h1 {
  display: flex;
  align-items: center;
  font-size: 25px;
  font-weight: 600;
  color: #101828;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
  cursor: pointer;
}
.setting .body .setting-right .verify-email .otp .title {
  font-size: 20px;
  font-weight: 500;
  color: #101828;
  margin: 20px 0px;
}
.setting .body .setting-right .verify-email .otp .label {
  font-size: 16px;
  font-weight: 600;
  color: #6b7280;
  margin-bottom: 10px;
}
.setting .body .setting-right .verify-email .otp .resend {
  font-size: 14px;
  font-weight: 500;
  color: #101828;
  margin-top: 7px;
}
.setting .body .setting-right .verify-email .btn-contained {
  margin-top: 20vh;
  min-width: 200px;
}
.setting .body .setting-right .my-activity {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  width: 100%;
  min-height: 500px;
  padding: 20px 25px;
}
.setting .body .setting-right .my-activity .title {
  display: flex;
  align-items: center;
  font-size: 25px;
  font-weight: 600;
  color: #101828;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
}
.setting .body .setting-right .my-activity .my-activity-container-list {
  max-height: 78vh;
  overflow-y: auto;
}
.setting .body .setting-right .my-activity .my-activity-container-list .my-activity-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 15px;
  margin-top: 15px;
  cursor: pointer;
}
.setting .body .setting-right .my-activity .my-activity-container-list .my-activity-list:last-child {
  border-bottom: none;
}
.setting .body .setting-right .my-activity .my-activity-container-list .my-activity-list .my-activity-container p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(16, 24, 40, 0.5);
}
.setting .body .setting-right .my-activity .my-activity-container-list .my-activity-list .my-activity-container h1 {
  font-size: 16px;
  font-weight: 600;
  color: rgb(16, 24, 40);
}
.setting .body .setting-right .my-activity .my-activity-container-list .selected-activity {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 8px;
  width: 50%;
  margin-top: 20px;
}
.setting .body .setting-right .my-activity .my-activity-container-list .selected-activity .top {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
}
.setting .body .setting-right .my-activity .my-activity-container-list .selected-activity .top p {
  font-size: 14px;
  font-weight: 600;
  color: rgba(16, 24, 40, 0.5);
}
.setting .body .setting-right .my-activity .my-activity-container-list .selected-activity .top h3 {
  font-size: 20px;
  font-weight: 600;
  color: rgb(16, 24, 40);
  margin: 10px 0px;
}
.setting .body .setting-right .my-activity .my-activity-container-list .selected-activity .top .browser-name {
  display: flex;
  align-items: center;
  gap: 10px;
}
.setting .body .setting-right .my-activity .my-activity-container-list .selected-activity .top .browser-name p {
  font-weight: 400;
}
.setting .body .setting-right .my-activity .my-activity-container-list .selected-activity .top .browser-name h4 {
  font-size: 14px;
  font-weight: 600;
  color: #101828;
}
.setting .body .setting-right .my-activity .my-activity-container-list .selected-activity .bottom-setting .ip {
  margin-top: 20px;
}
.setting .body .setting-right .my-activity .my-activity-container-list .selected-activity .bottom-setting .ip p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(16, 24, 40, 0.5);
}
.setting .body .setting-right .my-activity .my-activity-container-list .selected-activity .bottom-setting .ip h4 {
  font-size: 14px;
  font-weight: 600;
  color: #101828;
}
.setting .body .setting-right .my-activity .my-activity-container-list .selected-activity .bottom-setting .ip h5 {
  font-size: 14px;
  font-weight: 600;
  color: rgba(16, 24, 40, 0.6);
}
.setting .body .setting-right .help {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  width: 100%;
  min-height: 500px;
  padding: 20px 25px;
}
.setting .body .setting-right .help h1 {
  font-size: 25px;
  font-weight: 600;
  color: #101828;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
}
.setting .body .setting-right .help .list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  cursor: pointer;
}
.setting .body .setting-right .help .list h4 {
  font-size: 20px;
  font-weight: 500;
  color: #101828;
}
.setting .body .setting-right .help .text-fields .row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}
.setting .body .setting-right .help .text-fields .row .field {
  width: 50%;
}
.setting .body .setting-right .help .text-fields .row .field p {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin: 5px 0px;
}
.setting .body .setting-right .help .text-fields .row .field .MuiInputBase-input::placeholder {
  font-size: 14px;
  font-weight: 400;
  opacity: unset;
  color: rgba(0, 0, 0, 0.3);
}
.setting .body .setting-right .help .text-fields .row .field .MuiOutlinedInput-root {
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
}
.setting .body .setting-right .help .text-fields .row .area-field {
  width: 100%;
}
.setting .body .setting-right .help .text-fields .row .area-field p {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin: 5px 0px;
}
.setting .body .setting-right .help .text-fields .row .area-field .MuiInputBase-input::placeholder {
  font-size: 14px;
  font-weight: 400;
  opacity: unset;
  color: rgba(0, 0, 0, 0.3);
}
.setting .body .setting-right .help .text-fields .row .area-field .MuiOutlinedInput-root {
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
}
.setting .body .setting-right .help .text-fields .row .text-area {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 15px;
  font-size: 12px;
}
.setting .body .setting-right .help .text-fields .btn-contained {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  margin: auto;
  margin-top: 40px;
  background: #0034bb;
  color: #ffffff;
}

.add-user .top-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-user .top-header h1 {
  font-size: 40px;
  font-weight: 600;
  color: #101828;
}
.add-user .bottom-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.add-user .bottom-header .left {
  display: flex;
  align-items: center;
  gap: 20px;
}
.add-user .bottom-header .left .search {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 20vw;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  border-radius: 8px;
}
.add-user .bottom-header .left .search input {
  outline: none;
  border: none;
  font-size: 16px;
  width: 100%;
}
.add-user .bottom-header .left .search ::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #667085;
}
.add-user .bottom-header .left .menu {
  display: flex;
  align-items: center;
  padding: 7px 15px;
  gap: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #374151;
}
.add-user .bottom-header .right {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.add-user .bottom-header .right .check {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1px 0px;
  padding-left: 15px;
}
.add-user .bottom-header .right .check-two {
  padding: 1px 0px;
  padding-left: 15px;
}
.add-user .body {
  margin-top: 20px;
}
.add-user .body .table-container {
  border: 1px solid #eaecf0;
  height: 65vh;
  border-radius: 8px;
}
.add-user .body .table-container .head {
  background: #f9fafb;
  width: 100%;
}
.add-user .body .table-container .head .head-cell {
  font-size: 12px;
  font-weight: 500;
  color: #667085;
}
.add-user .body .table-container .head .head-cell:nth-child(1) {
  width: 200px !important;
}
.add-user .body .table-container .head .head-cell:nth-child(2) {
  width: 100px !important;
}
.add-user .body .table-container .head .head-cell:nth-child(3) {
  width: 100px !important;
}
.add-user .body .table-container .head .head-cell:nth-child(4) {
  width: 150px !important;
}
.add-user .body .table-container .head .head-cell:nth-child(5) {
  width: 170px !important;
}
.add-user .body .table-container .head .head-cell:nth-child(6) {
  width: 170px !important;
}
.add-user .body .table-container .image {
  text-align: center;
  width: 100%;
  margin-top: 40px;
}
.add-user .body .table-container .image h2 {
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}

.inviteUser {
  height: 90vh;
}
.inviteUser .title {
  font-size: 24px;
  font-weight: 600;
  color: #212121;
  margin-top: 20px;
}
.inviteUser .stepper {
  margin-bottom: 0px;
  margin-top: 20px;
}
.inviteUser .stepper .MuiStepper-root .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer .MuiStepLabel-label {
  margin-top: 1px;
  font-size: 16px;
  font-weight: 500;
  color: #374151;
}
.inviteUser .user-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 65vh;
  margin-top: 0px;
  margin-bottom: 0px;
  position: relative;
  padding-right: 30px;
}
.inviteUser .user-detail .top {
  max-height: 74vh;
  overflow-y: scroll;
}
.inviteUser .user-detail .top .title {
  font-size: 18px;
  font-weight: 600;
  color: #374151;
}
.inviteUser .user-detail .top .users-list {
  border-bottom: 1px solid #d1d5db;
  padding-bottom: 20px;
}
.inviteUser .user-detail .top .users-list h2 {
  font-size: 24px;
  font-weight: 600;
  color: #374151;
}
.inviteUser .user-detail .top .users-list .row {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}
.inviteUser .user-detail .top .users-list .row .col {
  width: 32.6%;
  height: 70px;
}
.inviteUser .user-detail .top .users-list .row .col .designation {
  display: flex;
  gap: 26px;
  align-items: center;
}
.inviteUser .user-detail .top .users-list .row .col .designation .delete-icon {
  margin-top: 42px;
  color: rgba(33, 33, 33, 0.7);
  cursor: pointer;
}
.inviteUser .user-detail .top .users-list .row .col p {
  font-size: 14px;
  font-weight: 400;
  color: #374151;
  margin-bottom: 5px;
}
.inviteUser .user-detail .top .users-list .row .col .auto-box {
  width: 100%;
}
.inviteUser .user-detail .top .users-list .row .col .auto-box .MuiOutlinedInput-root {
  height: unset;
  padding: 0px 10px;
}
.inviteUser .user-detail .top .users-list .row .col .error {
  font-size: 14px;
  font-weight: 400;
  color: rgb(186, 0, 0);
  margin-top: 5px;
}
.inviteUser .user-detail .top .users-list .button {
  text-align: right;
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: right;
}
.inviteUser .user-detail .top .users-list .button .btn-outlined {
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  color: #212121;
  padding: 8px 14px;
}
.inviteUser .user-detail .top .users-list .button .btn-outlined:hover {
  background: none;
}
.inviteUser .user-detail .top .import {
  margin-top: 30px;
  text-align: center;
}
.inviteUser .user-detail .top .import h2 {
  font-size: 18px;
  font-weight: 600;
  color: #374151;
}
.inviteUser .user-detail .top .import p {
  font-size: 16px;
  font-weight: 400;
  color: #6b7280;
  width: 50%;
  margin: 15px auto;
}
.inviteUser .user-detail .top .import .input-name {
  border: 1px solid #d1d5db;
  width: 17%;
  margin: auto;
  border-radius: 8px;
  padding: 20px;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}
.inviteUser .user-detail .top .import .input-name .input {
  display: flex;
  align-items: center;
  justify-content: center;
}
.inviteUser .user-detail .top .import .input-name .input input[type=file] {
  display: none;
}
.inviteUser .user-detail .top .import .input-name .input .custom-file-upload {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  font-weight: 600;
  font-size: 16px;
  color: #374151;
}
.inviteUser .user-detail .top .import .input-name .input .replace {
  width: 100%;
  margin: 8px 0px;
  color: #0034bb;
  text-align: center;
  border: 1px solid #0034bb;
  border-radius: 8px;
  padding: 7px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: center;
}
.inviteUser .user-detail .top .import .input-name h3 {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: auto;
  margin-top: 5px;
  color: #374151;
  font-weight: 600;
}
.inviteUser .user-detail .bottom-button {
  position: absolute;
  right: 30px;
  bottom: 20px;
}
.inviteUser .user-detail .bottom-button .btn-contained {
  min-width: 150px;
}
.inviteUser .user-detail .bottom-button .btn-contained:hover {
  background: #004fd8;
}
.inviteUser .role {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70vh;
  margin-right: 30px;
}
.inviteUser .role .MuiPaper-root {
  border-left: none !important;
  border-right: none !important;
}
.inviteUser .role .MuiPaper-root .MuiTableCell-root {
  border-right: 0px !important;
}
.inviteUser .role .MuiPaper-root .MuiTableRow-root {
  height: 40px !important;
}
.inviteUser .role .MuiPaper-root .MuiTableCell-root {
  padding: 11px !important;
}
.inviteUser .role .bottom-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}
.inviteUser .role .bottom-button .left {
  display: flex;
  align-items: center;
  gap: 80px;
}
.inviteUser .role .bottom-button .btn-outlined,
.inviteUser .role .bottom-button .btn-contained {
  min-width: 150px;
}
.inviteUser .role .bottom-button .btn-contained:hover {
  background: #004fd8;
}
.inviteUser .preview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70vh;
  padding-right: 30px;
  margin-top: 20px;
}
.inviteUser .preview .top .review-title {
  font-size: 18px;
  font-weight: 600;
  color: #374151;
  margin-bottom: 10px;
  margin-top: 15px;
}
.inviteUser .preview .top .para {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 20px;
}
.inviteUser .preview .top .preview-table .table-heading {
  border-top: 1px solid rgba(33, 33, 33, 0.1);
  background: rgba(33, 33, 33, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 19px;
}
.inviteUser .preview .top .preview-table .table-heading .table-heading-left {
  display: flex;
  align-items: center;
  width: 50%;
}
.inviteUser .preview .top .preview-table .table-heading .table-heading-left p {
  font-size: 14px;
  font-weight: 500;
  color: #121619;
}
.inviteUser .preview .top .preview-table .table-heading .table-heading-right {
  width: 50%;
}
.inviteUser .preview .top .preview-table .table-heading .table-heading-right p {
  font-size: 14px;
  font-weight: 500;
  color: #121619;
}
.inviteUser .preview .top .list-user .user {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 8px 0px;
  max-height: 600px;
  overflow-y: scroll;
}
.inviteUser .preview .top .list-user .user .user-left {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 0px;
}
.inviteUser .preview .top .list-user .user .user-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}
.inviteUser .preview .top .list-user .user .user-right h1 {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.inviteUser .preview .top .list-user .user .user-right .icons {
  padding-right: 10px;
}
.inviteUser .preview .top .list-user .user .user-right .icons .edit {
  margin-right: 10px;
  cursor: pointer;
}
.inviteUser .preview .top .list-user .user .user-right .icons .delete {
  cursor: pointer;
}
.inviteUser .preview .bottom-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}
.inviteUser .preview .bottom-button .left {
  display: flex;
  align-items: center;
  gap: 80px;
}
.inviteUser .preview .bottom-button .btn-outlined,
.inviteUser .preview .bottom-button .btn-contained {
  min-width: 150px;
}

.maping-table {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 40vh;
  padding-bottom: 30px;
}
.maping-table .MuiInputBase-root {
  border-radius: 9px !important;
}
.maping-table .container {
  padding: 30px;
}
.maping-table .container .title {
  font-size: 22px;
  font-weight: 600;
}
.maping-table .container .req {
  font-size: 16px;
  font-weight: 400;
}
.maping-table .container .field {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}
.maping-table .container .detected {
  font-size: 14px;
  font-weight: 600;
  margin: 20px 10px;
  margin-bottom: 10px;
  margin-left: 0px;
}
.maping-table .container .error {
  background: rgba(186, 0, 0, 0.08);
  border: 0.5px solid rgba(186, 0, 0, 0.7);
  border-radius: 9px;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
.maping-table .container .error .missing {
  font-size: 16px;
  font-weight: 600;
}
.maping-table .container .error .missing span {
  color: #ba0000;
  margin-right: 5px;
}
.maping-table .container .error .replace {
  font-size: 16px;
  font-weight: 600;
  color: #004fd8;
  cursor: pointer;
}
.maping-table .error {
  font-size: 14px;
  font-weight: 400;
  color: rgb(186, 0, 0);
  margin-top: 5px;
}
.maping-table .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  padding: 0px 30px;
}
.maping-table .bottom .left {
  display: flex;
  align-items: center;
  gap: 80px;
}
.maping-table .bottom .btn-outlined,
.maping-table .bottom .btn-contained {
  min-width: 150px;
}

.billing-mobile-input {
  height: auto;
  border-radius: 8px;
  border: 1.5px solid rgba(33, 33, 33, 0.2) !important;
  padding-right: 2px;
  border-left: none;
}
.billing-mobile-input:hover, .billing-mobile-input:focus, .billing-mobile-input:focus-within {
  border-color: rgba(0, 52, 187, 0.7) !important;
}
.billing-mobile-input input {
  font-size: 16px;
  font-weight: 500;
  font-family: Poppins;
  color: #212121;
}
.billing-mobile-input .form-control {
  font-size: 16px !important;
  font-weight: 500 !important;
  width: 91% !important;
  border-radius: 8px !important;
  margin-left: 26px !important;
  border: unset !important;
  border-left: none !important;
  height: 40px !important;
}
.billing-mobile-input .flag-dropdown {
  border-radius: 8px 0 0 8px !important;
  border-right: none !important;
  border: unset !important;
  background: rgba(255, 255, 255, 0.8) !important;
}
.billing-mobile-input .flag-dropdown.open {
  border-radius: 8px !important;
}
.billing-mobile-input .flag-dropdown.open:hover, .billing-mobile-input .flag-dropdown.open:focus, .billing-mobile-input .flag-dropdown.open:focus-within {
  border-color: rgba(0, 52, 187, 0.7) !important;
  border-radius: 8px !important;
}
.billing-mobile-input .flag-dropdown.open .selected-flag {
  border-radius: 8px !important;
}
.billing-mobile-input .flag-dropdown.open .country-list {
  border-radius: 8px !important;
  text-align: left;
  padding: 11px 10px;
}
.billing-mobile-input .flag-dropdown.open .country-list .country {
  padding: 12px 20px !important;
  border-radius: 8px !important;
  margin-bottom: 10px !important;
}
.billing-mobile-input .flag-dropdown.open .country-list .country:last-child {
  margin-bottom: 0;
}
.billing-mobile-input .flag-dropdown.open .country-list .highlight {
  background: rgba(0, 52, 187, 0.05) !important;
}
.billing-mobile-input .flag-dropdown .selected-flag {
  padding: 0 0 0 19px;
  background-color: unset;
  margin-right: 26px;
}
.billing-mobile-input .flag-dropdown .selected-flag .flag .arrow {
  border: none;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M4 6L8 10L12 6" stroke="%23212121" stroke-opacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  width: 16px;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
  top: unset;
  left: 22px;
}

.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.error-page .card {
  width: 65%;
  margin-inline: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  text-align: center;
  padding: 28px 45px 19px 45px;
}
.error-page .card h2 {
  margin: 19px 0;
  font-size: 35px;
  font-weight: 600;
  line-height: normal;
  color: #212121;
}
.error-page .card p {
  font-size: 22px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.7);
}
.error-page .card .btn-home {
  margin-top: 19px;
  font-size: 16px;
  font-weight: 500;
  width: 360px;
}

.resetPassword .container {
  display: flex;
  padding: 20px;
  height: 100vh;
}
.resetPassword .container .wrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 100px;
  padding-right: 80px;
  gap: 80px;
}
.resetPassword .container .wrapper .main_logo {
  height: auto;
  max-width: 240px;
}
.resetPassword .container .wrapper .reset-detail h1 {
  font-size: 44px;
  font-weight: 700;
  color: #000000;
}
.resetPassword .container .wrapper .reset-detail .title {
  margin-bottom: 20px;
}
.resetPassword .container .wrapper .reset-detail .input-text {
  margin-bottom: 30px;
}
.resetPassword .container .wrapper .reset-detail .input-text p {
  font-size: 13px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 10px;
}
.resetPassword .container .wrapper .reset-detail .btn-contained {
  padding: 8px 10px !important;
  font-weight: 500;
}
.resetPassword .container .wrapper .reset-detail .helper-link {
  margin-top: 26px;
  display: flex;
  align-items: center;
}
.resetPassword .container .wrapper .reset-detail .helper-link span {
  font-weight: 600;
  text-decoration: none;
  color: #0034bb;
  margin-left: 6px;
}
.resetPassword .container .right {
  background: #000000;
  overflow-x: hidden;
  width: 50%;
}
.resetPassword .container .right img {
  height: 88%;
  margin-top: 5%;
  margin-left: 10%;
  border-radius: 20px;
}

.job-promotion {
  padding-left: 30px;
  padding-top: 30px;
}
.job-promotion .background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5); /* Adjust the alpha value for transparency */
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.job-promotion .card-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.job-promotion .card-grid .cards {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 50%;
  text-align: center;
  min-width: 40%;
  min-height: 400px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  border-radius: 14px;
}
.job-promotion .card-grid .cards .loading-card {
  margin-top: -40px;
}
.job-promotion .card-grid .cards .loading-card h1 {
  font-size: 30px;
  font-weight: 600;
  color: #212121;
}
.job-promotion .card-grid .cards .loading-card p {
  font-size: 22px;
  font-weight: 500;
  color: #212121;
  margin: 2px 0px 30px 0px;
}
.job-promotion .card-grid .cards .loading-card .image img {
  max-width: 50%;
  max-height: 50%;
}
.job-promotion .card-grid .cards .success-card {
  padding-top: 50px;
}
.job-promotion .card-grid .cards .success-card h1 {
  font-size: 30px;
  font-weight: 600;
  color: #212121;
}
.job-promotion .card-grid .cards .success-card p {
  font-size: 22px;
  font-weight: 500;
  color: #212121;
  width: 80%;
  margin: auto;
  margin-bottom: 10px;
  line-height: 1.3;
}
.job-promotion .card-grid .cards .success-card .image img {
  max-width: 34%;
  max-height: 20%;
}
.job-promotion .card-grid .cards .success-card .button {
  margin-top: 30px;
  margin-bottom: 40px;
}
.job-promotion .card-grid .cards .success-card .button .submit-button {
  width: 50%;
  border: none;
  border-radius: 8px;
  padding: 6px;
  font-size: 16px;
  color: #ffffff;
  background: #0034bb;
  font-weight: 500;
  cursor: pointer;
}
.job-promotion .card-grid .cards .error-card {
  padding-top: 30px;
}
.job-promotion .card-grid .cards .error-card h1 {
  font-size: 30px;
  font-weight: 600;
  color: #212121;
}
.job-promotion .card-grid .cards .error-card p {
  font-size: 22px;
  font-weight: 500;
  color: #212121;
  width: 100%;
  margin: auto;
  margin-bottom: 25px;
}
.job-promotion .card-grid .cards .error-card .image img {
  max-width: 45%;
  max-height: 25%;
}
.job-promotion .card-grid .cards .error-card .button {
  margin-top: 30px;
  margin-bottom: 40px;
}
.job-promotion .card-grid .cards .error-card .button .submit-button {
  width: 70%;
  border: none;
  border-radius: 8px;
  padding: 6px;
  font-size: 16px;
  color: #ffffff;
  background: #0034bb;
  font-weight: 500;
  cursor: pointer;
}
.job-promotion .body {
  display: flex;
  gap: 0px;
}
.job-promotion .body .left-body {
  width: 62%;
}
.job-promotion .body .left-body .wrapper {
  height: 94vh;
  padding: 0px 30px 50px 10px;
  overflow-y: scroll;
}
.job-promotion .body .left-body .wrapper .title {
  font-size: 29px;
  font-weight: 600;
  color: #101828;
  margin-bottom: 18px;
}
.job-promotion .body .left-body .wrapper .question-title {
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 8px;
  margin-top: 2px;
  padding-left: 10px;
}
.job-promotion .body .left-body .wrapper .select {
  padding-right: 30px;
  padding-left: 10px;
}
.job-promotion .body .left-body .wrapper .select .MuiFormControl-root {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 4px 0;
  margin-bottom: 10px;
}
.job-promotion .body .left-body .wrapper .select .MuiFormControl-root .label {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
  margin-top: -4px;
  margin-left: 5px;
}
.job-promotion .body .left-body .wrapper .select .MuiFormControl-root .MuiInputBase-root {
  margin-top: 0px !important;
}
.job-promotion .body .left-body .wrapper .select .MuiFormControl-root .MuiInputBase-root {
  border: none;
}
.job-promotion .body .left-body .wrapper .select .MuiFormControl-root .MuiInputBase-root .MuiSvgIcon-root {
  margin-right: 10px;
}
.job-promotion .body .left-body .wrapper .select .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  padding: 5px 20px;
  font-size: 14px;
  background: transparent;
}
.job-promotion .body .left-body .wrapper .chips {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding-left: 20px;
  margin-bottom: 10px;
  padding-right: 20px;
}
.job-promotion .body .left-body .wrapper .chips .check-box {
  border-radius: 12px;
  border: 0.917px solid rgba(0, 0, 0, 0.12);
  padding: 1.5px 7px;
  padding-right: 15.4px;
  background: #fff;
  margin-bottom: 5px;
}
.job-promotion .body .left-body .wrapper .chips .check-box .MuiFormControlLabel-label {
  font-size: 12.4px;
  font-weight: 500;
  color: #374151;
}
.job-promotion .body .left-body .wrapper .button {
  margin-top: 20px;
  padding-right: 30px;
}
.job-promotion .body .left-body .wrapper .button .submit-button {
  width: 100%;
  border: none;
  border-radius: 8px;
  padding: 9px;
  font-size: 16px;
  color: #ffffff;
  background: #0034bb;
  font-weight: 500;
  cursor: pointer;
}
.job-promotion .body .right-body {
  width: 38%;
  padding: 60px 70px 0px 50px;
}
.job-promotion .body .right-body .job-card {
  width: 100%;
  background: #f5f7ff;
  border-radius: 12px;
  padding: 12px 25px 20px 31px;
}
.job-promotion .body .right-body .job-card .top {
  margin-bottom: -15px;
}
.job-promotion .body .right-body .job-card .top p {
  padding: 1px 14px;
  color: #027a48;
  border-radius: 5px;
  background: rgba(2, 122, 72, 0.17);
  width: fit-content;
  margin-left: auto;
  font-size: 12px;
  font-weight: 500;
}
.job-promotion .body .right-body .job-card .top .under-review {
  color: #bf5f08;
  background: rgba(191, 95, 8, 0.17);
}
.job-promotion .body .right-body .job-card .title {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 8px;
}
.job-promotion .body .right-body .job-card .detail {
  display: flex;
  gap: 10px;
}
.job-promotion .body .right-body .job-card .detail .logo {
  width: 64px;
  height: 60px;
  border-radius: 12px;
}
.job-promotion .body .right-body .job-card .detail .logo img {
  width: 100%;
  max-height: 100%;
  border-radius: 12px;
  object-fit: cover;
  margin: auto;
}
.job-promotion .body .right-body .job-card .detail .job-details h2 {
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  line-height: 1;
}
.job-promotion .body .right-body .job-card .detail .job-details h3 {
  font-size: 15px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.3);
  margin: 1px 0px;
  margin-bottom: 4px;
}
.job-promotion .body .right-body .job-card .detail .job-details .role {
  display: flex;
  align-items: center;
  gap: 7px;
}
.job-promotion .body .right-body .job-card .detail .job-details .role p {
  font-size: 10px;
  font-weight: 500;
  color: #6941c6;
  background: rgba(105, 65, 198, 0.13);
  padding: 2px 10px;
  border-radius: 4px;
}
.job-promotion .body .right-body .job-card .detail .job-details .role p:nth-child(2) {
  background: rgba(23, 92, 211, 0.18);
  color: rgb(23, 92, 211);
}
.job-promotion .body .right-body .estimate-card {
  background: rgb(245, 247, 255);
  padding: 25px 30px;
  border-radius: 16px;
  margin-top: 36px;
  padding-bottom: 40px;
  padding-right: 20px;
}
.job-promotion .body .right-body .estimate-card h1 {
  font-size: 25px;
  font-weight: 600;
  color: #212121;
  line-height: 1.4;
  margin-bottom: 15px;
}
.job-promotion .body .right-body .estimate-card .reach {
  margin-top: 8px;
}
.job-promotion .body .right-body .estimate-card .reach p {
  font-size: 20px;
  font-weight: 500;
  color: #212121;
}
.job-promotion .body .right-body .estimate-card .reach h1 {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 50px;
  font-weight: 500;
  color: #0034bb;
  line-height: 1.4;
  margin-bottom: -3px;
}
.job-promotion .body .right-body .fallback {
  position: relative;
  background: rgb(245, 247, 255);
  padding-top: 10px;
  border-radius: 16px;
  margin-top: 36px;
  text-align: center;
  padding-bottom: 10px;
}
.job-promotion .body .right-body .fallback h1 {
  font-size: 25px;
  font-weight: 600;
  color: #212121;
  top: 20px;
  line-height: 1.4;
  position: absolute;
  z-index: 1;
  left: 30px;
  right: 30px;
}
.job-promotion .body .right-body .fallback .image {
  width: 100%;
  height: 420px;
}
.job-promotion .body .right-body .fallback .image img {
  width: 90%;
  max-height: 100%;
}

.list-item:hover {
  background: #f5f7ff !important;
}

.list-item {
  background: #fff !important;
}

.job-promotion-v2 {
  padding: 40px;
  padding-bottom: 10px;
}
.job-promotion-v2 .back-btn {
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
}
.job-promotion-v2 .back-btn .MuiButton-startIcon {
  margin-right: 12px;
}
.job-promotion-v2 .promotion-body .loading-state,
.job-promotion-v2 .promotion-body .error-state {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 25px 35px;
  margin-top: 15px;
  max-height: 80vh;
  height: 77vh;
  overflow-y: scroll;
}
.job-promotion-v2 .promotion-body .loading-state .wrapper,
.job-promotion-v2 .promotion-body .error-state .wrapper {
  text-align: center;
  position: relative;
}
.job-promotion-v2 .promotion-body .loading-state .wrapper .heading,
.job-promotion-v2 .promotion-body .error-state .wrapper .heading {
  font-weight: 700;
  margin-top: -10px;
}
.job-promotion-v2 .promotion-body .loading-state .wrapper .sub,
.job-promotion-v2 .promotion-body .error-state .wrapper .sub {
  font-size: 14px;
  font-weight: 500;
  margin-top: 12px;
}
.job-promotion-v2 .promotion-body .promotion-data {
  position: relative;
}
.job-promotion-v2 .promotion-body .promotion-data .ai-says {
  padding: 1px;
  border-radius: 10px;
  margin-top: 25px;
  background: linear-gradient(to right bottom, #29af82, #d100e5, #ffcd6e, #0e086d);
}
.job-promotion-v2 .promotion-body .promotion-data .ai-says .container {
  background: #ffffff;
  border-radius: 8px;
  margin: 1px;
  padding: 19px 25px;
  margin-top: 0.5px;
}
.job-promotion-v2 .promotion-body .promotion-data .ai-says .container .head {
  display: flex;
  align-items: center;
  column-gap: 11px;
}
.job-promotion-v2 .promotion-body .promotion-data .ai-says .container .head .icon-wrapper {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 52, 187, 0.05);
}
.job-promotion-v2 .promotion-body .promotion-data .ai-says .container .head .heading {
  font-size: 19px;
  font-weight: 600;
  color: #0034bb;
}
.job-promotion-v2 .promotion-body .promotion-data .ai-says .container .body {
  font-size: 14px;
  color: #212121;
  margin-top: 17px;
}
.job-promotion-v2 .promotion-body .promotion-data .ai-says .container .body ul,
.job-promotion-v2 .promotion-body .promotion-data .ai-says .container .body ol {
  margin-left: 30px;
}
.job-promotion-v2 .promotion-body .promotion-data .ai-says .container .body ul ul,
.job-promotion-v2 .promotion-body .promotion-data .ai-says .container .body ul ol,
.job-promotion-v2 .promotion-body .promotion-data .ai-says .container .body ol ul,
.job-promotion-v2 .promotion-body .promotion-data .ai-says .container .body ol ol {
  margin-left: 30px;
}
.job-promotion-v2 .promotion-body .promotion-data .scores {
  margin-top: 26px;
}
.job-promotion-v2 .promotion-body .promotion-data .scores .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.job-promotion-v2 .promotion-body .promotion-data .scores .head .score-heading {
  font-size: 20px;
  font-weight: 600;
  color: #212121;
}
.job-promotion-v2 .promotion-body .promotion-data .scores .head .info {
  display: flex;
  align-items: center;
  column-gap: 4px;
  font-size: 14px;
  color: rgba(33, 33, 33, 0.6);
}
.job-promotion-v2 .promotion-body .promotion-data .scores .wrapper {
  margin-top: 14px;
  display: flex;
  align-items: center;
  column-gap: 19px;
}
.job-promotion-v2 .promotion-body .promotion-data .scores .wrapper .card {
  flex: 1;
  border-radius: 7px;
  padding: 23px 21px;
  border: 1.5px solid black;
}
.job-promotion-v2 .promotion-body .promotion-data .scores .wrapper .card .num {
  font-size: 30px;
  color: #212121;
}
.job-promotion-v2 .promotion-body .promotion-data .scores .wrapper .card .helper {
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
}
.job-promotion-v2 .promotion-body .promotion-data .scores .wrapper .first {
  border: 1.5px solid #005ac0;
  background: rgba(0, 90, 192, 0.1);
}
.job-promotion-v2 .promotion-body .promotion-data .scores .wrapper .second {
  border: 1.5px solid #028d77;
  background: rgba(2, 141, 119, 0.1);
}
.job-promotion-v2 .promotion-body .promotion-data .scores .wrapper .third {
  border: 1.5px solid #7151bf;
  background: rgba(113, 81, 191, 0.1);
}
.job-promotion-v2 .promotion-body .promotion-data .analytics {
  margin-top: 31px;
}
.job-promotion-v2 .promotion-body .promotion-data .sticky-cta {
  position: sticky;
  margin-left: -40px;
  margin-right: -40px;
  bottom: 0;
  padding: 10px 37px;
  border-top: 1px solid rgba(33, 33, 33, 0.2);
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.job-promotion-v2 .promotion-body .promotion-data .sticky-cta .cta-helper {
  font-weight: 500;
  color: #212121;
  text-transform: capitalize;
}
.job-promotion-v2 .promotion-body .promotion-data .sticky-cta .bottom-cta {
  border-radius: 7px;
  padding: 10px 18px;
  box-shadow: none;
}

.analytics .top-header {
  display: flex;
  align-items: center;
  justify-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 12px 20px;
}
.analytics .top-header .left {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 50%;
  padding-right: 40px;
}
.analytics .top-header .left .dots {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background: rgba(33, 33, 33, 0.6);
}
.analytics .top-header .left .icon {
  width: 24px;
  height: 24px;
  color: rgba(33, 33, 33, 0.6);
}
.analytics .top-header .left h3 {
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
  text-transform: capitalize;
}
.analytics .top-header .left h2 {
  width: 300px;
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.analytics .top-header .analytics-right {
  width: 50%;
  display: flex;
  align-items: center;
}
.analytics .top-header .analytics-right .switch {
  display: flex;
  align-content: center;
  border: 1px solid rgba(33, 33, 33, 0.09);
  border-radius: 8px;
  padding: 3px;
  margin-right: -6px;
}
.analytics .top-header .analytics-right .switch .button {
  outline: none;
  border: none;
  background: transparent;
  font-size: 16px;
  font-weight: 500;
  color: rgb(33, 33, 33);
  padding: 5px 20px;
  cursor: pointer;
}
.analytics .top-header .analytics-right .switch .active {
  background: rgb(0, 52, 187);
  border-radius: 8px;
  color: #fff;
}
.analytics .top-header .analytics-right .MuiFormControl-root {
  width: 100%;
  border: 1px solid rgba(33, 33, 33, 0.09);
  border-left: 0px;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 5.5px 0;
}
.analytics .top-header .analytics-right .MuiFormControl-root .label {
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
  margin-top: 22px;
  margin-left: 0px;
  z-index: -1;
}
.analytics .top-header .analytics-right .MuiFormControl-root .MuiInputBase-root {
  margin-top: 0px !important;
}
.analytics .top-header .analytics-right .MuiFormControl-root .MuiInputBase-root {
  border: none;
}
.analytics .top-header .analytics-right .MuiFormControl-root .MuiInputBase-root .MuiSvgIcon-root {
  margin-right: 10px;
}
.analytics .top-header .analytics-right .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  padding: 5px 20px;
  font-size: 14px;
  background: transparent;
}
.analytics .error-card {
  padding-top: 30px;
  width: 600px;
  margin: auto;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 20px 80px;
  margin-top: 10%;
}
.analytics .error-card h1 {
  font-size: 30px;
  font-weight: 600;
  color: #212121;
}
.analytics .error-card p {
  font-size: 22px;
  font-weight: 500;
  color: #212121;
  width: 100%;
  margin: auto;
  margin-bottom: 25px;
}
.analytics .error-card .image img {
  max-width: 45%;
  max-height: 25%;
}
.analytics .error-card .button {
  margin-top: 30px;
  margin-bottom: 40px;
}
.analytics .error-card .button .submit-button {
  width: 70%;
  border: none;
  border-radius: 8px;
  padding: 6px;
  font-size: 16px;
  color: #ffffff;
  background: #0034bb;
  font-weight: 500;
  cursor: pointer;
}
.analytics .bottom-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
}
.analytics .bottom-header .card-wrapper {
  display: flex;
  flex-direction: column;
  width: 32%;
  background: #e9effe;
  padding: 12px 20px;
  border-radius: 12px;
}
.analytics .bottom-header .card-wrapper .top {
  margin-bottom: 3px;
}
.analytics .bottom-header .card-wrapper .top h3 {
  font-size: 16px;
  font-weight: 400;
  color: #212121;
}
.analytics .bottom-header .card-wrapper .card-wrapper-bottom {
  display: flex;
  align-items: center;
  gap: 20px;
}
.analytics .bottom-header .card-wrapper .card-wrapper-bottom h1 {
  font-size: 37px;
  font-weight: 600;
  color: #212121;
}
.analytics .bottom-header .card-wrapper .card-wrapper-bottom .image {
  width: 100%;
}
.analytics .bottom-header .card-wrapper .card-wrapper-bottom .image img {
  max-width: 100%;
  max-height: 100%;
}
.analytics .bottom-header .card-wrapper:nth-child(2) {
  background: rgb(233, 243, 254);
}
.analytics .body .fallback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  height: 60%;
}
.analytics .body .fallback h4 {
  font-size: 15px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
  margin-top: 15px;
  text-align: center;
}
.analytics .body .analytics-switch {
  text-align: right;
  margin-right: 25px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 10px;
}
.analytics .body .analytics-switch .switch {
  display: flex;
  align-items: center;
  justify-content: left;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: fit-content;
  margin-left: 20px;
  border-radius: 8px;
  overflow: hidden;
  padding: 7px 10px;
}
.analytics .body .analytics-switch .switch p {
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 7px 15px;
  padding-right: 15px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}
.analytics .body .analytics-switch .switch p .icon {
  width: 23px;
  height: 23px;
}
.analytics .body .analytics-switch .switch .active {
  background: #0034bb;
  color: #ffffff;
  border-radius: 4px;
}
.analytics .body .promotion-analytics {
  display: flex;
  gap: 2%;
  flex-wrap: wrap;
  padding: 12px 20px;
}
.analytics .body .promotion-analytics .university-card {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 52%;
  margin-bottom: 20px;
  min-height: 330px;
}
.analytics .body .promotion-analytics .university-card h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 13px;
}
.analytics .body .promotion-analytics .university-card .wrapper {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
}
.analytics .body .promotion-analytics .university-card .wrapper .university-card-left {
  width: 47%;
}
.analytics .body .promotion-analytics .university-card .wrapper .university-card-left .list {
  display: flex;
  align-content: center;
  gap: 30px;
  margin-bottom: 12px;
}
.analytics .body .promotion-analytics .university-card .wrapper .university-card-left .list p {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
  text-align: left;
}
.analytics .body .promotion-analytics .university-card .wrapper .university-card-left .list p:nth-child(2) {
  width: 90%;
}
.analytics .body .promotion-analytics .university-card .wrapper .university-card-left .list button {
  border: none;
  background: rgb(233, 239, 254);
  color: #0034bb;
  padding: 4px 10px;
  outline: none;
  border-radius: 6px;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  height: 19px;
}
.analytics .body .promotion-analytics .experience {
  width: 58%;
}
.analytics .body .promotion-analytics .source-ai {
  width: 45.5%;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  min-height: 330px;
}
.analytics .body .promotion-analytics .source-ai .source {
  background: #fafbfb;
  padding: 22px 20px;
  border-radius: 16px;
  width: 47%;
}
.analytics .body .promotion-analytics .source-ai .source h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.analytics .body .promotion-analytics .source-ai .source .wrapper .pie-legend {
  text-align: center;
  margin-top: 15px;
}
.analytics .body .promotion-analytics .source-ai .source .wrapper .pie-legend .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  margin: auto;
  margin-bottom: 12px;
}
.analytics .body .promotion-analytics .source-ai .source .wrapper .pie-legend .pie-legend-list .pie-legend-list-item {
  display: flex;
  align-items: center;
  gap: 5px;
}
.analytics .body .promotion-analytics .source-ai .source .wrapper .pie-legend .pie-legend-list .pie-legend-list-item .dots {
  width: 6px;
  height: 6px;
  border-radius: 100%;
}
.analytics .body .promotion-analytics .source-ai .source .wrapper .pie-legend .pie-legend-list .pie-legend-list-item p {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.analytics .body .promotion-analytics .source-ai .source .wrapper .pie-legend .pie-legend-list span {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.analytics .body .promotion-analytics .source-ai .ai {
  min-height: 300px;
  width: 54%;
  border-radius: 12px;
  padding: 20px;
  background: #212121;
  position: relative;
  overflow: hidden;
  z-index: 0;
  padding-bottom: 0px;
}
.analytics .body .promotion-analytics .source-ai .ai h2 {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.analytics .body .promotion-analytics .source-ai .ai h2 .star {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-left: 3px;
}
.analytics .body .promotion-analytics .source-ai .ai .blue {
  background: #214cbc;
  width: 110px;
  height: 110px;
  position: absolute;
  z-index: -1;
  filter: blur(50px);
  bottom: -10px;
  left: -20px;
}
.analytics .body .promotion-analytics .source-ai .ai .light-blue {
  background: #214cbc;
  width: 270px;
  height: 300px;
  position: absolute;
  z-index: -1;
  filter: blur(60px);
  top: -20px;
  right: -50px;
  border-radius: 29% 21% 30% 30%/30% 30% 70% 70%;
}
.analytics .body .promotion-analytics .source-ai .ai .white {
  width: 140px;
  height: 140px;
  position: absolute;
  z-index: -1;
  background: white;
  filter: blur(60px);
  top: -20px;
  left: 0px;
}
.analytics .body .promotion-analytics .source-ai .ai .container {
  text-align: center;
  padding: 2px 20px;
  position: relative;
  z-index: 2;
}
.analytics .body .promotion-analytics .source-ai .ai .container .mySwiper {
  width: 100%;
  height: auto;
  height: 270px;
}
.analytics .body .promotion-analytics .source-ai .ai .container .mySwiper .swiper-pagination-bullet-active {
  background-color: #ffffff !important;
}
.analytics .body .promotion-analytics .source-ai .ai .container .mySwiper .swiper-pagination-bullet {
  background-color: transparent;
  border: 1px solid #ffffff !important;
  opacity: 1 !important;
}
.analytics .body .promotion-analytics .source-ai .ai .container .mySwiper .ai-card {
  width: 100%;
  height: 240px;
  overflow-y: scroll;
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
}
.analytics .body .promotion-analytics .source-ai .ai .container .mySwiper .ai-card p {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}
.analytics .body .promotion-analytics .source-ai .ai .container .mySwiper .ai-card button {
  background: transparent;
  border: none;
  outline: none;
  border-radius: 7px;
  background: #ffffff;
  border: none;
  padding: 6px 22px;
  font-size: 14px;
  font-weight: 400;
  color: #2b385b;
  cursor: pointer;
}
.analytics .body .promotion-analytics .source-ai .ai .container p {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 20px;
}
.analytics .body .promotion-analytics .line {
  padding: 22px 10px;
  background: #fafbfb;
  border-radius: 16px;
  width: 35%;
  margin-bottom: 20px;
  min-height: 330px;
  padding-bottom: 0px;
}
.analytics .body .promotion-analytics .line h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
  padding-left: 10px;
}
.analytics .body .promotion-analytics .line .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 100%;
  margin: auto;
  margin-top: -10px;
}
.analytics .body .promotion-analytics .line .pie-legend-list .pie-legend-list-item {
  display: flex;
  align-items: center;
  gap: 5px;
}
.analytics .body .promotion-analytics .line .pie-legend-list .pie-legend-list-item .dots {
  width: 6px;
  height: 6px;
  border-radius: 100%;
}
.analytics .body .promotion-analytics .line .pie-legend-list .pie-legend-list-item p {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.analytics .body .promotion-analytics .line .pie-legend-list span {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.analytics .body .promotion-analytics .job-function {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 35%;
  margin-bottom: 20px;
  padding-bottom: 0px;
  min-height: 330px;
}
.analytics .body .promotion-analytics .job-function .chart-icon {
  text-align: right;
  margin-top: -6px;
  margin-bottom: -17px;
}
.analytics .body .promotion-analytics .job-function .chart-icon .icon {
  width: 16px;
  height: 16px;
  color: #0034bb;
}
.analytics .body .promotion-analytics .job-function h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.analytics .body .promotion-analytics .job-function .wrapper {
  display: flex;
  justify-content: space-between;
}
.analytics .body .promotion-analytics .job-function .wrapper .wrapper-list {
  width: 45%;
}
.analytics .body .promotion-analytics .job-function .wrapper .wrapper-list .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.analytics .body .promotion-analytics .job-function .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.analytics .body .promotion-analytics .job-function .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item .dots {
  width: 13px;
  height: 13px;
  border-radius: 50%;
}
.analytics .body .promotion-analytics .job-function .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item p {
  width: 90%;
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.analytics .body .promotion-analytics .job-function-year {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 25%;
  margin-bottom: 20px;
  min-height: 330px;
}
.analytics .body .promotion-analytics .job-function-year .chart-icon {
  text-align: right;
  margin-top: -6px;
  margin-bottom: -17px;
}
.analytics .body .promotion-analytics .job-function-year .chart-icon .icon {
  width: 16px;
  height: 16px;
  color: #0034bb;
}
.analytics .body .promotion-analytics .job-function-year h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.analytics .body .promotion-analytics .job-function-year .chart {
  height: 140px;
  width: 100%;
}
.analytics .body .promotion-analytics .job-function-year .chart div div div div div span:first-child {
  border-radius: 50%;
  width: 8px !important;
  height: 8px !important;
}
.analytics .body .promotion-analytics .job-function-year .wrapper-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
  padding-left: 15px;
  margin-top: 5px;
}
.analytics .body .promotion-analytics .job-function-year .wrapper-list .pie-legend-list {
  display: flex;
  align-items: center;
  flex: 0 0 33.333333%;
  margin-bottom: 10px;
}
.analytics .body .promotion-analytics .job-function-year .wrapper-list .pie-legend-list .pie-legend-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.analytics .body .promotion-analytics .job-function-year .wrapper-list .pie-legend-list .pie-legend-list-item .dots {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.analytics .body .promotion-analytics .job-function-year .wrapper-list .pie-legend-list .pie-legend-list-item p {
  font-size: 14px;
  font-weight: 400;
  color: rgb(64, 64, 64);
}
.analytics .body .promotion-analytics .bar-graph {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 61.5%;
  margin-bottom: 20px;
  min-height: 330px;
}
.analytics .body .promotion-analytics .bar-graph h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}
.analytics .body .promotion-analytics .bar-graph .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 40px;
  width: 100%;
  margin: auto;
  margin-bottom: 12px;
  margin-top: -15px;
}
.analytics .body .promotion-analytics .bar-graph .pie-legend-list .pie-legend-list-item {
  display: flex;
  align-items: center;
  gap: 5px;
}
.analytics .body .promotion-analytics .bar-graph .pie-legend-list .pie-legend-list-item .dots {
  width: 6px;
  height: 6px;
  border-radius: 100%;
}
.analytics .body .promotion-analytics .bar-graph .pie-legend-list .pie-legend-list-item p {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.analytics .body .promotion-analytics .bar-graph .pie-legend-list span {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.analytics .body .promotion-analytics .job-function-diversity {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 35%;
  margin-bottom: 20px;
  min-height: 330px;
}
.analytics .body .promotion-analytics .job-function-diversity .chart-icon {
  text-align: right;
  margin-top: -6px;
  margin-bottom: -17px;
}
.analytics .body .promotion-analytics .job-function-diversity .chart-icon .icon {
  width: 16px;
  height: 16px;
  color: #0034bb;
}
.analytics .body .promotion-analytics .job-function-diversity h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.analytics .body .promotion-analytics .job-function-diversity .wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 20px;
}
.analytics .body .promotion-analytics .job-function-diversity .wrapper .wrapper-list {
  width: 45%;
}
.analytics .body .promotion-analytics .job-function-diversity .wrapper .wrapper-list .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.analytics .body .promotion-analytics .job-function-diversity .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.analytics .body .promotion-analytics .job-function-diversity .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item .dots {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.analytics .body .promotion-analytics .job-function-diversity .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item p {
  width: 95%;
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.analytics .body .promotion-analytics .job-function-diversity .wrapper .wrapper-list .pie-legend-list span {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.analytics .body .promotion-analytics .gpa-ai {
  width: 45.5%;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  min-height: 330px;
}
.analytics .body .promotion-analytics .gpa-ai .gpa {
  background: #fafbfb;
  padding: 22px 25px;
  border-radius: 16px;
  width: 39%;
}
.analytics .body .promotion-analytics .gpa-ai .gpa .gpa-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.analytics .body .promotion-analytics .gpa-ai .gpa .gpa-top h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 5px;
}
.analytics .body .promotion-analytics .gpa-ai .gpa .gpa-contain {
  height: 264px;
  overflow-y: scroll;
}
.analytics .body .promotion-analytics .gpa-ai .gpa .gpa-contain .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 10px 10px;
}
.analytics .body .promotion-analytics .gpa-ai .gpa .gpa-contain .item p {
  font-size: 12px;
  color: #212121;
  font-weight: 400;
}
.analytics .body .promotion-analytics .gpa-ai .gpa .gpa-contain .item p:nth-child(2) {
  font-size: 13px;
  font-weight: 600;
  color: #0034bb;
}
.analytics .body .promotion-analytics .gpa-ai .gpa .gpa-contain .item:last-child {
  border-bottom: none;
}
.analytics .body .promotion-analytics .gpa-ai .gpa .gpa-contain::-webkit-scrollbar {
  width: 0px;
}
.analytics .body .promotion-analytics .gpa-ai .ai {
  min-height: 300px;
  width: 56%;
  border-radius: 12px;
  padding: 20px;
  background: #212121;
  position: relative;
  overflow: hidden;
  z-index: 0;
  padding-bottom: 0px;
}
.analytics .body .promotion-analytics .gpa-ai .ai h2 {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.analytics .body .promotion-analytics .gpa-ai .ai h2 .star {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-left: 3px;
}
.analytics .body .promotion-analytics .gpa-ai .ai .blue {
  background: #214cbc;
  width: 110px;
  height: 110px;
  position: absolute;
  z-index: -1;
  filter: blur(50px);
  bottom: -10px;
  left: -20px;
}
.analytics .body .promotion-analytics .gpa-ai .ai .light-blue {
  background: #214cbc;
  width: 270px;
  height: 300px;
  position: absolute;
  z-index: -1;
  filter: blur(60px);
  top: -20px;
  right: -50px;
  border-radius: 29% 21% 30% 30%/30% 30% 70% 70%;
}
.analytics .body .promotion-analytics .gpa-ai .ai .white {
  width: 140px;
  height: 140px;
  position: absolute;
  z-index: -1;
  background: white;
  filter: blur(60px);
  top: -20px;
  left: 0px;
}
.analytics .body .promotion-analytics .gpa-ai .ai .container {
  text-align: center;
  padding: 2px 20px;
  position: relative;
  z-index: 2;
}
.analytics .body .promotion-analytics .gpa-ai .ai .container .mySwiper {
  width: 100%;
  height: auto;
  height: 270px;
}
.analytics .body .promotion-analytics .gpa-ai .ai .container .mySwiper .swiper-pagination-bullet-active {
  background-color: #ffffff !important;
}
.analytics .body .promotion-analytics .gpa-ai .ai .container .mySwiper .swiper-pagination-bullet {
  background-color: transparent;
  border: 1px solid #ffffff !important;
  opacity: 1 !important;
}
.analytics .body .promotion-analytics .gpa-ai .ai .container .mySwiper .ai-card {
  width: 100%;
  height: 240px;
  overflow-y: scroll;
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
}
.analytics .body .promotion-analytics .gpa-ai .ai .container .mySwiper .ai-card p {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}
.analytics .body .promotion-analytics .gpa-ai .ai .container .mySwiper .ai-card button {
  background: transparent;
  border: none;
  outline: none;
  border-radius: 7px;
  background: #ffffff;
  border: none;
  padding: 6px 22px;
  font-size: 14px;
  font-weight: 400;
  color: #2b385b;
  cursor: pointer;
}
.analytics .body .promotion-analytics .gpa-ai .ai .container p {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 20px;
}
.analytics .body .promotion-analytics .current-level {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 33%;
  margin-bottom: 20px;
  min-height: 330px;
}
.analytics .body .promotion-analytics .current-level .chart-icon {
  text-align: right;
  margin-top: -6px;
  margin-bottom: -17px;
}
.analytics .body .promotion-analytics .current-level .chart-icon .icon {
  width: 16px;
  height: 16px;
  color: #0034bb;
}
.analytics .body .promotion-analytics .current-level h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.analytics .body .promotion-analytics .current-level .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 15px;
}
.analytics .body .promotion-analytics .current-level .wrapper .wrapper-list {
  width: 45%;
  margin: auto;
  margin-top: -20px;
}
.analytics .body .promotion-analytics .current-level .wrapper .wrapper-list .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.analytics .body .promotion-analytics .current-level .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.analytics .body .promotion-analytics .current-level .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item .dots {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.analytics .body .promotion-analytics .current-level .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item p {
  width: 95%;
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.analytics .body .promotion-analytics .current-level .wrapper .wrapper-list .pie-legend-list span {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.analytics .body .promotion-analytics .analytics-diversity {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 35%;
  margin-bottom: 20px;
  padding-right: 40px;
  min-height: 330px;
}
.analytics .body .promotion-analytics .analytics-diversity .chart-icon {
  text-align: right;
  margin-top: -6px;
  margin-bottom: -17px;
}
.analytics .body .promotion-analytics .analytics-diversity .chart-icon .icon {
  width: 16px;
  height: 16px;
  color: #0034bb;
}
.analytics .body .promotion-analytics .analytics-diversity h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.analytics .body .promotion-analytics .analytics-diversity .wrapper {
  display: flex;
  gap: 30px;
  margin-top: 30px;
}
.analytics .body .promotion-analytics .analytics-diversity .wrapper .wrapper-list {
  width: 35%;
  margin-right: 50px;
}
.analytics .body .promotion-analytics .analytics-diversity .wrapper .wrapper-list .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.analytics .body .promotion-analytics .analytics-diversity .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.analytics .body .promotion-analytics .analytics-diversity .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item .dots {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.analytics .body .promotion-analytics .analytics-diversity .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item p {
  width: 95%;
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.analytics .body .promotion-analytics .analytics-diversity .wrapper .wrapper-list .pie-legend-list span {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.analytics .body .promotion-analytics .top-rated {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 28%;
  margin-bottom: 20px;
  min-height: 330px;
}
.analytics .body .promotion-analytics .top-rated h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.analytics .body .promotion-analytics .top-rated .top-rated-wrapper {
  height: 244px;
  overflow-y: scroll;
}
.analytics .body .promotion-analytics .top-rated .top-rated-wrapper ::-webkit-scrollbar {
  width: 0px;
}
.analytics .body .promotion-analytics .top-rated .top-rated-wrapper .top-rated-wrapper-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  padding: 10px 10px;
  border-radius: 12px;
  margin-bottom: 10px;
}
.analytics .body .promotion-analytics .top-rated .top-rated-wrapper .top-rated-wrapper-card .top-rated-wrapper-card-left {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 5px;
}
.analytics .body .promotion-analytics .top-rated .top-rated-wrapper .top-rated-wrapper-card .top-rated-wrapper-card-left .avatar {
  width: 20px;
  height: 20px;
  font-size: 12px;
}
.analytics .body .promotion-analytics .top-rated .top-rated-wrapper .top-rated-wrapper-card .top-rated-wrapper-card-left .details h4 {
  font-size: 8px;
  font-weight: 400;
  color: #212121;
}
.analytics .body .promotion-analytics .top-rated .top-rated-wrapper .top-rated-wrapper-card .top-rated-wrapper-card-left .details p {
  font-size: 8px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.4);
}
.analytics .body .promotion-analytics .top-rated .top-rated-wrapper .top-rated-wrapper-card .top-rated-wrapper-card-right {
  width: 50%;
  text-align: right;
}
.analytics .body .promotion-analytics .top-rated .top-rated-wrapper .top-rated-wrapper-card .top-rated-wrapper-card-right p {
  font-size: 8px;
  font-weight: 700;
  color: #0034bb;
}
.analytics .body .promotion-analytics .ava-time {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 20%;
  margin-bottom: 20px;
  min-height: 330px;
  text-align: center;
}
.analytics .body .promotion-analytics .ava-time h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
  margin-top: 20px;
}
.analytics .body .promotion-analytics .university-partner {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 51%;
  margin-bottom: 20px;
  min-height: 330px;
  padding-bottom: 5px;
}
.analytics .body .promotion-analytics .university-partner h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.analytics .body .promotion-analytics .ana-job-function {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 40%;
  margin-bottom: 20px;
  padding-bottom: 0px;
  min-height: 330px;
}
.analytics .body .promotion-analytics .ana-job-function .chart-icon {
  text-align: right;
  margin-top: -6px;
  margin-bottom: -17px;
}
.analytics .body .promotion-analytics .ana-job-function .chart-icon .icon {
  width: 16px;
  height: 16px;
  color: #0034bb;
}
.analytics .body .promotion-analytics .ana-job-function h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.analytics .body .promotion-analytics .ana-job-function .wrapper {
  display: flex;
  justify-content: space-between;
}
.analytics .body .promotion-analytics .ana-job-function .wrapper .wrapper-list {
  width: 45%;
}
.analytics .body .promotion-analytics .ana-job-function .wrapper .wrapper-list .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.analytics .body .promotion-analytics .ana-job-function .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.analytics .body .promotion-analytics .ana-job-function .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item .dots {
  width: 13px;
  height: 13px;
  border-radius: 50%;
}
.analytics .body .promotion-analytics .ana-job-function .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item p {
  width: 90%;
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.analytics .MuiChartsAxis-tick,
.analytics .MuiChartsAxis-line {
  display: none;
}

.gpa-menu .MuiMenu-paper .MuiList-root .MuiButtonBase-root {
  margin: 10px;
  border-radius: 10px;
}
.gpa-menu .MuiMenu-paper .MuiList-root .MuiButtonBase-root:hover {
  background: rgba(0, 52, 187, 0.05);
}

.lists-and-pie-v2 {
  min-height: 420px;
  border-radius: 18.655px;
  width: 100%;
  padding: 30px 20px;
  padding-bottom: 30px;
  gap: 50px;
  padding-right: 50px;
  margin-bottom: 20px;
}
.lists-and-pie-v2 h2 {
  font-size: 21px;
  font-weight: 600;
  color: #212121;
}
.lists-and-pie-v2 .body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}
.lists-and-pie-v2 .body .lists-pie {
  height: 350px;
}
.lists-and-pie-v2 .body .lists-pie div div div div div span:first-child {
  border-radius: 50%;
  width: 10px !important;
  height: 10px !important;
}
.lists-and-pie-v2 .body .lists .lists-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow-y: scroll;
  height: 340px;
  justify-content: space-between;
}
.lists-and-pie-v2 .body .lists .lists-container .item {
  width: 50%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  /* Add a border to the bottom of each row */
}
.lists-and-pie-v2 .body .lists .lists-container .item .item-list {
  padding-left: 0px;
  transition: all 0.5s;
}
.lists-and-pie-v2 .body .lists .lists-container .item .item-list .list-name-v2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 14px;
  cursor: pointer;
  gap: 15px;
}
.lists-and-pie-v2 .body .lists .lists-container .item .item-list .list-name-v2 .name {
  display: flex;
  gap: 10px;
}
.lists-and-pie-v2 .body .lists .lists-container .item .item-list .list-name-v2 .name p {
  font-size: 12px;
  font-weight: 400;
  color: rgb(33, 33, 33);
}
.lists-and-pie-v2 .body .lists .lists-container .item .item-list .list-name-v2 .name .uni {
  font-size: 12px;
  font-weight: 400;
  color: rgb(33, 33, 33);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.lists-and-pie-v2 .body .lists .lists-container .item .item-list .list-name-v2 .button {
  background: transparent;
  border: none;
  outline: none;
  padding: 4px 10px;
  border-radius: 6px;
  font-size: 11px;
  font-weight: 600;
  color: rgb(0, 52, 187);
  background: rgb(233, 239, 254);
  min-width: 60px;
}
.lists-and-pie-v2 .body .lists .lists-container .item .item-list .list-name-v2:hover {
  background: rgba(0, 52, 187, 0.09);
  border-radius: 9px;
}
.lists-and-pie-v2 .body .lists .lists-container .item .active {
  background: rgba(0, 52, 187, 0.09);
  border-radius: 9px;
}

.lists-and-pie-v2-center .body {
  width: 70% !important;
  margin: auto !important;
  padding: 40px 0px !important;
  padding-top: 50px !important;
}
.lists-and-pie-v2-center .body .lists-pie {
  width: 62% !important;
}
.lists-and-pie-v2-center .body .lists {
  width: 33% !important;
}
.lists-and-pie-v2-center .body .lists .lists-container .item {
  width: 100%;
}

.metrics-v2 {
  padding: 30px 20px;
  padding-right: 0px;
  border-radius: 18.655px;
  margin-bottom: 20px;
  width: 100%;
}
.metrics-v2 h2 {
  font-size: 21px;
  font-weight: 600;
  color: #212121;
}
.metrics-v2 .body {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}
.metrics-v2 .body .gpa {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  position: relative;
  height: 320px;
}
.metrics-v2 .body .gpa div div div div div span:first-child {
  border-radius: 50%;
  width: 10px !important;
  height: 10px !important;
}
.metrics-v2 .body .gpa .gpa-chart {
  width: 70%;
  height: 320px;
}
.metrics-v2 .body .gpa .center {
  position: absolute;
  left: 31%;
  font-size: 17px;
  font-weight: 400;
  color: rgb(33, 33, 33);
}
.metrics-v2 .body .gpa .gmat {
  left: 30% !important;
}
.metrics-v2 .body .gpa .gpa-legend {
  width: 29%;
  height: 220px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.metrics-v2 .body .gpa .gpa-legend .legend {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 7px 14px;
}
.metrics-v2 .body .gpa .gpa-legend .legend .dots {
  width: 13px;
  height: 13px;
  border-radius: 50%;
}
.metrics-v2 .body .gpa .gpa-legend .legend p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.metrics-v2 .body .gpa .gpa-legend .legend:hover {
  background: rgba(0, 52, 187, 0.09);
  border-radius: 9px;
  width: fit-content;
}
.metrics-v2 .body .gpa .gpa-legend .active {
  background: rgba(0, 52, 187, 0.09);
  border-radius: 9px;
  width: fit-content;
}

.level-v2 {
  padding: 30px 20px;
  padding-right: 0px;
  border-radius: 18.655px;
  margin-bottom: 30px;
  width: 100%;
}
.level-v2 h2 {
  font-size: 23px;
  font-weight: 600;
  color: #212121;
}
.level-v2 .body {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.level-v2 .body .level-pie {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.level-v2 .body .level-pie .level-chart {
  width: 70%;
  height: 320px;
}
.level-v2 .body .level-pie .level-chart div div div div div span:first-child {
  border-radius: 50%;
  width: 10px !important;
  height: 10px !important;
}
.level-v2 .body .level-pie .legend {
  width: 30%;
  display: flex;
  flex-direction: column;
}
.level-v2 .body .level-pie .legend h1 {
  font-size: 14.5px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 5px;
  padding-left: 12px;
}
.level-v2 .body .level-pie .legend .level-legend {
  height: 220px;
  width: fit-content;
  overflow-y: scroll;
}
.level-v2 .body .level-pie .legend .level-legend .item-legend {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
  border-radius: 7px;
  padding: 5px 15px;
}
.level-v2 .body .level-pie .legend .level-legend .item-legend .dots {
  width: 13px;
  height: 13px;
  border-radius: 50%;
}
.level-v2 .body .level-pie .legend .level-legend .item-legend p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.level-v2 .body .level-pie .legend .level-legend .item-legend:hover {
  background: rgba(0, 52, 187, 0.09);
  border-radius: 9px;
  width: fit-content;
}
.level-v2 .body .level-pie .legend .level-legend .active {
  background: rgba(0, 52, 187, 0.09);
  border-radius: 9px;
  width: fit-content;
}
.level-v2 .body .level-list {
  padding: 40px 10%;
  margin-top: 25px;
}
.level-v2 .body .level-list .title {
  font-size: 15px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  margin-bottom: 10px;
  padding-left: 12px;
}
.level-v2 .body .level-list .level-list-body {
  height: 250px;
  overflow-y: scroll;
  padding-right: 20px;
}
.level-v2 .body .level-list .level-list-body .level-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3px;
  padding: 7px 14px;
}
.level-v2 .body .level-list .level-list-body .level-list-item .list-left {
  display: flex;
  align-items: center;
  gap: 6px;
}
.level-v2 .body .level-list .level-list-body .level-list-item .list-left p {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.level-v2 .body .level-list .level-list-body .level-list-item button {
  outline: none;
  border: none;
  color: rgb(0, 52, 187);
  background: rgb(233, 239, 254);
  font-size: 11px;
  font-weight: 600;
  padding: 4px 15px;
  border-radius: 6px;
  min-width: 70px;
}
.level-v2 .body .level-list .level-list-body .level-list-item:hover {
  background: rgba(0, 52, 187, 0.09);
  border-radius: 9px;
}

.only-list-v2 {
  padding-right: 20px;
  border-radius: 18.655px;
  margin-bottom: 20px;
  width: 100%;
}
.only-list-v2 .title {
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 23px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 20px;
}
.only-list-v2 .only-list-body {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}
.only-list-v2 .only-list-body .list-container {
  padding: 25px 30px;
  border-radius: 18px;
}
.only-list-v2 .only-list-body .list-container .container-title {
  font-size: 14.3px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 6px;
}
.only-list-v2 .only-list-body .list-container .container-body {
  height: 345px;
  overflow-y: scroll;
  padding: 5px 0px;
}
.only-list-v2 .only-list-body .list-container .container-body .wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5px 10px;
  margin-bottom: 3px;
}
.only-list-v2 .only-list-body .list-container .container-body .wrapper .body-left {
  display: flex;
  gap: 6px;
}
.only-list-v2 .only-list-body .list-container .container-body .wrapper .body-left p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.only-list-v2 .only-list-body .list-container .container-body .wrapper .body-left .uni-list {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
}
.only-list-v2 .only-list-body .list-container .container-body .wrapper button {
  outline: none;
  border: none;
  color: rgb(0, 52, 187);
  background: rgb(233, 239, 254);
  font-size: 11px;
  font-weight: 600;
  padding: 4px 0px;
  border-radius: 6px;
  text-align: center;
  min-width: 60px;
}
.only-list-v2 .only-list-body .list-container .container-body .wrapper:hover {
  background: rgba(0, 52, 187, 0.09);
  border-radius: 9px;
}
.only-list-v2 .only-list-body .without-number .container-body .wrapper .body-left {
  gap: 50px;
}

.fallback-message {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  width: 100%;
  margin-top: 15%;
}
.fallback-message p {
  font-size: 20px;
  font-weight: 500;
  color: #6b7280;
}

.custom-tooltip {
  position: relative;
  background: #ffffff;
  padding: 7px 20px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  left: 120px;
  gap: 5px;
}
.custom-tooltip .dots {
  width: 12px;
  height: 12px;
  border-radius: 100%;
}
.custom-tooltip p {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 400;
  color: #212121;
}
.custom-tooltip p span {
  font-weight: 600;
  font-size: 16px;
  color: #212121;
}

.candidates-v2 {
  padding-left: 40px;
}
.candidates-v2 .top-header {
  padding-top: 30px;
}
.candidates-v2 .top-header .breadcrumb {
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 15px;
}
.candidates-v2 .top-header .breadcrumb .dots {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: rgba(33, 33, 33, 0.6);
}
.candidates-v2 .top-header .breadcrumb p {
  padding: 6px 10px;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
}
.candidates-v2 .top-header .breadcrumb p:last-child {
  color: #212121;
}
.candidates-v2 .top-header .breadcrumb p:last-child:hover {
  background: none;
}
.candidates-v2 .top-header .breadcrumb p:hover {
  background: rgba(0, 52, 187, 0.05);
}
.candidates-v2 .top-header h2 {
  font-size: 24px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 25px;
  margin-top: 10px;
}
.candidates-v2 .top-header .status {
  margin-bottom: 25px;
}
.candidates-v2 .top-header .status h4 {
  font-size: 14px;
  font-weight: 400;
  color: rgb(33, 33, 33);
  margin-bottom: 5px;
}
.candidates-v2 .top-header .status .top-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.candidates-v2 .top-header .status .top-row .select {
  width: 57.6%;
}
.candidates-v2 .top-header .status .top-row .select .MuiFormControl-root {
  width: 100%;
  border-radius: 8px;
  background: #ffffff;
}
.candidates-v2 .top-header .status .top-row .select .MuiFormControl-root .MuiInputBase-root {
  border: none;
}
.candidates-v2 .top-header .status .top-row .select .MuiFormControl-root .MuiInputBase-root .MuiSvgIcon-root {
  margin-right: 10px;
  color: #000000;
  font-size: 25px;
}
.candidates-v2 .top-header .status .top-row .select .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  font-size: 16px;
  background: transparent;
  padding: 10px 16px !important;
  font-weight: 500;
  color: rgb(33, 33, 33);
}
.candidates-v2 .top-header .status .top-row .select .item {
  display: flex;
  gap: 40px;
}
.candidates-v2 .top-header .status .top-row .btn-group {
  margin-right: 40px;
}
.candidates-v2 .top-header .status .top-row .btn-group button {
  padding: 9px 18px;
}
.candidates-v2 .body {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-right: 40px;
  gap: 25px;
}
.candidates-v2 .body .candidates-v2-left {
  width: 60%;
}
.candidates-v2 .body .candidates-v2-left .user-card {
  background: #ffffff;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 25px 25px;
}
.candidates-v2 .body .candidates-v2-left .user-card .user-top {
  display: flex;
  justify-content: space-between;
}
.candidates-v2 .body .candidates-v2-left .user-card .user-top .user-left {
  display: flex;
  gap: 10px;
}
.candidates-v2 .body .candidates-v2-left .user-card .user-top .user-left .avatar {
  width: 45px;
  height: 45px;
}
.candidates-v2 .body .candidates-v2-left .user-card .user-top .user-left .name h2 {
  font-size: 18px;
  font-weight: 700;
  color: #000000;
}
.candidates-v2 .body .candidates-v2-left .user-card .user-top .user-left .name h3 {
  font-size: 14px;
  font-weight: 500;
  color: rgb(33, 33, 33);
  margin: 1px 0px;
}
.candidates-v2 .body .candidates-v2-left .user-card .user-top .user-left .name p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}
.candidates-v2 .body .candidates-v2-left .user-card .user-top .user-right p {
  font-size: 14px;
  font-weight: 500;
  color: rgb(33, 33, 33);
  margin-bottom: 5px;
  text-align: right;
}
.candidates-v2 .body .candidates-v2-left .user-card .buttons {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 25px;
}
.candidates-v2 .body .candidates-v2-left .user-card .buttons button {
  display: flex;
  align-items: center;
  gap: 5px;
  outline: none;
  border: none;
  background: transparent;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 7px 18px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  cursor: pointer;
}
.candidates-v2 .body .candidates-v2-left .user-card .buttons button .icon {
  width: 20px;
  height: 20px;
  color: rgb(0, 52, 187);
}
.candidates-v2 .body .candidates-v2-left .user-card .buttons button:hover {
  background: rgba(0, 52, 187, 0.05);
}
.candidates-v2 .body .candidates-v2-left .user-card .score {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-top: 18px;
  border-top: 1px solid rgba(33, 33, 33, 0.2);
  padding-top: 18px;
}
.candidates-v2 .body .candidates-v2-left .user-card .score .ug {
  background: rgba(0, 52, 187, 0.05);
  padding: 20px 22px;
  border-radius: 9px;
  min-width: 105px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
}
.candidates-v2 .body .candidates-v2-left .user-card .score .ug p {
  font-size: 14px;
  font-weight: 400;
  color: rgb(33, 33, 33);
}
.candidates-v2 .body .candidates-v2-left .user-card .score .ug h1 {
  font-size: 18px;
  font-weight: 600;
  color: rgb(33, 33, 33);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view {
  margin-top: 20px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .tabs {
  margin-top: 20px;
  margin-bottom: 10px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .tabs .MuiTabs-scroller {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .tabs .MuiTabs-scroller .MuiTabs-flexContainer {
  display: flex;
  justify-content: space-between;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .tabs .MuiTabs-scroller .MuiTabs-flexContainer button {
  min-width: 135px;
  font-size: 18px;
  color: rgba(33, 33, 33, 0.6);
  font-weight: 500;
  text-transform: capitalize;
  width: fit-content;
  padding: 1px 20px;
  border-radius: 7px 7px 0px 0px;
  min-height: 41px !important;
  margin-top: 5px !important;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .tabs .MuiTabs-scroller .MuiTabs-flexContainer button:hover {
  background-color: rgba(0, 52, 187, 0.05);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .tabs .MuiTabs-scroller .MuiTabs-flexContainer .Mui-selected {
  color: rgb(0, 52, 187);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .tabs .MuiTabs-scroller .MuiTabs-indicator {
  border-bottom: 4px solid rgb(0, 52, 187) !important;
  font-weight: 700;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .profile-tab {
  margin-top: 30px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .profile-tab .experience-card {
  background: #ffffff;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 20px 25px;
  margin-bottom: 30px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .profile-tab .experience-card h1 {
  font-size: 20px;
  font-weight: 700;
  color: #212121;
  margin-bottom: 15px;
  margin-top: 0px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .profile-tab .experience-card .experience-list {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 20px;
  margin-top: 20px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .profile-tab .experience-card .experience-list:last-child {
  border-bottom: 0px;
  padding-bottom: 0px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .profile-tab .experience-card .experience-list h2 {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .profile-tab .experience-card .experience-list h3 {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
  margin: 3px 0px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .profile-tab .experience-card .experience-list p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .profile-tab .questions {
  background: #ffffff;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 20px 25px;
  margin-bottom: 30px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .profile-tab .questions h1 {
  font-size: 20px;
  font-weight: 700;
  color: #212121;
  margin-bottom: 15px;
  margin-top: 5px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .profile-tab .questions .ques {
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  padding-bottom: 13px;
  margin-bottom: 17px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .profile-tab .questions .ques:last-child {
  border-bottom: none;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .profile-tab .questions .ques p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
  margin-bottom: 4px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .profile-tab .questions .ques .ans {
  color: #212121;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-title {
  display: flex;
  align-items: flex-start;
  gap: 6px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 7px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-title .icon {
  width: 20px;
  height: 20px;
  color: #0034bb;
  margin-top: 1px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .view-para {
  font-size: 14px;
  font-weight: 500;
  color: rgb(0, 52, 187);
  text-decoration: underline;
  width: fit-content;
  margin-bottom: 14px;
  padding: 10px;
  cursor: pointer;
  border-radius: 6px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .view-para:hover {
  background: rgba(0, 52, 187, 0.05);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card {
  background: #ffffff;
  border-radius: 0px 0px 7px 7px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  height: auto;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .tabs {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .tabs .tab:first-child {
  padding: 15px 20px;
  border-radius: 6.815px 6.815px 0px 0px;
  border-top: 1px solid rgba(33, 33, 33, 0.2);
  border-right: 1px solid rgba(33, 33, 33, 0.2);
  border-left: 1px solid rgba(33, 33, 33, 0.2);
  background: rgba(33, 33, 33, 0.02);
  margin-bottom: -12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .tabs .tab:first-child .last {
  font-size: 10px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.8);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .tabs .tab:first-child .my-score {
  display: flex;
  align-items: center;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .tabs .tab:first-child .my-score h3 {
  font-size: 16px;
  font-weight: 600;
  color: rgb(33, 33, 33);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .tabs .tab {
  padding: 15px 20px;
  border-radius: 6.815px;
  border-bottom: none;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: rgba(33, 33, 33, 0.02);
  position: relative;
  margin-bottom: -12px;
  height: 74px;
  width: 33%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .tabs .tab .tab-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .tabs .tab .last {
  font-size: 10px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.8);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .tabs .tab .my-score {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .tabs .tab .my-score h3 {
  font-size: 16px;
  font-weight: 600;
  color: rgb(33, 33, 33);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .tabs .active {
  position: relative;
  background: #fff !important;
  z-index: 3;
  border-bottom: none;
  margin-bottom: -12px !important;
  border-bottom: none;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab {
  border: 1px solid rgba(33, 33, 33, 0.2);
  position: relative;
  background: #fff;
  border-radius: 7px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  z-index: 1;
  padding: 20px 25px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .users-tab {
  margin-bottom: 20px;
  margin-left: -20px;
  margin-right: -10px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .users-tab .container {
  padding-left: 20px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .users-tab .container .swiper-button-prev {
  position: absolute;
  width: 110px !important;
  top: 50%;
  bottom: 0px;
  left: -18px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .users-tab .container .swiper-button-prev::after {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2em' height='2em' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m15 18l-6-6l6-6'/%3E%3C/svg%3E") no-repeat;
  background-repeat: no-repeat;
  background-position: 8px;
  font-size: 14px;
  content: "";
  cursor: pointer;
  padding: 10px;
  padding-left: 30px;
  padding-right: 10px;
  color: rgb(0, 0, 0);
  width: 160px !important;
  font-family: unset !important;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .users-tab .container .swiper-button-next {
  position: absolute;
  width: 110px !important;
  top: 50%;
  bottom: 0px;
  right: -27px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .users-tab .container .swiper-button-next::after {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2em' height='2em' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m9 18l6-6l-6-6'/%3E%3C/svg%3E") no-repeat;
  background-repeat: no-repeat;
  background-position: 62px;
  font-size: 14px;
  content: "";
  cursor: pointer;
  padding: 10px;
  padding-left: 30px;
  padding-right: 10px;
  color: rgb(0, 0, 0);
  width: 160px !important;
  font-family: unset !important;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .users-tab .container .swiper-slide {
  max-width: 150px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .users-tab .container .swiper-slide .user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid rgba(0, 52, 187, 0.2);
  padding: 8px 10px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .users-tab .container .swiper-slide .user:hover {
  background: rgba(0, 52, 187, 0.05);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .users-tab .container .swiper-slide .user .left {
  display: flex;
  align-items: center;
  gap: 6px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .users-tab .container .swiper-slide .user .left .avatar {
  width: 23px;
  height: 23px;
  font-size: 12px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .users-tab .container .swiper-slide .user .left h4 {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 0, 0);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .users-tab .container .swiper-slide .user h4 {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 0, 0);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .users-tab .container .swiper-slide .active {
  background: rgba(0, 52, 187, 0.05);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .scores .top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0px;
  margin-bottom: 25px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .scores .top-bar .edit {
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .scores .top-bar .edit h1 {
  font-size: 16px;
  font-weight: 500;
  color: rgb(33, 33, 33);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .scores .top-bar h2 {
  font-size: 16px;
  font-weight: 500;
  color: rgb(33, 33, 33);
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 9px 18px;
  border-radius: 7px;
  cursor: pointer;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .scores .top-bar h2:hover {
  background: rgba(0, 52, 187, 0.05);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .scores .right-align {
  display: flex;
  align-items: center;
  justify-content: right;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .scores .table-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background: rgba(33, 33, 33, 0.05);
  padding: 12px 16px;
  margin-bottom: 20px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .scores .table-title h2 {
  font-size: 14px;
  font-weight: 600;
  color: rgb(33, 33, 33);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .scores .rating-data {
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  padding-bottom: 20px;
  margin-bottom: 15px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .scores .rating-data:last-child {
  border-bottom: none;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .scores .rating-data .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-right: 15px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .scores .rating-data .title p {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #212121;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .scores .rating-data .rating-button {
  background: transparent;
  margin-right: 12px;
  outline: none;
  border-radius: 6px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  cursor: pointer;
  width: 35px;
  height: 32px;
  text-align: center;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .scores .rating-data .rating-button:hover {
  background: rgba(0, 52, 187, 0.05);
  color: rgba(0, 52, 187, 0.7);
  border: none;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .scores .rating-data .active {
  border: 1px solid rgb(0, 52, 187);
  background: rgba(0, 52, 187, 0.05);
  color: rgba(0, 52, 187, 0.7);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .not-valid {
  margin-top: 15px;
  border-radius: 7px;
  margin-bottom: 25px;
  background-image: repeating-linear-gradient(4deg, rgb(255, 194, 70), rgb(255, 194, 70) 13px, transparent 13px, transparent 26px, rgb(255, 194, 70) 26px), repeating-linear-gradient(94deg, rgb(255, 194, 70), rgb(255, 194, 70) 13px, transparent 13px, transparent 26px, rgb(255, 194, 70) 26px), repeating-linear-gradient(184deg, rgb(255, 194, 70), rgb(255, 194, 70) 13px, transparent 13px, transparent 26px, rgb(255, 194, 70) 26px), repeating-linear-gradient(274deg, rgb(255, 194, 70), rgb(255, 194, 70) 13px, transparent 13px, transparent 26px, rgb(255, 194, 70) 26px);
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .not-valid .container {
  background: rgba(255, 194, 70, 0.1);
  padding: 20px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .not-valid .container p {
  font-size: 14px;
  font-weight: 500;
  color: rgb(33, 33, 33);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .total-card {
  border-radius: 10px;
  background: rgba(0, 52, 187, 0.05);
  padding: 15px 16px;
  padding-right: 18px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .total-card .total {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .rating-tab .total-card .total p {
  font-size: 20px;
  font-weight: 600;
  color: #0034bb;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .user-notes {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 25px;
  padding-top: 16px;
  margin-bottom: 30px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .user-notes h1 {
  font-size: 20px;
  font-weight: 700;
  color: rgb(33, 33, 33);
  margin-bottom: 10px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .user-notes .nots-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .user-notes .nots-container .notes-card {
  padding: 20px 15px;
  width: 48%;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .user-notes .nots-container .notes-card .card-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .user-notes .nots-container .notes-card .card-top h2 {
  font-size: 13px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  text-transform: capitalize;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .user-notes .nots-container .notes-card .card-top p {
  font-size: 13px;
  font-weight: 400;
  color: rgb(186, 0, 0);
  border-radius: 11px;
  background: rgba(186, 0, 0, 0.05);
  padding: 3px 7px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .user-notes .nots-container .notes-card .notes-body {
  margin-bottom: 10px;
  height: 95px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .user-notes .nots-container .notes-card .notes-body p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .user-notes .nots-container .notes-card .notes-bottom .left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .user-notes .nots-container .notes-card .notes-bottom .left p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.8);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .user-notes .nots-container .notes-card .notes-bottom .left span {
  font-size: 12px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .user-notes .nots-container .notes-card .notes-bottom .right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .user-notes .nots-container .notes-card .notes-bottom .right h3 {
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.8);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .score-card-rating .user-notes .nots-container .notes-card .notes-bottom .right span {
  font-size: 12px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .no-data .message {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .no-data .message p {
  font-size: 16px;
  font-weight: 500;
  color: #212121;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score .score-card .no-data .message Button {
  background: transparent;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 8px 18px;
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  margin-top: 20px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score1 {
  background: #ffffff;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 20px 25px;
  margin-bottom: 30px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin-top: 20px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .score1 h2 {
  font-size: 20px;
  font-weight: 700;
  color: rgb(33, 33, 33);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container {
  margin-top: 31px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .notes-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .notes-header h2 {
  font-size: 20px;
  font-weight: 700;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .notes-header .create-note {
  display: flex;
  min-width: 120px;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .notes-header .create-note p {
  color: #0034bb;
  font-size: 14px;
  font-weight: 500;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .notes-header .create-note:hover {
  border-radius: 8px;
  background: rgba(0, 52, 187, 0.05);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .notes-header .create-note:hover .icon {
  display: unset;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .notes-header .create-note .icon {
  width: 14px;
  height: 14px;
  color: #0034bb;
  display: none;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .fallback-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 20px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .fallback-screen h3 {
  color: rgba(33, 33, 33, 0.6);
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .fallback-screen .btn-outlined-v2 {
  color: #0034bb !important;
  font-size: 18px;
  font-weight: 500;
  min-height: 43px;
  min-width: 236px;
  border-radius: 8px;
  border: 1px solid #0034bb !important;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .all-notes {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  margin-top: 30px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .all-notes .active {
  border-color: rgba(0, 52, 187, 0.7) !important;
  outline: 0.5px solid rgba(0, 52, 187, 0.7);
  box-shadow: 0px 4px 18.6px 0px rgba(33, 33, 33, 0.05);
  cursor: pointer;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .all-notes .active .notes-menu {
  visibility: unset;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .all-notes .notes-body {
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  max-width: 303px;
  max-height: 233px;
  min-width: 303px;
  min-height: 233px;
  padding: 21px 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .all-notes .notes-body:hover {
  border-color: rgba(0, 52, 187, 0.7);
  outline: 0.5px solid rgba(0, 52, 187, 0.7);
  box-shadow: 0px 4px 18.6px 0px rgba(33, 33, 33, 0.05);
  cursor: pointer;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .all-notes .notes-body:hover .notes-menu {
  visibility: unset;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .all-notes .notes-body .note-description {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .all-notes .notes-body .note-description .markdown-data {
  color: #212121;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .all-notes .notes-body .note-description .markdown-data p {
  font-size: 14px;
  font-weight: 500 !important;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .all-notes .notes-body .note-description .markdown-data ul,
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .all-notes .notes-body .note-description .markdown-data ol {
  margin-left: 20px;
  font-size: 12px;
  font-weight: 400;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .all-notes .notes-body .notes-top-no-title {
  display: flex;
  font-size: 14px;
  margin-bottom: 20px;
  justify-content: space-between;
  gap: 10px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .all-notes .notes-body .notes-top-no-title h4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .all-notes .notes-body .notes-top-no-title .note-description {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .all-notes .notes-body .notes-top {
  font-size: 14px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .all-notes .notes-body .notes-top .title-container {
  margin-bottom: -1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .all-notes .notes-body .notes-top .title-container h4 {
  font-weight: 500;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .all-notes .notes-body .notes-top p {
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .all-notes .notes-body .notes-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(33, 33, 33, 0.2);
  padding-top: 16px;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .all-notes .notes-body .notes-bottom .left p {
  font-size: 14px;
  font-weight: 400;
  color: 33, 33, 33, 0.8;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .all-notes .notes-body .notes-bottom .left .user-name {
  color: rgba(33, 33, 33, 0.8);
  font-size: 14px;
  font-weight: 600;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .all-notes .notes-body .notes-bottom .right {
  text-align: right;
}
.candidates-v2 .body .candidates-v2-left .swipeable-view .slider .notes-container .all-notes .notes-body .notes-bottom .right p {
  font-size: 12px;
  font-weight: 400;
  color: 33, 33, 33, 0.6;
}
.candidates-v2 .body .candidates-v2-right {
  width: 38%;
  background: #ffffff;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 20px 25px;
}
.candidates-v2 .body .candidates-v2-right .refresh .title {
  display: flex;
  align-items: flex-start;
  font-size: 26px;
  font-weight: 600;
  color: rgb(0, 52, 187);
}
.candidates-v2 .body .candidates-v2-right .refresh .title .svg {
  margin-top: 3px;
}
.candidates-v2 .body .candidates-v2-right .refresh .wrapper {
  text-align: center;
  padding-bottom: 40px;
  position: relative;
}
.candidates-v2 .body .candidates-v2-right .refresh .wrapper .heading {
  font-size: 16px;
  font-weight: 700;
  color: #212121;
}
.candidates-v2 .body .candidates-v2-right .refresh .wrapper .sub {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
}
.candidates-v2 .body .candidates-v2-right .refresh .container {
  padding: 20px;
  border-radius: 7px;
  margin-top: 20px;
  background: rgba(255, 194, 70, 0.1);
}
.candidates-v2 .body .candidates-v2-right .refresh .container h2 {
  font-size: 16px;
  font-weight: 600;
  color: rgb(33, 33, 33);
}
.candidates-v2 .body .candidates-v2-right .refresh .container .retry {
  border-radius: 6px;
  text-align: center;
  margin-top: 25px;
}
.candidates-v2 .body .candidates-v2-right .refresh .container .retry p {
  font-size: 13px;
  font-weight: 400;
  color: rgb(33, 33, 33);
  margin-left: 8px;
}
.candidates-v2 .body .candidates-v2-right .refresh .container .retry Button {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 600;
  background: rgb(0, 52, 187);
  min-width: 220px;
  padding: 9px;
  margin: auto;
  color: #fff;
  border-radius: 6px;
  margin-bottom: 10px;
}
.candidates-v2 .body .candidates-v2-right .refresh .container .retry Button .icon {
  animation: rotate 2s linear infinite;
  /* Define the animation */
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .title {
  display: flex;
  align-items: flex-start;
  font-size: 26px;
  font-weight: 600;
  color: rgb(0, 52, 187);
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .title .svg {
  margin-top: 0px;
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .wrapper {
  text-align: center;
  padding-bottom: 40px;
  position: relative;
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .wrapper .heading {
  font-size: 16px;
  font-weight: 700;
  color: #212121;
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .wrapper .sub {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number {
  padding: 22px 20px;
  margin-top: 20px;
  background: linear-gradient(white, white) padding-box, linear-gradient(to right, #29af82, #d100e5, #ffcd6e, #0e086d) border-box;
  border-radius: 7px;
  border: 2px solid transparent;
  cursor: pointer;
  animation: animateBorder 15s linear infinite;
  /* Add animation */
  transition: all 0.5s;
  /* Add transition */
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .top-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .top-cont h2 {
  font-size: 16px;
  font-weight: 600;
  color: rgb(33, 33, 33);
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .top-cont .MuiButton-root {
  gap: 6px;
  font-weight: 600;
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .top-cont .MuiButton-root .iconify {
  font-size: 16px;
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .rating {
  border-radius: 6px;
  padding: 12px 15px;
  background: rgba(0, 52, 187, 0.05);
  margin-top: 20px;
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .rating .score-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .rating .score-row .old-score {
  font-size: 17px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .rating .score-row h1 {
  font-size: 26px;
  font-weight: 700;
  color: rgb(0, 52, 187);
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .rating .score-row h1 span {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .rating .score-row .emoji {
  margin-right: 9px;
  border-radius: 999px;
  padding: 7px;
  width: 41px;
  height: 41px;
  background-color: rgba(33, 33, 33, 0.05);
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .rating .score-row .emoji img {
  max-width: 27px;
  max-height: 27px;
  animation: pop 0.5s ease-in-out;
}
@keyframes pop {
  0% {
    transform: scale(0.7);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .rating p {
  font-size: 14px;
  font-weight: 500;
  color: rgb(0, 52, 187);
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .bar-cont {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
  font-size: 14px;
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .bar-cont span {
  font-weight: 600;
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .bar-cont .bar-map {
  display: flex;
  gap: 4.5px;
  justify-content: space-between;
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .bar-cont .bar-map .bar {
  height: 29px;
  width: 100%;
  border-radius: 5px;
  background-color: rgba(33, 33, 33, 0.1);
  transition: background-color 0.2s ease, opacity 0.2s ease, transform 0.2s ease;
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .bar-cont .bar-map .bar.Poor {
  background-color: #dd2025;
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .bar-cont .bar-map .bar.Fair {
  background-color: #e67b2e;
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .bar-cont .bar-map .bar.Average {
  background-color: #e4ea2e;
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .bar-cont .bar-map .bar.Satisfactory {
  background-color: #66ea2e;
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .bar-cont .bar-map .bar.Excellent {
  background-color: #07c677;
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .insights {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  padding-top: 13px;
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .insights h3 {
  font-size: 16px;
  font-weight: 700;
  color: rgb(33, 33, 33);
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .insights p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-top: 15px;
}
.candidates-v2 .body .candidates-v2-right .Ai-Rating .number .insights p span {
  font-weight: 600;
  color: rgb(33, 33, 33);
  cursor: pointer;
}
.candidates-v2 .body .candidates-v2-right .para {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: rgb(33, 33, 33);
  margin: 22px 0px;
  gap: 15px;
}
.candidates-v2 .body .candidates-v2-right .para .icons {
  width: 24px;
  height: 24px;
  color: #0034bb;
}
.candidates-v2 .body .candidates-v2-right .parameter .parameter-card {
  position: relative;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 20px 24px;
  border-radius: 7px;
  margin-bottom: 20px;
}
.candidates-v2 .body .candidates-v2-right .parameter .parameter-card h1 {
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 20px;
  font-weight: 700;
  color: rgb(33, 33, 33);
  margin-bottom: 10px;
  text-transform: capitalize;
}
.candidates-v2 .body .candidates-v2-right .parameter .parameter-card h1 span {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
  margin-top: 4px;
}
.candidates-v2 .body .candidates-v2-right .parameter .parameter-card .p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  overflow-wrap: break-word;
}
.candidates-v2 .body .candidates-v2-right .parameter .see {
  font-size: 14px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  cursor: pointer;
  background: #fff;
}
.candidates-v2 .body .candidates-v2-right .parameter .seeLess {
  position: absolute;
  right: 0;
  margin-top: -21px;
  padding-right: 30px;
  display: inline-block;
  background: #fff;
  z-index: 1;
}
.candidates-v2 .expend-view {
  padding-top: 30px;
}
.candidates-v2 .expend-view .top .back {
  background: transparent;
  display: flex;
  align-content: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  margin-left: -10px;
}
.candidates-v2 .expend-view .top .back:hover {
  background: rgba(0, 52, 187, 0.05);
}
.candidates-v2 .expend-view .top h2 {
  font-size: 20px;
  font-weight: 700;
  color: #212121;
  margin-top: 10px;
}
.candidates-v2 .expend-view .top p {
  font-size: 14px;
  font-weight: 400;
  color: rgb(33, 33, 33);
  margin-top: 5px;
}
.candidates-v2 .expend-view .body {
  display: flex;
  margin-top: 25px;
}
.candidates-v2 .expend-view .body .score-card-rating .tabs {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}
.candidates-v2 .expend-view .body .score-card-rating .tabs .tab:first-child {
  padding: 15px 20px;
  border-radius: 6.815px 6.815px 0px 0px;
  border-top: 1px solid rgba(33, 33, 33, 0.2);
  border-right: 1px solid rgba(33, 33, 33, 0.2);
  border-left: 1px solid rgba(33, 33, 33, 0.2);
  background: rgba(33, 33, 33, 0.02);
  margin-bottom: -2px !important;
  cursor: pointer;
}
.candidates-v2 .expend-view .body .score-card-rating .tabs .tab:first-child .last {
  font-size: 10px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.8);
}
.candidates-v2 .expend-view .body .score-card-rating .tabs .tab:first-child .my-score {
  display: flex;
  align-items: center;
}
.candidates-v2 .expend-view .body .score-card-rating .tabs .tab:first-child .my-score h3 {
  font-size: 16px;
  font-weight: 600;
  color: rgb(33, 33, 33);
}
.candidates-v2 .expend-view .body .score-card-rating .tabs .tab {
  padding: 15px 20px;
  border-radius: 6.815px;
  border-bottom: none;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: rgba(33, 33, 33, 0.02);
  position: relative;
  margin-bottom: -2px !important;
  height: 74px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 33%;
  flex-grow: 1;
  cursor: pointer;
}
.candidates-v2 .expend-view .body .score-card-rating .tabs .tab .tab-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.candidates-v2 .expend-view .body .score-card-rating .tabs .tab .last {
  font-size: 10px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.8);
}
.candidates-v2 .expend-view .body .score-card-rating .tabs .tab .my-score {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.candidates-v2 .expend-view .body .score-card-rating .tabs .tab .my-score h3 {
  font-size: 16px;
  font-weight: 600;
  color: rgb(33, 33, 33);
}
.candidates-v2 .expend-view .body .score-card-rating .tabs .active {
  position: relative;
  background: #fff !important;
  z-index: 3;
  border-bottom: none;
  margin-bottom: -2px !important;
  border-bottom: none;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab {
  border: 1px solid rgba(33, 33, 33, 0.2);
  position: relative;
  background: #fff;
  z-index: 1;
  padding: 20px 25px;
  border-radius: 0px 0px 7px 7px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .users-tab {
  margin-bottom: 20px;
  margin-left: -20px;
  margin-right: -10px;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .users-tab .container {
  padding-left: 20px;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .users-tab .container .swiper-button-prev {
  position: absolute;
  width: 110px !important;
  top: 50%;
  bottom: 0px;
  left: -18px;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .users-tab .container .swiper-button-prev::after {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2em' height='2em' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m15 18l-6-6l6-6'/%3E%3C/svg%3E") no-repeat;
  background-repeat: no-repeat;
  background-position: 8px;
  font-size: 14px;
  content: "";
  cursor: pointer;
  padding: 10px;
  padding-left: 30px;
  padding-right: 10px;
  color: rgb(0, 0, 0);
  width: 160px !important;
  font-family: unset !important;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .users-tab .container .swiper-button-next {
  position: absolute;
  width: 110px !important;
  top: 50%;
  bottom: 0px;
  right: -27px;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .users-tab .container .swiper-button-next::after {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2em' height='2em' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m9 18l6-6l-6-6'/%3E%3C/svg%3E") no-repeat;
  background-repeat: no-repeat;
  background-position: 62px;
  font-size: 14px;
  content: "";
  cursor: pointer;
  padding: 10px;
  padding-left: 30px;
  padding-right: 10px;
  color: rgb(0, 0, 0);
  width: 160px !important;
  font-family: unset !important;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .users-tab .container .swiper-slide {
  max-width: 150px;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .users-tab .container .swiper-slide .user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid rgba(0, 52, 187, 0.2);
  padding: 8px 10px;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .users-tab .container .swiper-slide .user:hover {
  background: rgba(0, 52, 187, 0.05);
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .users-tab .container .swiper-slide .user .left {
  display: flex;
  align-items: center;
  gap: 6px;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .users-tab .container .swiper-slide .user .left .avatar {
  width: 23px;
  height: 23px;
  font-size: 12px;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .users-tab .container .swiper-slide .user .left h4 {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 0, 0);
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .users-tab .container .swiper-slide .user h4 {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 0, 0);
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .users-tab .container .swiper-slide .active {
  background: rgba(0, 52, 187, 0.05);
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .scores .Add-notes {
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  margin-bottom: 20px;
  min-width: 120px;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .scores .top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0px;
  margin-bottom: 25px;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .scores .top-bar .edit {
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .scores .top-bar .edit h1 {
  font-size: 16px;
  font-weight: 500;
  color: rgb(33, 33, 33);
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .scores .top-bar h2 {
  font-size: 16px;
  font-weight: 500;
  color: rgb(33, 33, 33);
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 9px 18px;
  border-radius: 7px;
  cursor: pointer;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .scores .top-bar h2:hover {
  background: rgba(0, 52, 187, 0.05);
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .scores .table-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background: rgba(33, 33, 33, 0.05);
  padding: 12px 16px;
  margin-bottom: 20px;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .scores .table-title h2 {
  font-size: 14px;
  font-weight: 600;
  color: rgb(33, 33, 33);
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .scores .rating-data {
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  padding-bottom: 20px;
  margin-bottom: 15px;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .scores .rating-data:last-child {
  border-bottom: none;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .scores .rating-data .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-right: 15px;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .scores .rating-data .title p {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #212121;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .scores .rating-data .rating-button {
  background: transparent;
  margin-right: 12px;
  outline: none;
  border-radius: 6px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  cursor: pointer;
  width: 35px;
  height: 32px;
  text-align: center;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .scores .rating-data .rating-button:hover {
  background: rgba(0, 52, 187, 0.05);
  color: rgba(0, 52, 187, 0.7);
  border: none;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .scores .rating-data .active {
  border: 1px solid rgb(0, 52, 187);
  background: rgba(0, 52, 187, 0.05);
  color: rgba(0, 52, 187, 0.7);
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .not-valid {
  margin-top: 15px;
  border-radius: 7px;
  margin-bottom: 25px;
  background-image: repeating-linear-gradient(4deg, rgb(255, 194, 70), rgb(255, 194, 70) 13px, transparent 13px, transparent 26px, rgb(255, 194, 70) 26px), repeating-linear-gradient(94deg, rgb(255, 194, 70), rgb(255, 194, 70) 13px, transparent 13px, transparent 26px, rgb(255, 194, 70) 26px), repeating-linear-gradient(184deg, rgb(255, 194, 70), rgb(255, 194, 70) 13px, transparent 13px, transparent 26px, rgb(255, 194, 70) 26px), repeating-linear-gradient(274deg, rgb(255, 194, 70), rgb(255, 194, 70) 13px, transparent 13px, transparent 26px, rgb(255, 194, 70) 26px);
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .not-valid .container {
  background: rgba(255, 194, 70, 0.1);
  padding: 20px;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .not-valid .container p {
  font-size: 14px;
  font-weight: 500;
  color: rgb(33, 33, 33);
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .total-card {
  border-radius: 10px;
  background: rgba(0, 52, 187, 0.05);
  padding: 15px 16px;
  padding-right: 18px;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .total-card .total {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.candidates-v2 .expend-view .body .score-card-rating .rating-tab .total-card .total p {
  font-size: 20px;
  font-weight: 600;
  color: #0034bb;
}
.candidates-v2 .expend-view .body .score-card-rating .user-notes {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 25px;
  padding-top: 16px;
  margin-bottom: 30px;
}
.candidates-v2 .expend-view .body .score-card-rating .user-notes h1 {
  font-size: 20px;
  font-weight: 700;
  color: rgb(33, 33, 33);
  margin-bottom: 10px;
}
.candidates-v2 .expend-view .body .score-card-rating .user-notes .nots-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.candidates-v2 .expend-view .body .score-card-rating .user-notes .nots-container .notes-card {
  padding: 20px;
  width: 48%;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
}
.candidates-v2 .expend-view .body .score-card-rating .user-notes .nots-container .notes-card .card-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.candidates-v2 .expend-view .body .score-card-rating .user-notes .nots-container .notes-card .card-top h2 {
  font-size: 14px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  text-transform: capitalize;
}
.candidates-v2 .expend-view .body .score-card-rating .user-notes .nots-container .notes-card .card-top p {
  font-size: 14px;
  font-weight: 400;
  color: rgb(186, 0, 0);
  border-radius: 11px;
  background: rgba(186, 0, 0, 0.05);
  padding: 3px 10px;
}
.candidates-v2 .expend-view .body .score-card-rating .user-notes .nots-container .notes-card .notes-body {
  margin-bottom: 10px;
  height: 80px;
}
.candidates-v2 .expend-view .body .score-card-rating .user-notes .nots-container .notes-card .notes-body p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
  display: -webkit-box;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.candidates-v2 .expend-view .body .score-card-rating .user-notes .nots-container .notes-card .notes-bottom .left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.candidates-v2 .expend-view .body .score-card-rating .user-notes .nots-container .notes-card .notes-bottom .left p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.8);
}
.candidates-v2 .expend-view .body .score-card-rating .user-notes .nots-container .notes-card .notes-bottom .left span {
  font-size: 12px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
}
.candidates-v2 .expend-view .body .score-card-rating .user-notes .nots-container .notes-card .notes-bottom .right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.candidates-v2 .expend-view .body .score-card-rating .user-notes .nots-container .notes-card .notes-bottom .right h3 {
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.8);
}
.candidates-v2 .expend-view .body .score-card-rating .user-notes .nots-container .notes-card .notes-bottom .right span {
  font-size: 12px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
}
.candidates-v2 .expend-view .body .score-card-rating-expend {
  width: 56%;
}
.candidates-v2 .expend-view .body .right-side-expand {
  width: 44%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  padding-bottom: 15px;
}
.candidates-v2 .expend-view .body .right-side-expand .tabs {
  margin-top: 20px;
  margin-bottom: 10px;
}
.candidates-v2 .expend-view .body .right-side-expand .tabs .MuiTabs-scroller {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.candidates-v2 .expend-view .body .right-side-expand .tabs .MuiTabs-scroller .MuiTabs-flexContainer {
  display: flex;
  justify-content: space-between;
  padding: 0px 60px;
}
.candidates-v2 .expend-view .body .right-side-expand .tabs .MuiTabs-scroller .MuiTabs-flexContainer button {
  font-size: 18px;
  color: rgba(33, 33, 33, 0.6);
  font-weight: 500;
  text-transform: capitalize;
  max-width: none !important;
  width: 50%;
  padding: 1px 20px;
  border-radius: 7px 7px 0px 0px;
  min-height: 41px !important;
  margin-top: 5px !important;
}
.candidates-v2 .expend-view .body .right-side-expand .tabs .MuiTabs-scroller .MuiTabs-flexContainer button:hover {
  background-color: rgba(0, 52, 187, 0.05);
}
.candidates-v2 .expend-view .body .right-side-expand .tabs .MuiTabs-scroller .MuiTabs-flexContainer .Mui-selected {
  color: rgb(0, 52, 187);
}
.candidates-v2 .expend-view .body .right-side-expand .tabs .MuiTabs-scroller .MuiTabs-indicator {
  border-bottom: 4px solid rgb(0, 52, 187) !important;
  font-weight: 700;
}
.candidates-v2 .expend-view .body .right-side-expand .user-card {
  background: #ffffff;
  border-radius: 7px;
  padding: 25px 20px;
}
.candidates-v2 .expend-view .body .right-side-expand .user-card .user-top {
  display: flex;
  justify-content: space-between;
}
.candidates-v2 .expend-view .body .right-side-expand .user-card .user-top .user-left {
  display: flex;
  gap: 10px;
}
.candidates-v2 .expend-view .body .right-side-expand .user-card .user-top .user-left .avatar {
  width: 45px;
  height: 45px;
}
.candidates-v2 .expend-view .body .right-side-expand .user-card .user-top .user-left .name h2 {
  font-size: 18px;
  font-weight: 700;
  color: #000000;
}
.candidates-v2 .expend-view .body .right-side-expand .user-card .user-top .user-left .name h3 {
  font-size: 14px;
  font-weight: 500;
  color: rgb(33, 33, 33);
  margin: 1px 0px;
}
.candidates-v2 .expend-view .body .right-side-expand .user-card .user-top .user-left .name p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}
.candidates-v2 .expend-view .body .right-side-expand .user-card .score {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 18px;
}
.candidates-v2 .expend-view .body .right-side-expand .user-card .score .ug {
  background: rgba(0, 52, 187, 0.05);
  padding: 15px 12px;
  border-radius: 9px;
  min-width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
}
.candidates-v2 .expend-view .body .right-side-expand .user-card .score .ug p {
  font-size: 12px;
  font-weight: 400;
  color: rgb(33, 33, 33);
}
.candidates-v2 .expend-view .body .right-side-expand .user-card .score .ug h1 {
  font-size: 16px;
  font-weight: 600;
  color: rgb(33, 33, 33);
}
.candidates-v2 .expend-view .body .right-side-expand .experience-card {
  background: #ffffff;
  border-radius: 7px;
  padding: 20px 25px;
  padding-bottom: 10px;
}
.candidates-v2 .expend-view .body .right-side-expand .experience-card h1 {
  font-size: 20px;
  font-weight: 700;
  color: #212121;
  margin-bottom: 15px;
  margin-top: 0px;
}
.candidates-v2 .expend-view .body .right-side-expand .experience-card .experience-list {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 20px;
  margin-top: 20px;
}
.candidates-v2 .expend-view .body .right-side-expand .experience-card .experience-list:last-child {
  border-bottom: 0px;
  padding-bottom: 0px;
}
.candidates-v2 .expend-view .body .right-side-expand .experience-card .experience-list h2 {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
}
.candidates-v2 .expend-view .body .right-side-expand .experience-card .experience-list h3 {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
  margin: 3px 0px;
}
.candidates-v2 .expend-view .body .right-side-expand .experience-card .experience-list p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
}
.candidates-v2 .expend-view .body .right-side-expand .pdf-container {
  border-radius: 12px 12px 0px 0px;
  border-bottom: none;
}
.candidates-v2 .expend-view .body .right-side-expand .pdf-container ::-webkit-scrollbar {
  width: 0px;
  width: none;
}
.candidates-v2 .expend-view .body .right-side-expand .pdf-container .container {
  height: 76vh;
  display: flex;
  justify-content: center;
  justify-items: center;
}
.candidates-v2 .expend-view .body .right-side-expand .pdf-container .container .inner-container {
  width: 95%;
  overflow: scroll;
  display: flex;
  justify-content: center;
  justify-items: center;
  padding: 50px 0px;
  padding-top: 10px;
}
.candidates-v2 .expend-view .body .right-side-expand .pdf-container .container .inner-container ::-webkit-scrollbar {
  width: 0px;
  width: none;
}
.candidates-v2 .expend-view .body .right-side-expand .pdf-container .container .page {
  position: absolute;
  bottom: 0px;
  width: 200px;
  height: 80px;
  text-align: center;
  border-bottom: none;
  background: transparent;
}
.candidates-v2 .expend-view .body .right-side-expand .pdf-container .container .page .page-number {
  border-radius: 5px;
  background: transparent;
  padding: 8px 25px;
}
.candidates-v2 .expend-view .body .right-side-expand .pdf-container .container .page .page-number .icons {
  width: 0px;
  height: 0px;
}
.candidates-v2 .expend-view .body .right-side-expand .pdf-container .container .page .page-number h1 {
  font-size: 0px;
}
.candidates-v2 .expend-view .body .right-side-expand .pdf-container .container .page .page-number:hover {
  background: rgba(33, 33, 33, 0.7);
  display: flex;
  align-items: center;
  gap: 20px;
}
.candidates-v2 .expend-view .body .right-side-expand .pdf-container .container .page .page-number:hover .icons {
  width: 23px;
  height: 23px;
  color: #ffffff;
  cursor: pointer;
}
.candidates-v2 .expend-view .body .right-side-expand .pdf-container .container .page .page-number:hover h1 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
}
.candidates-v2 .expend-view .body .right-side-expand .pdf-container .container .page .page-number:hover h1 span {
  color: rgba(255, 255, 255, 0.6);
}
.candidates-v2 .expend-view .body .right-side-expand .pdf-container .container .page:hover .page-number {
  width: 180px;
  cursor: pointer;
  margin: auto;
  background: rgba(33, 33, 33, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.candidates-v2 .expend-view .body .right-side-expand .pdf-container .container .page:hover .page-number .icons {
  width: 23px;
  height: 23px;
  color: #ffffff;
  cursor: pointer;
}
.candidates-v2 .expend-view .body .right-side-expand .pdf-container .container .page:hover .page-number h1 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
}
.candidates-v2 .expend-view .body .right-side-expand .pdf-container .container .page:hover .page-number h1 span {
  color: rgba(255, 255, 255, 0.6);
}
.candidates-v2 .expend-view .body .right-side-expand .pdf-container .hide-scroll {
  overflow: hidden;
}

.quill-candidatesdetail {
  display: flex;
  flex-direction: column-reverse;
  white-space: pre-wrap;
  min-height: 20px;
  height: 100%;
  border-radius: 8px;
  padding-bottom: 20px;
}
.quill-candidatesdetail .ql-toolbar {
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2) !important;
  background: rgba(255, 255, 255, 0.8);
  padding: 0px;
  margin-top: 0px;
  max-width: 284px;
}
.quill-candidatesdetail .ql-toolbar .ql-formats {
  margin-right: unset !important;
}
.quill-candidatesdetail .ql-toolbar .ql-formats:last-child button {
  margin-right: 0px;
}
.quill-candidatesdetail .ql-toolbar .ql-formats button {
  height: 28px;
  color: rgba(33, 33, 33, 0.8);
  width: auto;
  padding: 2px 0px;
  margin-right: 16px;
}
.quill-candidatesdetail .ql-toolbar .ql-formats .ql-active {
  background-color: rgba(0, 52, 187, 0.1);
  color: #212121 !important;
  border-radius: 4px;
}
.quill-candidatesdetail .ql-tooltip {
  left: 30px !important;
  border-radius: 9px;
  border: 1px solid rgba(138, 97, 97, 0.2);
  background: #fff;
  box-shadow: 0px 7px 11.9px 0px rgba(33, 33, 33, 0.1);
}
.quill-candidatesdetail .ql-tooltip input[type=text] {
  border-radius: 4px;
}
.quill-candidatesdetail .ql-tooltip input[type=text]:focus {
  border-color: #0034bb !important;
  outline: 0.5px solid #0034bb !important;
}
.quill-candidatesdetail .ql-tooltip input[type=text]:hover {
  outline: 0.5px solid #0034bb !important;
  border-color: #0034bb !important;
}
.quill-candidatesdetail .ql-tooltip[data-mode=link]::before {
  content: "Enter link :";
  font-size: 14px;
  font-weight: 400;
}
.quill-candidatesdetail .ql-tooltip::before {
  content: "Go to link :";
}
.quill-candidatesdetail .ql-preview {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 52, 187);
}
.quill-candidatesdetail a {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 52, 187);
}
.quill-candidatesdetail .ql-action {
  border-right: none;
}
.quill-candidatesdetail .ql-container {
  border: none !important;
  min-height: 50px;
  max-width: 840px;
  font-size: 16px;
  font-weight: 400;
  font-family: Poppins;
}
.quill-candidatesdetail .ql-container .ql-editor {
  padding-left: 0px;
  padding-top: 0px;
}
.quill-candidatesdetail .ql-container .ql-editor.ql-blank::before {
  font-size: 16px;
  font-weight: 400;
}
.quill-candidatesdetail .ql-picker-options {
  position: absolute;
  top: -150px;
  z-index: 2;
  height: 145px;
  overflow-y: scroll;
  border-radius: 5px;
}

.drawer-resume .MuiDrawer-paper {
  border-radius: 0px !important;
  min-width: 65%;
  padding: 15px 40px;
  justify-content: unset;
  padding-bottom: 0px !important;
  height: 110vh !important;
  margin-top: -10px !important;
}
.drawer-resume .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  margin-left: -10px;
  margin-right: -8px;
  margin-bottom: 15px;
}
.drawer-resume .top .icons h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}
.drawer-resume .top .icons .icon {
  color: #212121;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-right: 5px;
}
.drawer-resume .top .icons:hover {
  background: rgba(0, 52, 187, 0.05);
}
.drawer-resume .top .download {
  font-size: 16px;
  font-weight: 500;
  color: #212121;
}
.drawer-resume .top .download .icons {
  color: #212121;
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-right: 5px;
}
.drawer-resume .pdf-container {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 12px 12px 0px 0px;
  border-bottom: none;
}
.drawer-resume .pdf-container ::-webkit-scrollbar {
  width: 0px;
  width: none;
}
.drawer-resume .pdf-container .pdf-top {
  position: relative;
}
.drawer-resume .pdf-container .pdf-top .zoom {
  display: flex;
  align-items: center;
  gap: 13px;
  padding: 11px 25px;
  width: 160px;
  border-radius: 5px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  box-shadow: 0px 4px 11.3px 0px rgba(33, 33, 33, 0.05);
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 2;
}
.drawer-resume .pdf-container .pdf-top .zoom p {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
}
.drawer-resume .pdf-container .pdf-top .zoom .icons {
  width: 23px;
  height: 23px;
  color: #212121;
  cursor: pointer;
}
.drawer-resume .pdf-container .container {
  display: flex;
  justify-content: center;
  justify-items: center;
}
.drawer-resume .pdf-container .container .inner-container {
  width: 100%;
  overflow: scroll;
  display: flex;
  justify-content: center;
  justify-items: center;
  padding: 50px 0px;
  padding-top: 10px;
}
.drawer-resume .pdf-container .container .inner-container ::-webkit-scrollbar {
  width: 0px;
  width: none;
}
.drawer-resume .pdf-container .container .react-pdf__message {
  margin-top: 33vh;
}
.drawer-resume .pdf-container .container .page {
  position: absolute;
  bottom: 0px;
  width: 300px;
  height: 80px;
  text-align: center;
  border-bottom: none;
  background: transparent;
}
.drawer-resume .pdf-container .container .page .page-number {
  border-radius: 5px;
  background: transparent;
  padding: 8px 25px;
}
.drawer-resume .pdf-container .container .page .page-number .icons {
  width: 0px;
  height: 0px;
}
.drawer-resume .pdf-container .container .page .page-number h1 {
  font-size: 0px;
}
.drawer-resume .pdf-container .container .page .page-number:hover {
  background: rgba(33, 33, 33, 0.7);
  display: flex;
  align-items: center;
  gap: 20px;
}
.drawer-resume .pdf-container .container .page .page-number:hover .icons {
  width: 23px;
  height: 23px;
  color: #ffffff;
  cursor: pointer;
}
.drawer-resume .pdf-container .container .page .page-number:hover h1 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
}
.drawer-resume .pdf-container .container .page .page-number:hover h1 span {
  color: rgba(255, 255, 255, 0.6);
}
.drawer-resume .pdf-container .container .page:hover .page-number {
  width: 210px;
  cursor: pointer;
  margin: auto;
  background: rgba(33, 33, 33, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.drawer-resume .pdf-container .container .page:hover .page-number .icons {
  width: 23px;
  height: 23px;
  color: #ffffff;
  cursor: pointer;
}
.drawer-resume .pdf-container .container .page:hover .page-number h1 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
}
.drawer-resume .pdf-container .container .page:hover .page-number h1 span {
  color: rgba(255, 255, 255, 0.6);
}
.drawer-resume .pdf-container .hide-scroll {
  overflow: hidden;
}

.fixed-height .MuiDrawer-paper {
  overflow: hidden !important;
}

.drawer-link .MuiDrawer-paper {
  border-radius: 0px !important;
  min-width: 65%;
  padding: 15px 40px;
  justify-content: unset;
  padding-bottom: 0px;
}
.drawer-link .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  margin-left: -10px;
  margin-right: -8px;
  margin-bottom: 5px;
}
.drawer-link .top .icons .icon {
  color: #212121;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.drawer-link .top .icons:hover {
  background: rgba(0, 52, 187, 0.05);
}
.drawer-link .title-download {
  margin: 10px 0px;
  margin-top: 30px;
}
.drawer-link .title-download .drawer-title {
  font-size: 18px;
  font-weight: 600;
  color: rgb(33, 33, 33);
}
.drawer-link .drawer-body {
  border-radius: 13px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  padding: 20px 25px;
  margin-top: 10px;
}
.drawer-link .drawer-body .sub-card {
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  padding-bottom: 15px;
  margin-top: 25px;
}
.drawer-link .drawer-body .sub-card:last-child {
  border-bottom: none;
}
.drawer-link .drawer-body .sub-card p {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.drawer-link .drawer-body .sub-card p .icon {
  width: 18px;
  height: 18px;
  color: #212121;
}
.drawer-link .drawer-body .sub-card .copy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.drawer-link .drawer-body .sub-card .copy .h2 {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  margin-left: 25px;
}
.drawer-link .drawer-body .sub-card .copy .h2 .icon {
  width: 20px;
  height: 20px;
}
.drawer-link .drawer-body .sub-card .copy .copied {
  color: #0034bb;
  cursor: pointer;
}
.drawer-link .drawer-body .sub-card .copy .copied .icon {
  width: 20px;
  height: 20px;
}

.score-modifier .MuiDrawer-paper {
  border-radius: 0px !important;
  max-width: 65%;
  justify-content: unset;
  padding-bottom: 0px !important;
  margin-bottom: 0px;
}
.score-modifier .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -10px;
  margin-right: -8px;
  padding: 15px 40px;
  padding-bottom: 0px;
}
.score-modifier .top .icons .icon {
  color: #212121;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.score-modifier .top .icons:hover {
  background: rgba(0, 52, 187, 0.05);
}
.score-modifier .drawer-title {
  padding: 15px 40px;
  padding-top: 5px;
}
.score-modifier .drawer-title h3 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}
.score-modifier .drawer-title p {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
}
.score-modifier .chips {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 15px 40px;
  padding-bottom: 0px;
  padding-top: 9px;
  margin-bottom: 5px;
}
.score-modifier .chips .chip {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 15px 10px !important;
  border-radius: 16px;
  background: transparent;
}
.score-modifier .chips .chip p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.score-modifier .chips .chip .MuiSvgIcon-root {
  display: none;
}
.score-modifier .chips .filled {
  padding: 15px 2px !important;
  background: #0034bb;
  color: #fff;
  padding-right: 5px !important;
}
.score-modifier .chips .add {
  background: transparent;
  font-size: 16px;
  font-weight: 500;
  color: #0034bb;
}
.score-modifier .add-custom {
  margin: 15px 40px;
  padding: 15px 20px;
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  padding-bottom: 4px;
}
.score-modifier .add-custom .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.score-modifier .add-custom .row .row-left {
  width: 70%;
}
.score-modifier .add-custom .row .row-left .field p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 3px;
}
.score-modifier .add-custom .row .row-right {
  width: 30%;
}
.score-modifier .add-custom .row .row-right .field p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 3px;
}
.score-modifier .add-custom .button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
.score-modifier .add-custom .button .dis {
  background: transparent;
  font-size: 16px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.8);
}
.score-modifier .add-custom .button .dis:hover {
  background: rgba(0, 52, 187, 0.05);
  border-radius: 7px;
}
.score-modifier .add-custom .button .done {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(0, 52, 187);
  background: transparent;
}
.score-modifier .add-custom .button .done:hover {
  background: rgba(0, 52, 187, 0.05);
  border-radius: 7px;
}
.score-modifier .drawer-body {
  margin-top: 20px;
  padding: 15px 40px;
  padding-bottom: 20px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.score-modifier .drawer-body .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 52, 187, 0.05);
  padding: 10px 30px;
  padding-left: 50px;
  border-radius: 7px;
  margin-top: 20px;
}
.score-modifier .drawer-body .wrapper p {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
}
.score-modifier .drawer-body .wrapper h2 {
  font-size: 22px;
  font-weight: 600;
  color: #2bba1d;
}
.score-modifier .drawer-body .exceed {
  background: rgba(255, 194, 70, 0.05);
  padding: 10px 30px;
  border-radius: 7px;
  margin-top: 20px;
}
.score-modifier .drawer-body .exceed .exceed-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px;
}
.score-modifier .drawer-body .exceed .exceed-wrapper p {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
}
.score-modifier .drawer-body .exceed .exceed-wrapper h2 {
  font-size: 22px;
  font-weight: 600;
  color: #212121;
}
.score-modifier .drawer-body .exceed .notes {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
  margin-top: 15px;
  padding: 10px 15px;
  border-radius: 7px;
  background-image: repeating-linear-gradient(4deg, rgb(255, 194, 70), rgb(255, 194, 70) 13px, transparent 13px, transparent 26px, rgb(255, 194, 70) 26px), repeating-linear-gradient(94deg, rgb(255, 194, 70), rgb(255, 194, 70) 13px, transparent 13px, transparent 26px, rgb(255, 194, 70) 26px), repeating-linear-gradient(184deg, rgb(255, 194, 70), rgb(255, 194, 70) 13px, transparent 13px, transparent 26px, rgb(255, 194, 70) 26px), repeating-linear-gradient(274deg, rgb(255, 194, 70), rgb(255, 194, 70) 13px, transparent 13px, transparent 26px, rgb(255, 194, 70) 26px);
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}
.score-modifier .total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 35px;
  margin-right: 35px;
  border-radius: 7px;
  background: rgba(0, 52, 187, 0.05);
  padding: 20px 30px;
  margin-top: 0px;
  margin-bottom: 100px;
}
.score-modifier .total p {
  font-size: 16px;
  font-weight: 500;
  color: rgb(33, 33, 33);
}
.score-modifier .total .right {
  text-align: right;
}
.score-modifier .total .right h2 {
  font-size: 22px;
  font-weight: 600;
  color: #027a48;
  margin-bottom: 5px;
}
.score-modifier .total .right .auto-set {
  border: none;
  outline: none;
  font-size: 12px;
  font-weight: 500;
  border-radius: 7px;
  border: 1px solid rgba(0, 52, 187, 0.2);
  background: transparent;
  padding: 7px 20px;
  color: #0034bb;
  cursor: pointer;
}
.score-modifier .buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 22px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  max-width: 65%;
  margin-left: auto;
  padding: 15px 40px;
  margin-top: 10px;
  z-index: 223;
  background: #fff;
}
.score-modifier .buttons .cancel {
  background: transparent;
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  border: 1px solid rgba(0, 0, 0, 0.2);
  min-width: 200px;
}
.score-modifier .buttons .cancel:hover {
  background: rgba(0, 52, 187, 0.05);
}
.score-modifier .buttons .save {
  background: #0034bb;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 7px !important;
  padding: 8px 20px;
}

.score-element:last-child {
  border-bottom: none !important;
}

.notes-for-user {
  position: relative;
}
.notes-for-user .MuiDrawer-paper {
  border-radius: 0px !important;
  width: 67%;
  height: 100vh;
  justify-content: unset;
  overflow-x: hidden;
  padding-bottom: 0px !important;
  margin-bottom: -10px !important;
  margin-top: 0px !important;
}
.notes-for-user .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -10px;
  margin-right: -8px;
  padding: 15px 40px;
  padding-bottom: 0px;
}
.notes-for-user .top .icons .icon {
  color: #212121;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.notes-for-user .top .icons:hover {
  background: rgba(0, 52, 187, 0.05);
}
.notes-for-user .react-tool {
  width: 510px;
  height: 400px;
  padding: 0px;
}
.notes-for-user .react-tool .quill-candidatesdetail {
  margin: 0px !important;
  padding: 0px !important;
}
.notes-for-user .large-width {
  width: 100%;
  min-height: 400px;
}
.notes-for-user .notes-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  box-shadow: 0px -4px 15px 0px rgba(0, 0, 0, 0.05);
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 16px 25px;
  width: 67%;
  margin-left: auto;
}
.notes-for-user .notes-bottom .dis {
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: transparent;
  box-shadow: none;
  min-width: 150px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(33, 33, 33);
}
.notes-for-user .notes-bottom .cre {
  box-shadow: none;
  min-width: 150px;
  font-size: 16px;
  font-weight: 500;
  background: rgb(0, 52, 187);
  color: #fff;
  border-radius: 7px;
}

@keyframes animateBorder {
  0% {
    background-size: 200% auto;
    /* Initial background size */
    background-position: left center;
    /* Initial background position */
  }
  100% {
    background-size: 800% auto;
    /* Final background size */
    background-position: right center;
    /* Final background position */
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
    /* Start rotation from 0 degrees */
  }
  to {
    transform: rotate(360deg);
    /* End rotation at 360 degrees */
  }
}
/* for backdrop shadow*/
.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.notes-menu {
  display: unset !important;
  padding: 0px !important;
  text-align: right !important;
  width: 10px !important;
  min-width: 10px !important;
  max-width: 10px !important;
  background: transparent;
  border: none;
  background: transparent !important;
  cursor: pointer;
  margin-right: 0px;
  visibility: hidden;
}

.notes-menu-item .MuiPaper-root {
  padding: 10px 10px;
  margin-left: -20px;
  padding-bottom: 5px;
  border-radius: 6px;
  box-shadow: 0px 7px 30px 7px rgba(0, 0, 0, 0.1);
}
.notes-menu-item .MuiPaper-root .MuiMenuItem-root {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.notes-menu-item .MuiPaper-root .MuiMenuItem-root .icons {
  color: rgba(33, 33, 33, 0.6);
  flex-shrink: 0;
}
.notes-menu-item .MuiPaper-root .MuiMenuItem-root span {
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.7);
}
.notes-menu-item .MuiPaper-root .MuiMenuItem-root:hover {
  border-radius: 8px;
  background: rgba(0, 52, 187, 0.05);
}
.notes-menu-item .MuiPaper-root .MuiMenuItem-root:hover .MuiTypography-root {
  color: #0034bb;
}
.notes-menu-item .MuiPaper-root .MuiMenuItem-root:hover span {
  font-size: 14px;
  font-weight: 500;
  color: #0034bb !important;
}
.notes-menu-item .MuiPaper-root .MuiMenuItem-root:hover .icons {
  color: #0034bb !important;
}
.notes-menu-item .MuiPaper-root .MuiMenuItem-root:hover .MuiTypography-root {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.7);
  opacity: unset;
}
.notes-menu-item .MuiPaper-root .MuiMenuItem-root .Mui-selected {
  background-color: rgba(0, 52, 187, 0.05) !important;
}
.notes-menu-item .MuiPaper-root .MuiMenuItem-root .Mui-selected:hover {
  background-color: rgba(0, 52, 187, 0.05) !important;
}
.notes-menu-item .MuiPaper-root .MuiMenuItem-root .Mui-selected .MuiTypography-root {
  color: #0034bb;
}
.notes-menu-item .MuiPaper-root .menu-hidden {
  display: none;
}

.notes-drawer .notes-detail {
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  padding: 25px;
  margin-bottom: 20px;
  margin-top: 15px;
}
.notes-drawer .notes-detail .notes-detail-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.notes-drawer .notes-detail .notes-detail-header .delete-btn {
  gap: 8px;
  padding: 5px 9px;
  border-radius: 7px;
  color: #0034bb;
  font-size: 16px;
  font-weight: 500;
  min-height: 44px;
  min-width: 114px;
}
.notes-drawer .notes-detail .notes-detail-header .edit-btn {
  gap: 8px;
  padding: 5px 9px;
  border-radius: 7px;
  color: #0034bb !important;
  font-size: 16px;
  font-weight: 500;
  min-width: 92px;
  min-height: 44px;
}
.notes-drawer .notes-detail .title {
  margin-top: 25px;
  color: #212121;
  font-size: 18px;
  font-weight: 500;
}
.notes-drawer .notes-detail .no-title {
  margin-top: 25px;
  color: rgba(33, 33, 33, 0.6);
  font-size: 18px;
  font-weight: 500;
}
.notes-drawer .notes-detail .markdown-data {
  color: #212121;
  margin-top: 16px;
  margin-bottom: 25px;
}
.notes-drawer .notes-detail .markdown-data p {
  font-size: 14px;
  font-weight: 500 !important;
}
.notes-drawer .notes-detail .markdown-data ul,
.notes-drawer .notes-detail .markdown-data ol {
  margin-left: 20px;
  font-size: 12px;
  font-weight: 400;
}
.notes-drawer .notes-detail .notes-detail-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.notes-drawer .notes-detail .notes-detail-footer .create-username {
  color: rgba(33, 33, 33, 0.8);
  font-size: 12px;
  font-weight: 400;
}
.notes-drawer .notes-detail .notes-detail-footer span {
  color: rgba(33, 33, 33, 0.8);
  font-size: 12px;
  font-weight: 600;
}
.notes-drawer .notes-detail .create-datetime {
  color: rgba(33, 33, 33, 0.6);
  font-size: 12px;
  font-weight: 400;
}

.regen-ai-score-popup {
  background-color: rgba(0, 0, 0, 0.4);
}
.regen-ai-score-popup .MuiPaper-root {
  padding: 52px 43px;
  max-width: 868px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  color: #212121;
}
.regen-ai-score-popup .MuiPaper-root h2 {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
}
.regen-ai-score-popup .MuiPaper-root h4 {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin: 11px auto 35px auto;
  max-width: 674px;
}
.regen-ai-score-popup .MuiPaper-root h4 span {
  font-weight: 600;
}
.regen-ai-score-popup .MuiPaper-root .btn-cont {
  display: flex;
  margin: auto;
  max-width: fit-content;
  gap: 55px;
}
.regen-ai-score-popup .MuiPaper-root .btn-cont .confirm-btn,
.regen-ai-score-popup .MuiPaper-root .btn-cont .cancel-btn {
  width: 218px;
  padding: 11px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 9px;
  text-align: center;
  border: 1.5px solid;
  height: 44px;
}
.regen-ai-score-popup .MuiPaper-root .btn-cont .confirm-btn {
  color: #0034bb;
  border-color: #0034bb;
}
.regen-ai-score-popup .MuiPaper-root .btn-cont .cancel-btn {
  color: #ba0000;
  border-color: #ba0000;
}

.mails .top-select {
  padding-right: 30px;
  margin-top: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.mails .top-select .field {
  width: 55%;
}
.mails .top-select .field p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 5px;
}
.mails .top-select .field .select {
  width: 100%;
}
.mails .top-select .field .select .MuiFormControl-root {
  width: 100%;
  border-radius: 8px;
  background: #ffffff;
}
.mails .top-select .field .select .MuiFormControl-root .MuiInputBase-root {
  border: none;
}
.mails .top-select .field .select .MuiFormControl-root .MuiInputBase-root .MuiSvgIcon-root {
  margin-right: 10px;
  color: #000000;
  font-size: 25px;
}
.mails .top-select .field .select .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  font-size: 16px;
  background: transparent;
  padding: 10px 16px !important;
  font-weight: 500;
  color: rgb(33, 33, 33);
}
.mails .top-select .field .select .item {
  display: flex;
  gap: 40px;
}
.mails .top-select .button {
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 7px;
  font-size: 16px;
  font-weight: 500;
  padding: 7.5px 18px;
  box-shadow: none;
}
.mails .nav-tab {
  padding-right: 30px;
}
.mails .nav-tab .slider .tabs {
  margin-top: 20px;
}
.mails .nav-tab .slider .tabs .MuiTabs-scroller {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.mails .nav-tab .slider .tabs .MuiTabs-scroller .MuiTabs-flexContainer {
  display: flex;
  gap: 35px;
}
.mails .nav-tab .slider .tabs .MuiTabs-scroller .MuiTabs-flexContainer button {
  border-radius: 7px 7px 0px 0px;
  margin-bottom: -2px;
  padding: 7px 20px;
}
.mails .nav-tab .slider .tabs .MuiTabs-scroller .MuiTabs-flexContainer button p {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 18px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
  border-radius: 7px 7px 0px 0px;
}
.mails .nav-tab .slider .tabs .MuiTabs-scroller .MuiTabs-flexContainer button p:hover {
  color: rgb(0, 52, 187);
}
.mails .nav-tab .slider .tabs .MuiTabs-scroller .MuiTabs-flexContainer .Mui-selected p {
  color: rgb(0, 52, 187);
}
.mails .nav-tab .slider .tabs .MuiTabs-scroller .MuiTabs-indicator {
  border-bottom: 4px solid rgb(0, 52, 187) !important;
  font-weight: 700;
}
.mails .nav-tab .slider .tabs .chip {
  display: flex;
  padding: 1.387px 6.24px;
  align-items: flex-start;
  gap: 17px;
  border-radius: 10px;
  background: #29AF82;
  color: #FFF;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.mails .nav-tab .body {
  max-height: 473px;
  overflow: scroll;
}
.mails .nav-tab .body .table-data {
  margin-top: 20px;
}
.mails .nav-tab .body .table-data .table-head {
  background: rgba(33, 33, 33, 0.09);
  border-radius: 8px;
  padding: 6px;
  display: flex;
  align-items: center;
  gap: 18px;
}
.mails .nav-tab .body .table-data .bin-delete {
  font-size: 14px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  margin: 20px auto;
  text-align: center;
}
.mails .nav-tab .body .table-data .bin-delete span {
  color: rgb(0, 52, 187);
  padding: 5px 20px;
  margin-left: 4px;
  cursor: pointer;
}
.mails .nav-tab .body .table-data .bin-delete span:hover {
  background: rgba(0, 52, 187, 0.05);
  padding: 5px 20px;
  border-radius: 7px;
}
.mails .nav-tab .body .table-data .table-body .row {
  display: flex;
  align-items: center;
  gap: 0px;
  padding: 13px 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 0px;
  cursor: pointer;
}
.mails .nav-tab .body .table-data .table-body .row .row-left {
  display: flex;
  align-items: center;
  gap: 16px;
}
.mails .nav-tab .body .table-data .table-body .row .row-left .name {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 200px;
}
.mails .nav-tab .body .table-data .table-body .row .row-left .name .default-user-icon {
  text-align: center;
  padding-top: 6px;
  background: #f2f4f8;
  border-radius: 100%;
  width: 40px;
  height: 40px;
}
.mails .nav-tab .body .table-data .table-body .row .row-left .name p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  display: flex;
  gap: 15px;
  align-items: center;
}
.mails .nav-tab .body .table-data .table-body .row .row-left .name p .chip {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.387px 6.24px;
  border-radius: 10px;
  background: #29AF82;
  color: #FFF;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-height: 17.77px;
}
.mails .nav-tab .body .table-data .table-body .row .row-left .name .active {
  font-weight: 600;
}
.mails .nav-tab .body .table-data .table-body .row .row-right {
  width: 70%;
  padding-right: 15px;
}
.mails .nav-tab .body .table-data .table-body .row .row-right .p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.mails .nav-tab .body .table-data .table-body .row .row-right .active {
  font-weight: 600;
}
.mails .nav-tab .body .table-data .table-body .row .time {
  min-width: 100px;
  text-align: right;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 400;
  color: rgb(33, 33, 33);
}
.mails .nav-tab .body .table-data .table-body .row .time-active {
  font-weight: 600;
}
.mails .nav-tab .body .table-data .table-body .row:last-child {
  border-bottom: none;
}
.mails .nav-tab .body .table-data .table-body .row-active {
  background: rgba(0, 52, 187, 0.05);
}
.mails .nav-tab .body .fallback {
  display: flex;
  align-items: center;
  margin-top: 5%;
}
.mails .nav-tab .body .fallback .fallback-container {
  text-align: center;
  margin: auto;
  width: 300px;
}
.mails .nav-tab .body .fallback .fallback-container p {
  font-size: 14px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  margin-bottom: 10px;
  margin-top: -50px;
}
.mails .nav-tab .body .fallback .fallback-container Button {
  padding: 7px 18px;
  min-width: 220px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 7px;
  box-shadow: none;
}
.mails .nav-tab .pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 0;
  border-top: 1px solid rgba(33, 33, 33, 0.2);
}
.mails .nav-tab .pagination-wrapper .job-pagination {
  flex: 1;
  display: flex;
  justify-content: center;
}
.mails .nav-tab .pagination-wrapper .job-pagination .MuiPagination-ul {
  justify-content: center;
}
.mails .nav-tab .pagination-wrapper .email-count {
  margin: 0 15px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: #000;
  text-align: right;
  margin-left: auto;
}
.mails .composed-email .top {
  padding-right: 30px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}
.mails .composed-email .top .back {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #212121;
  padding-bottom: 16px;
  cursor: pointer;
}
.mails .composed-email .top Button {
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.7);
  margin-top: -15px;
}
.mails .composed-email .select-job {
  display: flex;
  align-items: center;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
}
.mails .composed-email .select-job p {
  width: 18%;
  font-size: 16px;
  font-weight: 400;
  color: rgb(33, 33, 33);
}
.mails .composed-email .select-job .menu-button {
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  color: rgb(33, 33, 33);
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.mails .composed-email .member-lists {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  width: 100%;
}
.mails .composed-email .member-lists p {
  font-size: 16px;
  font-weight: 400;
  color: rgb(33, 33, 33);
}
.mails .composed-email .member-lists .job-member {
  width: 81%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.mails .composed-email .member-lists .job-member .item-chip {
  display: flex;
  align-items: center;
  gap: 7px;
  width: fit-content;
  border-radius: 12px;
  background: rgba(0, 52, 187, 0.05);
  padding: 3px 14px;
  padding-left: 17px;
  cursor: pointer;
}
.mails .composed-email .member-lists .job-member .item-chip p {
  font-size: 14px;
  font-weight: 500;
  color: rgb(33, 33, 33);
}
.mails .composed-email .member-lists .job-member .number {
  gap: 0px;
}
.mails .composed-email .member-lists .job-member .number p {
  font-weight: 500;
  color: rgb(0, 52, 187);
}
.mails .composed-email .member-lists .job-member Button {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 500;
  padding: 1px 14px;
  border-radius: 12px;
}
.mails .composed-email .subject {
  display: flex;
  align-items: center;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
}
.mails .composed-email .subject p {
  width: 18%;
  font-size: 16px;
  font-weight: 400;
  color: rgb(33, 33, 33);
}
.mails .composed-email .subject input {
  margin-left: 30px;
  width: 80%;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  color: rgb(33, 33, 33);
  white-space: pre-wrap;
  height: auto;
}
.mails .composed-email .email-body-template-and-buttons {
  display: flex;
  height: calc(100vh - 283.5px);
  position: relative;
}
.mails .composed-email .email-body-template-and-buttons .text-body {
  height: calc(100% - 60px);
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  width: 70%;
  overflow-y: scroll;
  cursor: pointer;
}
.mails .composed-email .email-body-template-and-buttons .text-body .quill {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mails .composed-email .email-body-template-and-buttons .text-body .quill .quill-candidatesdetail {
  display: flex;
  flex-direction: column-reverse;
  white-space: pre-wrap;
  border-radius: 8px;
  width: 100%;
}
.mails .composed-email .email-body-template-and-buttons .text-body .quill .quill-candidatesdetail ::-webkit-scrollbar {
  width: none;
  width: 0px;
}
.mails .composed-email .email-body-template-and-buttons .text-body .quill .quill-candidatesdetail .ql-toolbar {
  border-radius: 7px;
  background: transparent;
  padding: 10px;
  width: 300px;
  margin-left: 20px;
  position: fixed;
  bottom: 13px;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  margin-left: 15px;
  z-index: 10;
}
.mails .composed-email .email-body-template-and-buttons .text-body .quill .quill-candidatesdetail .ql-toolbar .ql-formats {
  margin-right: unset !important;
}
.mails .composed-email .email-body-template-and-buttons .text-body .quill .quill-candidatesdetail .ql-toolbar .ql-formats:last-child button {
  margin-right: 0px;
}
.mails .composed-email .email-body-template-and-buttons .text-body .quill .quill-candidatesdetail .ql-toolbar .ql-formats button {
  height: 27px;
  color: rgba(33, 33, 33, 0.8);
  width: unset;
  padding: 2px 0px;
  margin-right: 16px;
  position: relative;
  z-index: 1;
}
.mails .composed-email .email-body-template-and-buttons .text-body .quill .quill-candidatesdetail .ql-toolbar .ql-formats .ql-active {
  background-color: rgba(0, 52, 187, 0.1);
  color: #212121 !important;
  border-radius: 4px;
}
.mails .composed-email .email-body-template-and-buttons .text-body .quill .quill-candidatesdetail .ql-container {
  border: none !important;
  min-height: 50px;
  max-width: 100%;
  max-height: 100%;
  font-size: 16px;
  font-weight: 400;
  font-family: Poppins;
  padding: 20px 15px;
  padding-bottom: 20px;
}
.mails .composed-email .email-body-template-and-buttons .text-body .quill .quill-candidatesdetail .ql-container .ql-editor {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  font-family: Poppins;
}
.mails .composed-email .email-body-template-and-buttons .text-body .quill .quill-candidatesdetail .ql-container .ql-editor.ql-blank::before {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}
.mails .composed-email .email-body-template-and-buttons .text-body .quill .quill-candidatesdetail .ql-container .ql-tooltip {
  left: 30px !important;
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  box-shadow: 0px 7px 11.9px 0px rgba(33, 33, 33, 0.1);
}
.mails .composed-email .email-body-template-and-buttons .text-body .quill .quill-candidatesdetail .ql-container .ql-tooltip[data-mode=link]::before {
  content: "Enter link :";
  font-size: 14px;
  font-weight: 400;
}
.mails .composed-email .email-body-template-and-buttons .text-body .quill .quill-candidatesdetail .ql-container .ql-tooltip::before {
  content: "Go to link :";
}
.mails .composed-email .email-body-template-and-buttons .text-body .quill .quill-candidatesdetail .ql-container .ql-preview {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 52, 187);
}
.mails .composed-email .email-body-template-and-buttons .text-body .quill .quill-candidatesdetail .ql-container a {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 52, 187);
}
.mails .composed-email .email-body-template-and-buttons .text-body .quill .quill-candidatesdetail .ql-container .ql-action {
  border-right: none;
}
.mails .composed-email .email-body-template-and-buttons .text-body .quill .quill-candidatesdetail .ql-picker-options {
  position: absolute;
  top: -150px;
  z-index: 2;
  height: 145px;
  overflow-y: scroll;
  border-radius: 5px;
}
.mails .composed-email .email-body-template-and-buttons .text-body .attach {
  margin-bottom: 30px;
  width: 70%;
}
.mails .composed-email .email-body-template-and-buttons .text-body .attach .file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 7px;
  border: 1px solid rgba(0, 52, 187, 0.2);
  background: #fff;
  padding: 4px 20px;
  margin-left: 25px;
  margin-bottom: 10px;
  font-size: 16px;
}
.mails .composed-email .email-body-template-and-buttons .text-body .attach .file:last-child {
  margin-bottom: 0px;
}
.mails .composed-email .email-body-template-and-buttons .templates-sidebar {
  width: 30%;
  height: calc(100% - 60px);
  border-left: 1px solid rgba(33, 33, 33, 0.2);
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
}
.mails .composed-email .email-body-template-and-buttons .templates-sidebar .templates-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 22px;
  background: rgba(33, 33, 33, 0.05);
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
}
.mails .composed-email .email-body-template-and-buttons .templates-sidebar .templates-header .title {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
}
.mails .composed-email .email-body-template-and-buttons .templates-sidebar .templates-header .create-new-link {
  color: #0034bb;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
}
.mails .composed-email .email-body-template-and-buttons .templates-sidebar .templates-header .create-new-link:hover {
  cursor: pointer;
}
.mails .composed-email .email-body-template-and-buttons .templates-sidebar .templates-list-container {
  padding: 22px;
  height: calc(100% - 61px);
  overflow-y: scroll;
}
.mails .composed-email .email-body-template-and-buttons .templates-sidebar .templates-list-container .template-list-item {
  padding: 26px 0px;
  border-radius: 5px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  margin-bottom: 18px;
}
.mails .composed-email .email-body-template-and-buttons .templates-sidebar .templates-list-container .template-list-item .template-title {
  color: #212121;
  font-size: 16px;
  font-weight: 600;
  padding: 0px 22px;
}
.mails .composed-email .email-body-template-and-buttons .templates-sidebar .templates-list-container .template-list-item .template-body {
  margin-top: 8px;
  color: #212121;
  font-size: 14px;
  font-weight: 400;
  padding: 0px 22px;
}
.mails .composed-email .email-body-template-and-buttons .templates-sidebar .templates-list-container .template-list-item:hover {
  cursor: pointer;
}
.mails .composed-email .email-body-template-and-buttons .templates-sidebar .templates-list-container .template-list-item.template-item-selected {
  padding-bottom: 0px;
}
.mails .composed-email .email-body-template-and-buttons .templates-sidebar .templates-list-container .template-list-item.template-item-selected .selected-state {
  background: #0034bb;
  color: #ffffff;
  display: flex;
  padding: 6px 22px;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.mails .composed-email .email-body-template-and-buttons .templates-sidebar .templates-list-container .template-list-item.template-item-selected .selected-state .text {
  font-size: 12px;
  font-weight: 500;
}
.mails .composed-email .buttons {
  overflow-x: hidden;
  width: 80.8%;
  position: fixed;
  bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px;
  padding-left: 335px;
}
.mails .composed-email .buttons .button-left {
  display: flex;
  align-items: center;
  gap: 20px;
}
.mails .composed-email .buttons .button-left Button {
  display: flex;
  align-items: center;
  gap: 5px;
  border: none;
  outline: none;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: transparent;
  font-size: 16px;
  font-weight: 500;
  color: rgb(0, 52, 187);
  padding: 9px 18px;
  border-radius: 7px;
}
.mails .composed-email .buttons .button-left .placeholder {
  color: rgb(33, 33, 33);
}
.mails .composed-email .buttons .button-right Button {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 150px;
  font-size: 14px;
  font-weight: 500;
  padding: 9px 18px;
  border-radius: 7px;
}
.mails .replies {
  padding-top: 20px;
  padding-right: 30px;
}
.mails .replies .MuiButtonBase-root {
  margin-left: -10px;
}
.mails .replies .unstar-rating .MuiRating-icon {
  color: rgba(0, 0, 0, 0.8);
}
.mails .replies .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.mails .replies .top h2 {
  font-size: 16px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  text-transform: capitalize;
}
.mails .replies .top .top-right {
  display: flex;
  align-items: center;
  gap: 20px;
}
.mails .replies .top .top-right .reply-button {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 18px;
  font-weight: 500;
  color: rgb(0, 52, 187);
  padding: 5px 20px;
  border-radius: 7px;
}
.mails .replies .message-card {
  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  padding: 25px 35px;
  margin-bottom: 30px;
}
.mails .replies .message-card ol,
.mails .replies .message-card ul {
  padding-left: 60px;
}
.mails .replies .message-card .message-card-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mails .replies .message-card .message-card-top .top-left {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}
.mails .replies .message-card .message-card-top .top-left .avatar {
  background: rgba(0, 52, 187, 0.05);
  color: #0034bb;
  font-size: 16px;
  text-transform: uppercase;
}
.mails .replies .message-card .message-card-top .top-left .name h3 {
  font-size: 20px;
  font-weight: 600;
  color: rgb(33, 33, 33);
}
.mails .replies .message-card .message-card-top .top-left .name p {
  font-size: 14px;
  font-weight: 400;
  color: rgb(33, 33, 33);
}
.mails .replies .message-card .message-card-top .top-right h3 {
  font-size: 14px;
  font-weight: 600;
  color: rgb(33, 33, 33);
}
.mails .replies .message-card .message-card-top .top-right p {
  font-size: 14px;
  font-weight: 400;
  color: rgb(33, 33, 33);
  text-align: right;
}
.mails .replies .message-card .message-body {
  margin: 15px 0px;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
  color: rgb(33, 33, 33);
}
.mails .replies .message-card .message-body .gmail_quote {
  display: none !important;
}
.mails .replies .message-card .message-body .hidden {
  display: block !important;
  margin-bottom: 30px;
}
.mails .replies .message-card .message-body .attach {
  margin-bottom: 30px;
  width: 70%;
  margin-top: 20px;
}
.mails .replies .message-card .message-body .attach .file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 7px;
  border: 1px solid rgba(0, 52, 187, 0.2);
  background: #fff;
  padding: 15px 20px;
  color: #0034bb;
  text-decoration: underline;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
}
.mails .replies .message-card .message-body .attach .file:last-child {
  margin-bottom: 0px;
}
.mails .replies .message-card .menu-icon {
  margin-top: 10px;
  margin-left: 15px;
  background: rgba(33, 33, 33, 0.3);
  padding: 0px 5px;
  width: 30px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
}
.mails .replies .message-card .reply {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 18px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.7);
  border-radius: 6px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 5px 16px;
  margin-top: 30px;
  margin-left: 1px;
}
.mails .replies .my-card {
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
}
.mails .replies .my-card .avatar {
  background: rgba(0, 52, 187, 0.05);
  color: #0034bb;
  font-size: 16px;
  text-transform: uppercase;
}
.mails .replies .my-card .message-card {
  width: 100%;
  box-shadow: -7px 8px 27.4px 0px rgba(33, 33, 33, 0.05);
}
.mails .replies .reply-card {
  margin-top: 30px;
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}
.mails .replies .reply-card .avatar {
  background: rgba(0, 52, 187, 0.05);
  color: #0034bb;
  font-size: 16px;
}
.mails .replies .reply-card .reply-card-right {
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  box-shadow: -7px 8px 27.4px 0px rgba(33, 33, 33, 0.05);
}
.mails .replies .reply-card .reply-card-right .reply-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 25px;
  padding-bottom: 0px;
}
.mails .replies .reply-card .reply-card-right .reply-top h3 {
  font-size: 14px;
  font-weight: 600;
  color: rgb(33, 33, 33);
}
.mails .replies .reply-card .reply-card-right .reply-top .discard {
  font-size: 16px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.7);
  border-radius: 7px;
}
.mails .replies .reply-card .reply-card-right .reply-email {
  position: relative;
}
.mails .replies .reply-card .reply-card-right .reply-email .text-body {
  min-height: 12vh;
  max-height: 40vh;
  width: 100%;
  overflow-y: scroll;
  cursor: pointer;
}
.mails .replies .reply-card .reply-card-right .reply-email .text-body .quill {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mails .replies .reply-card .reply-card-right .reply-email .text-body .quill .quill-candidatesdetail {
  display: flex;
  flex-direction: column-reverse;
  white-space: pre-wrap;
  border-radius: 8px;
  width: 100%;
}
.mails .replies .reply-card .reply-card-right .reply-email .text-body .quill .quill-candidatesdetail ::-webkit-scrollbar {
  width: none;
  width: 0px;
}
.mails .replies .reply-card .reply-card-right .reply-email .text-body .quill .quill-candidatesdetail .ql-toolbar {
  border-radius: 7px;
  background: transparent;
  padding: 10px;
  width: 280px;
  margin-left: 10px;
  position: absolute;
  bottom: 13px;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}
.mails .replies .reply-card .reply-card-right .reply-email .text-body .quill .quill-candidatesdetail .ql-toolbar .ql-formats {
  margin-right: unset !important;
}
.mails .replies .reply-card .reply-card-right .reply-email .text-body .quill .quill-candidatesdetail .ql-toolbar .ql-formats:last-child button {
  margin-right: 0px;
}
.mails .replies .reply-card .reply-card-right .reply-email .text-body .quill .quill-candidatesdetail .ql-toolbar .ql-formats button {
  height: 27px;
  color: rgba(33, 33, 33, 0.8);
  width: unset;
  padding: 2px 0px;
  margin-right: 16px;
  position: relative;
  z-index: 1;
}
.mails .replies .reply-card .reply-card-right .reply-email .text-body .quill .quill-candidatesdetail .ql-toolbar .ql-formats .ql-active {
  background-color: rgba(0, 52, 187, 0.1);
  color: #212121 !important;
  border-radius: 4px;
}
.mails .replies .reply-card .reply-card-right .reply-email .text-body .quill .quill-candidatesdetail .ql-container {
  border: none !important;
  min-height: 50px;
  max-width: 100%;
  max-height: 100%;
  font-size: 16px;
  font-weight: 400;
  font-family: Poppins;
  padding: 20px 10px;
  padding-bottom: 20px;
  padding-top: 0px;
}
.mails .replies .reply-card .reply-card-right .reply-email .text-body .quill .quill-candidatesdetail .ql-container .ql-editor {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  font-family: Poppins;
}
.mails .replies .reply-card .reply-card-right .reply-email .text-body .quill .quill-candidatesdetail .ql-container .ql-editor.ql-blank::before {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  padding-left: 10px;
  left: 0px;
}
.mails .replies .reply-card .reply-card-right .reply-email .text-body .quill .quill-candidatesdetail .ql-container .ql-tooltip {
  left: 30px !important;
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  box-shadow: 0px 7px 11.9px 0px rgba(33, 33, 33, 0.1);
}
.mails .replies .reply-card .reply-card-right .reply-email .text-body .quill .quill-candidatesdetail .ql-container .ql-tooltip[data-mode=link]::before {
  content: "Enter link :";
  font-size: 14px;
  font-weight: 400;
}
.mails .replies .reply-card .reply-card-right .reply-email .text-body .quill .quill-candidatesdetail .ql-container .ql-tooltip::before {
  content: "Go to link :";
}
.mails .replies .reply-card .reply-card-right .reply-email .text-body .quill .quill-candidatesdetail .ql-container .ql-preview {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 52, 187);
}
.mails .replies .reply-card .reply-card-right .reply-email .text-body .quill .quill-candidatesdetail .ql-container a {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 52, 187);
}
.mails .replies .reply-card .reply-card-right .reply-email .text-body .quill .quill-candidatesdetail .ql-container .ql-action {
  border-right: none;
}
.mails .replies .reply-card .reply-card-right .reply-email .text-body .quill .quill-candidatesdetail .ql-picker-options {
  position: absolute;
  top: -150px;
  z-index: 2;
  height: 145px;
  overflow-y: scroll;
  border-radius: 5px;
}
.mails .replies .reply-card .reply-card-right .reply-email .text-body .attach {
  padding-bottom: 100px;
  width: 70%;
}
.mails .replies .reply-card .reply-card-right .reply-email .text-body .attach .file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 7px;
  border: 1px solid rgba(0, 52, 187, 0.2);
  background: #fff;
  padding: 4px 20px;
  margin-left: 25px;
  margin-bottom: 10px;
  font-size: 16px;
}
.mails .replies .reply-card .reply-card-right .reply-email .text-body .attach .file:last-child {
  margin-bottom: 0px;
}
.mails .replies .reply-card .reply-card-right .reply-email .buttons {
  overflow-x: hidden;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px;
  padding-left: 330px;
}
.mails .replies .reply-card .reply-card-right .reply-email .buttons .button-left {
  display: flex;
  align-items: center;
  gap: 20px;
}
.mails .replies .reply-card .reply-card-right .reply-email .buttons .button-left Button {
  display: flex;
  align-items: center;
  gap: 5px;
  border: none;
  outline: none;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: transparent;
  font-size: 16px;
  font-weight: 500;
  color: rgb(0, 52, 187);
  padding: 11px 20px;
  border-radius: 7px;
}
.mails .replies .reply-card .reply-card-right .reply-email .buttons .button-left .placeholder {
  color: rgb(33, 33, 33);
}
.mails .replies .reply-card .reply-card-right .reply-email .buttons .button-right Button {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 80px;
  font-size: 14px;
  font-weight: 500;
  padding: 9px 18px;
  border-radius: 7px;
  background: rgb(0, 52, 187);
}

.open-member {
  position: relative;
}
.open-member .MuiDrawer-paper {
  border-radius: 0px !important;
  max-width: 65% !important;
  min-width: 65%;
  justify-content: unset;
  padding-bottom: 0px !important;
  margin-bottom: 0px;
  border: none !important;
}
.open-member .Search-box {
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  width: 38%;
  padding: 10px 14px;
  margin-top: -45px;
  margin-left: auto;
}
.open-member .Search-box:hover {
  border: 1.5px solid rgb(0, 52, 187);
}
.open-member .Search-box input {
  border: none;
  outline: none;
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  color: #212121;
}
.open-member .member-list {
  padding-left: 40px;
  padding-right: 5px;
  height: 100%;
  overflow: scroll;
}
.open-member .member-list .name-heading {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-right: 33px;
}
.open-member .member-list .name-heading .heading {
  font-size: 24px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  margin-top: 15px;
  margin-bottom: 12px;
}
.open-member .member-list .name-heading p {
  font-size: 16px;
  font-weight: 500;
  color: rgb(0, 52, 187);
}
.open-member .member-list .row {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-right: 33px;
}
.open-member .member-list .row .status {
  width: 40%;
}
.open-member .member-list .row .status h1 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
  margin-bottom: 5px;
}
.open-member .member-list .row .status .select {
  width: 100%;
}
.open-member .member-list .row .status .select .MuiFormControl-root {
  width: 100%;
  border-radius: 8px;
}
.open-member .member-list .row .status .select .MuiFormControl-root .MuiInputBase-root {
  border: none;
}
.open-member .member-list .row .status .select .MuiFormControl-root .MuiInputBase-root .MuiSvgIcon-root {
  margin-right: 10px;
}
.open-member .member-list .row .status .select .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  font-size: 16px;
  background: transparent;
  padding: 9px 16px !important;
  font-weight: 500;
}
.open-member .member-list .row .status .select .item {
  display: flex;
  gap: 40px;
}
.open-member .member-list-bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #fff;
  box-shadow: 0px -4px 15px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 30px;
  padding-left: 40px;
}
.open-member .member-list-bottom .cancel {
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  font-size: 16px;
  font-weight: 500;
  color: rgb(33, 33, 33);
  min-width: 200px;
}
.open-member .member-list-bottom .select {
  min-width: 200px;
  border-radius: 7px;
  background: #0034bb;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.open-attachment .MuiDrawer-paper {
  border-radius: 0px !important;
  max-width: 65% !important;
  min-width: 65% !important;
  justify-content: unset;
  padding-bottom: 0px !important;
  margin-bottom: 0px;
  border: none !important;
  padding: 20px 30px;
}
.open-attachment .top {
  display: flex;
  align-content: center;
  justify-content: space-between;
}
.open-attachment .title {
  font-size: 24px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  padding-left: 12px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.open-placeholder .MuiDrawer-paper {
  border-radius: 0px !important;
  max-width: 65% !important;
  min-width: 65% !important;
  height: 100vh !important;
  justify-content: unset;
  padding-bottom: 0px !important;
  margin-bottom: 0px;
  border: none !important;
}
.open-placeholder .top {
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding-top: 20px;
}
.open-placeholder .title {
  font-size: 24px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  padding-left: 12px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 40px;
}
.open-placeholder .total {
  font-size: 16px;
  font-weight: 400;
  color: rgb(33, 33, 33);
  padding-left: 14px;
  margin-top: -10px;
  margin-bottom: 20px;
  padding-left: 40px;
}
.open-placeholder .template-total {
  margin-bottom: -5px;
}
.open-placeholder .placeholder {
  padding: 20px 30px;
  padding-top: 0px;
  height: 80%;
  overflow: scroll;
}
.open-placeholder .placeholder ::-webkit-scrollbar {
  width: 0px;
}
.open-placeholder .placeholder .card-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
  padding-left: 15px;
}
.open-placeholder .placeholder .card-wrapper .card {
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  width: 48%;
  padding: 20px 30px;
  padding-left: 16px;
}
.open-placeholder .placeholder .card-wrapper .card .radio-name {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.open-placeholder .placeholder .card-wrapper .card .radio-name .text {
  padding-top: 7px;
}
.open-placeholder .placeholder .card-wrapper .card .radio-name .text h2 {
  font-size: 16px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  margin-bottom: 3px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.open-placeholder .placeholder .card-wrapper .card .radio-name .text p {
  font-size: 14px;
  font-weight: 400;
  color: rgb(33, 33, 33);
}
.open-placeholder .placeholder .card-wrapper .card .radio-name .text .markdown-data {
  height: 70px;
  overflow: hidden;
}
.open-placeholder .placeholder .card-wrapper .active {
  border-radius: 7px;
  border: 1px solid rgba(0, 52, 187, 0.8);
  background: rgba(0, 52, 187, 0.05);
}
.open-placeholder .member-list-bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #fff;
  box-shadow: 0px -4px 15px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 30px;
  padding-left: 40px;
}
.open-placeholder .member-list-bottom .cancel {
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  font-size: 16px;
  font-weight: 500;
  color: rgb(33, 33, 33);
  min-width: 200px;
}
.open-placeholder .member-list-bottom .select {
  min-width: 200px;
  border-radius: 7px;
  background: #0034bb;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.ecn-datagrid-group .MuiDataGrid-columnHeaderTitleContainer {
  border: none !important;
}
.ecn-datagrid-group .MuiDataGrid-columnHeaderTitleContainer .MuiCheckbox-indeterminate {
  color: #0034bb;
  width: 40px !important;
  height: 40px !important;
}
.ecn-datagrid-group .MuiDataGrid-menuIcon {
  margin-right: 37px !important;
}
.ecn-datagrid-group .tag-chip {
  background: #f2f4f8;
  color: #212121;
  padding: 1px 1px 1px 2px;
  min-height: 20px;
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.MuiDataGrid-row {
  padding-left: 11px !important;
}

.apply-template-confirmation-dialog .confirm-apply-template-btn {
  border: 1px solid #ba0000;
  color: #ba0000;
  font-size: 18px;
  font-weight: 600;
  padding: 6px 60px;
}
.apply-template-confirmation-dialog .confirm-apply-template-btn:hover {
  background: rgba(186, 0, 0, 0.08);
  border: 1px solid #ba0000;
}
.apply-template-confirmation-dialog .cancle-apply-template-btn {
  border: 1px solid rgba(33, 33, 33, 0.2);
  font-size: 18px;
  font-weight: 600;
  padding: 6px 60px;
}

.template .header-template {
  padding-right: 20px;
}
.template .header-template .top {
  display: flex;
  align-items: center;
  gap: 16px;
}
.template .header-template .top .dots {
  width: 5px;
  height: 5px;
  background: rgba(33, 33, 33, 0.6);
  border-radius: 100%;
}
.template .header-template .top p {
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
}
.template .header-template h1 {
  font-size: 24px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  margin-top: 15px;
}
.template .header-template .total {
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
  margin-top: 10px;
}
.template .template-body {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 15px;
  padding-right: 20px;
}
.template .template-body .default-card {
  width: 30%;
  border-radius: 5px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 160px;
  cursor: pointer;
}
.template .template-body .default-card p {
  font-size: 14px;
  font-weight: 500;
  color: rgb(33, 33, 33);
}
.template .template-body .list-card {
  width: 30%;
  border-radius: 5px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  min-height: 160px;
  position: relative;
}
.template .template-body .list-card:hover {
  border-radius: 5px;
  border: 1px solid rgba(0, 52, 187, 0.4);
  background: #fff;
  box-shadow: 0px 10px 16px 0px rgba(0, 52, 187, 0.05);
}
.template .template-body .list-card:hover .card h2 {
  margin-top: -8px;
  margin-bottom: 2px;
}
.template .template-body .list-card:hover .card h2 .icon-button {
  display: block;
  cursor: pointer;
  width: 40px;
  height: 40px;
}
.template .template-body .list-card .card {
  height: 100%;
  padding-top: 20px;
  padding-bottom: 50px;
}
.template .template-body .list-card .card h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  margin-bottom: 10px;
  padding-left: 20px;
  width: 95%;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.template .template-body .list-card .card h2 .icon-button {
  display: none;
}
.template .template-body .list-card .card .markdown-data {
  line-clamp: 1;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-left: 20px;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 400;
  color: rgb(33, 33, 33);
  height: 70px;
  overflow: hidden;
}
.template .template-body .list-card .card .template-card-bottom {
  position: absolute;
  bottom: 0px;
  padding: 6px 20px;
  border-radius: 5px;
  background: rgba(0, 52, 187, 0.05);
  left: 0px;
  right: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.template .template-body .list-card .card .template-card-bottom .text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.template .template-body .list-card .card .template-card-bottom .text p {
  font-size: 12px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
}
.template .template-body .list-card .card .edit-text {
  position: absolute;
  bottom: 0px;
  padding: 6px 15px;
  border-radius: 5px;
  left: 0px;
  right: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  text-align: right;
}
.template .template-body .list-card .card .edit-text .created {
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 52, 187, 0.7);
}
.template .template-body .list-card .feedback {
  background: rgba(0, 52, 187, 0.05);
  min-height: 160px;
  padding-top: 30px;
  padding: 25px 50px;
}
.template .template-body .list-card .feedback h1 {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: rgb(33, 33, 33);
}
.template .template-body .list-card .feedback .button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
}
.template .template-body .list-card .feedback .button button {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.7);
}
.template .template-body .list-card .active h2 {
  margin-top: -8px;
  margin-bottom: 2px;
}
.template .template-body .list-card .active h2 .icon-button {
  display: block;
  cursor: pointer;
  width: 40px;
  height: 40px;
}
.template .create-template .top {
  padding-right: 30px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}
.template .create-template .top .back {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #212121;
  padding-bottom: 16px;
  cursor: pointer;
}
.template .create-template .top Button {
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.7);
  margin-top: -15px;
}
.template .create-template .subject {
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
}
.template .create-template .subject p {
  width: 18%;
  font-size: 16px;
  font-weight: 400;
  color: rgb(33, 33, 33);
}
.template .create-template .subject .view-subject {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: rgb(33, 33, 33);
  margin-left: 30px;
}
.template .create-template .subject input {
  margin-left: 30px;
  width: 80%;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  color: rgb(33, 33, 33);
  white-space: pre-wrap;
  height: auto;
}
.template .create-template .text-body {
  min-height: 52vh;
  width: 100%;
  overflow-y: scroll;
  cursor: pointer;
}
.template .create-template .text-body .quill {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.template .create-template .text-body .quill .quill-candidatesdetail {
  display: flex;
  flex-direction: column-reverse;
  white-space: pre-wrap;
  border-radius: 8px;
  width: 100%;
}
.template .create-template .text-body .quill .quill-candidatesdetail ::-webkit-scrollbar {
  width: none;
  width: 0px;
}
.template .create-template .text-body .quill .quill-candidatesdetail .ql-toolbar {
  border-radius: 7px;
  background: transparent;
  padding: 10px;
  width: 300px;
  margin-left: 20px;
  position: absolute;
  bottom: 12px;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  z-index: 2;
}
.template .create-template .text-body .quill .quill-candidatesdetail .ql-toolbar .ql-formats {
  margin-right: unset !important;
}
.template .create-template .text-body .quill .quill-candidatesdetail .ql-toolbar .ql-formats:last-child button {
  margin-right: 0px;
}
.template .create-template .text-body .quill .quill-candidatesdetail .ql-toolbar .ql-formats button {
  height: 27px;
  color: rgba(33, 33, 33, 0.8);
  width: unset;
  padding: 2px 0px;
  margin-right: 16px;
  position: relative;
  z-index: 1;
}
.template .create-template .text-body .quill .quill-candidatesdetail .ql-toolbar .ql-formats .ql-active {
  background-color: rgba(0, 52, 187, 0.1);
  color: #212121 !important;
  border-radius: 4px;
}
.template .create-template .text-body .quill .quill-candidatesdetail .ql-container {
  border: none !important;
  min-height: 50px;
  max-width: 100%;
  max-height: 100%;
  font-size: 16px;
  font-weight: 400;
  font-family: Poppins;
  padding: 20px 15px;
  padding-bottom: 20px;
}
.template .create-template .text-body .quill .quill-candidatesdetail .ql-container .ql-editor.ql-blank::before {
  left: 30px;
  font-style: unset;
}
.template .create-template .text-body .quill .quill-candidatesdetail .ql-container .ql-editor {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  font-family: Poppins;
}
.template .create-template .text-body .quill .quill-candidatesdetail .ql-container .ql-editor.ql-blank::before {
  font-size: 16px;
  font-weight: 400;
}
.template .create-template .text-body .quill .quill-candidatesdetail .ql-container .ql-tooltip {
  left: 30px !important;
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  box-shadow: 0px 7px 11.9px 0px rgba(33, 33, 33, 0.1);
}
.template .create-template .text-body .quill .quill-candidatesdetail .ql-container .ql-tooltip[data-mode=link]::before {
  content: "Enter link :";
  font-size: 14px;
  font-weight: 400;
}
.template .create-template .text-body .quill .quill-candidatesdetail .ql-container .ql-tooltip::before {
  content: "Go to link :";
}
.template .create-template .text-body .quill .quill-candidatesdetail .ql-container .ql-preview {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 52, 187);
}
.template .create-template .text-body .quill .quill-candidatesdetail .ql-container a {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 52, 187);
}
.template .create-template .text-body .quill .quill-candidatesdetail .ql-container .ql-action {
  border-right: none;
}
.template .create-template .text-body .quill .quill-candidatesdetail .ql-picker-options {
  position: absolute;
  top: -150px;
  z-index: 2;
  height: 145px;
  overflow-y: scroll;
  border-radius: 5px;
}
.template .create-template .text-body .view-mark {
  margin-left: 30px;
  padding-top: 20px;
}
.template .create-template .text-body .attach {
  margin-bottom: 100px;
  width: 70%;
}
.template .create-template .text-body .attach .file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 7px;
  border: 1px solid rgba(0, 52, 187, 0.2);
  background: #fff;
  padding: 15px 20px;
  margin-left: 25px;
  margin-bottom: 10px;
}
.template .create-template .text-body .attach .file:last-child {
  margin-bottom: 0px;
}
.template .create-template .buttons {
  overflow-x: hidden;
  width: 80.8%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px;
  padding-left: 340px;
  background: #fff;
}
.template .create-template .buttons .button-left {
  display: flex;
  align-items: center;
  gap: 20px;
}
.template .create-template .buttons .button-left Button {
  display: flex;
  align-items: center;
  gap: 5px;
  border: none;
  outline: none;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: transparent;
  font-size: 16px;
  font-weight: 500;
  color: rgb(33, 33, 33);
  padding: 9px 18px;
  border-radius: 7px;
}
.template .create-template .buttons .button-left .placeholder {
  color: rgb(33, 33, 33);
}
.template .create-template .buttons .button-right Button {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 150px;
  font-size: 14px;
  font-weight: 500;
  padding: 9px 25px;
  border-radius: 7px;
}

.notifications-page {
  margin-left: 3px;
  padding: 0 28px;
}
.notifications-page .heading {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 0;
  margin-top: 75px;
}
.notifications-page .heading h4 {
  color: #212121;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}
.notifications-page .heading p {
  font-size: 10px;
  font-weight: 800;
  line-height: 16px;
  background: #0034bb;
  padding: 1px 5px;
  border-radius: 50%;
  color: #fff;
  height: 19px;
  width: 19px;
  text-align: center;
}
.notifications-page .button-group {
  text-align: right;
  margin-bottom: 31px;
}
.notifications-page .button-group .filter-btn {
  border-radius: 7px !important;
  border: 1px solid rgba(33, 33, 33, 0.2) !important;
  padding: 7px 20px !important;
  color: #212121;
}
.notifications-page .button-group .sort-btn {
  margin-right: 10px;
}
.notifications-page .container {
  padding: 38px 25px;
  border-radius: 15px;
  border: 0.5px solid rgba(33, 33, 33, 0.1);
  margin-bottom: 50px;
}
.notifications-page .container .notification-wrapper {
  margin-bottom: 8px;
  margin-left: -3px;
  padding: 12px;
  position: relative;
}
.notifications-page .container .notification-wrapper:hover {
  border-radius: 10px;
  background: rgba(0, 52, 187, 0.03);
}
.notifications-page .container .notification-wrapper:last-child {
  margin-bottom: 0;
}
.notifications-page .container .notification-wrapper .message {
  color: #212121;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 4px;
}
.notifications-page .container .notification-wrapper .time {
  color: #212121;
  font-size: 12px;
  line-height: 18px;
}
.notifications-page .container .notification-wrapper::before {
  content: "";
  position: absolute;
  width: 3px;
  height: calc(100% - 24px);
  background: transparent;
  border-radius: 8px;
  left: 0;
}
.notifications-page .container .unread {
  margin-left: 0;
  padding-left: 28px;
}
.notifications-page .container .unread .message {
  font-weight: 500;
}
.notifications-page .container .unread::before {
  content: "";
  position: absolute;
  width: 3px;
  height: calc(100% - 24px);
  background: #0034bb;
  border-radius: 8px;
  left: 12px;
  top: 12px;
  bottom: 12px;
}
.notifications-page .container .fallback {
  text-align: center;
}
.notifications-page .container .fallback img {
  max-height: 460px;
}
.notifications-page .container .fallback p {
  color: #212121;
  font-size: 19px;
  font-weight: 500;
}

.notification-filter-menu .MuiPaper-root {
  border-radius: 6px;
  box-shadow: 0px 7px 30px 7px rgba(0, 0, 0, 0.5);
  min-width: 230px;
  padding-inline: 11px;
}
.notification-filter-menu .MuiPaper-root .MuiList-root .MuiButtonBase-root {
  border-radius: 10px;
  padding: 12px 20px;
  text-transform: capitalize;
  margin-bottom: 0;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #212121;
}
.notification-filter-menu .MuiPaper-root .MuiList-root .MuiButtonBase-root:hover {
  background: rgba(0, 52, 187, 0.05);
}
.notification-filter-menu .MuiPaper-root .MuiList-root .selected {
  background: rgba(0, 52, 187, 0.05);
  color: #0034bb;
  font-weight: 600 !important;
}

.about-company {
  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  padding: 20px 35px;
  position: relative;
  min-height: 600px;
  position: relative;
}
.about-company .title {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}
.about-company .row {
  width: 100%;
  margin-top: 20px;
}
.about-company .row .p {
  font-size: 16px;
  font-weight: 400;
  color: #212121;
}
.about-company .row .input-box {
  width: 100%;
  margin-top: 6px;
}
.about-company .row .input-box ::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
}
.about-company .row .input-box .MuiInputBase-root .MuiInputBase-inputMultiline,
.about-company .row .input-box .MuiInputBase-root .MuiInputBase-input {
  padding: 12px 22px !important;
  font-size: 16px;
  font-weight: 500;
  color: #212121;
}
.about-company .row .input-box .MuiOutlinedInput-root .MuiInputBase-inputMultiline {
  padding: 0px 10px !important;
}
.about-company .row .button {
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 9px 30px;
  font-size: 14px;
  font-weight: 500;
  color: rgb(0, 52, 187);
  background: transparent;
  margin-top: 12px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 180px;
}
.about-company .row .button .icon {
  display: none;
}
.about-company .row .button:hover {
  background: rgba(0, 52, 187, 0.05);
}
.about-company .row .button:hover .icon {
  display: block;
}
.about-company .row .edit-button {
  border: none;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 500;
}
.about-company .row .image {
  width: 65px;
  height: 65px;
  margin-top: 10px;
}
.about-company .row .image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.about-company .row .sub-heading {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
}
.about-company .row .quill-candidatesdetail {
  display: flex;
  flex-direction: column-reverse;
  white-space: pre-wrap;
  margin-top: 6px;
  border-radius: 8px;
  width: 100%;
  height: 250px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
}
.about-company .row .quill-candidatesdetail:hover {
  border: 2px solid rgb(0, 52, 187);
}
.about-company .row .quill-candidatesdetail .ql-container {
  border: none !important;
  min-height: 50px;
  max-width: 100%;
  max-height: 100%;
  font-size: 16px;
  font-weight: 400;
  font-family: Poppins;
  padding: 20px 10px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.about-company .row .quill-candidatesdetail .ql-container .ql-editor {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  font-family: Poppins;
  padding-top: 20px;
  color: rgb(33, 33, 33);
}
.about-company .row .quill-candidatesdetail .ql-container .ql-editor.ql-blank::before {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  padding-left: 10px;
  left: 0px;
  color: rgba(33, 33, 33, 0.3);
}
.about-company .row .quill-candidatesdetail .ql-container .ql-tooltip {
  left: 30px !important;
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  box-shadow: 0px 7px 11.9px 0px rgba(33, 33, 33, 0.1);
}
.about-company .row .quill-candidatesdetail .ql-container .ql-tooltip[data-mode=link]::before {
  content: "Enter link :";
  font-size: 14px;
  font-weight: 400;
}
.about-company .row .quill-candidatesdetail .ql-container .ql-tooltip::before {
  content: "Go to link :";
}
.about-company .row .quill-candidatesdetail .ql-container .ql-preview {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 52, 187);
}
.about-company .row .quill-candidatesdetail .ql-container a {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 52, 187);
}
.about-company .row .quill-candidatesdetail .ql-container .ql-action {
  border-right: none;
}
.about-company .row .quill-candidatesdetail .ql-picker-options {
  position: absolute;
  top: -150px;
  z-index: 2;
  height: 145px;
  overflow-y: scroll;
  border-radius: 5px;
}
.about-company .bottom-button {
  position: absolute;
  bottom: 20px;
  right: 30px;
  text-align: right;
  margin-top: 20px;
}
.about-company .bottom-button .next {
  display: flex;
  align-items: center;
  gap: 5px;
  min-width: 160px;
  height: 42px;
  box-shadow: none;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
}
.about-company .bottom-button .next .icon {
  display: none;
}
.about-company .bottom-button .next:hover .icon {
  display: block;
}
.about-company .private-job-board-url .p {
  font-size: 16px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 6px;
}
.about-company .private-job-board-url .private-job-board-url-input {
  max-width: 450px;
  position: relative;
}
.about-company .private-job-board-url .private-job-board-url-input.editing:hover .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: 1.5px solid #0034bb;
}
.about-company .private-job-board-url .private-job-board-url-input.success:hover .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: 1.5px solid #027a48;
}
.about-company .private-job-board-url .private-job-board-url-input.success .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: 1.5px solid #027a48;
}
.about-company .private-job-board-url .private-job-board-url-input.error:hover .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: 1.5px solid #ba0000 !important;
}
.about-company .private-job-board-url .private-job-board-url-input.error .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: 1.5px solid #ba0000;
}
.about-company .private-job-board-url .private-job-board-url-input input {
  width: 100%;
  padding: 12px 16px;
  padding-left: 154px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  border: 0px;
  border-radius: 8px;
}
.about-company .private-job-board-url .private-job-board-url-input .icon {
  position: absolute;
  right: 16px;
  top: 13px;
  width: 20px;
  height: 20px;
}
.about-company .private-job-board-url .private-job-board-url-input .icon-success {
  color: #027a48;
}
.about-company .private-job-board-url .private-job-board-url-input .icon-error {
  color: #ba0000;
}
.about-company .private-job-board-url .private-job-board-url-input .copy-icon-button {
  position: absolute;
  right: 10px;
  top: 6px;
}
.about-company .private-job-board-url .private-job-board-url-input .icon-loader {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.about-company .private-job-board-url .private-job-board-url-input::before {
  content: "jobs.onefinnet.com/";
  position: absolute;
  left: 16px;
  top: 11px;
  color: rgba(33, 33, 33, 0.3);
  font-weight: 400;
  line-height: 24px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}
.about-company .private-job-board-url .input-btn-row {
  display: flex;
  align-items: center;
  gap: 18px;
}
.about-company .private-job-board-url .input-btn-row .buttons {
  display: flex;
  gap: 12px;
}
.about-company .private-job-board-url .input-btn-row .buttons button {
  border-radius: 7px;
  background: #ffffff;
  height: 47px;
  margin-top: 3px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 8px 18px;
}
.about-company .private-job-board-url .input-btn-row .buttons button:hover {
  cursor: pointer;
}
.about-company .private-job-board-url .input-btn-row .buttons .edit-button > span {
  margin-left: 12px;
}
.about-company .private-job-board-url .input-btn-row .buttons .save-button {
  background: #0034bb;
  border-color: #0034bb;
  color: #ffffff;
}
.about-company .private-job-board-url .input-btn-row .buttons .save-button:disabled {
  background: rgba(0, 52, 187, 0.6);
}
.about-company .private-job-board-url .sub-heading {
  font-size: 14px;
  font-weight: 400;
  margin-top: 6px;
}
.about-company .private-job-board-url .sub-error {
  color: #ba0000;
}
.about-company .private-job-board-url .sub-success {
  color: #027a48;
}

.open-file-upload {
  padding: 20px 30px;
}
.open-file-upload .MuiDrawer-paper {
  border-radius: 0px !important;
  max-width: 65% !important;
  min-width: 65% !important;
  height: 100vh !important;
  justify-content: unset;
  padding-bottom: 0px !important;
  margin-bottom: 0px;
  border: none !important;
  padding: 30px 40px;
}
.open-file-upload .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.open-file-upload .top .back {
  font-size: 18px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: -10px;
}

.job-creation {
  padding-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 80px;
  position: relative;
}
.job-creation .breadcrumb p {
  margin-top: 2px;
}
.job-creation .field-body {
  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  padding: 20px 35px;
  min-height: 70vh;
  position: relative;
}
.job-creation .field-body .top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.job-creation .field-body .top h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}
.job-creation .field-body .top .top-left .step {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
}
.job-creation .field-body .top .top-left .step span {
  color: rgb(33, 33, 33);
}
.job-creation .field-body .top .top-left .progress {
  width: 130px;
  background: rgba(0, 52, 187, 0.05);
  padding: 4px;
  border-radius: 8px;
}
.job-creation .field-body .top .top-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.job-creation .field-body .top .top-body .btn-contained {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  min-width: 200px;
  padding: 9px 2px !important;
  gap: 8px;
  border-radius: 8px;
  box-shadow: none;
  background: radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(94deg, #0034bb 1.69%, #0034bb 99.83%);
}
.job-creation .field-body .basic-details {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.job-creation .field-body .basic-details .body-wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.job-creation .field-body .basic-details .body-wrapper .body {
  width: 65%;
  position: relative;
  padding-bottom: 60px;
}
.job-creation .field-body .basic-details .body-wrapper .body .row {
  margin-top: 15px;
}
.job-creation .field-body .basic-details .body-wrapper .body .row .label {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 7px;
}
.job-creation .field-body .basic-details .body-wrapper .body .row .input-box {
  width: 100%;
}
.job-creation .field-body .basic-details .body-wrapper .body .row .input-box .MuiInputBase-root {
  height: 46px;
  padding: unset;
}
.job-creation .field-body .basic-details .body-wrapper .body .row .input-box .MuiInputBase-root .MuiInputBase-input {
  padding: 11.4px 14px !important;
}
.job-creation .field-body .basic-details .body-wrapper .body .row .auto-box {
  width: 100%;
}
.job-creation .field-body .basic-details .body-wrapper .body .row .auto-box .MuiOutlinedInput-root {
  height: unset;
  padding: 4px 10px;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card {
  width: 35%;
  height: 91%;
  max-width: 329px;
  position: absolute;
  right: 0px;
  top: 30px;
  background: #ffffff;
  perspective: 1000px;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 18px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  border-radius: 12px;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner.flipped {
  transform: rotateY(180deg);
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner.flipped .card-front {
  visibility: hidden;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front,
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-back {
  position: absolute;
  width: 100%;
  backface-visibility: hidden;
  background: #ffffff;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front {
  transition: visibility 0.3s;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front .card-header {
  height: 126px;
  width: 90%;
  border-radius: 5px;
  background: rgba(217, 217, 217, 0.5);
  position: relative;
  overflow: clip;
  padding: 0px 18px;
  margin: auto;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front .card-header .header-text {
  color: #533f0d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 84px;
  width: 96px;
  position: absolute;
  top: 17px;
  left: 14px;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front .card-header .header-text .reduce {
  color: rgba(83, 63, 13, 0.7);
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  letter-spacing: -0.6px;
  padding-left: 14px;
  padding-bottom: 8px;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front .card-header .header-text .number {
  font-size: 65.743px;
  font-style: italic;
  font-weight: 600;
  line-height: 0;
  letter-spacing: -4.602px;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front .card-header .header-text .number .small {
  font-size: 36.948px;
  font-style: italic;
  font-weight: 275;
  line-height: normal;
  letter-spacing: -2.586px;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front .card-header .header-text .time-effort {
  line-height: 20%;
  color: #533f0d;
  font-size: 12px;
  font-style: italic;
  font-weight: 600;
  letter-spacing: -0.24px;
  margin-top: -4px;
  margin-left: 8px;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front .card-header .new-chip {
  display: inline-flex;
  padding: 3.71px 12.839px;
  justify-content: center;
  align-items: center;
  gap: 6.419px;
  border-radius: 17.065px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #000;
  font-size: 10.387px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  z-index: 1;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front .card-header .search-gif {
  position: absolute;
  right: -18px;
  top: -30px;
  transform: rotate(15deg);
}
@media (max-width: 1320px) {
  .job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front .card-header .search-gif {
    display: none;
  }
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front .card-body {
  margin-top: 12px;
  padding: 0px 18px;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front .card-body .title {
  color: #000;
  font-size: 21px;
  font-weight: 600;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front .card-body .text {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-top: 6px;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front .card-body .credits {
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 7px;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front .card-body .credits .number {
  color: #000;
  font-size: 56px;
  font-weight: 500;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front .card-body .credits .line {
  width: 2px;
  height: 58px;
  background: rgba(33, 33, 33, 0.1);
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front .card-body .credits .description {
  color: rgba(33, 33, 33, 0.6);
  font-size: 14px;
  font-weight: 500;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front .card-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 18px;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front .divider {
  margin: 19px 0 15px 0;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front .params-selector {
  padding: 0px 18px;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front .params-selector .heading {
  font-size: 14px;
  margin-bottom: 8px;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front .params-selector .chip {
  border-radius: 16px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: transparent;
  margin-right: 9px;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-front .params-selector .selected {
  background: #0034bb;
  color: #ffffff;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-back {
  transform: rotateY(180deg);
  padding: 18px;
  padding-top: 0px;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-back .job-parameter-header {
  color: #000;
  margin-bottom: 16px;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-back .job-parameter-header .title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 11px;
  display: flex;
  align-items: center;
  margin-left: -10px;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-back .job-parameter-header .sub-title {
  font-size: 14px;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-back .separator {
  height: 1px;
  background: rgba(33, 33, 33, 0.2);
  margin-bottom: 15px;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-back .options .option-container {
  display: flex;
  align-items: start;
  cursor: pointer;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-back .options .option-container .option-text {
  padding-top: 10px;
  color: #212121;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-back .options .option-container .option-text-heading {
  font-size: 14px;
  font-weight: 500;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-back .options .option-container .option-text-description {
  font-size: 14px;
  font-weight: 400;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-back .options .option-container .option-text-note {
  color: rgba(33, 33, 33, 0.5);
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-back .parameters-section {
  margin-top: 14px;
  max-height: 220px;
  overflow-y: scroll;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-back .parameters-section .title {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.15px;
  text-align: left;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-back .parameters-section .parameters-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-back .parameters-section .parameters-list .parameter {
  border-radius: 16px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 6px 11px;
  color: #000;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-back .parameters-section .parameters-list .parameter[data-selected=true] {
  background: #0034bb;
  color: white;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-back .cta-wrapper {
  display: flex;
  justify-content: end;
  margin-top: 24px;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-back .cta-wrapper .confirm-button {
  font-size: 16px;
  font-weight: 500;
  box-shadow: none;
}
.job-creation .field-body .basic-details .body-wrapper .ai-resume-screening-card .card-inner .card-back .cta-wrapper .confirm-button.MuiButtonBase-root {
  padding: 7px 24px;
  border-radius: 7px;
}
.job-creation .field-body .basic-details .bottom-button {
  position: fixed;
  bottom: 0px;
  width: 100%;
  margin: auto;
  margin-left: -5%;
}
.job-creation .field-body .basic-details .bottom-button .bottom-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  width: 80vw;
}
.job-creation .field-body .basic-details::-webkit-scrollbar {
  width: 0px;
  width: none;
}
.job-creation .field-body .skill {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.job-creation .field-body .skill .body {
  position: relative;
}
.job-creation .field-body .skill .body .row {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 3px;
}
.job-creation .field-body .skill .body .row .label {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 5px;
}
.job-creation .field-body .skill .body .row .exp-input {
  display: flex;
  align-items: center;
  gap: 20px;
}
.job-creation .field-body .skill .body .row .exp-input .ecn-number-input-v2 .input-icon ::placeholder {
  font-family: Poppins;
}
.job-creation .field-body .skill .body .row .chip-container {
  display: flex;
  flex-direction: column;
  gap: 11px;
}
.job-creation .field-body .skill .body .row .chip-container p {
  font-size: 13px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}
.job-creation .field-body .skill .body .row .chip-container .chip-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin: 10px 0 30px 0;
}
.job-creation .field-body .skill .body .row .chip-container .chip-wrapper .skill-chip {
  display: flex;
  align-items: center;
  gap: 6px;
  height: 32px;
  padding: 3px 16px !important;
  color: #212121;
  font-weight: 400;
  font-size: 14px;
  border-radius: 16px !important;
  border: 1px solid rgba(33, 33, 33, 0.2);
  cursor: pointer;
}
.job-creation .field-body .skill .body .row .chip-container .chip-wrapper .skill-chip.selected {
  color: #ffffff;
  background: rgb(0, 52, 187);
  border: none;
}
.job-creation .field-body .skill .body .row .chip-container .add-chip {
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  color: rgb(0, 52, 187);
  padding: 20px 10px !important;
  font-weight: 500;
  width: 150px;
  margin-top: -15px;
}
.job-creation .field-body .skill .body .row .MuiAutocomplete-root .auto-box {
  width: 100%;
}
.job-creation .field-body .skill .body .row .MuiAutocomplete-root .auto-box .MuiInputBase-root {
  padding: 5px 10px !important;
}
.job-creation .field-body .skill .body .row .press {
  font-size: 14px;
  font-weight: 400;
  color: rgb(33, 33, 33);
  margin-top: 7px;
  margin-left: 3px;
}
.job-creation .field-body .skill .bottom-button {
  position: fixed !important;
  bottom: 0px !important;
  width: 100%;
  margin: auto;
  margin-left: -5%;
}
.job-creation .field-body .skill .bottom-button .bottom-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  width: 80vw;
}
.job-creation .field-body .description {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.job-creation .field-body .description .body {
  position: relative;
}
.job-creation .field-body .description .body .row {
  margin-top: 20px;
}
.job-creation .field-body .description .body .row .label {
  font-size: 14px;
  font-weight: 400;
  color: rgb(33, 33, 33);
  margin-bottom: 6px;
}
.job-creation .field-body .description .body .row .quill-candidatesdetail {
  display: flex;
  flex-direction: column-reverse;
  white-space: pre-wrap;
  margin-top: 6px;
  margin-bottom: 30px;
  border-radius: 8px;
  width: 100%;
  height: 390px;
  border: 1.5px solid rgba(0, 0, 0, 0.2);
  position: relative;
}
.job-creation .field-body .description .body .row .quill-candidatesdetail:hover {
  border: 1.5px solid rgb(0, 52, 187);
}
.job-creation .field-body .description .body .row .quill-candidatesdetail .ql-toolbar {
  border-radius: 7px;
  background: transparent;
  padding: 10px;
  width: 280px;
  margin-left: 10px;
  position: absolute;
  bottom: 13px;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}
.job-creation .field-body .description .body .row .quill-candidatesdetail .ql-toolbar .ql-formats {
  margin-right: unset !important;
}
.job-creation .field-body .description .body .row .quill-candidatesdetail .ql-toolbar .ql-formats:last-child button {
  margin-right: 0px;
}
.job-creation .field-body .description .body .row .quill-candidatesdetail .ql-toolbar .ql-formats button {
  height: 27px;
  color: rgba(33, 33, 33, 0.8);
  width: unset;
  padding: 2px 0px;
  margin-right: 16px;
  position: relative;
  z-index: 1;
}
.job-creation .field-body .description .body .row .quill-candidatesdetail .ql-toolbar .ql-formats .ql-active {
  background-color: rgba(0, 52, 187, 0.1);
  color: #212121 !important;
  border-radius: 4px;
}
.job-creation .field-body .description .body .row .quill-candidatesdetail .ql-container {
  border: none !important;
  min-height: 50px;
  max-width: 100%;
  max-height: 100%;
  font-size: 16px;
  font-weight: 400;
  font-family: Poppins;
  padding: 20px 10px;
  padding-bottom: 60px;
  padding-top: 0px;
}
.job-creation .field-body .description .body .row .quill-candidatesdetail .ql-container .ql-editor {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  font-family: Poppins;
  padding-top: 20px;
  color: rgb(33, 33, 33);
}
.job-creation .field-body .description .body .row .quill-candidatesdetail .ql-container .ql-editor.ql-blank::before {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  padding-left: 10px;
  left: 0px;
  color: rgba(33, 33, 33, 0.3);
}
.job-creation .field-body .description .body .row .quill-candidatesdetail .ql-container .ql-tooltip {
  left: 30px !important;
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  box-shadow: 0px 7px 11.9px 0px rgba(33, 33, 33, 0.1);
}
.job-creation .field-body .description .body .row .quill-candidatesdetail .ql-container .ql-tooltip[data-mode=link]::before {
  content: "Enter link :";
  font-size: 14px;
  font-weight: 400;
}
.job-creation .field-body .description .body .row .quill-candidatesdetail .ql-container .ql-tooltip::before {
  content: "Go to link :";
}
.job-creation .field-body .description .body .row .quill-candidatesdetail .ql-container .ql-preview {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 52, 187);
}
.job-creation .field-body .description .body .row .quill-candidatesdetail .ql-container a {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 52, 187);
}
.job-creation .field-body .description .body .row .quill-candidatesdetail .ql-container .ql-action {
  border-right: none;
}
.job-creation .field-body .description .body .row .quill-candidatesdetail .ql-picker-options {
  position: absolute;
  top: -150px;
  z-index: 2;
  height: 145px;
  overflow-y: scroll;
  border-radius: 5px;
}
.job-creation .field-body .description .body .row .generate-ai-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: -100px;
  margin-right: 27px;
}
.job-creation .field-body .description .body .row .generate-ai-btn .btn-contained {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  min-width: 200px;
  padding: 9px 2px !important;
  gap: 8px;
  border-radius: 8px;
  background: radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(94deg, #0034bb 1.69%, #0034bb 99.83%);
}
.job-creation .field-body .description .body .row .generate-ai-btn .check-draft {
  min-width: 168px;
}
.job-creation .field-body .description .bottom-button {
  position: fixed !important;
  bottom: 0px !important;
  width: 100%;
  margin: auto;
  margin-left: -5%;
}
.job-creation .field-body .description .bottom-button .bottom-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  width: 80vw;
}
.job-creation .field-body .bottom-button {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}
.job-creation .field-body .bottom-button .left {
  display: flex;
  align-items: center;
  gap: 20px;
}
.job-creation .field-body .bottom-button .left .back {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 6px 30px;
  border-radius: 7px;
  color: #212121;
}
.job-creation .field-body .bottom-button .left .save {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 6px 20px;
  border-radius: 7px;
  color: rgb(0, 52, 187);
}
.job-creation .field-body .bottom-button .next {
  font-size: 16px;
  font-weight: 500;
  padding: 6px 20px;
  border-radius: 7px;
  box-shadow: none;
  width: 140px;
  position: relative;
}
.job-creation .field-body .bottom-button .next .icon {
  position: absolute;
  top: 50%;
  right: 65%;
  transform: translateY(-50%);
  opacity: 1;
  transition: opacity 0.6s ease;
}
.job-creation .field-body .bottom-button .next:hover .icon {
  display: block;
  opacity: 1;
}
.job-creation .field-body .question {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 30px;
}
.job-creation .field-body .question .question-body {
  position: relative;
  margin-top: 30px;
  padding-bottom: 30px;
}
.job-creation .field-body .question .question-body .ques-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-bottom: 20px;
}
.job-creation .field-body .question .question-body .ques-wrapper .ques {
  padding: 20px 25px;
  padding-bottom: 0px;
  padding-top: 0px;
}
.job-creation .field-body .question .question-body .ques-wrapper .ques .drags {
  text-align: center;
  padding: 5px;
}
.job-creation .field-body .question .question-body .ques-wrapper .ques .drags .drags-button {
  margin: auto;
  width: 50px;
  border-radius: 8px;
  color: #212121;
}
.job-creation .field-body .question .question-body .ques-wrapper .ques .container {
  display: flex;
  gap: 20px;
  padding-bottom: 30px;
  padding-top: 0px;
}
.job-creation .field-body .question .question-body .ques-wrapper .ques .container .question-answer {
  width: 60%;
}
.job-creation .field-body .question .question-body .ques-wrapper .ques .container .question-answer .MuiFormControl-root {
  width: 100%;
  border-radius: 8px;
}
.job-creation .field-body .question .question-body .ques-wrapper .ques .container .question-answer .MuiFormControl-root .MuiInputBase-root {
  border: none;
  height: 43px;
  padding: 12px 0px !important;
}
.job-creation .field-body .question .question-body .ques-wrapper .ques .container .question-answer .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  padding: 15px;
  font-size: 16px;
  font-weight: 500;
  color: #212121;
}
.job-creation .field-body .question .question-body .ques-wrapper .ques .container .question-answer .radio-container {
  margin-top: 10px;
}
.job-creation .field-body .question .question-body .ques-wrapper .ques .container .question-answer .radio-container .radio-div {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 15px;
}
.job-creation .field-body .question .question-body .ques-wrapper .ques .container .question-answer .radio-container .add-closed {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  justify-content: left;
}
.job-creation .field-body .question .question-body .ques-wrapper .ques .container .question-answer .radio-container .add-closed button {
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
}
.job-creation .field-body .question .question-body .ques-wrapper .ques .container .select {
  width: 40%;
}
.job-creation .field-body .question .question-body .ques-wrapper .ques .container .select .MuiFormControl-root {
  width: 100%;
  border-radius: 8px;
  background: #ffffff;
}
.job-creation .field-body .question .question-body .ques-wrapper .ques .container .select .MuiFormControl-root .MuiInputBase-root {
  border: none;
}
.job-creation .field-body .question .question-body .ques-wrapper .ques .container .select .MuiFormControl-root .MuiInputBase-root .MuiSvgIcon-root {
  margin-right: 10px;
  color: #000000;
  font-size: 25px;
}
.job-creation .field-body .question .question-body .ques-wrapper .ques .container .select .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  font-size: 16px;
  background: transparent;
  padding: 10px 16px !important;
  font-weight: 500;
  color: rgb(33, 33, 33);
}
.job-creation .field-body .question .question-body .ques-wrapper .ques .container .select .item {
  display: flex;
  gap: 40px;
}
.job-creation .field-body .question .question-body .ques-wrapper .state-btns {
  width: 100%;
  display: flex;
  text-align: right;
  justify-content: right;
  padding: 10px 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.job-creation .field-body .question .question-body .ques-wrapper .state-btns .button {
  color: #000000;
}
.job-creation .field-body .question .question-body .ques-wrapper .state-btns .MuiButtonBase-root .MuiSwitch-thumb {
  background: rgb(122, 122, 122);
}
.job-creation .field-body .question .question-body .ques-wrapper .state-btns .Mui-checked .MuiSwitch-thumb {
  background: rgb(0, 52, 187);
}
.job-creation .field-body .question .question-body .ques-wrapper .state-btns .MuiSwitch-track {
  background: rgb(217, 217, 217);
  height: 80%;
  margin-top: 2px;
}
.job-creation .field-body .question .question-body .ques-wrapper .state-btns .switch {
  border-left: 1px solid rgba(33, 33, 33, 0.2);
  padding-left: 15px;
  margin-left: 15px;
}
.job-creation .field-body .question .question-body .add-question {
  width: 150px;
  border-radius: 7px;
  border: 1px solid #0034bb;
  color: #0034bb;
  font-size: 16px;
  font-weight: 500;
}
.job-creation .field-body .question .bottom-button {
  position: fixed !important;
  bottom: 0px !important;
  width: 100%;
  margin: auto;
  margin-left: -5%;
}
.job-creation .field-body .question .bottom-button .bottom-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  width: 80vw;
}
.job-creation .field-body .question .question-body::-webkit-scrollbar {
  width: 0px;
  width: none;
}
.job-creation .previews {
  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  padding: 20px 33px;
  padding-bottom: 15px;
  position: relative;
}
.job-creation .previews .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.job-creation .previews .top .left .back-button {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-left: -8px;
  border-radius: 9px;
  cursor: auto;
}
.job-creation .previews .top .left .back-button:hover {
  background: none;
}
.job-creation .previews .top .right {
  display: flex;
  align-items: center;
  gap: 20px;
}
.job-creation .previews .top .right .edit {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 500;
  color: rgb(33, 33, 33);
}
.job-creation .previews .top .right button {
  font-size: 14px;
  font-weight: 500;
  border-radius: 9px;
}
.job-creation .previews .top .right .MuiButtonBase-root .MuiSwitch-thumb {
  background: rgb(122, 122, 122);
}
.job-creation .previews .top .right .Mui-checked .MuiSwitch-thumb {
  background: rgb(0, 52, 187);
}
.job-creation .previews .top .right .MuiSwitch-track {
  background: rgb(217, 217, 217);
  height: 80%;
  margin-top: 2px;
}
.job-creation .previews .previews-body {
  position: relative;
  padding-bottom: 40px;
}
.job-creation .previews .previews-body .preview-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.job-creation .previews .previews-body .preview-top p {
  font-size: 18px;
  font-weight: 400;
  color: #212121;
}
.job-creation .previews .previews-body .preview-top p span {
  font-weight: 600;
}
.job-creation .previews .previews-body .Basic-details-review {
  margin-top: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 35px;
}
.job-creation .previews .previews-body .Basic-details-review .row {
  margin-top: 20px;
}
.job-creation .previews .previews-body .Basic-details-review .row .label {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 6px;
}
.job-creation .previews .previews-body .Basic-details-review .row .text {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 11px 20px;
}
.job-creation .previews .previews-body .Basic-details-review .row .text p {
  font-size: 16px;
  font-weight: 500;
}
.job-creation .previews .previews-body .skill-preview {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 35px;
  margin-top: 20px;
}
.job-creation .previews .previews-body .skill-preview .row {
  margin-top: 20px;
}
.job-creation .previews .previews-body .skill-preview .row .label {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 6px;
}
.job-creation .previews .previews-body .skill-preview .row .exp {
  display: flex;
  align-items: center;
  gap: 20px;
}
.job-creation .previews .previews-body .skill-preview .row .exp .text {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 11px 20px;
  width: 120px;
}
.job-creation .previews .previews-body .skill-preview .row .exp .text p {
  font-size: 16px;
  font-weight: 500;
}
.job-creation .previews .previews-body .skill-preview .row .chips {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.job-creation .previews .previews-body .skill-preview .row .chips .chip {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 400;
  background: rgb(0, 52, 187);
  color: #ffffff;
  padding: 6px 11px;
  border-radius: 16px;
}
.job-creation .previews .previews-body .description-preview {
  margin-top: 20px;
}
.job-creation .previews .previews-body .description-preview .row {
  margin-top: 10px;
}
.job-creation .previews .previews-body .description-preview .row .label {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 6px;
}
.job-creation .previews .previews-body .description-preview .row .text {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 20px 20px;
}
.job-creation .previews .previews-body .description-preview .row .text p {
  font-size: 16px;
  font-weight: 500;
}
.job-creation .previews .previews-body .description-preview .row .text ul,
.job-creation .previews .previews-body .description-preview .row .text ol {
  margin-left: 15px;
}
.job-creation .previews .bottom-button {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}
.job-creation .previews .bottom-button .left {
  display: flex;
  align-items: center;
  gap: 20px;
}
.job-creation .previews .bottom-button .left .back {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 6px 30px;
  border-radius: 7px;
  color: #212121;
}
.job-creation .previews .bottom-button .left .save {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 6px 20px;
  border-radius: 7px;
  color: rgb(0, 52, 187);
}
.job-creation .previews .bottom-button .next {
  font-size: 16px;
  font-weight: 500;
  padding: 6px 20px;
  border-radius: 7px;
  box-shadow: none;
  width: 140px;
  position: relative;
}
.job-creation .previews .bottom-button .next .icon {
  position: absolute;
  top: 50%;
  right: 65%;
  transform: translateY(-50%);
  opacity: 1;
  transition: opacity 0.6s ease;
}
.job-creation .previews .bottom-button .next:hover .icon {
  display: block;
  opacity: 1;
}
.job-creation .previews .bottom-button {
  position: fixed !important;
  bottom: 0px !important;
  width: 100%;
  margin: auto;
  margin-left: -5%;
}
.job-creation .previews .bottom-button .bottom-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  width: 80vw;
}
.job-creation .question-preview {
  margin-top: 20px;
}
.job-creation .question-preview .question-preview-body {
  position: relative;
  height: 65vh;
  overflow: scroll;
}
.job-creation .question-preview .question-preview-body .question-container {
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  padding: 15px 25px;
  margin-bottom: 20px;
}
.job-creation .question-preview .question-preview-body .question-container .icon {
  text-align: center;
}
.job-creation .question-preview .question-preview-body .question-container .icon .drags-icon {
  height: 22px;
  width: 40px;
  background: rgba(0, 52, 187, 0.05);
  border-radius: 4px;
}
.job-creation .question-preview .question-preview-body .question-container .body {
  display: flex;
  align-content: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 20px;
}
.job-creation .question-preview .question-preview-body .question-container .body .left {
  width: 68%;
}
.job-creation .question-preview .question-preview-body .question-container .body .left .ques {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 11px 20px;
}
.job-creation .question-preview .question-preview-body .question-container .body .left .ques p {
  font-size: 16px;
  font-weight: 500;
}
.job-creation .question-preview .question-preview-body .question-container .body .left .option {
  margin-top: 20px;
  width: 90%;
}
.job-creation .question-preview .question-preview-body .question-container .body .right {
  width: 30%;
}
.job-creation .question-preview .question-preview-body .question-container .body .right .select {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 11px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.job-creation .question-preview .question-preview-body .question-container .body .right .select .select-left {
  display: flex;
  align-items: center;
  gap: 10px;
}
.job-creation .question-preview .question-preview-body .question-container .body .right .select .select-left p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.8);
}
.job-creation .question-preview .question-preview-body .question-container .question-bottom {
  display: flex;
  align-items: center;
  justify-content: right;
  padding-top: 20px;
}
.job-creation .question-preview .question-preview-body .question-container .question-bottom .left {
  display: flex;
  align-items: center;
  gap: 10px;
}
.job-creation .question-preview .question-preview-body .question-container .question-bottom .left .copy-delete {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-right: 20px;
}
.job-creation .question-preview .question-preview-body .question-container .question-bottom .switch {
  display: flex;
  align-items: center;
  border-left: 1px solid rgba(33, 33, 33, 0.2);
  padding-left: 20px;
  gap: 20px;
}
.job-creation .question-preview .question-preview-body .question-container .question-bottom .switch p {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
}

.dialog-shake div[role=dialog] {
  animation-name: shake;
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
@keyframes shake {
  25% {
    transform: rotateZ(2deg);
  }
  50% {
    transform: rotateZ(0deg);
  }
  75% {
    transform: rotateZ(2deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

.ai-resume-screening-alert-dialog .MuiDialog-container {
  background: rgba(0, 0, 0, 0.4);
}
.ai-resume-screening-alert-dialog .MuiDialogContent-root {
  padding: 17px 60px;
}
.ai-resume-screening-alert-dialog .dialog-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 11px;
  text-align: center;
  position: relative;
}
.ai-resume-screening-alert-dialog .dialog-body .info-text {
  color: #000;
  font-size: 18px;
  font-weight: 600;
}
.ai-resume-screening-alert-dialog .dialog-body .sub-info {
  color: rgba(33, 33, 33, 0.6);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}
.ai-resume-screening-alert-dialog .dialog-body .sub-info > span {
  font-weight: 600;
}
.ai-resume-screening-alert-dialog .dialog-body .pricing-info-link {
  display: flex;
  gap: 3px;
  color: #0034bb;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}
.ai-resume-screening-alert-dialog .dialog-action {
  border-top: 1px solid rgba(33, 33, 33, 0.2);
  background: rgba(33, 33, 33, 0.05);
  padding-top: 24px;
  padding-bottom: 36px;
  text-align: center;
}
.ai-resume-screening-alert-dialog .dialog-action .action-question {
  color: #000;
  font-size: 18px;
  font-weight: 600;
}
.ai-resume-screening-alert-dialog .dialog-action .action-buttons {
  margin-top: 22px;
  display: flex;
  justify-content: center;
  gap: 55px;
}
.ai-resume-screening-alert-dialog .dialog-action .action-buttons .MuiButtonBase-root {
  padding: 6px 25px;
  width: 200px;
  border-radius: 9px;
  font-size: 18px;
  font-weight: 600;
  box-shadow: none;
}

.open-question {
  padding: 20px 30px;
  position: relative;
}
.open-question .MuiDrawer-paper {
  border-radius: 0px !important;
  max-width: 65% !important;
  min-width: 65% !important;
  height: 100vh !important;
  justify-content: unset;
  padding-bottom: 0px !important;
  margin-bottom: 0px;
  border: none !important;
  padding: 30px 40px;
}
.open-question .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.open-question .top .back {
  font-size: 18px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: -10px;
}
.open-question .title {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  font-weight: 600;
  color: #0034bb;
}
.open-question .drawer-body {
  margin-top: 20px;
  height: 80%;
  overflow: scroll;
}
.open-question .drawer-body .question-item {
  border-radius: 6px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  padding: 18px 16px;
  margin-bottom: 20px;
  cursor: pointer;
}
.open-question .drawer-body .question-item .button {
  display: flex;
  align-items: center;
  gap: 7px;
  border-radius: 9px;
  background: rgba(0, 52, 187, 0.05);
  padding: 7px 15px;
  font-size: 14px;
  font-weight: 500;
  color: #212121;
}
.open-question .drawer-body .question-item .item {
  display: flex;
  align-items: flex-start;
  gap: 2px;
  margin-top: 15px;
  margin-left: -6px;
}
.open-question .drawer-body .question-item .item .left h2 {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
  margin-bottom: 15px;
  margin-top: 10px;
}
.open-question .drawer-body .question-item .item .left .ul {
  list-style: none;
}
.open-question .drawer-body .question-item .item .left .ul li {
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
  margin-bottom: 10px;
}
.open-question .drawer-body .question-item:hover {
  border-radius: 6px;
  border: 1px solid rgba(0, 52, 187, 0.7);
  background: rgba(0, 52, 187, 0.05);
}
.open-question .drawer-body .active {
  border-radius: 6px;
  border: 1px solid rgba(0, 52, 187, 0.7);
  background: rgba(0, 52, 187, 0.05);
}
.open-question .bottom-button {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  box-shadow: 0px -4px 40px 0px rgba(33, 33, 33, 0.25);
  padding: 12px 38px;
}
.open-question .bottom-button .clear {
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  padding: 6px 40px;
}
.open-question .bottom-button .add {
  border-radius: 7px;
  background: rgb(0, 52, 187);
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  padding: 6px 40px;
  color: #ffffff;
}
.open-question .fallback {
  text-align: center;
}
.open-question .fallback h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}
.open-question .fallback p {
  font-size: 18px;
  font-weight: 400;
  color: #212121;
}

.open-job-details {
  padding: 20px 30px;
  position: relative;
}
.open-job-details .MuiDrawer-paper {
  border-radius: 0px !important;
  max-width: 65% !important;
  min-width: 65% !important;
  height: 110vh !important;
  justify-content: unset;
  padding-bottom: 0px !important;
  margin-bottom: 0px;
  border: none !important;
  padding: 30px 40px;
  margin-top: -10px !important;
}
.open-job-details .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.open-job-details .top .back {
  font-size: 18px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: -10px;
}
.open-job-details .body {
  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  padding: 30px 25px;
  position: relative;
  height: 88vh;
  overflow: scroll;
}
.open-job-details .body .body-top {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.open-job-details .body .body-top .left {
  width: 65px;
  height: 65px;
  border-radius: 4px;
  margin-top: 5px;
}
.open-job-details .body .body-top .left img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}
.open-job-details .body .body-top .right h2 {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 52, 187);
}
.open-job-details .body .body-top .right h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}
.open-job-details .body .body-top .right .type-city {
  display: flex;
  align-items: center;
  gap: 10px;
}
.open-job-details .body .body-top .right .type-city .dot {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background: #d9d9d9;
}
.open-job-details .body .body-top .right .type-city p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.open-job-details .body .industry-exp {
  display: flex;
  align-items: center;
  gap: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.open-job-details .body .industry-exp .row .label {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
}
.open-job-details .body .industry-exp .row h3 {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
}
.open-job-details .body .skill {
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  padding-bottom: 35px;
  margin-bottom: 30px;
}
.open-job-details .body .skill .label {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
  margin-bottom: 5px;
}
.open-job-details .body .skill .skills {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}
.open-job-details .body .skill .skills p {
  font-size: 12px;
  font-weight: 400;
  color: #0034bb;
  background: rgba(0, 52, 187, 0.05);
  padding: 5px 12px;
  border-radius: 4px;
}
.open-job-details .body .descriptions {
  margin-bottom: 30px;
}
.open-job-details .body .descriptions:last-child {
  margin-bottom: 0px;
}
.open-job-details .body .descriptions .label {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 15px;
}
.open-job-details .body .descriptions p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.open-job-details .body .descriptions ul,
.open-job-details .body .descriptions ol {
  margin-left: 15px;
}

.job-visibility-option-dialog .MuiDialog-paper {
  box-shadow: none;
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  width: 461px;
  overflow-x: clip;
}
.job-visibility-option-dialog .MuiDialog-container {
  background: rgba(33, 33, 33, 0.2);
}
.job-visibility-option-dialog-content {
  min-width: 461px;
}
.job-visibility-option-dialog-content .header {
  color: #000;
  font-family: "Poppins", sans-serif;
  line-height: 140%;
  margin-bottom: 16px;
}
.job-visibility-option-dialog-content .header .title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 11px;
}
.job-visibility-option-dialog-content .header .sub-title {
  font-size: 14px;
}
.job-visibility-option-dialog-content .separator {
  height: 1px;
  background: rgba(33, 33, 33, 0.2);
}
.job-visibility-option-dialog-content .options .option-container {
  margin-top: 15px;
  display: flex;
  align-items: start;
  cursor: pointer;
}
.job-visibility-option-dialog-content .options .option-container .option-text {
  padding-top: 10px;
  color: #212121;
}
.job-visibility-option-dialog-content .options .option-container .option-text-heading {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}
.job-visibility-option-dialog-content .options .option-container .option-text-description {
  font-size: 14px;
  font-weight: 400;
}
.job-visibility-option-dialog-content .options .option-container .option-text-note {
  color: rgba(33, 33, 33, 0.5);
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
}
.job-visibility-option-dialog-content .cta-wrapper {
  display: flex;
  justify-content: end;
  margin-top: 44px;
}
.job-visibility-option-dialog-content .cta-wrapper .host-job-button {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.job-visibility-option-dialog-content .cta-wrapper .host-job-button.MuiButtonBase-root {
  padding: 10px 18px;
  width: 172px;
  border-radius: 7px;
}

.ai-resume-screening-preferences-dialog .MuiDialog-paper {
  box-shadow: none;
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  width: 500px;
  overflow-x: clip;
}
.ai-resume-screening-preferences-dialog .MuiDialog-container {
  background: rgba(33, 33, 33, 0.2);
}
.ai-resume-screening-preferences-dialog-content {
  min-width: 461px;
}
.ai-resume-screening-preferences-dialog-content .header {
  color: #000;
  font-family: "Poppins", sans-serif;
  line-height: 140%;
  margin-bottom: 16px;
}
.ai-resume-screening-preferences-dialog-content .header .title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 11px;
}
.ai-resume-screening-preferences-dialog-content .header .sub-title {
  font-size: 14px;
}
.ai-resume-screening-preferences-dialog-content .separator {
  height: 1px;
  background: rgba(33, 33, 33, 0.2);
}
.ai-resume-screening-preferences-dialog-content .options .option-container {
  margin-top: 15px;
  display: flex;
  align-items: start;
  cursor: pointer;
}
.ai-resume-screening-preferences-dialog-content .options .option-container .option-text {
  padding-top: 10px;
  color: #212121;
}
.ai-resume-screening-preferences-dialog-content .options .option-container .option-text-heading {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}
.ai-resume-screening-preferences-dialog-content .options .option-container .option-text-description {
  font-size: 14px;
  font-weight: 400;
}
.ai-resume-screening-preferences-dialog-content .options .option-container .option-text-note {
  color: rgba(33, 33, 33, 0.5);
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
}
.ai-resume-screening-preferences-dialog-content .parameters-section {
  margin-top: 14px;
  margin-left: 32px;
  height: 0px;
  overflow-y: clip;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
.ai-resume-screening-preferences-dialog-content .parameters-section[data-visible=true] {
  animation-name: parameters-show;
}
.ai-resume-screening-preferences-dialog-content .parameters-section[data-visible=false] {
  animation-name: parameters-hide;
}
.ai-resume-screening-preferences-dialog-content .parameters-section .title {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.15px;
  text-align: left;
}
.ai-resume-screening-preferences-dialog-content .parameters-section .parameters-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.ai-resume-screening-preferences-dialog-content .parameters-section .parameters-list .parameter {
  border-radius: 16px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 6px 11px;
  color: #000;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */
  cursor: pointer;
}
.ai-resume-screening-preferences-dialog-content .parameters-section .parameters-list .parameter[data-selected=true] {
  background: #0034bb;
  color: white;
}
@keyframes parameters-show {
  0% {
    height: 0px;
  }
  100% {
    height: 140px;
  }
}
@keyframes parameters-hide {
  0% {
    height: 140px;
  }
  100% {
    height: 0px;
  }
}
.ai-resume-screening-preferences-dialog-content .cta-wrapper {
  display: flex;
  justify-content: end;
  margin-top: 44px;
}
.ai-resume-screening-preferences-dialog-content .cta-wrapper .confirm-button {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  box-shadow: none;
}
.ai-resume-screening-preferences-dialog-content .cta-wrapper .confirm-button.MuiButtonBase-root {
  padding: 10px 18px;
  width: 172px;
  border-radius: 7px;
}

.job-lists {
  padding-top: 0px;
  padding-right: 30px;
  position: relative;
}
.job-lists .breadcrumb {
  padding-top: 30px;
}
.job-lists .title {
  font-size: 24px;
  font-weight: 600;
  color: #212121;
  margin-top: -5px;
}
.job-lists .fallback {
  width: 330px;
  height: auto;
  margin: auto;
  text-align: center;
  margin-top: 15%;
}
.job-lists .fallback p {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}
.job-lists .fallback .create {
  border-radius: 7px;
  min-width: 250px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
}
.job-lists .permission-fallback {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 13%;
}
.job-lists .permission-fallback .add-group {
  border-radius: 7px;
  border: 1px solid #0034bb;
  padding: 8px 20px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
}
.job-lists .filter-chip {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
}
.job-lists .filter-chip .chip {
  font-size: 14px;
  font-weight: 400;
  background: rgb(0, 52, 187);
  color: #ffffff;
  padding: 6px 13px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  text-transform: capitalize;
}
.job-lists .search-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}
.job-lists .search-filter .search {
  min-width: 400px;
  display: flex;
  align-items: center;
  width: 38.5%;
  gap: 10px;
  padding: 10px 18px;
  border-radius: 7px;
  border: 1.6px solid rgba(33, 33, 33, 0.2);
}
.job-lists .search-filter .search:hover {
  border: 1.6px solid rgb(0, 52, 187);
}
.job-lists .search-filter .search input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  color: #212121;
}
.job-lists .search-filter .right {
  display: flex;
  align-items: center;
  gap: 20px;
}
.job-lists .search-filter .right .filter {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  padding: 8px 30px;
}
.job-lists .search-filter .right .create-jobs-button {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  padding: 8px 30px;
  box-shadow: none;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
}
.job-lists .card-grid {
  display: flex;
  gap: 7px;
  margin-top: 15px;
  margin-bottom: 0px;
  max-height: calc(100vh - 260.37px);
}
.job-lists .card-grid .job-list {
  flex: 0.4;
  min-width: 400px;
  margin-bottom: 20px;
  overflow: scroll;
}
.job-lists .card-grid .job-list .card {
  margin-top: -1.5px;
  border: 1.5px solid rgba(33, 33, 33, 0.2);
  border-bottom-color: transparent;
  padding: 20px 25px;
  cursor: pointer;
}
.job-lists .card-grid .job-list .card:first-child {
  margin-top: 0;
  border-radius: 6px 6px 0 0;
}
.job-lists .card-grid .job-list .card:nth-last-child(2) {
  border-bottom-color: rgba(33, 33, 33, 0.2);
  border-radius: 0 0 6px 6px;
}
.job-lists .card-grid .job-list .card:hover {
  border: 1.5px solid rgb(0, 52, 187);
}
.job-lists .card-grid .job-list .card.selected-card {
  border: 1.5px solid rgb(0, 52, 187);
  background: rgba(0, 52, 187, 0.05);
}
.job-lists .card-grid .job-list .card .card-top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.job-lists .card-grid .job-list .card .card-top h2 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 10px;
  width: 90%;
}
.job-lists .card-grid .job-list .card .type-city {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 6px;
}
.job-lists .card-grid .job-list .card .type-city p {
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  width: fit-content;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.job-lists .card-grid .job-list .card .type-city .dots {
  width: 4px;
  height: 4px;
  background: rgb(217, 217, 217);
  border-radius: 100%;
}
.job-lists .card-grid .job-list .card .type-city h2 {
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.job-lists .card-grid .job-list .card .type-city .job-status-chip {
  flex: 1;
  margin-left: 7px;
}
.job-lists .card-grid .job-list .card .type-city .job-status-chip .active {
  border-radius: 7px;
  background: rgba(2, 122, 72, 0.1);
  font-size: 14px;
  font-weight: 400;
  border: none;
  outline: none;
  padding: 4px 10px;
  color: rgb(2, 122, 72);
  width: fit-content;
  cursor: auto;
}
.job-lists .card-grid .job-list .card .type-city .job-status-chip .closed {
  border-radius: 7px;
  background: rgba(241, 174, 73, 0.1);
  font-size: 14px;
  font-weight: 400;
  border: none;
  outline: none;
  padding: 4px 10px;
  color: rgb(241, 174, 73);
  width: fit-content;
  cursor: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.job-lists .card-grid .job-list .card .type-city .draft {
  border-radius: 7px;
  background: rgba(33, 33, 33, 0.1);
  font-size: 14px;
  font-weight: 400;
  border: none;
  outline: none;
  padding: 4px 10px;
  color: #212121;
  cursor: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.job-lists .card-grid .job-list .card .post {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.7);
  margin-bottom: 10px;
}
.job-lists .card-grid .job-list .pagination-wrapper {
  margin-top: 10px;
}
.job-lists .card-grid .job-list .pagination-wrapper .job-pagination .MuiPagination-ul {
  justify-content: center;
}
.job-lists .card-grid .active {
  border: 1.5px solid rgb(0, 52, 187);
}
.job-lists .card-grid .active .card-top .icon-button {
  display: block;
  width: 40px;
  height: 40px;
  margin-top: -8px;
  margin-right: -5px;
}
.job-lists .card-grid .candidates-wrapper {
  flex: 0.6;
  margin-bottom: 20px;
  overflow: scroll;
}
.job-lists .card-grid .candidates-wrapper .job-quick-view-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1.5px solid rgba(33, 33, 33, 0.2);
  border-radius: 8px;
  padding: 15px 17px;
}
.job-lists .card-grid .candidates-wrapper .job-quick-view-header .job-title {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}
.job-lists .card-grid .candidates-wrapper .job-quick-view-header .promote-btn {
  padding: 7px 17px;
  border-radius: 6px;
  box-shadow: none;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container {
  margin-top: 10px;
  padding: 11px 14px;
  border-radius: 8px;
  border: 1.5px solid rgba(33, 33, 33, 0.2);
  min-height: calc(100vh - 403.37px);
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 11px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container .head .heading {
  font-weight: 600;
  line-height: 110%;
  margin-left: 9px;
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container .head .detail-btn {
  padding: 6px 18px;
  border-radius: 6px;
  border: 1px solid rgba(33, 33, 33, 0.2);
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container .name {
  color: #212121;
  font-size: 14px;
  font-weight: 500;
  line-height: 110%;
  cursor: pointer;
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container .email {
  color: #212121;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  /* 16.8px */
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container .resume-cell {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container .resume-cell .icon-wrapper {
  width: 30px;
  height: 30px;
  background: rgba(0, 52, 187, 0.05);
  border-radius: 100%;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container .resume-cell .text {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container .resume-cell.resume-cell-disabled {
  cursor: auto;
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container .resume-cell.resume-cell-disabled .text {
  color: rgba(33, 33, 33, 0.2) !important;
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container .resume-cell.resume-cell-disabled .icon-wrapper > svg {
  color: rgba(33, 33, 33, 0.2) !important;
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container .job-cta-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 11px;
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container .job-cta-wrapper .candidate-count {
  font-size: 14px;
  color: #212121;
  font-weight: 500;
  margin-left: 9px;
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container .job-cta-wrapper .ctas {
  display: flex;
  gap: 9px;
  height: 41.5px;
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container .job-cta-wrapper .ctas .mail-btn {
  border: 1px solid rgba(33, 33, 33, 0.2);
  border-radius: 7px;
  padding-inline: 14px;
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container .job-cta-wrapper .ctas .ecn-select {
  width: 176px;
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container .table-wrapper .candidate-quick-view-table {
  padding-bottom: 0;
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container .table-wrapper .candidate-quick-view-table .MuiDataGrid-main .MuiDataGrid-columnHeaders {
  margin-top: 0;
}
@keyframes fadeEffect {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.2;
  }
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container .table-wrapper .candidate-quick-view-table .MuiDataGrid-main .loading-row {
  animation: fadeEffect 2s infinite ease-in-out;
  pointer-events: none;
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container .table-wrapper .no-applicants-fallback {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  padding: 30px 0px;
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container .table-wrapper .no-applicants-fallback .text {
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  font-weight: 500;
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container .table-wrapper .no-applicants-fallback .share-job-button {
  border: 1px solid rgba(33, 33, 33, 0.2);
  border-radius: 7;
}
.job-lists .card-grid .candidates-wrapper .job-quick-view .table-container .table-wrapper .show-more-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0px;
}
.job-lists .details {
  position: relative;
}
.job-lists .details .details-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  background: #fff;
  height: 70px;
  z-index: 10;
  padding-top: 10px;
}
.job-lists .details .details-title .title {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  cursor: pointer;
}
.job-lists .details .details-title button {
  border-radius: 7px;
  padding: 8px 30px;
  font-size: 16px;
  font-weight: 500;
  box-shadow: none;
}
.job-lists .details .member {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 5px;
  margin-bottom: 5px;
}
.job-lists .details .member .left {
  display: flex;
  align-items: center;
  gap: 5px;
}
.job-lists .details .member .left .avatars {
  display: flex;
  align-items: center;
}
.job-lists .details .member .left .avatars .avatar {
  width: 25px;
  height: 25px;
  margin-left: -8px;
}
.job-lists .details .member .left .avatars .avatar-num {
  background: #f2f5fc;
  width: 25px;
  height: 25px;
  margin-left: -8px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
}
.job-lists .details .member .left p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.7);
}
.job-lists .details .member .post {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.7);
}
.job-lists .details .details-body {
  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  padding: 25px 35px;
  padding-top: 10px;
  margin-top: 15px;
  min-height: 80vh;
}
.job-lists .details .details-body .body-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.job-lists .details .details-body .body-top h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.job-lists .details .details-body .body-top .body-top-right {
  display: flex;
  align-items: center;
  gap: 10px;
}
.job-lists .details .details-body .body-top .body-top-right .active {
  border-radius: 7px;
  background: rgba(2, 122, 72, 0.1);
  font-size: 14px;
  font-weight: 400;
  border: none;
  outline: none;
  padding: 5px 13px;
  color: rgb(2, 122, 72);
  cursor: auto;
}
.job-lists .details .details-body .body-top .body-top-right .closed {
  border-radius: 7px;
  background: rgba(241, 174, 73, 0.1);
  font-size: 14px;
  font-weight: 400;
  border: none;
  outline: none;
  padding: 7px 13px;
  color: rgb(241, 174, 73);
  cursor: auto;
}
.job-lists .details .details-body .body-top .draft {
  border-radius: 7px;
  background: rgba(33, 33, 33, 0.1);
  font-size: 14px;
  font-weight: 400;
  border: none;
  outline: none;
  padding: 7px 13px;
  color: #212121;
  cursor: auto;
}
.job-lists .details .details-body .city-type {
  display: flex;
  align-items: center;
  gap: 10px;
}
.job-lists .details .details-body .city-type .dots {
  width: 5px;
  height: 5px;
  background: #d9d9d9;
  border-radius: 100%;
}
.job-lists .details .details-body .city-type p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .city-type h2 {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
}
.job-lists .details .details-body .ind-location {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 20px;
}
.job-lists .details .details-body .ind-location .row p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
}
.job-lists .details .details-body .ind-location .row h3 {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
}
.job-lists .details .details-body .skill {
  margin-top: 15px;
}
.job-lists .details .details-body .skill p {
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
  margin-bottom: 8px;
}
.job-lists .details .details-body .skill .item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.job-lists .details .details-body .skill .item h2 {
  font-size: 12px;
  font-weight: 400;
  color: rgb(0, 52, 187);
  padding: 4px 10px;
  background: rgba(0, 52, 187, 0.05);
  border-radius: 4px;
}
.job-lists .details .details-body .details-tab .tabs {
  margin-top: 20px;
  margin-bottom: 10px;
  position: relative;
  z-index: 33;
}
.job-lists .details .details-body .details-tab .tabs .MuiTabs-scroller {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.job-lists .details .details-body .details-tab .tabs .MuiTabs-scroller .MuiTabs-flexContainer {
  display: flex;
  align-items: center;
  padding: 0px 0px;
  gap: 20px;
}
.job-lists .details .details-body .details-tab .tabs .MuiTabs-scroller .MuiTabs-flexContainer button {
  font-size: 18px;
  color: rgba(33, 33, 33, 0.6);
  font-weight: 500;
  text-transform: capitalize;
  max-width: none !important;
  width: 170px;
  padding: 1px 10px;
  border-radius: 7px 7px 0px 0px;
  min-height: 41px !important;
  margin-top: 5px !important;
}
.job-lists .details .details-body .details-tab .tabs .MuiTabs-scroller .MuiTabs-flexContainer button:hover {
  background-color: rgba(0, 52, 187, 0.05);
}
.job-lists .details .details-body .details-tab .tabs .MuiTabs-scroller .MuiTabs-flexContainer .Mui-selected {
  color: rgb(0, 52, 187);
}
.job-lists .details .details-body .details-tab .tabs .MuiTabs-scroller .MuiTabs-indicator {
  border-bottom: 4px solid rgb(0, 52, 187) !important;
  font-weight: 700;
}
.job-lists .details .details-body .details-tab .description {
  padding-top: 10px;
}
.job-lists .details .details-body .details-tab .description p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .details-tab .description ul,
.job-lists .details .details-body .details-tab .description ol {
  margin-left: 20px;
}
.job-lists .details .details-body .details-tab .question {
  padding-top: 10px;
}
.job-lists .details .details-body .details-tab .question .ques {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.job-lists .details .details-body .details-tab .question .ques:last-child {
  border-bottom: none;
}
.job-lists .details .details-body .details-tab .question .ques h3 {
  font-size: 18px;
  font-weight: 500;
  color: #212121;
  margin-bottom: 10px;
}
.job-lists .details .details-body .details-tab .question .ques p {
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
  margin-bottom: 5px;
}
.job-lists .details .details-body .details-tab .question .ques ul {
  list-style: none;
}
.job-lists .details .details-body .details-tab .question .ques ul li {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
  margin-bottom: 10px;
}
.job-lists .details .details-body .details-tab .table {
  margin-top: 20px;
}
.job-lists .details .details-body .details-tab .table .no-result {
  width: 50%;
  height: auto;
  margin: auto;
  text-align: center;
}
.job-lists .details .details-body .details-tab .table .no-result img {
  max-width: 100%;
}
.job-lists .details .details-body .details-tab .table .no-result h2 {
  font-size: 18px;
  font-weight: 600;
}
.job-lists .details .details-body .details-tab .table .table-top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 13px;
}
.job-lists .details .details-body .details-tab .table .table-top-container .search-select-container {
  display: flex;
  gap: 13px;
  width: 50%;
}
.job-lists .details .details-body .details-tab .table .table-top-container .search-select-container .Search-box {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 7px;
  border: 1.5px solid rgba(33, 33, 33, 0.2);
  padding: 10px 14px;
  width: 70%;
}
.job-lists .details .details-body .details-tab .table .table-top-container .search-select-container .Search-box:hover {
  border: 1.5px solid rgb(0, 52, 187);
}
.job-lists .details .details-body .details-tab .table .table-top-container .search-select-container .Search-box input {
  border: none;
  outline: none;
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .details-tab .table .table-top-container .search-select-container .status .select {
  height: 100%;
}
.job-lists .details .details-body .details-tab .table .table-top-container .search-select-container .status .select .MuiFormControl-root {
  width: 159px;
  height: 100%;
}
.job-lists .details .details-body .details-tab .table .table-top-container .search-select-container .status .select .MuiFormControl-root .MuiInputBase-root {
  font-size: 14px;
}
.job-lists .details .details-body .details-tab .table .table-top-container .buttons {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 13px;
  height: 41.6px;
}
.job-lists .details .details-body .details-tab .table .table-top-container .buttons .mail-button {
  display: inline-flex;
  font-family: inherit;
  padding: 8px 16.5px;
  justify-content: center;
  align-items: center;
  gap: 14px;
  border-radius: 8.259px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  max-height: 100%;
  color: #000000;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
}
.job-lists .details .details-body .details-tab .table .table-top-container .buttons .ecn-select {
  width: 159px;
  height: 100%;
  font-size: 14px;
}
.job-lists .details .details-body .details-tab .table .MuiBox-root .MuiDataGrid-root {
  border: none !important;
}
.job-lists .details .details-body .details-tab .table .MuiBox-root .MuiDataGrid-root .loading-row {
  animation: fadeEffect 2s infinite ease-in-out;
  pointer-events: none;
}
.job-lists .details .details-body .details-tab .table .pagination-wrapper {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.job-lists .details .details-body .details-tab .analytics-body .fallback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  height: 60%;
}
.job-lists .details .details-body .details-tab .analytics-body .fallback h4 {
  font-size: 15px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
  margin-top: 15px;
  text-align: center;
}
.job-lists .details .details-body .details-tab .analytics-body .analytics-switch {
  text-align: right;
  margin-right: 25px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 10px;
}
.job-lists .details .details-body .details-tab .analytics-body .analytics-switch .switch {
  display: flex;
  align-items: center;
  justify-content: left;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: fit-content;
  margin-left: 20px;
  border-radius: 8px;
  overflow: hidden;
  padding: 7px 10px;
}
.job-lists .details .details-body .details-tab .analytics-body .analytics-switch .switch p {
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 7px 15px;
  padding-right: 15px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}
.job-lists .details .details-body .details-tab .analytics-body .analytics-switch .switch p .icon {
  width: 23px;
  height: 23px;
}
.job-lists .details .details-body .details-tab .analytics-body .analytics-switch .switch .active {
  background: #0034bb;
  color: #ffffff;
  border-radius: 4px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics {
  display: flex;
  gap: 2%;
  flex-wrap: wrap;
  padding: 12px 20px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .university-card {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 52%;
  margin-bottom: 20px;
  min-height: 330px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .university-card h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 13px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .university-card .wrapper {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .university-card .wrapper .university-card-left {
  width: 47%;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .university-card .wrapper .university-card-left .list {
  display: flex;
  align-content: center;
  gap: 30px;
  margin-bottom: 12px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .university-card .wrapper .university-card-left .list p {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
  text-align: left;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .university-card .wrapper .university-card-left .list p:nth-child(2) {
  width: 90%;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .university-card .wrapper .university-card-left .list button {
  border: none;
  background: rgb(233, 239, 254);
  color: #0034bb;
  padding: 4px 10px;
  outline: none;
  border-radius: 6px;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  height: 19px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .experience {
  width: 58%;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .source-ai {
  width: 45.5%;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  min-height: 330px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .source-ai .source {
  background: #fafbfb;
  padding: 22px 20px;
  border-radius: 16px;
  width: 47%;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .source-ai .source h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .source-ai .source .wrapper .pie-legend {
  text-align: center;
  margin-top: 15px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .source-ai .source .wrapper .pie-legend .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  margin: auto;
  margin-bottom: 12px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .source-ai .source .wrapper .pie-legend .pie-legend-list .pie-legend-list-item {
  display: flex;
  align-items: center;
  gap: 5px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .source-ai .source .wrapper .pie-legend .pie-legend-list .pie-legend-list-item .dots {
  width: 6px;
  height: 6px;
  border-radius: 100%;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .source-ai .source .wrapper .pie-legend .pie-legend-list .pie-legend-list-item p {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .source-ai .source .wrapper .pie-legend .pie-legend-list span {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .source-ai .ai {
  min-height: 300px;
  width: 54%;
  border-radius: 12px;
  padding: 20px;
  background: #212121;
  position: relative;
  overflow: hidden;
  z-index: 0;
  padding-bottom: 0px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .source-ai .ai h2 {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .source-ai .ai h2 .star {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-left: 3px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .source-ai .ai .blue {
  background: #214cbc;
  width: 110px;
  height: 110px;
  position: absolute;
  z-index: -1;
  filter: blur(50px);
  bottom: -10px;
  left: -20px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .source-ai .ai .light-blue {
  background: #214cbc;
  width: 270px;
  height: 300px;
  position: absolute;
  z-index: -1;
  filter: blur(60px);
  top: -20px;
  right: -50px;
  border-radius: 29% 21% 30% 30%/30% 30% 70% 70%;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .source-ai .ai .white {
  width: 140px;
  height: 140px;
  position: absolute;
  z-index: -1;
  background: white;
  filter: blur(60px);
  top: -20px;
  left: 0px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .source-ai .ai .container {
  text-align: center;
  padding: 2px 20px;
  position: relative;
  z-index: 2;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .source-ai .ai .container .mySwiper {
  width: 100%;
  height: auto;
  height: 270px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .source-ai .ai .container .mySwiper .swiper-pagination-bullet-active {
  background-color: #ffffff !important;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .source-ai .ai .container .mySwiper .swiper-pagination-bullet {
  background-color: transparent;
  border: 1px solid #ffffff !important;
  opacity: 1 !important;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .source-ai .ai .container .mySwiper .ai-card {
  width: 100%;
  height: 240px;
  overflow-y: scroll;
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .source-ai .ai .container .mySwiper .ai-card p {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .source-ai .ai .container .mySwiper .ai-card button {
  background: transparent;
  border: none;
  outline: none;
  border-radius: 7px;
  background: #ffffff;
  border: none;
  padding: 6px 22px;
  font-size: 14px;
  font-weight: 400;
  color: #2b385b;
  cursor: pointer;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .source-ai .ai .container p {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 20px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .line {
  padding: 22px 10px;
  background: #fafbfb;
  border-radius: 16px;
  width: 35%;
  margin-bottom: 20px;
  min-height: 330px;
  padding-bottom: 0px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .line h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
  padding-left: 10px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .line .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 100%;
  margin: auto;
  margin-top: -10px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .line .pie-legend-list .pie-legend-list-item {
  display: flex;
  align-items: center;
  gap: 5px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .line .pie-legend-list .pie-legend-list-item .dots {
  width: 6px;
  height: 6px;
  border-radius: 100%;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .line .pie-legend-list .pie-legend-list-item p {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .line .pie-legend-list span {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 35%;
  margin-bottom: 20px;
  padding-bottom: 0px;
  min-height: 330px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function .chart-icon {
  text-align: right;
  margin-top: -6px;
  margin-bottom: -17px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function .chart-icon .icon {
  width: 16px;
  height: 16px;
  color: #0034bb;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function .wrapper {
  display: flex;
  justify-content: space-between;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function .wrapper .wrapper-list {
  width: 45%;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function .wrapper .wrapper-list .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item .dots {
  width: 13px;
  height: 13px;
  border-radius: 50%;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item p {
  width: 90%;
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function-year {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 25%;
  margin-bottom: 20px;
  min-height: 330px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function-year .chart-icon {
  text-align: right;
  margin-top: -6px;
  margin-bottom: -17px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function-year .chart-icon .icon {
  width: 16px;
  height: 16px;
  color: #0034bb;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function-year h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function-year .chart {
  height: 140px;
  width: 100%;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function-year .chart div div div div div span:first-child {
  border-radius: 50%;
  width: 8px !important;
  height: 8px !important;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function-year .wrapper-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
  padding-left: 15px;
  margin-top: 5px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function-year .wrapper-list .pie-legend-list {
  display: flex;
  align-items: center;
  flex: 0 0 33.333333%;
  margin-bottom: 10px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function-year .wrapper-list .pie-legend-list .pie-legend-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function-year .wrapper-list .pie-legend-list .pie-legend-list-item .dots {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function-year .wrapper-list .pie-legend-list .pie-legend-list-item p {
  font-size: 14px;
  font-weight: 400;
  color: rgb(64, 64, 64);
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .bar-graph {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 61.5%;
  margin-bottom: 20px;
  min-height: 330px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .bar-graph h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .bar-graph .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 40px;
  width: 100%;
  margin: auto;
  margin-bottom: 12px;
  margin-top: -15px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .bar-graph .pie-legend-list .pie-legend-list-item {
  display: flex;
  align-items: center;
  gap: 5px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .bar-graph .pie-legend-list .pie-legend-list-item .dots {
  width: 6px;
  height: 6px;
  border-radius: 100%;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .bar-graph .pie-legend-list .pie-legend-list-item p {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .bar-graph .pie-legend-list span {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function-diversity {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 35%;
  margin-bottom: 20px;
  min-height: 330px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function-diversity .chart-icon {
  text-align: right;
  margin-top: -6px;
  margin-bottom: -17px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function-diversity .chart-icon .icon {
  width: 16px;
  height: 16px;
  color: #0034bb;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function-diversity h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function-diversity .wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 20px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function-diversity .wrapper .wrapper-list {
  width: 45%;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function-diversity .wrapper .wrapper-list .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function-diversity .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function-diversity .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item .dots {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function-diversity .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item p {
  width: 95%;
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .job-function-diversity .wrapper .wrapper-list .pie-legend-list span {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai {
  width: 45.5%;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  min-height: 330px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai .gpa {
  background: #fafbfb;
  padding: 22px 25px;
  border-radius: 16px;
  width: 39%;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai .gpa .gpa-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai .gpa .gpa-top h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 5px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai .gpa .gpa-contain {
  height: 264px;
  overflow-y: scroll;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai .gpa .gpa-contain .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 10px 10px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai .gpa .gpa-contain .item p {
  font-size: 12px;
  color: #212121;
  font-weight: 400;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai .gpa .gpa-contain .item p:nth-child(2) {
  font-size: 13px;
  font-weight: 600;
  color: #0034bb;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai .gpa .gpa-contain .item:last-child {
  border-bottom: none;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai .gpa .gpa-contain::-webkit-scrollbar {
  width: 0px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai .ai {
  min-height: 300px;
  width: 56%;
  border-radius: 12px;
  padding: 20px;
  background: #212121;
  position: relative;
  overflow: hidden;
  z-index: 0;
  padding-bottom: 0px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai .ai h2 {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai .ai h2 .star {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-left: 3px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai .ai .blue {
  background: #214cbc;
  width: 110px;
  height: 110px;
  position: absolute;
  z-index: -1;
  filter: blur(50px);
  bottom: -10px;
  left: -20px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai .ai .light-blue {
  background: #214cbc;
  width: 270px;
  height: 300px;
  position: absolute;
  z-index: -1;
  filter: blur(60px);
  top: -20px;
  right: -50px;
  border-radius: 29% 21% 30% 30%/30% 30% 70% 70%;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai .ai .white {
  width: 140px;
  height: 140px;
  position: absolute;
  z-index: -1;
  background: white;
  filter: blur(60px);
  top: -20px;
  left: 0px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai .ai .container {
  text-align: center;
  padding: 2px 20px;
  position: relative;
  z-index: 2;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai .ai .container .mySwiper {
  width: 100%;
  height: auto;
  height: 270px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai .ai .container .mySwiper .swiper-pagination-bullet-active {
  background-color: #ffffff !important;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai .ai .container .mySwiper .swiper-pagination-bullet {
  background-color: transparent;
  border: 1px solid #ffffff !important;
  opacity: 1 !important;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai .ai .container .mySwiper .ai-card {
  width: 100%;
  height: 240px;
  overflow-y: scroll;
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai .ai .container .mySwiper .ai-card p {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai .ai .container .mySwiper .ai-card button {
  background: transparent;
  border: none;
  outline: none;
  border-radius: 7px;
  background: #ffffff;
  border: none;
  padding: 6px 22px;
  font-size: 14px;
  font-weight: 400;
  color: #2b385b;
  cursor: pointer;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .gpa-ai .ai .container p {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 20px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .current-level {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 33%;
  margin-bottom: 20px;
  min-height: 330px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .current-level .chart-icon {
  text-align: right;
  margin-top: -6px;
  margin-bottom: -17px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .current-level .chart-icon .icon {
  width: 16px;
  height: 16px;
  color: #0034bb;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .current-level h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .current-level .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 15px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .current-level .wrapper .wrapper-list {
  width: 45%;
  margin: auto;
  margin-top: -20px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .current-level .wrapper .wrapper-list .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .current-level .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .current-level .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item .dots {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .current-level .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item p {
  width: 95%;
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .current-level .wrapper .wrapper-list .pie-legend-list span {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .analytics-diversity {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 35%;
  margin-bottom: 20px;
  padding-right: 40px;
  min-height: 330px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .analytics-diversity .chart-icon {
  text-align: right;
  margin-top: -6px;
  margin-bottom: -17px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .analytics-diversity .chart-icon .icon {
  width: 16px;
  height: 16px;
  color: #0034bb;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .analytics-diversity h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .analytics-diversity .wrapper {
  display: flex;
  gap: 30px;
  margin-top: 30px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .analytics-diversity .wrapper .wrapper-list {
  width: 35%;
  margin-right: 50px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .analytics-diversity .wrapper .wrapper-list .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .analytics-diversity .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .analytics-diversity .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item .dots {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .analytics-diversity .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item p {
  width: 95%;
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .analytics-diversity .wrapper .wrapper-list .pie-legend-list span {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .top-rated {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 28%;
  margin-bottom: 20px;
  min-height: 330px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .top-rated h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .top-rated .top-rated-wrapper {
  height: 244px;
  overflow-y: scroll;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .top-rated .top-rated-wrapper ::-webkit-scrollbar {
  width: 0px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .top-rated .top-rated-wrapper .top-rated-wrapper-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  padding: 10px 10px;
  border-radius: 12px;
  margin-bottom: 10px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .top-rated .top-rated-wrapper .top-rated-wrapper-card .top-rated-wrapper-card-left {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 5px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .top-rated .top-rated-wrapper .top-rated-wrapper-card .top-rated-wrapper-card-left .avatar {
  width: 20px;
  height: 20px;
  font-size: 12px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .top-rated .top-rated-wrapper .top-rated-wrapper-card .top-rated-wrapper-card-left .details h4 {
  font-size: 8px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .top-rated .top-rated-wrapper .top-rated-wrapper-card .top-rated-wrapper-card-left .details p {
  font-size: 8px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.4);
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .top-rated .top-rated-wrapper .top-rated-wrapper-card .top-rated-wrapper-card-right {
  width: 50%;
  text-align: right;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .top-rated .top-rated-wrapper .top-rated-wrapper-card .top-rated-wrapper-card-right p {
  font-size: 8px;
  font-weight: 700;
  color: #0034bb;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .ava-time {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 20%;
  margin-bottom: 20px;
  min-height: 330px;
  text-align: center;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .ava-time h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
  margin-top: 20px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .university-partner {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 51%;
  margin-bottom: 20px;
  min-height: 330px;
  padding-bottom: 5px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .university-partner h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .ana-job-function {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 40%;
  margin-bottom: 20px;
  padding-bottom: 0px;
  min-height: 330px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .ana-job-function .chart-icon {
  text-align: right;
  margin-top: -6px;
  margin-bottom: -17px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .ana-job-function .chart-icon .icon {
  width: 16px;
  height: 16px;
  color: #0034bb;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .ana-job-function h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .ana-job-function .wrapper {
  display: flex;
  justify-content: space-between;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .ana-job-function .wrapper .wrapper-list {
  width: 45%;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .ana-job-function .wrapper .wrapper-list .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .ana-job-function .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .ana-job-function .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item .dots {
  width: 13px;
  height: 13px;
  border-radius: 50%;
}
.job-lists .details .details-body .details-tab .analytics-body .promotion-analytics .ana-job-function .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item p {
  width: 90%;
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .details-tab .description-permission .switch {
  display: flex;
  align-content: center;
  border: 1px solid rgba(33, 33, 33, 0.09);
  border-radius: 8px;
  padding: 3px;
  margin-right: -6px;
  width: 182px;
  margin: 15px 0px;
  position: relative;
  z-index: 44;
}
.job-lists .details .details-body .details-tab .description-permission .switch .button {
  outline: none;
  border: none;
  background: transparent;
  font-size: 16px;
  font-weight: 500;
  color: rgb(33, 33, 33);
  padding: 5px 20px;
  cursor: pointer;
}
.job-lists .details .details-body .details-tab .description-permission .switch .active {
  background: rgb(0, 52, 187);
  border-radius: 8px;
  color: #fff;
}
.job-lists .details .details-body .details-tab .description-permission .job-access {
  position: relative;
}
.job-lists .details .details-body .details-tab .description-permission .job-access .saved {
  position: absolute;
  right: 20px;
  z-index: 22;
  margin-top: 62px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  min-width: 120px;
  padding: 6px 10px;
  background: #0034bb;
  border-radius: 7px;
}
.job-lists .details .details-body .details-tab .description-permission .job-access .MuiBox-root .MuiDataGrid-root {
  border: none !important;
}
.job-lists .details .details-body .details-tab .description-permission .job-access .select {
  width: 100%;
}
.job-lists .details .details-body .details-tab .description-permission .job-access .select .MuiFormControl-root {
  width: 100%;
  border-radius: 8px;
  background: #ffffff;
}
.job-lists .details .details-body .details-tab .description-permission .job-access .select .MuiFormControl-root .MuiInputBase-root {
  border: none;
}
.job-lists .details .details-body .details-tab .description-permission .job-access .select .MuiFormControl-root .MuiInputBase-root .MuiSvgIcon-root {
  margin-right: 10px;
  color: #000000;
  font-size: 25px;
}
.job-lists .details .details-body .details-tab .description-permission .job-access .select .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  font-size: 16px;
  background: transparent;
  padding: 10px 16px !important;
  font-weight: 500;
  color: rgb(33, 33, 33);
}
.job-lists .details .details-body .details-tab .description-permission .job-access .select .item {
  display: flex;
  gap: 40px;
}
.job-lists .details .details-body .details-tab .description-permission .job-access .add-more {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 52, 187);
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
}
.job-lists .details .details-body .details-tab .description-permission .job-access .active {
  margin: auto;
  width: 120px;
  margin-top: 120px;
}
.job-lists .details .details-body .details-tab .MuiChartsAxis-tick,
.job-lists .details .details-body .details-tab .MuiChartsAxis-line {
  display: none;
}
.job-lists .details .details-body .bottom-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0px;
}
.job-lists .details .details-body .bottom-header .card-wrapper {
  display: flex;
  flex-direction: column;
  width: 32%;
  background: #e9effe;
  padding: 12px 20px;
  border-radius: 12px;
}
.job-lists .details .details-body .bottom-header .card-wrapper .top {
  margin-bottom: 3px;
}
.job-lists .details .details-body .bottom-header .card-wrapper .top h3 {
  font-size: 16px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .bottom-header .card-wrapper .card-wrapper-bottom {
  display: flex;
  align-items: center;
  gap: 20px;
}
.job-lists .details .details-body .bottom-header .card-wrapper .card-wrapper-bottom h1 {
  font-size: 37px;
  font-weight: 600;
  color: #212121;
}
.job-lists .details .details-body .bottom-header .card-wrapper .card-wrapper-bottom .image {
  width: 100%;
}
.job-lists .details .details-body .bottom-header .card-wrapper .card-wrapper-bottom .image img {
  max-width: 100%;
  max-height: 100%;
}
.job-lists .details .details-body .bottom-header .card-wrapper:nth-child(2) {
  background: rgb(233, 243, 254);
}
.job-lists .details .details-body .body .fallback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  width: 100%;
}
.job-lists .details .details-body .body .fallback h4 {
  font-size: 15px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
  margin-top: 15px;
  text-align: center;
}
.job-lists .details .details-body .body .analytics-switch {
  text-align: right;
  margin-top: 8px;
  margin-bottom: 8px;
}
.job-lists .details .details-body .body .analytics-switch .switch {
  display: flex;
  align-items: center;
  justify-content: left;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: fit-content;
  border-radius: 8px;
  overflow: hidden;
  padding: 7px 10px;
}
.job-lists .details .details-body .body .analytics-switch .switch p {
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 7px 15px;
  padding-right: 15px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}
.job-lists .details .details-body .body .analytics-switch .switch p .icon {
  width: 23px;
  height: 23px;
}
.job-lists .details .details-body .body .analytics-switch .switch .active {
  background: #0034bb;
  color: #ffffff;
  border-radius: 4px;
}
.job-lists .details .details-body .body .promotion-analytics {
  display: flex;
  gap: 2%;
  flex-wrap: wrap;
  padding: 12px 0px;
}
.job-lists .details .details-body .body .promotion-analytics .university-card {
  padding: 22px 15px;
  background: #fafbfb;
  border-radius: 16px;
  width: 52%;
  margin-bottom: 20px;
  min-height: 330px;
}
.job-lists .details .details-body .body .promotion-analytics .university-card h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 13px;
}
.job-lists .details .details-body .body .promotion-analytics .university-card .wrapper {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
}
.job-lists .details .details-body .body .promotion-analytics .university-card .wrapper .university-card-left {
  width: 47%;
}
.job-lists .details .details-body .body .promotion-analytics .university-card .wrapper .university-card-left .list {
  display: flex;
  align-content: center;
  gap: 30px;
  margin-bottom: 12px;
}
.job-lists .details .details-body .body .promotion-analytics .university-card .wrapper .university-card-left .list p {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
  text-align: left;
}
.job-lists .details .details-body .body .promotion-analytics .university-card .wrapper .university-card-left .list p:nth-child(2) {
  width: 90%;
}
.job-lists .details .details-body .body .promotion-analytics .university-card .wrapper .university-card-left .list button {
  border: none;
  background: rgb(233, 239, 254);
  color: #0034bb;
  padding: 4px 10px;
  outline: none;
  border-radius: 6px;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  height: 19px;
}
.job-lists .details .details-body .body .promotion-analytics .experience {
  width: 58%;
}
.job-lists .details .details-body .body .promotion-analytics .source-ai {
  width: 45.5%;
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
  min-height: 330px;
}
.job-lists .details .details-body .body .promotion-analytics .source-ai .source {
  background: #fafbfb;
  padding: 22px 10px;
  border-radius: 16px;
  width: 49%;
  text-align: center;
}
.job-lists .details .details-body .body .promotion-analytics .source-ai .source h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.job-lists .details .details-body .body .promotion-analytics .source-ai .source .wrapper .pie-legend {
  text-align: center;
  margin-top: 15px;
}
.job-lists .details .details-body .body .promotion-analytics .source-ai .source .wrapper .pie-legend .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  margin: auto;
  margin-bottom: 12px;
}
.job-lists .details .details-body .body .promotion-analytics .source-ai .source .wrapper .pie-legend .pie-legend-list .pie-legend-list-item {
  display: flex;
  align-items: center;
  gap: 5px;
}
.job-lists .details .details-body .body .promotion-analytics .source-ai .source .wrapper .pie-legend .pie-legend-list .pie-legend-list-item .dots {
  width: 6px;
  height: 6px;
  border-radius: 100%;
}
.job-lists .details .details-body .body .promotion-analytics .source-ai .source .wrapper .pie-legend .pie-legend-list .pie-legend-list-item p {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .body .promotion-analytics .source-ai .source .wrapper .pie-legend .pie-legend-list span {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .body .promotion-analytics .source-ai .ai {
  min-height: 300px;
  width: 50%;
  border-radius: 12px;
  padding: 20px 15px;
  background: #212121;
  position: relative;
  overflow: hidden;
  z-index: 0;
  padding-bottom: 0px;
}
.job-lists .details .details-body .body .promotion-analytics .source-ai .ai h2 {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}
.job-lists .details .details-body .body .promotion-analytics .source-ai .ai h2 .star {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-left: 3px;
}
.job-lists .details .details-body .body .promotion-analytics .source-ai .ai .blue {
  background: #214cbc;
  width: 110px;
  height: 110px;
  position: absolute;
  z-index: -1;
  filter: blur(50px);
  bottom: -10px;
  left: -20px;
}
.job-lists .details .details-body .body .promotion-analytics .source-ai .ai .light-blue {
  background: #214cbc;
  width: 270px;
  height: 300px;
  position: absolute;
  z-index: -1;
  filter: blur(60px);
  top: -20px;
  right: -50px;
  border-radius: 29% 21% 30% 30%/30% 30% 70% 70%;
}
.job-lists .details .details-body .body .promotion-analytics .source-ai .ai .white {
  width: 140px;
  height: 140px;
  position: absolute;
  z-index: -1;
  background: white;
  filter: blur(60px);
  top: -20px;
  left: 0px;
}
.job-lists .details .details-body .body .promotion-analytics .source-ai .ai .container {
  text-align: center;
  padding: 2px 10px;
  position: relative;
  z-index: 2;
}
.job-lists .details .details-body .body .promotion-analytics .source-ai .ai .container .mySwiper {
  width: 100%;
  height: auto;
  height: 270px;
}
.job-lists .details .details-body .body .promotion-analytics .source-ai .ai .container .mySwiper .swiper-pagination-bullet-active {
  background-color: #ffffff !important;
}
.job-lists .details .details-body .body .promotion-analytics .source-ai .ai .container .mySwiper .swiper-pagination-bullet {
  background-color: transparent;
  border: 1px solid #ffffff !important;
  opacity: 1 !important;
}
.job-lists .details .details-body .body .promotion-analytics .source-ai .ai .container .mySwiper .ai-card {
  width: 100%;
  height: 240px;
  overflow-y: scroll;
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
}
.job-lists .details .details-body .body .promotion-analytics .source-ai .ai .container .mySwiper .ai-card p {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}
.job-lists .details .details-body .body .promotion-analytics .source-ai .ai .container .mySwiper .ai-card button {
  background: transparent;
  border: none;
  outline: none;
  border-radius: 7px;
  background: #ffffff;
  border: none;
  padding: 6px 22px;
  font-size: 14px;
  font-weight: 400;
  color: #2b385b;
  cursor: pointer;
}
.job-lists .details .details-body .body .promotion-analytics .source-ai .ai .container p {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 20px;
}
.job-lists .details .details-body .body .promotion-analytics .line {
  padding: 22px 10px;
  background: #fafbfb;
  border-radius: 16px;
  width: 35%;
  margin-bottom: 20px;
  min-height: 330px;
  padding-bottom: 0px;
}
.job-lists .details .details-body .body .promotion-analytics .line h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
  padding-left: 10px;
}
.job-lists .details .details-body .body .promotion-analytics .line .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 100%;
  margin: auto;
  margin-top: -10px;
}
.job-lists .details .details-body .body .promotion-analytics .line .pie-legend-list .pie-legend-list-item {
  display: flex;
  align-items: center;
  gap: 5px;
}
.job-lists .details .details-body .body .promotion-analytics .line .pie-legend-list .pie-legend-list-item .dots {
  width: 6px;
  height: 6px;
  border-radius: 100%;
}
.job-lists .details .details-body .body .promotion-analytics .line .pie-legend-list .pie-legend-list-item p {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .body .promotion-analytics .line .pie-legend-list span {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .body .promotion-analytics .job-function {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 35%;
  margin-bottom: 20px;
  padding-bottom: 0px;
  min-height: 330px;
}
.job-lists .details .details-body .body .promotion-analytics .job-function .chart-icon {
  text-align: right;
  margin-top: -6px;
  margin-bottom: -17px;
}
.job-lists .details .details-body .body .promotion-analytics .job-function .chart-icon .icon {
  width: 16px;
  height: 16px;
  color: #0034bb;
}
.job-lists .details .details-body .body .promotion-analytics .job-function h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.job-lists .details .details-body .body .promotion-analytics .job-function .wrapper {
  display: flex;
  justify-content: space-between;
}
.job-lists .details .details-body .body .promotion-analytics .job-function .wrapper .wrapper-list {
  width: 45%;
}
.job-lists .details .details-body .body .promotion-analytics .job-function .wrapper .wrapper-list .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.job-lists .details .details-body .body .promotion-analytics .job-function .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.job-lists .details .details-body .body .promotion-analytics .job-function .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item .dots {
  width: 13px;
  height: 13px;
  border-radius: 50%;
}
.job-lists .details .details-body .body .promotion-analytics .job-function .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item p {
  width: 90%;
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .body .promotion-analytics .job-function-year {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 26%;
  margin-bottom: 20px;
  min-height: 330px;
}
.job-lists .details .details-body .body .promotion-analytics .job-function-year .chart-icon {
  text-align: right;
  margin-top: -6px;
  margin-bottom: -17px;
}
.job-lists .details .details-body .body .promotion-analytics .job-function-year .chart-icon .icon {
  width: 16px;
  height: 16px;
  color: #0034bb;
}
.job-lists .details .details-body .body .promotion-analytics .job-function-year h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.job-lists .details .details-body .body .promotion-analytics .job-function-year .chart {
  height: 140px;
  width: 100%;
}
.job-lists .details .details-body .body .promotion-analytics .job-function-year .chart div div div div div span:first-child {
  border-radius: 50%;
  width: 8px !important;
  height: 8px !important;
}
.job-lists .details .details-body .body .promotion-analytics .job-function-year .wrapper-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
  padding-left: 15px;
  margin-top: 5px;
}
.job-lists .details .details-body .body .promotion-analytics .job-function-year .wrapper-list .pie-legend-list {
  display: flex;
  align-items: center;
  flex: 0 0 33.333333%;
  margin-bottom: 10px;
}
.job-lists .details .details-body .body .promotion-analytics .job-function-year .wrapper-list .pie-legend-list .pie-legend-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.job-lists .details .details-body .body .promotion-analytics .job-function-year .wrapper-list .pie-legend-list .pie-legend-list-item .dots {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.job-lists .details .details-body .body .promotion-analytics .job-function-year .wrapper-list .pie-legend-list .pie-legend-list-item p {
  font-size: 14px;
  font-weight: 400;
  color: rgb(64, 64, 64);
}
.job-lists .details .details-body .body .promotion-analytics .bar-graph {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 67.5%;
  margin-bottom: 20px;
  min-height: 330px;
}
.job-lists .details .details-body .body .promotion-analytics .bar-graph h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}
.job-lists .details .details-body .body .promotion-analytics .bar-graph .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 40px;
  width: 100%;
  margin: auto;
  margin-bottom: 12px;
  margin-top: -15px;
}
.job-lists .details .details-body .body .promotion-analytics .bar-graph .pie-legend-list .pie-legend-list-item {
  display: flex;
  align-items: center;
  gap: 5px;
}
.job-lists .details .details-body .body .promotion-analytics .bar-graph .pie-legend-list .pie-legend-list-item .dots {
  width: 6px;
  height: 6px;
  border-radius: 100%;
}
.job-lists .details .details-body .body .promotion-analytics .bar-graph .pie-legend-list .pie-legend-list-item p {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .body .promotion-analytics .bar-graph .pie-legend-list span {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .body .promotion-analytics .job-function-diversity {
  padding: 22px 15px;
  background: #fafbfb;
  border-radius: 16px;
  width: 30%;
  margin-bottom: 20px;
  min-height: 330px;
}
.job-lists .details .details-body .body .promotion-analytics .job-function-diversity .chart-icon {
  text-align: right;
  margin-top: -6px;
  margin-bottom: -17px;
}
.job-lists .details .details-body .body .promotion-analytics .job-function-diversity .chart-icon .icon {
  width: 16px;
  height: 16px;
  color: #0034bb;
}
.job-lists .details .details-body .body .promotion-analytics .job-function-diversity h1 {
  font-size: 17px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.job-lists .details .details-body .body .promotion-analytics .job-function-diversity .wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 20px;
}
.job-lists .details .details-body .body .promotion-analytics .job-function-diversity .wrapper .wrapper-list {
  width: 45%;
}
.job-lists .details .details-body .body .promotion-analytics .job-function-diversity .wrapper .wrapper-list .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.job-lists .details .details-body .body .promotion-analytics .job-function-diversity .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.job-lists .details .details-body .body .promotion-analytics .job-function-diversity .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item .dots {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.job-lists .details .details-body .body .promotion-analytics .job-function-diversity .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item p {
  width: 95%;
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .body .promotion-analytics .job-function-diversity .wrapper .wrapper-list .pie-legend-list span {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai {
  width: 45.5%;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  min-height: 330px;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai .gpa {
  background: #fafbfb;
  padding: 22px 25px;
  border-radius: 16px;
  width: 39%;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai .gpa .gpa-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai .gpa .gpa-top h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 5px;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai .gpa .gpa-contain {
  height: 264px;
  overflow-y: scroll;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai .gpa .gpa-contain .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 10px 10px;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai .gpa .gpa-contain .item p {
  font-size: 12px;
  color: #212121;
  font-weight: 400;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai .gpa .gpa-contain .item p:nth-child(2) {
  font-size: 13px;
  font-weight: 600;
  color: #0034bb;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai .gpa .gpa-contain .item:last-child {
  border-bottom: none;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai .gpa .gpa-contain::-webkit-scrollbar {
  width: 0px;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai .ai {
  min-height: 300px;
  width: 56%;
  border-radius: 12px;
  padding: 20px;
  background: #212121;
  position: relative;
  overflow: hidden;
  z-index: 0;
  padding-bottom: 0px;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai .ai h2 {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai .ai h2 .star {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-left: 3px;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai .ai .blue {
  background: #214cbc;
  width: 110px;
  height: 110px;
  position: absolute;
  z-index: -1;
  filter: blur(50px);
  bottom: -10px;
  left: -20px;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai .ai .light-blue {
  background: #214cbc;
  width: 270px;
  height: 300px;
  position: absolute;
  z-index: -1;
  filter: blur(60px);
  top: -20px;
  right: -50px;
  border-radius: 29% 21% 30% 30%/30% 30% 70% 70%;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai .ai .white {
  width: 140px;
  height: 140px;
  position: absolute;
  z-index: -1;
  background: white;
  filter: blur(60px);
  top: -20px;
  left: 0px;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai .ai .container {
  text-align: center;
  padding: 2px 20px;
  position: relative;
  z-index: 2;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai .ai .container .mySwiper {
  width: 100%;
  height: auto;
  height: 270px;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai .ai .container .mySwiper .swiper-pagination-bullet-active {
  background-color: #ffffff !important;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai .ai .container .mySwiper .swiper-pagination-bullet {
  background-color: transparent;
  border: 1px solid #ffffff !important;
  opacity: 1 !important;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai .ai .container .mySwiper .ai-card {
  width: 100%;
  height: 240px;
  overflow-y: scroll;
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai .ai .container .mySwiper .ai-card p {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai .ai .container .mySwiper .ai-card button {
  background: transparent;
  border: none;
  outline: none;
  border-radius: 7px;
  background: #ffffff;
  border: none;
  padding: 6px 22px;
  font-size: 14px;
  font-weight: 400;
  color: #2b385b;
  cursor: pointer;
}
.job-lists .details .details-body .body .promotion-analytics .gpa-ai .ai .container p {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 20px;
}
.job-lists .details .details-body .body .promotion-analytics .current-level {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 33%;
  margin-bottom: 20px;
  min-height: 330px;
}
.job-lists .details .details-body .body .promotion-analytics .current-level .chart-icon {
  text-align: right;
  margin-top: -6px;
  margin-bottom: -17px;
}
.job-lists .details .details-body .body .promotion-analytics .current-level .chart-icon .icon {
  width: 16px;
  height: 16px;
  color: #0034bb;
}
.job-lists .details .details-body .body .promotion-analytics .current-level h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.job-lists .details .details-body .body .promotion-analytics .current-level .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 15px;
}
.job-lists .details .details-body .body .promotion-analytics .current-level .wrapper .wrapper-list {
  width: 45%;
  margin: auto;
  margin-top: -20px;
}
.job-lists .details .details-body .body .promotion-analytics .current-level .wrapper .wrapper-list .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.job-lists .details .details-body .body .promotion-analytics .current-level .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.job-lists .details .details-body .body .promotion-analytics .current-level .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item .dots {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.job-lists .details .details-body .body .promotion-analytics .current-level .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item p {
  width: 95%;
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .body .promotion-analytics .current-level .wrapper .wrapper-list .pie-legend-list span {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .body .promotion-analytics .analytics-diversity {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 35%;
  margin-bottom: 20px;
  padding-right: 40px;
  min-height: 330px;
}
.job-lists .details .details-body .body .promotion-analytics .analytics-diversity .chart-icon {
  text-align: right;
  margin-top: -6px;
  margin-bottom: -17px;
}
.job-lists .details .details-body .body .promotion-analytics .analytics-diversity .chart-icon .icon {
  width: 16px;
  height: 16px;
  color: #0034bb;
}
.job-lists .details .details-body .body .promotion-analytics .analytics-diversity h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.job-lists .details .details-body .body .promotion-analytics .analytics-diversity .wrapper {
  display: flex;
  gap: 30px;
  margin-top: 30px;
}
.job-lists .details .details-body .body .promotion-analytics .analytics-diversity .wrapper .wrapper-list {
  width: 35%;
  margin-right: 50px;
}
.job-lists .details .details-body .body .promotion-analytics .analytics-diversity .wrapper .wrapper-list .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.job-lists .details .details-body .body .promotion-analytics .analytics-diversity .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.job-lists .details .details-body .body .promotion-analytics .analytics-diversity .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item .dots {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.job-lists .details .details-body .body .promotion-analytics .analytics-diversity .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item p {
  width: 95%;
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .body .promotion-analytics .analytics-diversity .wrapper .wrapper-list .pie-legend-list span {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .body .promotion-analytics .top-rated {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 28%;
  margin-bottom: 20px;
  min-height: 330px;
}
.job-lists .details .details-body .body .promotion-analytics .top-rated h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.job-lists .details .details-body .body .promotion-analytics .top-rated .top-rated-wrapper {
  height: 244px;
  overflow-y: scroll;
}
.job-lists .details .details-body .body .promotion-analytics .top-rated .top-rated-wrapper ::-webkit-scrollbar {
  width: 0px;
}
.job-lists .details .details-body .body .promotion-analytics .top-rated .top-rated-wrapper .top-rated-wrapper-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  padding: 10px 10px;
  border-radius: 12px;
  margin-bottom: 10px;
}
.job-lists .details .details-body .body .promotion-analytics .top-rated .top-rated-wrapper .top-rated-wrapper-card .top-rated-wrapper-card-left {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 5px;
}
.job-lists .details .details-body .body .promotion-analytics .top-rated .top-rated-wrapper .top-rated-wrapper-card .top-rated-wrapper-card-left .avatar {
  width: 20px;
  height: 20px;
  font-size: 12px;
}
.job-lists .details .details-body .body .promotion-analytics .top-rated .top-rated-wrapper .top-rated-wrapper-card .top-rated-wrapper-card-left .details h4 {
  font-size: 8px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .body .promotion-analytics .top-rated .top-rated-wrapper .top-rated-wrapper-card .top-rated-wrapper-card-left .details p {
  font-size: 8px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.4);
}
.job-lists .details .details-body .body .promotion-analytics .top-rated .top-rated-wrapper .top-rated-wrapper-card .top-rated-wrapper-card-right {
  width: 50%;
  text-align: right;
}
.job-lists .details .details-body .body .promotion-analytics .top-rated .top-rated-wrapper .top-rated-wrapper-card .top-rated-wrapper-card-right p {
  font-size: 8px;
  font-weight: 700;
  color: #0034bb;
}
.job-lists .details .details-body .body .promotion-analytics .ava-time {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 20%;
  margin-bottom: 20px;
  min-height: 330px;
  text-align: center;
}
.job-lists .details .details-body .body .promotion-analytics .ava-time h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
  margin-top: 20px;
}
.job-lists .details .details-body .body .promotion-analytics .university-partner {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 51%;
  margin-bottom: 20px;
  min-height: 330px;
  padding-bottom: 5px;
}
.job-lists .details .details-body .body .promotion-analytics .university-partner h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.job-lists .details .details-body .body .promotion-analytics .ana-job-function {
  padding: 22px 20px;
  background: #fafbfb;
  border-radius: 16px;
  width: 40%;
  margin-bottom: 20px;
  padding-bottom: 0px;
  min-height: 330px;
}
.job-lists .details .details-body .body .promotion-analytics .ana-job-function .chart-icon {
  text-align: right;
  margin-top: -6px;
  margin-bottom: -17px;
}
.job-lists .details .details-body .body .promotion-analytics .ana-job-function .chart-icon .icon {
  width: 16px;
  height: 16px;
  color: #0034bb;
}
.job-lists .details .details-body .body .promotion-analytics .ana-job-function h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.job-lists .details .details-body .body .promotion-analytics .ana-job-function .wrapper {
  display: flex;
  justify-content: space-between;
}
.job-lists .details .details-body .body .promotion-analytics .ana-job-function .wrapper .wrapper-list {
  width: 45%;
}
.job-lists .details .details-body .body .promotion-analytics .ana-job-function .wrapper .wrapper-list .pie-legend-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.job-lists .details .details-body .body .promotion-analytics .ana-job-function .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.job-lists .details .details-body .body .promotion-analytics .ana-job-function .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item .dots {
  width: 13px;
  height: 13px;
  border-radius: 50%;
}
.job-lists .details .details-body .body .promotion-analytics .ana-job-function .wrapper .wrapper-list .pie-legend-list .pie-legend-list-item p {
  width: 90%;
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.job-lists .details .details-body .MuiChartsAxis-tick,
.job-lists .details .details-body .MuiChartsAxis-line {
  display: none;
}

#jobs-options-menu .MuiMenu-paper {
  border-radius: 6px;
  box-shadow: 0px 7px 30px 7px rgba(0, 0, 0, 0.1);
}
#jobs-options-menu .MuiMenu-paper .MuiList-padding {
  padding: 11px 0px;
}
#jobs-options-menu .MuiMenuItem-root {
  padding: 12px 12px;
  border-radius: 10px;
}
#jobs-options-menu .options-menu-item {
  display: flex;
  align-content: center;
  gap: 12px;
}

.menu-container-users .MuiPaper-root {
  width: 260px;
  border-radius: 6px;
  box-shadow: 0px 7px 30px 7px rgba(0, 0, 0, 0.1);
}
.menu-container-users .MuiPaper-root .MuiList-root {
  margin: 10px 15px;
  padding-bottom: 2px;
}
.menu-container-users .MuiPaper-root .MuiList-root .menu-item {
  border-radius: 4px;
  border: 1px solid rgba(0, 52, 187, 0.05);
  margin-bottom: 10px;
}
.menu-container-users .MuiPaper-root .MuiList-root .item-button {
  border: none;
  margin-top: 15px;
}
.menu-container-users .MuiPaper-root .MuiList-root .item-button .button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.menu-container-users .MuiPaper-root .MuiList-root .item-button .button .clear {
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
  padding: 5px 10px;
}
.menu-container-users .MuiPaper-root .MuiList-root .item-button .button .apply {
  font-size: 14px;
  font-weight: 500;
  color: rgb(0, 52, 187);
  padding: 5px 10px;
}
.menu-container-users .MuiPaper-root .MuiList-root .item-button:hover {
  background: transparent !important;
}
.menu-container-users .MuiPaper-root .MuiList-root .Mui-checked {
  color: #0034bb;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiButtonBase-root {
  min-height: unset;
  padding: 0px;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiButtonBase-root:hover {
  background: rgba(0, 52, 187, 0.05);
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiButtonBase-root:hover .MuiAccordionSummary-content {
  color: #0034bb;
  background: transparent;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiButtonBase-root .MuiPaper-root {
  border: none;
  border-radius: 0;
  box-shadow: none;
  margin-top: 0;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiButtonBase-root .MuiPaper-root:hover {
  box-shadow: none;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiButtonBase-root:before {
  display: none;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiButtonBase-root .MuiAccordionSummary-content {
  margin: 8px 0;
  font-size: 14px;
  color: #212121;
  font-weight: 500;
  padding: 4px 20px;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiButtonBase-root .MuiAccordionSummary-expandIconWrapper {
  margin-right: 18px;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiButtonBase-root .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(90deg);
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiAccordionSummary-root {
  width: 100% !important;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiCollapse-root .MuiAccordionDetails-root {
  padding: 5px 0;
  padding-inline: 0px;
  margin: 0px 5px;
  margin-right: 5px;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiCollapse-root .MuiAccordionDetails-root .option {
  display: flex;
  align-items: center;
  justify-self: flex-start;
  gap: 6px;
  padding: 10px 10px;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiCollapse-root .MuiAccordionDetails-root .option:hover {
  background: rgba(0, 52, 187, 0.05);
  border-radius: 10px;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiCollapse-root .MuiAccordionDetails-root .option .MuiCheckbox-root {
  width: auto;
  min-width: unset;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiCollapse-root .MuiAccordionDetails-root .option p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(16, 24, 40, 0.7);
}

.open-assign-job {
  padding: 20px 30px;
  position: relative;
}
.open-assign-job .MuiDrawer-paper {
  border-radius: 0px !important;
  max-width: 65% !important;
  min-width: 65% !important;
  height: 110vh !important;
  margin-top: -10px !important;
  justify-content: unset;
  padding-bottom: 0px !important;
  margin-bottom: 0px;
  border: none !important;
  padding: 30px 40px;
}
.open-assign-job .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.open-assign-job .top .back {
  font-size: 18px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: -10px;
}
.open-assign-job .body .sub-heading {
  margin-top: -20px;
}
.open-assign-job .body .switch {
  display: flex;
  align-content: center;
  border: 1px solid rgba(33, 33, 33, 0.09);
  border-radius: 8px;
  padding: 3px;
  margin-right: -6px;
  width: 182px;
  margin: 15px 0px;
}
.open-assign-job .body .switch .button {
  outline: none;
  border: none;
  background: transparent;
  font-size: 16px;
  font-weight: 500;
  color: rgb(33, 33, 33);
  padding: 5px 20px;
  cursor: pointer;
}
.open-assign-job .body .switch .active {
  background: rgb(0, 52, 187);
  border-radius: 8px;
  color: #fff;
}
.open-assign-job .body .input-box {
  width: 100%;
}
.open-assign-job .body .input-box .MuiInputBase-root {
  height: 46px;
  padding: unset;
}
.open-assign-job .body .input-box .MuiInputBase-root .MuiInputBase-input {
  padding: 11.4px 14px !important;
}
.open-assign-job .body .row {
  width: 95%;
  margin-top: 15px;
  margin-bottom: 40px;
}
.open-assign-job .body .row .label {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 7px;
}
.open-assign-job .body .row .input-box {
  width: 100%;
}
.open-assign-job .body .row .input-box .MuiInputBase-root {
  height: 46px;
  padding: unset;
}
.open-assign-job .body .row .input-box .MuiInputBase-root .MuiInputBase-input {
  padding: 11.4px 14px !important;
}
.open-assign-job .body .row .auto-box {
  width: 100%;
}
.open-assign-job .body .row .auto-box .MuiOutlinedInput-root {
  height: unset;
  padding: 4px 10px;
  border-radius: 7px;
}
.open-assign-job .body .save-button {
  text-align: right;
  padding-right: 5%;
  margin-top: 30%;
}
.open-assign-job .body .save-button .save {
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  background: rgb(0, 52, 187);
  border-radius: 7px;
  min-width: 200px;
}

.share-job-dialog {
  font-family: "Poppins", sans-serif;
}
.share-job-dialog div[role=dialog] {
  border-radius: 8px;
}
.share-job-dialog .MuiDialog-container {
  background: rgba(33, 33, 33, 0.2);
}
.share-job-dialog .dialog-title {
  padding: 20px 0px 11px 0px;
  margin: 0px 20px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  text-align: left;
}
.share-job-dialog .dialog-title .dialog-description {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
}
.share-job-dialog .dialog-content .job-link-container {
  margin-bottom: 8px;
}
.share-job-dialog .dialog-content .job-link-container .link-label {
  margin-bottom: 8px;
}
.share-job-dialog .dialog-content .job-link-container .link-input {
  width: 100%;
}
.share-job-dialog .dialog-content .job-link-container .link-input input {
  padding: 10px 16px;
  border-radius: 12px;
}
.share-job-dialog .dialog-content .job-link-container .link-input fieldset {
  border: none;
}
.share-job-dialog .dialog-content .job-link-container .link-input .MuiInputBase-root {
  outline: 1.5px solid rgba(33, 33, 33, 0.2);
}
.share-job-dialog .dialog-content .job-link-container .link-input.copied-input .MuiInputBase-root {
  outline: 1.5px solid #027a48;
}
.share-job-dialog .dialog-content .job-link-container .copied-text {
  margin-top: 4px;
  color: #027a48;
  font-size: 14px;
  overflow-y: clip;
  animation-name: copied-text-notification;
  animation-duration: 5s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
@keyframes copied-text-notification {
  0% {
    height: 0px;
  }
  5% {
    height: 18px;
  }
  95% {
    height: 18px;
  }
  100% {
    height: 0px;
  }
}
.share-job-dialog .dialog-content .or-label {
  margin-bottom: 8px;
}
.share-job-dialog .dialog-content .social-icon-container {
  display: flex;
  justify-content: left;
  gap: 10px;
  align-items: center;
  margin-bottom: 4px;
}
.share-job-dialog .dialog-content .social-icon-container .social-icon-button {
  border: 1px solid rgba(33, 33, 33, 0.2);
}
.share-job-dialog .dialog-content .social-icon-container .social-icon-button:hover {
  border: 1px solid rgba(33, 33, 33, 0.6);
}

.job-details-share-delete-menu .MuiPaper-root {
  height: fit-content;
}
.job-details-share-delete-menu .MuiPaper-root ul {
  height: fit-content;
  padding: 0;
}
.job-details-share-delete-menu .MuiPaper-root ul li {
  min-height: 45px;
}

.manual-resume-screen-popup {
  background-color: rgba(0, 0, 0, 0.3);
}
.manual-resume-screen-popup .MuiPaper-root {
  max-width: 70vw;
  width: 100%;
  height: 610px;
  padding: 20px 22px;
  color: black;
  border-radius: 8px !important;
}
.manual-resume-screen-popup .MuiPaper-root .static-content div {
  display: flex;
  justify-content: space-between;
}
.manual-resume-screen-popup .MuiPaper-root .static-content div h6 {
  font-size: 16px;
  font-weight: 600;
}
.manual-resume-screen-popup .MuiPaper-root .static-content div .iconify {
  cursor: pointer;
}
.manual-resume-screen-popup .MuiPaper-root .static-content p {
  font-size: 14px;
  margin-top: 11px;
}
.manual-resume-screen-popup .MuiPaper-root .static-content .sub-text {
  opacity: 0.6;
  margin-top: 7px;
  padding-bottom: 13px;
  border-bottom: 1.5px solid rgba(33, 33, 33, 0.2);
}
.manual-resume-screen-popup .MuiPaper-root .static-content .sub-text span {
  font-weight: 600;
}
.manual-resume-screen-popup .MuiPaper-root .resume-popup-table {
  margin-top: 12px;
}
.manual-resume-screen-popup .MuiPaper-root .resume-popup-table .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.manual-resume-screen-popup .MuiPaper-root .resume-popup-table .top .search-box {
  display: flex;
  align-items: center;
  position: relative;
  border: 1.5px solid rgba(33, 33, 33, 0.2);
  border-radius: 7px;
  width: 407px;
  height: 44px;
}
.manual-resume-screen-popup .MuiPaper-root .resume-popup-table .top .search-box:hover, .manual-resume-screen-popup .MuiPaper-root .resume-popup-table .top .search-box:focus, .manual-resume-screen-popup .MuiPaper-root .resume-popup-table .top .search-box:focus-within {
  border: 1.5px solid rgb(0, 52, 187);
}
.manual-resume-screen-popup .MuiPaper-root .resume-popup-table .top .search-box .iconify {
  opacity: 0.6;
  position: absolute;
  left: 18px;
  pointer-events: none;
}
.manual-resume-screen-popup .MuiPaper-root .resume-popup-table .top .search-box input {
  border: none;
  outline: none;
  padding: 10px 16px;
  width: 100%;
  height: 100%;
  padding-left: 58px;
  font-size: 14px;
  border-radius: 7px;
}
.manual-resume-screen-popup .MuiPaper-root .resume-popup-table .top .unreviewed-btn {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
  padding: 9px 19px;
  border: 1.5px solid rgba(33, 33, 33, 0.2);
  border-radius: 7px;
  cursor: pointer;
}
.manual-resume-screen-popup .MuiPaper-root .resume-popup-table .table-body {
  margin-top: 12px;
  max-height: 330px;
  overflow-y: auto;
}
.manual-resume-screen-popup .MuiPaper-root .resume-popup-table .table-body .top-bar {
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(33, 33, 33, 0.1);
  background-color: rgba(33, 33, 33, 0.05);
}
.manual-resume-screen-popup .MuiPaper-root .resume-popup-table .table-body .top-bar div {
  display: flex;
  align-items: center;
}
.manual-resume-screen-popup .MuiPaper-root .resume-popup-table .table-body .top-bar div h5 {
  font-size: 14px;
  font-weight: 500;
  margin-left: 20px;
}
.manual-resume-screen-popup .MuiPaper-root .resume-popup-table .table-body .top-bar .credit-count-cont {
  margin-right: 16px;
  display: flex;
  align-items: center;
  height: 35px;
  gap: 21px;
  font-size: 14px;
  font-weight: 500;
}
.manual-resume-screen-popup .MuiPaper-root .resume-popup-table .table-body .top-bar .credit-count-cont .credit-use span {
  color: #027a48;
  font-weight: 600;
  margin-left: 10px;
}
.manual-resume-screen-popup .MuiPaper-root .resume-popup-table .table-body .top-bar .credit-count-cont .credit-total span {
  color: #0034bb;
  font-weight: 600;
  margin-left: 10px;
}
.manual-resume-screen-popup .MuiPaper-root .resume-popup-table .table-body .data-cont {
  margin-top: 10px;
}
.manual-resume-screen-popup .MuiPaper-root .resume-popup-table .table-body .data-cont .data-row {
  display: flex;
  height: 60px;
  gap: 24px;
  align-items: center;
  border-bottom: 1.5px solid rgba(33, 33, 33, 0.2);
  font-size: 14px;
  color: #212121;
}
.manual-resume-screen-popup .MuiPaper-root .resume-popup-table .table-body .data-cont .data-row .details {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4px;
}
.manual-resume-screen-popup .MuiPaper-root .resume-popup-table .table-body .data-cont .data-row .details .name {
  font-weight: 500;
}
.manual-resume-screen-popup .MuiPaper-root .resume-popup-table .table-body .data-cont .data-row .details .role {
  font-size: 12px;
  font-weight: 400;
}
.manual-resume-screen-popup .MuiPaper-root .resume-popup-table .table-body .data-cont .data-row .email {
  flex: 1;
}
.manual-resume-screen-popup .MuiPaper-root .resume-popup-table .resume-popup-bottom {
  border: none;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: calc(100% - 44px);
  bottom: 20px;
  background-color: white;
}
.manual-resume-screen-popup .MuiPaper-root .resume-popup-table .resume-popup-bottom .confirm-btn {
  height: 44px;
  width: 172px;
  border-radius: 7px;
  font-size: 16px;
}

.job-applicants {
  padding-left: 40px;
  padding-top: 0px;
  padding-right: 30px;
}
.job-applicants .breadcrumb {
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 15px;
}
.job-applicants .breadcrumb .dots {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: rgba(33, 33, 33, 0.6);
}
.job-applicants .breadcrumb p {
  padding: 5px 10px;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
}
.job-applicants .breadcrumb p:last-child {
  color: #212121;
}
.job-applicants .breadcrumb p:last-child:hover {
  background: none;
}
.job-applicants .breadcrumb p:hover {
  background: rgba(0, 52, 187, 0.05);
}
.job-applicants .title {
  font-size: 24px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 20px;
}
.job-applicants .row {
  width: 45%;
}
.job-applicants .row p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 8px;
}
.job-applicants .row .select {
  width: 100%;
  margin-bottom: -44px;
  position: relative;
  z-index: 44;
}
.job-applicants .row .select .MuiFormControl-root {
  width: 100%;
  border-radius: 8px;
  background: #ffffff;
}
.job-applicants .row .select .MuiFormControl-root .MuiInputBase-root {
  border: none;
}
.job-applicants .row .select .MuiFormControl-root .MuiInputBase-root .MuiSvgIcon-root {
  margin-right: 10px;
  color: #000000;
  font-size: 25px;
}
.job-applicants .row .select .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  font-size: 16px;
  background: transparent;
  padding: 11px 16px !important;
  font-weight: 500;
  color: rgb(33, 33, 33);
}
.job-applicants .table {
  position: relative;
}
.job-applicants .table .no-result {
  text-align: center;
  max-width: 50vw;
  margin: auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  padding: 30px;
  margin-top: 80px;
}
.job-applicants .table .no-result img {
  max-width: 40vw;
}
.job-applicants .table .search-bar {
  position: relative;
  z-index: 1;
  margin-bottom: -38px;
}
.job-applicants .table .search-bar .search {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 155px;
  gap: 10px;
  width: 130px;
  border: 1.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 9px 10px;
  height: 43px;
  transition: all 0.5s ease;
  /* corrected transition shorthand */
}
.job-applicants .table .search-bar .search:hover {
  background: transparent;
  border: 1.5px solid #0034bb;
}
.job-applicants .table .search-bar .search input {
  width: 100%;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  background: transparent;
}
.job-applicants .table .search-bar .search ::placeholder {
  font-family: Poppins;
}
.job-applicants .table .search-bar .search .cross {
  cursor: pointer;
}
.job-applicants .table .search-bar .search-width {
  margin-right: 277px !important;
}
.job-applicants .table .search-bar .active-width {
  margin-right: 170px !important;
}
.job-applicants .table .search-bar .active {
  width: 300px;
  margin-right: 100px;
}
.job-applicants .table .table-body {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  background: #ffffff;
  padding: 20px 0px 0px 20px;
  margin-top: 50px;
  height: 72vh;
  border-radius: 0px 6px 6px 6px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  border-left: 0.5px solid rgba(0, 0, 0, 0.1);
  background: #fff;
}
.job-applicants .table .table-body .MuiDataGrid-root {
  border: none !important;
}
.job-applicants .table .table-body .MuiDataGrid-main {
  margin-top: 20px;
}
.job-applicants .table .table-body .new-class {
  font-size: 0px !important;
  width: auto;
  text-align: center;
  height: 44px;
  transition: all 0.5s ease;
  /* corrected transition shorthand */
  width: 60px;
}
.job-applicants .table .table-body .new-class .MuiButton-startIcon {
  margin: 0px;
}

.filter-style-class {
  display: block;
}
.filter-style-class .MuiPaper-root {
  min-width: 54vw;
  margin-left: auto;
  margin-top: 10px;
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent {
  width: 100% !important;
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-filterForm {
  gap: 20px !important;
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiDataGrid-filterFormDeleteIcon {
  width: 30px;
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root:nth-child(2) {
  width: 100px;
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root:nth-child(2) .MuiFormLabel-root {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 10px;
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root:nth-child(2) .MuiNativeSelect-select {
  background: transparent;
  border-radius: 8px;
  padding: 5px 20px;
  height: 33px;
  border: 1.5px solid rgba(33, 33, 33, 0.2);
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root:nth-child(2) .MuiNativeSelect-select:hover {
  border: 1.5px solid rgb(0, 52, 187);
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root:nth-child(2) .MuiSvgIcon-root {
  margin-right: 10px;
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root:nth-child(3) {
  width: 250px;
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root:nth-child(3) .MuiFormLabel-root {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root:nth-child(3) .MuiNativeSelect-select {
  background: transparent;
  border-radius: 8px;
  padding: 5px 20px;
  height: 33px;
  border: 1.5px solid rgba(33, 33, 33, 0.2);
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root:nth-child(3) .MuiNativeSelect-select:hover {
  border: 1.5px solid rgb(0, 52, 187);
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root:nth-child(3) .MuiSvgIcon-root {
  margin-right: 10px;
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root:nth-child(4) {
  width: 200px;
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root:nth-child(4) .MuiFormLabel-root {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root:nth-child(4) .MuiNativeSelect-select {
  background: transparent;
  border-radius: 8px;
  padding: 5px 20px;
  height: 33px;
  border: 1.5px solid rgba(33, 33, 33, 0.2);
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root:nth-child(4) .MuiNativeSelect-select:hover {
  border: 1.5px solid rgb(0, 52, 187);
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root:nth-child(4) .MuiSvgIcon-root {
  margin-right: 10px;
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root:nth-child(5) .MuiFormLabel-root {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root:nth-child(5) .MuiInputBase-root {
  background: transparent;
  border-radius: 8px;
  padding: 5px 20px;
  border: 1.5px solid rgba(33, 33, 33, 0.2);
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root:nth-child(5) .MuiInputBase-root:hover {
  border: 1.5px solid rgb(0, 52, 187);
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root:nth-child(5) .MuiSvgIcon-root {
  margin-right: 10px;
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiInputBase-root:before {
  border-bottom: none !important;
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiInput-root::after {
  border-bottom: none !important;
}
.filter-style-class .MuiPaper-root .MuiDataGrid-panelFooter .MuiButtonBase-root {
  font-size: 14px;
  font-weight: 500;
  color: rgb(0, 52, 187);
}

.dashboard {
  padding: 30px;
  padding-left: 0px;
}
.dashboard-hero .card {
  border-radius: 10px;
  border: 1px solid #cddafa;
  background: linear-gradient(90deg, #eaf3fd 0%, #dfe5ff 100%);
  padding: 38px 35px;
  height: 300px;
  overflow: clip;
  position: relative;
  z-index: -1;
}
.dashboard-hero .card h2,
.dashboard-hero .card p {
  position: relative;
  z-index: 1;
}
.dashboard-hero .card .wave-image {
  position: absolute;
  top: -50px;
  z-index: 0;
  width: 100%;
  height: auto;
}
.dashboard-hero .feature-list {
  display: flex;
  gap: 20px;
  margin-top: -137px;
  margin-left: 35px;
  padding-right: 35px;
}
.dashboard-hero .feature-list .feature {
  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: rgba(255, 255, 255, 0.7);
  padding: 38px 35px;
  backdrop-filter: blur(7px);
  flex: 1;
}
.dashboard-hero .feature-list .feature.feature-1 {
  min-width: 327px;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 12px;
  border: none;
  background: none;
}
.dashboard-hero .feature-list .feature.feature-1 .feature-1-card {
  min-height: 110px;
  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(7px);
  padding: 26px 20px;
  display: flex;
  gap: 18px;
}
.dashboard-hero .feature-list .feature.feature-1 .feature-1-card:last-child {
  flex: 1;
}
.dashboard-hero .feature-list .feature.feature-1 .feature-1-card-item {
  display: flex;
  justify-content: start;
  gap: 12px;
}
.dashboard-hero .feature-list .feature.feature-1 .feature-1-card-item .icon-wrapper {
  width: 37px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  filter: drop-shadow(0px 3.17px 3.17px rgba(0, 0, 0, 0.15));
}
.dashboard-hero .feature-list .feature.feature-1 .feature-1-card-item .icon-wrapper svg {
  color: #ffffff;
}
.dashboard-hero .feature-list .feature.feature-1 .feature-1-card-item .stats {
  margin-top: -10px;
}
.dashboard-hero .feature-list .feature.feature-1 .feature-1-card-item .stats-number {
  color: #000;
  font-size: 32px;
  font-weight: 600;
}
.dashboard-hero .feature-list .feature.feature-1 .feature-1-card-item .stats-subtext {
  color: rgba(33, 33, 33, 0.8);
  font-size: 14px;
  font-weight: 600;
}
.dashboard-hero .feature-list .feature.feature-1 .feature-1-card-empty-fallback {
  height: 100%;
  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(7px);
  padding: 26px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.dashboard-hero .feature-list .feature.feature-1 .feature-1-card-empty-fallback .stats-wrapper {
  display: flex;
  align-items: center;
  gap: 18px;
  margin-bottom: 26px;
}
.dashboard-hero .feature-list .feature.feature-1 .feature-1-card-empty-fallback .stats-wrapper .stats-item {
  display: flex;
  justify-content: start;
  gap: 12px;
}
.dashboard-hero .feature-list .feature.feature-1 .feature-1-card-empty-fallback .stats-wrapper .stats-item .icon-wrapper {
  width: 37px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  filter: drop-shadow(0px 3.17px 3.17px rgba(0, 0, 0, 0.15));
}
.dashboard-hero .feature-list .feature.feature-1 .feature-1-card-empty-fallback .stats-wrapper .stats-item .icon-wrapper svg {
  color: #ffffff;
}
.dashboard-hero .feature-list .feature.feature-1 .feature-1-card-empty-fallback .stats-wrapper .stats-item .stats {
  margin-top: -10px;
}
.dashboard-hero .feature-list .feature.feature-1 .feature-1-card-empty-fallback .stats-wrapper .stats-item .stats-number {
  color: #000;
  font-size: 32px;
  font-weight: 600;
}
.dashboard-hero .feature-list .feature.feature-1 .feature-1-card-empty-fallback .stats-wrapper .stats-item .stats-subtext {
  color: rgba(33, 33, 33, 0.8);
  font-size: 14px;
  font-weight: 600;
}
.dashboard-hero .feature-list .feature.feature-1 .feature-1-card-empty-fallback .fallback-bottom-wrapper {
  border-top: 1px solid transparent;
  border-image: repeating-linear-gradient(90deg, rgba(33, 33, 33, 0.2) 0, rgba(33, 33, 33, 0.2) 10px, transparent 10px, transparent 20px);
  border-image-slice: 1;
  padding-top: 20px;
}
.dashboard-hero .feature-list .feature.feature-1 .feature-1-card-empty-fallback .fallback-bottom-wrapper .fallback-text {
  color: rgba(33, 33, 33, 0.7);
  font-size: 14px;
  font-weight: 400;
}
.dashboard-hero .feature-list .feature.feature-1 .feature-1-card-empty-fallback .fallback-bottom-wrapper .fallback-text span {
  font-weight: 600;
}
.dashboard-hero .feature-list .feature.feature-1 .feature-1-card-empty-fallback .fallback-bottom-wrapper .host-job-button {
  width: 100%;
  border-radius: 8px;
  margin-top: 18px;
}
.dashboard-hero .feature-list .feature.feature-1 .feature-1-card-empty-fallback .fallback-bottom-wrapper .host-job-button svg {
  margin-right: 10px;
}
.dashboard-hero .feature-list .feature.feature-2 {
  display: flex;
  justify-content: center;
  gap: 22px;
  position: relative;
}
.dashboard-hero .feature-list .feature.feature-2 .chart-wrapper {
  width: 128px;
  height: 128px;
}
.dashboard-hero .feature-list .feature.feature-2 .view-analytics-link {
  position: absolute;
  color: #0034bb;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 20px;
  cursor: pointer;
}
.dashboard-hero .feature-list .feature.feature-2 .chart-color-lables-container .lable .color-chip {
  width: 31px;
  height: 14px;
  border-radius: 4px;
  margin-bottom: 11px;
}
.dashboard-hero .feature-list .feature.feature-2 .chart-color-lables-container .lable .text {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
}
.dashboard-hero .feature-list .feature.feature-2 .chart-color-lables-container .lable:first-child {
  margin-bottom: 21px;
}
.dashboard-hero .feature-list .feature.feature-3 {
  padding: 14px;
}
.dashboard-hero .feature-list .feature.feature-3:hover {
  cursor: pointer;
}
.dashboard-hero .feature-list .feature.feature-3:hover .header .company-logo-container {
  transition: all 300ms linear;
  transform: scale(1.1);
  outline: 2px solid rgba(76, 81, 163, 0.1);
}
.dashboard-hero .feature-list .feature.feature-3:hover .header .company-logo-container img {
  transition: all 300ms linear;
}
.dashboard-hero .feature-list .feature.feature-3 .header {
  height: 110px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  position: relative;
  overflow: clip;
  background: linear-gradient(90deg, #fff 25.19%, rgba(174, 176, 237, 0.32) 125.95%);
  margin-bottom: 12px;
}
.dashboard-hero .feature-list .feature.feature-3 .header .company-logo-container {
  position: absolute;
  right: 19px;
  top: 21px;
  width: 62px;
  height: 62px;
  border-radius: 6px;
  overflow: clip;
}
.dashboard-hero .feature-list .feature.feature-3 .header .company-logo-container img {
  width: 100%;
  object-fit: contain;
}
.dashboard-hero .feature-list .feature.feature-3 .title {
  color: #212121;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 6px;
}
.dashboard-hero .feature-list .feature.feature-3 .sub-title {
  color: rgba(33, 33, 33, 0.7);
  font-size: 14px;
  font-weight: 400;
}
.dashboard-hero .feature-list .feature.free-credit {
  padding: 20px 20px !important;
  padding-top: 10px !important;
  display: flex !important;
  flex-direction: column !important;
}
.dashboard-hero .feature-list .feature.free-credit .free-credit-top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid transparent;
  border-image: repeating-linear-gradient(90deg, rgba(33, 33, 33, 0.2) 0, rgba(33, 33, 33, 0.2) 10px, transparent 10px, transparent 20px);
  border-image-slice: 1;
  padding-bottom: 30px;
}
.dashboard-hero .feature-list .feature.free-credit .free-credit-top .free-credit-top-left {
  display: flex;
  align-items: center;
  gap: 20px;
}
.dashboard-hero .feature-list .feature.free-credit .free-credit-top .free-credit-top-left .icon-wrapper {
  border-radius: 100%;
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.dashboard-hero .feature-list .feature.free-credit .free-credit-top .free-credit-top-left .stats {
  margin-top: 10px;
}
.dashboard-hero .feature-list .feature.free-credit .free-credit-top .free-credit-top-left .stats .stats-number {
  font-size: 32px;
  font-weight: 600;
  color: #000000;
}
.dashboard-hero .feature-list .feature.free-credit .free-credit-top .free-credit-top-left .stats .stats-subtext {
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.8);
}
.dashboard-hero .feature-list .feature.free-credit .free-credit-top .exp {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.7);
  margin-top: 15px;
}
.dashboard-hero .feature-list .feature.free-credit .free-credit-bottom {
  padding-top: 0px;
}
.dashboard-hero .feature-list .feature.free-credit .free-credit-bottom h3 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.7);
  margin-bottom: 20px;
}
.dashboard-hero .feature-list .feature.free-credit .free-credit-bottom .free-credit-button {
  border-radius: 8.923px;
  background: rgba(0, 52, 187, 0.05);
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard-hero .feature-list .feature.free-credit .free-credit-bottom .free-credit-button .free-credit-button-left {
  display: flex;
  align-items: center;
  gap: 10px;
}
.dashboard-hero .feature-list .feature.free-credit .free-credit-bottom .free-credit-button .free-credit-button-left h2 {
  font-size: 16px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.7);
}
.dashboard-hero .feature-list .feature.free-credit .free-credit-bottom .free-credit-button .check-right {
  background: #FFF;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 0px 3.5px rgba(0, 0, 0, 0.05));
}
.dashboard .dashboard-walkthrough .dashboard-hero .skip-walkthrough {
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 20px;
  color: white;
  opacity: 0.7;
  z-index: 9999999;
  text-decoration: underline;
  cursor: pointer;
  pointer-events: all !important;
}
.dashboard .dashboard-walkthrough .reactour__mask {
  opacity: 0.6 !important;
}
.dashboard .dashboard-walkthrough .reactour__popover {
  color: white !important;
  background-color: transparent !important;
  box-shadow: none !important;
  position: relative;
  max-width: 400px !important;
}
.dashboard .dashboard-walkthrough .reactour__popover span {
  display: none;
}
.dashboard .dashboard-walkthrough .reactour__popover .reactour__close-button {
  display: none !important;
}
.dashboard .dashboard-walkthrough .reactour__popover h6 {
  font-size: 16px;
  font-weight: 500;
}
.dashboard .dashboard-walkthrough .reactour__popover p {
  font-size: 20px;
  font-weight: 500;
}
.dashboard .dashboard-walkthrough .reactour__popover .arrow1 {
  position: absolute;
  left: 127px;
  top: 160px;
}
.dashboard .dashboard-walkthrough .reactour__popover .arrow2 {
  position: absolute;
  left: 147px;
  top: 180px;
}
.dashboard .dashboard-walkthrough .reactour__popover div {
  margin-top: 18px !important;
}
.dashboard .dashboard-walkthrough .reactour__popover div button[aria-label="Go to prev step"],
.dashboard .dashboard-walkthrough .reactour__popover div div {
  display: none !important;
}
.dashboard .dashboard-walkthrough .reactour__popover div .next-btn {
  display: flex !important;
  padding: 6px 10px;
  width: 98px;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  font-family: "Shantell Sans";
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #0034bb 0%, #0034bb 100%);
  border: none;
  border-radius: 12px;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  padding: 10px;
}
.dashboard .dashboard-walkthrough .reactour__popover div .next-btn:hover {
  transform: translateY(-6px);
}
.dashboard .dashboard-walkthrough .reactour__popover div .next-btn:hover::before {
  opacity: 1;
}
.dashboard .dashboard-walkthrough .reactour__popover div .next-btn::after {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 6px solid rgba(255, 255, 255, 0.69);
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}
.dashboard .dashboard-walkthrough .reactour__popover div .next-btn:hover::after {
  animation: none;
  display: none;
}
@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 150px;
    height: 150px;
    opacity: 0;
  }
}
.dashboard .dashboard-walkthrough .reactour__popover div .next-btn.right-btn {
  position: absolute;
  right: 30px;
  bottom: -9px;
}
.dashboard .dashboard-walkthrough .reactour__popover div .next-btn.step-3-btn {
  position: absolute;
  bottom: -9px;
  left: -40px;
}
.dashboard .dashboard-walkthrough .reactour__popover div .next-btn.very-right-btn {
  position: absolute;
  right: -316px;
  bottom: -9px;
}
.dashboard .dashboard-walkthrough .reactour__popover div .next-btn.step-4-btn {
  left: -40px;
}
.dashboard .dashboard-walkthrough .reactour__popover div .next-btn.step-5-btn {
  right: -120px;
}
.dashboard .dashboard-walkthrough .reactour__popover div .next-btn.last-step-btn {
  right: -100px;
}
.dashboard .dashboard-walkthrough .reactour__popover .right-text {
  text-align: right;
  margin-top: 30px;
}
.dashboard .dashboard-walkthrough .reactour__popover .very-right {
  margin-left: 230px;
  margin-top: 50px;
  width: 450px;
  text-align: right;
}
.dashboard .dashboard-walkthrough .reactour__popover .very-right .arrow3 {
  position: absolute;
  right: -160px;
  top: 140px;
}
.dashboard .dashboard-walkthrough .reactour__popover .step-3 {
  margin-top: 30px;
  width: 300px;
  margin-left: -70px;
}
.dashboard .dashboard-walkthrough .reactour__popover .step-3 .arrow1 {
  top: 180px;
}
.dashboard .dashboard-walkthrough .reactour__popover .step-4 {
  margin-top: 40px;
  width: 600px;
  margin-left: -40px;
}
.dashboard .dashboard-walkthrough .reactour__popover .step-4 .arrow1 {
  left: 160px;
  top: 190px;
}
.dashboard .dashboard-walkthrough .reactour__popover .step-5 {
  width: 540px;
  margin-left: -50px;
}
.dashboard .dashboard-walkthrough .reactour__popover .step-5 .arrow2 {
  top: 140px;
  left: 170px;
}
.dashboard .dashboard-walkthrough .reactour__popover .last-step {
  margin-right: -120px;
}
.dashboard .dashboard-walkthrough .reactour__popover .last-step .arrow1 {
  left: auto;
  right: 0;
}
.dashboard .dashboard-body {
  margin-top: 33px;
  display: flex;
  gap: 32px;
}
.dashboard .dashboard-body .data-container-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}
.dashboard .dashboard-body .data-container-title h2 {
  color: #212121;
  font-size: 18px;
  font-weight: 600;
}
.dashboard .dashboard-body .data-container-title .draft-mail-button {
  color: #0034bb;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
}
.dashboard .dashboard-body .data-container-title .draft-mail-button svg {
  margin-left: 6px;
}
.dashboard .dashboard-body .latest-candidates {
  width: 50%;
  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(7px);
  padding: 29px 27px;
  padding-bottom: 15px;
}
.dashboard .dashboard-body .latest-candidates .latest-candidates-table-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100% - 110px);
}
.dashboard .dashboard-body .latest-candidates .latest-candidates-table-empty .urban-no-data-found {
  width: 272px;
  height: 204px;
}
.dashboard .dashboard-body .latest-candidates .latest-candidates-table-empty .text {
  color: rgba(33, 33, 33, 0.6);
  font-size: 16px;
  font-weight: 400;
  margin-top: -16px;
}
.dashboard .dashboard-body .latest-candidates .latest-candidates-table {
  border: none;
}
.dashboard .dashboard-body .latest-candidates .latest-candidates-table .MuiDataGrid-main .MuiDataGrid-virtualScroller {
  height: 320px;
  color: #212121;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  /* 13.2px */
}
.dashboard .dashboard-body .latest-candidates .latest-candidates-table .MuiDataGrid-main .row-index {
  color: #212121;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */
  margin-right: 20px;
}
.dashboard .dashboard-body .latest-candidates .latest-candidates-table .MuiDataGrid-main .name {
  color: #212121;
  font-size: 14px;
  font-weight: 500;
  line-height: 110%;
  cursor: pointer;
}
.dashboard .dashboard-body .latest-candidates .latest-candidates-table .MuiDataGrid-main .email {
  color: #212121;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  /* 16.8px */
}
.dashboard .dashboard-body .latest-candidates .latest-candidates-table .MuiDataGrid-main .MuiDataGrid-columnHeaders {
  margin: 0px;
  border-radius: 4px;
}
.dashboard .dashboard-body .latest-candidates .latest-candidates-table .MuiDataGrid-main .MuiDataGrid-row--lastVisible {
  border: none;
}
.dashboard .dashboard-body .job-postings {
  width: 50%;
  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(7px);
  padding: 29px 27px;
  padding-bottom: 0px;
  position: relative;
}
.dashboard .dashboard-body .job-postings .legend-container {
  position: absolute;
  bottom: 23px;
  display: flex;
  padding-left: 6px;
  gap: 4px;
  width: 90%;
  padding-right: 6px;
  justify-content: space-between;
}
.dashboard .dashboard-body .job-postings .legend-container .legend {
  display: flex;
  gap: 8px;
}
.dashboard .dashboard-body .job-postings .legend-container .legend .color {
  width: 12px;
  height: 100%;
  aspect-ratio: 1;
  border-radius: 3px;
}
.dashboard .dashboard-body .job-postings .legend-container .legend .job-name {
  color: #000000;
  font-size: 12px;
  font-weight: 600;
}

@media only screen and (min-width: 1600px) and (max-width: 1730px) {
  .dashboard .dashboard-walkthrough .reactour__popover .right-text {
    margin-right: -200px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .right-text .arrow2 {
    left: 247px;
    top: 150px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .very-right {
    margin-left: 330px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .very-right .arrow3 {
    right: -260px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .last-step .arrow1 {
    right: -80px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover div .next-btn.right-btn {
    right: -180px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover div .next-btn.very-right-btn {
    right: -416px;
  }
}
@media only screen and (min-width: 1730px) and (max-width: 1920px) {
  .dashboard .dashboard-walkthrough .reactour__popover .right-text {
    margin-right: -200px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .right-text .arrow2 {
    left: 247px;
    top: 150px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .very-right {
    margin-left: 330px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .very-right .arrow3 {
    right: -260px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .last-step .arrow1 {
    right: -260px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover div .next-btn.right-btn {
    right: -180px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover div .next-btn.very-right-btn {
    right: -416px;
  }
}
@media only screen and (min-width: 1921px) and (max-width: 2160px) {
  .dashboard .dashboard-walkthrough .reactour__popover .right-text {
    margin-right: -200px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .right-text .arrow2 {
    left: 247px;
    top: 150px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .very-right {
    margin-left: 490px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .very-right .arrow3 {
    right: -420px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .last-step .arrow1 {
    right: -260px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover div .next-btn.right-btn {
    right: -180px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover div .next-btn.very-right-btn {
    right: -576px;
  }
}
@media only screen and (min-width: 2161px) and (max-width: 2560px) {
  .dashboard .dashboard-walkthrough .reactour__popover .right-text {
    margin-right: -300px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .right-text .arrow2 {
    left: 347px;
    top: 150px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .very-right {
    margin-left: 620px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .very-right .arrow3 {
    right: -550px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .last-step {
    width: 500px;
    margin-left: 230px;
    margin-right: -390px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .last-step .arrow1 {
    right: -450px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover div .next-btn.right-btn {
    right: -280px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover div .next-btn.very-right-btn {
    right: -706px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover div .next-btn.last-step-btn {
    right: -370px;
  }
}
@media only screen and (max-width: 1340px) {
  .dashboard .dashboard-walkthrough .arrow1,
  .dashboard .dashboard-walkthrough .arrow2,
  .dashboard .dashboard-walkthrough .arrow3 {
    display: none;
  }
}
@media only screen and (max-height: 778px) {
  .dashboard .dashboard-walkthrough .reactour__popover .step-4 {
    width: 1100px;
    margin-left: -100px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .step-4 .arrow1 {
    top: 130px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .very-right {
    margin-top: 30px;
    width: 600px;
    margin-left: 70px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .very-right .arrow3 {
    top: 110px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .step-5 {
    width: 1100px;
    margin-left: -620px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .step-5 .arrow2 {
    top: 120px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .last-step {
    width: 680px;
    margin-right: auto;
    margin-left: -210px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover .last-step .arrow1 {
    top: 120px;
  }
  .dashboard .dashboard-walkthrough .reactour__popover div .step-4-btn {
    position: absolute;
    left: -70px !important;
    bottom: -10px;
  }
}
.permission {
  padding-left: 10px;
  padding-top: 10px;
}
.permission .breadcrumb {
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 15px;
}
.permission .breadcrumb .dots {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: rgba(33, 33, 33, 0.6);
}
.permission .breadcrumb p {
  padding: 5px 10px;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
}
.permission .breadcrumb p:last-child {
  color: #212121;
}
.permission .breadcrumb p:last-child:hover {
  background: none;
}
.permission .breadcrumb p:hover {
  background: rgba(0, 52, 187, 0.05);
}
.permission .title {
  font-size: 24px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 20px;
}
.permission .sub-title {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.permission .access-tab {
  position: relative;
}
.permission .access-tab .tab-switch {
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  border-bottom: none;
  width: 200px;
  position: relative;
  z-index: 22;
  margin-bottom: -1px;
  background: #FFF;
  padding: 20px;
  padding-bottom: 20px;
  border-radius: 15px 15px 0px 0px;
  text-align: center;
}
.permission .access-tab .tab-switch h1 {
  font-size: 18px;
  font-weight: 500;
  color: #0034BB;
}
.permission .access-tab .tab-switch h1 span {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.permission .access-tab .invite-button {
  position: absolute;
  right: 30px;
  top: 5px;
}
.permission .access-tab .invite-button button {
  display: flex;
  align-items: center;
  gap: 5;
  justify-content: center;
  background: transparent;
  border: none;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  height: 44px;
  padding: 9px 25px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(33, 33, 33);
  cursor: pointer;
}
.permission .access-tab .global-accesss {
  border-radius: 0px 6px 6px 6px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  background: #FFF;
  padding: 5px 20px;
  padding-right: 0px;
  margin-right: 30px;
}
.permission .access-tab .global-accesss .tabs {
  margin-top: 30px;
  margin-bottom: 10px;
  width: 360px;
}
.permission .access-tab .global-accesss .tabs .MuiTabs-scroller {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.permission .access-tab .global-accesss .tabs .MuiTabs-scroller .MuiTabs-flexContainer {
  display: flex;
  align-items: center;
  padding: 0px 0px;
  gap: 20px;
}
.permission .access-tab .global-accesss .tabs .MuiTabs-scroller .MuiTabs-flexContainer button {
  font-size: 18px;
  color: rgba(33, 33, 33, 0.6);
  font-weight: 500;
  text-transform: capitalize;
  max-width: none !important;
  width: 170px;
  padding: 1px 10px;
  border-radius: 7px 7px 0px 0px;
  min-height: 41px !important;
  margin-top: 5px !important;
}
.permission .access-tab .global-accesss .tabs .MuiTabs-scroller .MuiTabs-flexContainer button:hover {
  background-color: rgba(0, 52, 187, 0.05);
}
.permission .access-tab .global-accesss .tabs .MuiTabs-scroller .MuiTabs-flexContainer .Mui-selected {
  color: rgb(0, 52, 187);
}
.permission .access-tab .global-accesss .tabs .MuiTabs-scroller .MuiTabs-indicator {
  border-bottom: 4px solid rgb(0, 52, 187) !important;
  font-weight: 700;
}
.permission .access-tab .global-accesss .MuiBox-root .MuiDataGrid-root {
  border: none !important;
}
.permission .access-tab .global-accesss .Search-box {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 180px;
  gap: 10px;
  border-radius: 7px;
  border: 1.5px solid rgba(33, 33, 33, 0.2);
  width: 300px;
  padding: 10px 14px;
  margin-top: -75px;
}
.permission .access-tab .global-accesss .Search-box:hover {
  border: 1.5px solid rgb(0, 52, 187);
}
.permission .access-tab .global-accesss .Search-box input {
  border: none;
  outline: none;
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  color: #212121;
}
.permission .access-tab .global-accesss .select {
  width: 100%;
}
.permission .access-tab .global-accesss .select .MuiFormControl-root {
  width: 100%;
  border-radius: 8px;
  background: #ffffff;
}
.permission .access-tab .global-accesss .select .MuiFormControl-root .MuiInputBase-root {
  border: none;
}
.permission .access-tab .global-accesss .select .MuiFormControl-root .MuiInputBase-root .MuiSvgIcon-root {
  margin-right: 10px;
  color: #000000;
  font-size: 25px;
}
.permission .access-tab .global-accesss .select .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  font-size: 16px;
  background: transparent;
  padding: 10px 16px !important;
  font-weight: 500;
  color: rgb(33, 33, 33);
}
.permission .access-tab .global-accesss .select .item {
  display: flex;
  gap: 40px;
}
.permission .access-tab .global-accesss .save-button {
  text-align: right;
}
.permission .access-tab .global-accesss .save-button button {
  border-radius: 9px;
  background: #0034bb;
  padding: 8px 20px;
  box-shadow: none;
  min-width: 140px;
  color: #ffffff;
}

.global-search {
  padding-left: 30px;
}
.global-search .header-breadcrumbs {
  margin-top: 30px;
  margin-bottom: 25px;
}
.global-search .header-breadcrumbs .top {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 14px;
  color: rgba(33, 33, 33, 0.6);
}
.global-search .header-breadcrumbs .top .icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.global-search .header-breadcrumbs .top p {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.global-search .header-breadcrumbs .top p:last-child {
  color: #212121;
}
.global-search .header-breadcrumbs .top .dots {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: rgba(33, 33, 33, 0.6);
}
.global-search .main-heading {
  font-size: 24px;
  color: #212121;
  font-weight: 600;
  line-height: 1;
}
.global-search .result-count {
  color: #212121;
}
.global-search .results-wrapper {
  max-width: 80%;
}
.global-search .results-wrapper .search-group {
  margin-top: 25px;
  margin-bottom: 0;
}
.global-search .results-wrapper .search-group .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(33, 33, 33, 0.15);
}
.global-search .results-wrapper .search-group .head .group-heading {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  line-height: 1.5;
  margin-bottom: 10px;
  text-transform: capitalize;
  padding-top: 10px;
}
.global-search .results-wrapper .search-group .head .group-result-count {
  color: #0034bb;
  font-size: 13px;
  line-height: normal;
  text-decoration-line: underline;
}
.global-search .results-wrapper .search-group .search-results .result {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 2px;
  border-radius: 10px;
  padding: 12px;
  cursor: pointer;
}
.global-search .results-wrapper .search-group .search-results .result:last-child {
  margin-bottom: 0;
}
.global-search .results-wrapper .search-group .search-results .result:hover {
  background: rgba(0, 52, 187, 0.03);
}
.global-search .results-wrapper .search-group .search-results .result .avatar {
  height: 32px;
  width: 32px;
  font-size: 13.2px;
  font-weight: 500;
}
.global-search .results-wrapper .search-group .search-results .result .right {
  height: fit-content;
}
.global-search .results-wrapper .search-group .search-results .result .right .heading {
  font-size: 16px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.8);
  line-height: 1;
}
.global-search .results-wrapper .search-group .search-results .result .right .sub {
  font-size: 12px;
  color: rgba(33, 33, 33, 0.7);
}

.global-search-fallback {
  text-align: center;
  margin-top: 100px;
}
.global-search-fallback img {
  max-width: 350px;
  height: auto;
}
.global-search-fallback p {
  color: #212121;
  font-weight: 500;
}

.events-list {
  padding-right: 30px;
}
.events-list .title {
  font-size: 24px;
  font-weight: 600;
  color: #212121;
  margin-top: -5px;
}
.events-list .event-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 25px;
}
.events-list .event-list-header .event-search {
  max-width: 300px;
}
.events-list .event-list-header .btn-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
.events-list .event-list-header .btn-group button {
  border-radius: 7px;
  padding: 10px 18px;
  font-size: 16px;
  font-weight: 500;
}
.events-list .tabs {
  margin-bottom: 28px;
}
.events-list .events-tab .event-cards .event-list-card {
  display: flex;
  align-items: flex-start;
  gap: 28px;
  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  overflow: hidden;
  margin-bottom: 25px;
  position: relative;
}
.events-list .events-tab .event-cards .event-list-card .img-wrapper {
  width: 250px;
  height: 200px;
  overflow: hidden;
}
.events-list .events-tab .event-cards .event-list-card .img-wrapper img {
  width: 250px;
  height: 200px;
  object-fit: cover;
}
.events-list .events-tab .event-cards .event-list-card .card-content {
  padding: 15px 24px 20px 0;
}
.events-list .events-tab .event-cards .event-list-card .card-content .card-body .main .date-time {
  color: #212121;
  font-size: 14px;
  font-weight: 400;
}
.events-list .events-tab .event-cards .event-list-card .card-content .card-body .main .event-name {
  color: #212121;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 141%; /* 22.56px */
  margin-top: 5px;
}
.events-list .events-tab .event-cards .event-list-card .card-content .card-body .main .city-applicants {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 5px;
  color: #212121;
  font-size: 14px;
  font-weight: 400;
}
.events-list .events-tab .event-cards .event-list-card .card-content .card-body .main .city-applicants .applicants {
  font-weight: 500;
}
.events-list .events-tab .event-cards .event-list-card .card-content .card-body .main .event-scheduled-date {
  color: rgba(33, 33, 33, 0.7);
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
}
.events-list .events-tab .event-cards .event-list-card .card-content .card-body .icon-cta {
  position: absolute;
  right: 12px;
  top: 12px;
}
.events-list .events-tab .event-cards .event-list-card .card-content .card-body .status-chip {
  padding: 4px 10px;
  border-radius: 7px;
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: rgba(2, 122, 72, 0.1);
  color: #027a48;
}
.events-list .events-tab .event-cards .event-list-card .card-content .card-body .status-chip[data-registration-closed=true] {
  background: rgba(186, 0, 0, 0.1);
  color: #ba0000;
}
.events-list .events-tab .event-cards .event-list-card .card-content .card-body .status-chip[data-event-draft=true] {
  background: rgba(33, 33, 33, 0.1);
  color: #212121;
}
.events-list .events-tab .pagination-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 64px;
}
.events-list .requests-tab .requests-list .request-card {
  display: flex;
  align-items: flex-start;
  gap: 28px;
  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  overflow: hidden;
  margin-bottom: 25px;
  position: relative;
}
.events-list .requests-tab .requests-list .request-card .img-wrapper {
  width: 250px;
  height: 200px;
  overflow: hidden;
}
.events-list .requests-tab .requests-list .request-card .img-wrapper img {
  width: 250px;
  height: 200px;
  object-fit: cover;
}
.events-list .requests-tab .requests-list .request-card .card-content {
  padding: 15px 24px 20px 0;
}
.events-list .requests-tab .requests-list .request-card .card-content .card-body .main .date-time {
  color: #212121;
  font-size: 14px;
  font-weight: 400;
}
.events-list .requests-tab .requests-list .request-card .card-content .card-body .main .event-name {
  color: #212121;
  font-size: 16px;
  font-weight: 600;
  line-height: 141%; /* 22.56px */
  margin-top: 5px;
}
.events-list .requests-tab .requests-list .request-card .card-content .card-body .main .city-applicants {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 5px;
  color: #212121;
  font-size: 14px;
  font-weight: 400;
}
.events-list .requests-tab .requests-list .request-card .card-content .card-body .main .city-applicants .applicants {
  font-weight: 500;
}
.events-list .requests-tab .requests-list .request-card .card-content .card-body .main .event-scheduled-date {
  color: rgba(33, 33, 33, 0.7);
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
}
.events-list .requests-tab .requests-list .request-card .card-content .card-body .icon-cta {
  position: absolute;
  right: 12px;
  top: 12px;
}
.events-list .requests-tab .pagination-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 64px;
}
.events-list .requests-tab .requests-not-found {
  margin: 60px 0px;
}
.events-list .requests-tab .requests-not-found .text {
  color: #212121;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.event-card-menu .MuiMenu-paper {
  border-radius: 6px;
  box-shadow: 0px 7px 30px 7px rgba(0, 0, 0, 0.1);
}
.event-card-menu .MuiMenu-paper .MuiList-padding {
  padding: 10px 11px;
}
.event-card-menu .MuiMenuItem-root {
  padding: 12px 12px;
  border-radius: 10px;
}
.event-card-menu .MuiMenuItem-root:hover {
  background: rgba(0, 52, 187, 0.05);
}
.event-card-menu .menu-item-wrapper {
  display: flex;
  align-content: center;
  gap: 12px;
  color: rgba(33, 33, 33, 0.7);
  font-size: 14px;
  font-weight: 500;
}

.event-detail {
  margin-right: 30px;
}
.event-detail .body {
  margin-top: 18px;
  margin-bottom: 40px;
}
.event-detail .body .event-details-tab .hero {
  width: 100%;
  aspect-ratio: 3;
  border-radius: 6px;
  border: 1px solid rgba(33, 33, 33, 0.15);
  position: relative;
  overflow: clip;
}
.event-detail .body .event-details-tab .hero .hero-banner {
  object-fit: contain;
  width: 100%;
  aspect-ratio: 3;
}
.event-detail .body .event-details-tab .hero .title {
  color: #000000;
  max-width: 35%;
  font-size: 26.153px;
  font-weight: 500;
  line-height: 36px; /* 137.653% */
  letter-spacing: 0.28px;
}
.event-detail .body .event-details-tab .details-body {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 30px;
}
.event-detail .body .event-details-tab .details-body .left-main-content {
  color: #212121;
}
.event-detail .body .event-details-tab .details-body .left-main-content .event-name {
  font-size: 18px;
  font-weight: 600;
}
.event-detail .body .event-details-tab .details-body .left-main-content .company-name {
  margin-top: 2px;
  font-size: 14px;
  font-weight: 400;
}
.event-detail .body .event-details-tab .details-body .left-main-content .event-description {
  margin-top: 28px;
  font-size: 14px;
  font-weight: 400;
}
.event-detail .body .event-details-tab .details-body .left-main-content .event-description p {
  font-size: 15px;
  font-weight: 400;
}
.event-detail .body .event-details-tab .details-body .left-main-content .event-description ul,
.event-detail .body .event-details-tab .details-body .left-main-content .event-description ol {
  margin-left: 15px;
}
.event-detail .body .event-details-tab .details-body .left-main-content .speakers-title {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
}
.event-detail .body .event-details-tab .details-body .left-main-content .speakers-list {
  margin-top: 10px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}
.event-detail .body .event-details-tab .details-body .left-main-content .speakers-list .speaker-card img {
  width: 145px;
  height: 170px;
  border-radius: 6px;
  border: 1px solid rgba(33, 33, 33, 0.15);
  object-fit: cover;
}
.event-detail .body .event-details-tab .details-body .right-side-content .right-header {
  margin-top: -5px;
  display: flex;
  justify-content: space-between;
}
.event-detail .body .event-details-tab .details-body .right-side-content .right-header .text {
  color: rgba(33, 33, 33, 0.6);
  font-size: 14px;
  font-weight: 400;
  padding-top: 8px;
}
.event-detail .body .event-details-tab .details-body .right-side-content .right-header .text .registrants-number {
  margin-top: 8px;
  color: #000000;
  font-size: 32px;
  font-weight: 500;
}
.event-detail .body .event-details-tab .details-body .right-side-content .right-header .view-all-button {
  text-decoration: underline;
}
.event-detail .body .event-details-tab .details-body .right-side-content .add-new-registrant-button {
  margin-top: 24px;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #212121;
  font-size: 16px;
  font-weight: 500;
}
.event-detail .body .event-details-tab .details-body .right-side-content .event-info {
  margin-top: 46px;
}
.event-detail .body .event-details-tab .details-body .right-side-content .event-info .info-wrapper {
  margin-bottom: 10px;
}
.event-detail .body .event-details-tab .details-body .right-side-content .event-info .info-wrapper .info-lable {
  color: rgba(33, 33, 33, 0.6);
  font-size: 14px;
  font-weight: 400;
}
.event-detail .body .event-details-tab .details-body .right-side-content .event-info .info-wrapper .info-content {
  color: #212121;
  font-size: 16px;
  font-weight: 600;
}
.event-detail .body .registrants-tab .table-wrapper,
.event-detail .body .payments-tab .table-wrapper {
  margin-top: 18px;
  padding: 6px 18px;
  border-radius: 6px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
}
.event-detail .body .registrants-tab .registrant-search-filter,
.event-detail .body .payments-tab .registrant-search-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.event-detail .body .registrants-tab .registrant-search-filter .search-input,
.event-detail .body .payments-tab .registrant-search-filter .search-input {
  max-width: 350px;
}
.event-detail .body .registrants-tab .registrant-search-filter .search-input .MuiInputBase-root .MuiOutlinedInput-input,
.event-detail .body .payments-tab .registrant-search-filter .search-input .MuiInputBase-root .MuiOutlinedInput-input {
  padding-left: 0;
}
.event-detail .body .registrants-tab .registrant-search-filter .btn-group button,
.event-detail .body .payments-tab .registrant-search-filter .btn-group button {
  margin-right: 10px;
  border-radius: 7px;
  padding: 10px 18px;
  font-size: 16px;
  font-weight: 500;
}
.event-detail .body .registrants-tab .registrant-search-filter .btn-group button:last-child,
.event-detail .body .payments-tab .registrant-search-filter .btn-group button:last-child {
  margin-right: 0;
}
.event-detail .body .registrants-tab .registrants-table .avatar-icon-wrapper,
.event-detail .body .registrants-tab .payments-table .avatar-icon-wrapper,
.event-detail .body .payments-tab .registrants-table .avatar-icon-wrapper,
.event-detail .body .payments-tab .payments-table .avatar-icon-wrapper {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 100px;
  background: #f2f4f8;
}
.event-detail .body .registrants-tab .registrants-table .candidate-name-text,
.event-detail .body .registrants-tab .payments-table .candidate-name-text,
.event-detail .body .payments-tab .registrants-table .candidate-name-text,
.event-detail .body .payments-tab .payments-table .candidate-name-text {
  color: #212121;
  font-size: 14px;
  font-weight: 500;
  line-height: 110%; /* 15.4px */
}
.event-detail .body .registrants-tab .registrants-table .affinity-groups-list,
.event-detail .body .registrants-tab .payments-table .affinity-groups-list,
.event-detail .body .payments-tab .registrants-table .affinity-groups-list,
.event-detail .body .payments-tab .payments-table .affinity-groups-list {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.event-detail .body .registrants-tab .registrants-table .affinity-groups-list .affinity-group-chip,
.event-detail .body .registrants-tab .payments-table .affinity-groups-list .affinity-group-chip,
.event-detail .body .payments-tab .registrants-table .affinity-groups-list .affinity-group-chip,
.event-detail .body .payments-tab .payments-table .affinity-groups-list .affinity-group-chip {
  display: inline-flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}
.event-detail .body .registrants-tab .registrants-table .affinity-groups-list .affinity-group-chip[data-affinity-group=Black],
.event-detail .body .registrants-tab .payments-table .affinity-groups-list .affinity-group-chip[data-affinity-group=Black],
.event-detail .body .payments-tab .registrants-table .affinity-groups-list .affinity-group-chip[data-affinity-group=Black],
.event-detail .body .payments-tab .payments-table .affinity-groups-list .affinity-group-chip[data-affinity-group=Black] {
  background: #fff9dd;
}
.event-detail .body .registrants-tab .registrants-table .affinity-groups-list .affinity-group-chip[data-affinity-group=Women], .event-detail .body .registrants-tab .registrants-table .affinity-groups-list .affinity-group-chip[data-affinity-group=Female],
.event-detail .body .registrants-tab .payments-table .affinity-groups-list .affinity-group-chip[data-affinity-group=Women],
.event-detail .body .registrants-tab .payments-table .affinity-groups-list .affinity-group-chip[data-affinity-group=Female],
.event-detail .body .payments-tab .registrants-table .affinity-groups-list .affinity-group-chip[data-affinity-group=Women],
.event-detail .body .payments-tab .registrants-table .affinity-groups-list .affinity-group-chip[data-affinity-group=Female],
.event-detail .body .payments-tab .payments-table .affinity-groups-list .affinity-group-chip[data-affinity-group=Women],
.event-detail .body .payments-tab .payments-table .affinity-groups-list .affinity-group-chip[data-affinity-group=Female] {
  background: #e6f7ff;
}
.event-detail .body .registrants-tab .registrants-table .select-payment-status,
.event-detail .body .registrants-tab .payments-table .select-payment-status,
.event-detail .body .payments-tab .registrants-table .select-payment-status,
.event-detail .body .payments-tab .payments-table .select-payment-status {
  min-width: 114px;
}
.event-detail .body .registrants-tab .selection-action-buttons,
.event-detail .body .payments-tab .selection-action-buttons {
  position: absolute;
  right: 100px;
  bottom: 60px;
  display: flex;
  gap: 14px;
}
.event-detail .body .registrants-tab .selection-action-buttons button,
.event-detail .body .payments-tab .selection-action-buttons button {
  border-radius: 7px;
  padding: 10px 18px;
  box-shadow: none;
  font-size: 16px;
  font-weight: 500;
}
.event-detail .body .registrants-tab .pagination,
.event-detail .body .payments-tab .pagination {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.select-payment-status-menu .MuiPaper-root {
  box-shadow: 0px 7px 30px 7px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}
.select-payment-status-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root {
  padding: 6px 14px;
}

#registrants-export-menu .MuiMenu-paper {
  border-radius: 6px;
  box-shadow: 0px 7px 30px 7px rgba(0, 0, 0, 0.1);
}
#registrants-export-menu .MuiMenu-paper .MuiList-padding {
  padding: 11px 0px;
}
#registrants-export-menu .MuiMenuItem-root {
  padding: 12px 12px;
  border-radius: 10px;
}
#registrants-export-menu .options-menu-item {
  display: flex;
  align-content: center;
  gap: 12px;
}

.create-certificate-page .body {
  margin: 25px 40px;
  padding: 25px 35px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.create-certificate-page .body .page-title {
  color: #212121;
  font-size: 18px;
  font-weight: 600;
}
.create-certificate-page .body .sub-heading {
  color: #212121;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 25px;
}
.create-certificate-page .body .field {
  margin-bottom: 16px;
  position: relative;
}
.create-certificate-page .body .field .field-label {
  display: block;
  color: #212121;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.15px;
  text-transform: capitalize;
  margin-bottom: 2px;
}
.create-certificate-page .body .field .field-input {
  width: 100%;
}
.create-certificate-page .body .field .text-area-char-limit {
  position: absolute;
  right: 16px;
  bottom: 10px;
  font-size: 14px;
  color: rgba(33, 33, 33, 0.25);
}
.create-certificate-page .body .field .input-char-limit {
  position: absolute;
  right: 16px;
  top: 40px;
  font-size: 14px;
  color: rgba(33, 33, 33, 0.25);
}
.create-certificate-page .body .field .quill-event-description {
  position: relative;
}
.create-certificate-page .body .field .quill-event-description .ql-container {
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  min-height: 250px;
  width: 100%;
  max-height: 100%;
  font-size: 16px;
  font-weight: 400;
  padding: 12px 16px;
  padding-bottom: 12px;
}
.create-certificate-page .body .field .quill-event-description .ql-container .ql-editor.ql-blank::before {
  left: 30px;
  font-style: unset;
}
.create-certificate-page .body .field .quill-event-description .ql-container .ql-editor {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  font-family: Poppins;
}
.create-certificate-page .body .field .quill-event-description .ql-container .ql-editor.ql-blank::before {
  font-size: 16px;
  font-weight: 400;
}
.create-certificate-page .body .field .quill-event-description .ql-container .ql-tooltip {
  left: 30px !important;
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  box-shadow: 0px 7px 11.9px 0px rgba(33, 33, 33, 0.1);
}
.create-certificate-page .body .field .quill-event-description .ql-container .ql-tooltip[data-mode=link]::before {
  content: "Enter link :";
  font-size: 14px;
  font-weight: 400;
}
.create-certificate-page .body .field .quill-event-description .ql-container .ql-tooltip::before {
  content: "Go to link :";
}
.create-certificate-page .body .field .quill-event-description .ql-container .ql-preview {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 52, 187);
}
.create-certificate-page .body .field .quill-event-description .ql-container a {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 52, 187);
}
.create-certificate-page .body .field .quill-event-description .ql-container .ql-action {
  border-right: none;
}
.create-certificate-page .body .field .quill-event-description .ql-toolbar {
  border-radius: 7px;
  background: transparent;
  padding: 10px;
  position: absolute;
  bottom: -60px;
  left: 0px;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  z-index: 2;
}
.create-certificate-page .body .field .quill-event-description .ql-toolbar .ql-formats {
  margin-right: unset !important;
}
.create-certificate-page .body .field .quill-event-description .ql-toolbar .ql-formats button {
  height: 27px;
  color: rgba(33, 33, 33, 0.8);
  width: unset;
  padding: 2px 0px;
  margin-right: 16px;
  position: relative;
  z-index: 1;
}
.create-certificate-page .body .field .quill-event-description .ql-toolbar .ql-formats button:last-child {
  margin-right: 0px;
}
.create-certificate-page .body .field .quill-event-description .ql-toolbar .ql-formats .ql-active {
  background-color: rgba(0, 52, 187, 0.1);
  border-radius: 4px;
}
.create-certificate-page .body .field .quill-event-description .ql-toolbar .ql-formats .ql-active svg {
  fill: #212121 !important;
  color: #212121;
}
.create-certificate-page .body .buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 60px;
  width: 100%;
}
.create-certificate-page .body .buttons-wrapper button {
  border-radius: 7px;
  padding: 10px 18px;
  box-shadow: none;
  font-size: 16px;
  font-weight: 500;
}

.new-registrant-page .body {
  margin: 25px 40px;
  padding: 25px 35px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.new-registrant-page .body .page-title {
  color: #212121;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
}
.new-registrant-page .body .sub-heading {
  color: #212121;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 25px;
}
.new-registrant-page .body .field {
  margin-bottom: 16px;
}
.new-registrant-page .body .field .field-label {
  display: block;
  color: #212121;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.15px;
  text-transform: capitalize;
  margin-bottom: 2px;
}
.new-registrant-page .body .field .field-input {
  width: 100%;
}
.new-registrant-page .body .field .field-input .MuiInputBase-input {
  padding: 12px 16px;
}
.new-registrant-page .body .field .select-input {
  width: 100%;
  border-radius: 8px;
}
.new-registrant-page .body .field .select-input .MuiInputBase-input {
  padding: 12px 16px;
}
.new-registrant-page .body .field .select-input .select-placeholder {
  color: rgba(33, 33, 33, 0.4);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.15px;
}
.new-registrant-page .body .field .error-message {
  color: #ba0000;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.15px;
}
.new-registrant-page .body .field .new-registrant-autocomplete .MuiOutlinedInput-root {
  padding: 12px 16px;
}
.new-registrant-page .body .field .new-registrant-autocomplete .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0px;
}
.new-registrant-page .body .buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 60px;
  width: 100%;
}
.new-registrant-page .body .buttons-wrapper button {
  border-radius: 7px;
  padding: 10px 18px;
  box-shadow: none;
  font-size: 16px;
  font-weight: 500;
}

.training-materials-page .button {
  padding: 10px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 7px;
  box-shadow: none;
  color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
}
.training-materials-page .button:hover {
  box-shadow: none;
}
.training-materials-page .body {
  margin-top: 25px;
  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 25px 35px;
}
.training-materials-page .body .page-title {
  color: #212121;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}
.training-materials-page .body .no-materials-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.training-materials-page .body .no-materials-found .text {
  color: #212121;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 50px;
}

.add-training-materials-page .button {
  padding: 10px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 7px;
  box-shadow: none;
  color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
}
.add-training-materials-page .button:hover {
  box-shadow: none;
}
.add-training-materials-page .button-outlined {
  border: 1px solid rgba(33, 33, 33, 0.2);
  color: #212121;
}
.add-training-materials-page .body {
  margin-top: 25px;
  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 25px 35px;
}
.add-training-materials-page .body .page-title {
  color: #212121;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}
.add-training-materials-page .body .form {
  margin-top: 25px;
  margin-bottom: 30px;
}
.add-training-materials-page .body .form .field-group {
  display: flex;
  gap: 24px;
}
.add-training-materials-page .body .form .field-group > * {
  width: 100%;
}
.add-training-materials-page .body .form .field {
  margin-bottom: 18px;
}
.add-training-materials-page .body .form .field .field-label {
  display: block;
  color: #212121;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.15px;
  text-transform: capitalize;
  margin-bottom: 2px;
}
.add-training-materials-page .body .form .field .field-input {
  width: 100%;
}
.add-training-materials-page .body .form .field .field-input .MuiInputBase-input {
  padding: 12px 16px;
}
.add-training-materials-page .body .form .field .select-input {
  width: 100%;
  border-radius: 8px;
}
.add-training-materials-page .body .form .field .select-input .MuiInputBase-input {
  padding: 12px 16px;
}
.add-training-materials-page .body .form .field .select-input .select-placeholder {
  color: rgba(33, 33, 33, 0.4);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.15px;
}
.add-training-materials-page .body .form .field .material-availability {
  padding: 5px;
  border-radius: 6px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  display: flex;
  width: auto;
}
.add-training-materials-page .body .form .field .material-availability .item {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 9px;
  border-radius: 4px;
  cursor: pointer;
}
.add-training-materials-page .body .form .field .material-availability .item[data-selected=true] {
  background: rgba(0, 52, 187, 0.1);
  color: #0034bb;
}
.add-training-materials-page .body .form .file-uploader {
  border-radius: 6px;
  border: 1px dashed rgba(33, 33, 33, 0.2);
  background: #ffffff;
  padding: auto 100px;
  padding-top: 73px;
  padding-bottom: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.add-training-materials-page .body .form .file-uploader .drag-text {
  color: #212121;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
}
.add-training-materials-page .body .form .file-uploader .or {
  color: rgba(33, 33, 33, 0.4);
  font-size: 18px;
  font-weight: 400;
  margin: 12px auto;
}
.add-training-materials-page .body .form .file-uploader .select-from-device-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 20px;
  border-radius: 9px;
  text-transform: none;
  border: 1px solid rgba(33, 33, 33, 0.2);
  color: #212121;
  cursor: pointer;
}
.add-training-materials-page .body .form .file-uploader .muted-info {
  margin-top: 10px;
  max-width: 700px;
  color: rgba(33, 33, 33, 0.6);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.15px;
}
.add-training-materials-page .body .form .file-uploaded {
  margin-top: 18px;
}
.add-training-materials-page .body .form .file-uploaded .file-name-discard {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 8px;
}
.add-training-materials-page .body .form .file-uploaded .file-name-discard .file-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.add-training-materials-page .body .form .file-uploaded .file-name-discard .delete-file-btn {
  cursor: pointer;
  color: #212121;
}
.add-training-materials-page .body .form .file-uploaded .uploaded-buttons {
  display: flex;
  gap: 22px;
}
.add-training-materials-page .body .form .file-uploaded .uploaded-buttons .replace-file-btn {
  color: #212121;
  min-width: 119px;
  padding: 8px 30px;
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  cursor: pointer;
}
.add-training-materials-page .body .form .file-uploaded .uploaded-buttons .view-btn {
  min-width: 119px;
  padding: 8px 30px;
  color: #212121;
  background: rgba(0, 52, 187, 0.05);
}
.add-training-materials-page .body .page-buttons {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-access-screen {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 100000;
  display: none;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .mobile-access-screen {
    display: flex;
  }
}
.mobile-access-screen .mobile-body {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.mobile-access-screen .mobile-body .mobile-access-title {
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
  margin: 10px;
}
.mobile-access-screen .mobile-body .mobile-access-subtitle {
  font-size: 14px;
  font-weight: 400;
}
.mobile-access-screen .mobile-body .image {
  width: 100%;
  height: auto;
}

.error-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.error-page img {
  max-height: 60vh;
}
.error-page .btn-contained {
  height: 50px;
  width: 150px;
  border-radius: 15px;
}

.layout-two {
  height: 100vh;
}
.layout-two .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  padding: 5px 40px;
  height: 54px;
  padding-top: 8px;
  padding-left: 42px;
}
.layout-two .header .left img {
  max-width: 105px;
}
.layout-two .header .center {
  width: 600px;
}
.layout-two .header .center .search-input .MuiInputBase-root:focus .MuiOutlinedInput-notchedOutline, .layout-two .header .center .search-input .MuiInputBase-root:focus-within .MuiOutlinedInput-notchedOutline {
  border-color: #0034bb;
}
.layout-two .header .center .search-input .MuiInputBase-root:hover {
  border-radius: 9px;
}
.layout-two .header .center .search-input .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #0034bb;
}
.layout-two .header .center .search-input .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 10px 9px 0;
  max-height: 38px;
  font-size: 14px;
  color: #212121;
}
.layout-two .header .center .search-input .MuiInputBase-root .MuiInputBase-input::placeholder {
  color: #212121;
  font-weight: 400;
  opacity: 0.5;
}
.layout-two .header .center .search-input .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-radius: 9px;
  border: 1.5px solid rgba(33, 33, 33, 0.1);
}
.layout-two .header .center .search-input .MuiInputBase-root .MuiOutlinedInput-notchedOutline:hover {
  border: 1px solid #0034bb;
}
.layout-two .header .right ul {
  display: flex;
  align-items: center;
  gap: 26px;
  list-style: none;
}
.layout-two .header .right ul li {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  color: rgb(33, 33, 33);
}
.layout-two .header .right ul li .icon {
  width: 22px;
  height: 22px;
  color: rgba(33, 33, 33, 0.8);
}
.layout-two .header .right ul li .icon:hover {
  color: #0034bb;
}
.layout-two .header .right ul li .selected {
  color: #0034bb;
}
.layout-two .header .right ul li .avatar {
  width: 25px;
  height: 25px;
  font-size: 15px;
}
.layout-two .header .status-snackbar {
  position: fixed;
  left: 34px;
  bottom: 34px;
  border-radius: 12px;
  padding: 20px 30px;
  background-color: white;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
  z-index: 20;
  transform: translateX(-500px);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.layout-two .header .status-snackbar .close-btn {
  position: absolute;
  right: 8px;
  top: 8px;
  height: 24px;
  cursor: pointer;
  border-radius: 50px;
  padding: 6px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s;
}
.layout-two .header .status-snackbar .close-btn .iconify {
  font-size: 12px;
}
.layout-two .header .status-snackbar .close-btn:hover {
  background-color: rgba(33, 33, 33, 0.3);
}
.layout-two .header .status-snackbar h3 {
  font-size: 14px;
}
.layout-two .header .status-snackbar p {
  font-size: 12px;
  margin-bottom: 14px;
}
.layout-two .header .status-snackbar p span {
  font-weight: 600;
}
.layout-two .header .status-snackbar .bar-cont {
  width: 100%;
  height: 8px;
  background-color: rgba(33, 33, 33, 0.1);
  border-radius: 10px;
}
.layout-two .header .status-snackbar .bar-cont .bar {
  height: 100%;
  border-radius: 10px;
  background-color: rgba(0, 52, 187, 0.7);
  transition: all 0.3s ease;
}
.layout-two .header .status-snackbar.active {
  transform: translateX(0);
  border: none;
}
.layout-two .page {
  display: flex;
  align-items: center;
  gap: 30px;
}
.layout-two .page .page-right {
  height: calc(100vh - 54px);
  overflow: scroll;
  width: 100%;
}

.new-side-bar {
  position: relative;
  width: 264px;
  padding-right: 1px;
  background: #ffffff;
  border-right: none;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 33%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 1.4px 10px;
  background-repeat: repeat-y;
  transition: width 0.3s ease;
  /* Add transition property for smooth width changes */
}
.new-side-bar .switch {
  position: absolute;
  z-index: 1;
  right: -16px;
  cursor: pointer;
  bottom: 10px;
}
.new-side-bar .switch .switch-icon {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 24%;
  left: 24%;
  bottom: 0;
}
.new-side-bar .expended-view {
  height: calc(100vh - 54px);
  overflow-y: scroll;
  position: relative;
}
.new-side-bar .expended-view .item {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 20px;
  width: 263px;
  overflow-x: hidden;
}
.new-side-bar .expended-view .item_::-webkit-scrollbar {
  width: none;
  width: 0px;
}
.new-side-bar .expended-view .item .top-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  gap: 15px;
  padding-left: 40px;
  height: 55px;
  width: 263px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
  cursor: pointer;
}
.new-side-bar .expended-view .item .top-head .icons {
  width: 24px;
  height: 24px;
  color: rgba(33, 33, 33, 0.6);
}
.new-side-bar .expended-view .item .top-head a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
  cursor: pointer;
}
.new-side-bar .expended-view .item .top-head:hover {
  background: rgba(0, 52, 187, 0.1);
  color: #0034bb;
}
.new-side-bar .expended-view .item .top-head:hover .icons {
  color: #0034bb;
}
.new-side-bar .expended-view .item .top-head:hover a {
  color: #0034bb;
}
.new-side-bar .expended-view .item .active {
  border-right: 4px solid #0034bb;
  background: rgba(0, 52, 187, 0.1);
  color: #0034bb;
}
.new-side-bar .expended-view .item .active .icons {
  color: #0034bb;
}
.new-side-bar .expended-view .item .active a {
  color: #0034bb;
}
.new-side-bar .expended-view .item .menu {
  box-shadow: none !important;
  border: none !important;
  margin-bottom: 0px;
  min-width: 280px;
}
.new-side-bar .expended-view .item .menu ::before {
  height: 0px !important;
}
.new-side-bar .expended-view .item .menu .list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px;
}
.new-side-bar .expended-view .item .menu .list .a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
  padding: 8px 40px;
  padding-left: 77px;
  cursor: pointer;
}
.new-side-bar .expended-view .item .menu .list .a:hover {
  padding: 8px 38px;
  padding-left: 77px;
  background: rgba(0, 52, 187, 0.1);
  color: #0034bb;
}
.new-side-bar .expended-view .item .menu .list .active1 {
  border-right: 4px solid #0034bb;
  background: rgba(0, 52, 187, 0.1);
  color: #0034bb;
}
.new-side-bar .expended-view .item .menu .MuiButtonBase-root {
  padding-left: 40px;
  width: 264px;
  padding-right: 10px;
}
.new-side-bar .expended-view .item .menu .MuiButtonBase-root .MuiAccordionSummary-content {
  width: 100px;
}
.new-side-bar .expended-view .item .menu .MuiButtonBase-root .MuiAccordionSummary-content .title-head {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 150px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
  cursor: pointer;
}
.new-side-bar .expended-view .item .menu .MuiButtonBase-root .MuiAccordionSummary-content .title-head .icons {
  width: 24px;
  height: 24px;
  color: rgba(33, 33, 33, 0.6);
}
.new-side-bar .expended-view .item .menu .MuiButtonBase-root:hover {
  background: rgba(0, 52, 187, 0.1);
}
.new-side-bar .expended-view .item .menu .MuiButtonBase-root:hover .MuiAccordionSummary-content .title-head {
  color: #0034bb;
}
.new-side-bar .expended-view .item .menu .MuiButtonBase-root:hover .MuiAccordionSummary-content .title-head .icons {
  color: #0034bb;
}
.new-side-bar .expended-view .item .menu .Mui-expanded {
  color: #0034bb;
  align-items: flex-start;
  min-height: 30px;
  gap: 20px;
}
.new-side-bar .expended-view .item .menu .Mui-expanded .MuiAccordionSummary-content .title-head {
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: #0034bb;
  cursor: pointer;
}
.new-side-bar .expended-view .item .menu .Mui-expanded .MuiAccordionSummary-content .title-head .icons {
  width: 24px;
  height: 24px;
  color: #0034bb;
}
.new-side-bar .expended-view .item .menu:before {
  height: 0px !important;
}
.new-side-bar .expended-view .request-credit-card {
  position: absolute;
  width: 225px;
  left: 24px;
  bottom: 15px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: linear-gradient(129deg, #e1edff -5.19%, rgba(255, 255, 255, 0.8) 54.83%, #c7d7ff 122.81%);
  padding: 20px;
}
.new-side-bar .expended-view .request-credit-card .title {
  font-size: 18px;
  color: #000000;
  font-weight: 500;
}
.new-side-bar .expended-view .request-credit-card .title span {
  color: #0034bb;
}
.new-side-bar .expended-view .request-credit-card .sub-title {
  color: rgba(33, 33, 33, 0.7);
  font-size: 14px;
  font-weight: 400;
  margin-top: 14px;
}
.new-side-bar .expended-view .request-credit-card .request-button {
  margin-top: 20px;
  width: 198px;
  height: 37px;
  border-radius: 8px;
  overflow: clip;
  position: relative;
}
.new-side-bar .expended-view .request-credit-card .request-button .request-content {
  width: 198px;
  height: 30px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
}
.new-side-bar .expended-view .request-credit-card .request-button .sent-content {
  width: 198px;
  height: 37px;
  background: #2159ec;
  width: 100%;
  left: 0px;
  top: 0px;
  transform: translateX(-200px);
  position: absolute;
  overflow: clip;
}
.new-side-bar .expended-view .request-credit-card .request-button .sent-content .content-wrapper {
  display: flex;
  height: 37px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  transform: translateY(-30px);
  overflow: clip;
}
.new-side-bar .expended-view .request-credit-card .request-button .sent-content .content-wrapper .sparkle-animation-wrapper {
  width: 162px;
  height: 162px;
  position: absolute;
  left: 15px;
  top: 0px;
  transform: translateY(-50%);
}
.new-side-bar .expended-view .request-credit-card .request-button.animate .sent-content {
  animation-name: sent-content;
  animation-duration: 800ms;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
.new-side-bar .expended-view .request-credit-card .request-button.animate .sent-content .content-wrapper {
  animation-name: sent-content-wrapper;
  animation-delay: 900ms;
  animation-duration: 500ms;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
@keyframes sent-content {
  100% {
    transform: translateX(0px);
  }
}
@keyframes sent-content-wrapper {
  0% {
    transform: translateY(-25px);
  }
  100% {
    transform: translateY(0px);
    background: #506fbf;
  }
}
@media (max-height: 800px) {
  .new-side-bar .expended-view .request-credit-card {
    position: static;
    margin-left: 15px;
    margin-top: 15px;
  }
  .new-side-bar .expended-view .request-credit-card .sub-title {
    display: none;
  }
}
.new-side-bar .expended-view .unlock-premium {
  width: 240px;
  height: 390px;
  position: relative;
  margin: 0px auto;
  margin-top: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}
.new-side-bar .expended-view .unlock-premium .unlock-premium-container .unlock-premium-line {
  width: 250px;
  height: 100%;
  animation: zoomInOut 2s infinite;
  transform: scale(1.12);
}
.new-side-bar .expended-view .unlock-premium .unlock-premium-container .unlock-premium-line img {
  width: 100%;
  max-height: 100%;
}
.new-side-bar .expended-view .unlock-premium .unlock-premium-container .unlock-premium-image {
  position: absolute;
  top: 10%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: auto;
  animation: zoomImageTwoInOut 2s infinite;
  margin: auto;
}
.new-side-bar .expended-view .unlock-premium .unlock-premium-container .unlock-premium-image img {
  width: 100%;
  height: 100%;
}
.new-side-bar .expended-view .unlock-premium .unlock-premium-container .unlock-premium-body {
  background: linear-gradient(0deg, #fff 41.12%, rgba(255, 255, 255, 0) 100%);
  animation: none !important;
  position: absolute;
  bottom: 10px;
}
.new-side-bar .expended-view .unlock-premium .unlock-premium-container .unlock-premium-body .unlock-premium-title {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  margin: 35px 5.5px 0px 5.5px;
  color: #000000;
  line-height: normal;
}
.new-side-bar .expended-view .unlock-premium .unlock-premium-container .unlock-premium-body .unlock-premium-description {
  width: 200px;
  margin: 16px 14px 0px 15px;
  color: var(--Neutral-600, #818ea1);
  text-align: center;
  font-family: "Helvetica Neue";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 165%;
}
.new-side-bar .expended-view .unlock-premium .unlock-premium-container .unlock-premium-body .unlock-premium-button {
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  width: 100px;
  padding: 8px 16px;
  margin: 18px 64px 16px 65px;
  border-radius: 8px;
  border: 1px solid #f5f7fb;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1), 0px -1px 1px 0px rgba(0, 0, 0, 0.08) inset;
}
.new-side-bar .expended-view .unlock-premium .unlock-premium-container .unlock-premium-body .unlock-premium-button .MuiButton-startIcon {
  transform: scale(0.7);
}
.new-side-bar .expended-view::-webkit-scrollbar {
  width: 0px;
}
.new-side-bar.collapse {
  width: 60px;
  padding: 0px;
}
.new-side-bar.collapse .collapse-view {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 23px;
  padding-top: 27px;
  height: calc(100vh - 54px);
  overflow-y: scroll;
}
.new-side-bar.collapse .collapse-view .active {
  border-right: 4px solid #0034bb !important;
  background: rgba(0, 52, 187, 0.1) !important;
}
.new-side-bar.collapse .collapse-view .active .icons {
  margin-left: 4px;
  color: #0034bb !important;
}
.new-side-bar.collapse .collapse-view p {
  width: 100%;
  padding: 8px;
  padding-bottom: 6px;
  text-align: center;
}
.new-side-bar.collapse .collapse-view p .icons {
  width: 24px;
  height: 24px;
  color: rgba(33, 33, 33, 0.6);
  cursor: pointer;
}
.new-side-bar.collapse .collapse-view p:hover {
  border-right: 4px solid #0034bb;
  width: 100%;
  background: rgba(0, 52, 187, 0.1);
}
.new-side-bar.collapse .collapse-view p:hover .icons {
  margin-left: 4px;
  color: #0034bb !important;
}
.new-side-bar.collapse .collapse-view .collapse-list .button-icon {
  border-radius: 0px;
  margin: 0px;
  width: 100%;
  height: 44px;
}
.new-side-bar.collapse .collapse-view .collapse-list .button-icon .icons {
  width: 24px;
  height: 24px;
  color: rgba(33, 33, 33, 0.6);
}
.new-side-bar.collapse .collapse-view .collapse-list .button-icon:hover {
  border-right: 4px solid #0034bb;
  background: rgba(0, 52, 187, 0.1);
}
.new-side-bar.collapse .collapse-view .collapse-list .button-icon:hover .icons {
  margin-left: 4px;
}

.collapse-list-menu {
  margin-top: -30px;
}
.collapse-list-menu .MuiPaper-root {
  min-width: 180px;
}
.collapse-list-menu .MuiPaper-root .MuiList-root {
  margin: 2px 10px;
}
.collapse-list-menu .MuiPaper-root .MuiList-root .MuiButtonBase-root {
  padding: 10px 15px;
  font-size: 16px !important;
  font-weight: 500;
  color: #212121;
  background-color: #ffffff;
  margin-bottom: 5px;
}
.collapse-list-menu .MuiPaper-root .MuiList-root .MuiButtonBase-root:hover {
  border-radius: 10px;
  background: rgba(0, 52, 187, 0.05);
}
.collapse-list-menu .MuiPaper-root .MuiList-root .active {
  border-radius: 10px;
  background: rgba(0, 52, 187, 0.05);
}

.snackbar-cross {
  color: rgba(33, 33, 33, 0.8) !important;
  margin-right: -10px !important;
  margin-top: -49px;
  width: 19px;
  height: 19px;
  cursor: pointer;
}
.snackbar-cross:hover {
  background-color: rgba(33, 33, 33, 0.19);
  border-radius: 50px;
}

.Unauthorize {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin-top: 25%;
}
.Unauthorize .content {
  text-align: center;
}
.Unauthorize .content p {
  font-size: 20px;
  color: #212121;
  font-weight: 500;
  margin-bottom: 20px;
}
.Unauthorize .content button {
  font-size: 16px;
  font-weight: 600;
  border-radius: 7px;
  padding: 6px 40px;
}

.search-popover {
  margin-left: -42px;
  margin-top: 7px;
}
.search-popover .MuiPaper-root {
  padding: 13px 18px 26px 18px;
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  box-shadow: 4px 4px 4px 3px rgba(0, 0, 0, 0.02);
  max-height: 453px;
  overflow-y: scroll;
}
.search-popover .MuiPaper-root .default {
  display: flex;
  align-items: center;
  column-gap: 7px;
  margin-left: 12px;
  margin-top: 7px;
}
.search-popover .MuiPaper-root .default .text .query {
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.8);
  line-height: 1;
  margin-bottom: 0;
}
.search-popover .MuiPaper-root .default .text .sub-text {
  font-size: 10px;
  color: rgba(33, 33, 33, 0.7);
}
.search-popover .MuiPaper-root .search-group {
  margin-top: 15px;
  margin-bottom: 0;
}
.search-popover .MuiPaper-root .search-group .group-heading {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  line-height: 1.5;
  margin-bottom: 10px;
  text-transform: capitalize;
  margin-left: 12px;
}
.search-popover .MuiPaper-root .search-group .search-results .result {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 2px;
  border-radius: 10px;
  padding: 12px;
  cursor: pointer;
}
.search-popover .MuiPaper-root .search-group .search-results .result:last-child {
  margin-bottom: 0;
}
.search-popover .MuiPaper-root .search-group .search-results .result:hover {
  background: rgba(0, 52, 187, 0.03);
}
.search-popover .MuiPaper-root .search-group .search-results .result .avatar {
  height: 29px;
  width: 29px;
  font-size: 12px;
  font-weight: 500;
}
.search-popover .MuiPaper-root .search-group .search-results .result .right .heading {
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.8);
  line-height: 1;
}
.search-popover .MuiPaper-root .search-group .search-results .result .right .sub {
  font-size: 12px;
  color: rgba(33, 33, 33, 0.7);
}
.search-popover .MuiPaper-root .search-group .search-results .email-section {
  display: flex;
  align-items: center;
  column-gap: 15px;
  width: 100%;
}
.search-popover .MuiPaper-root .search-group .search-results .email-section .sub {
  text-align: left;
}
.search-popover .MuiPaper-root .search-group:first-child {
  margin-top: 0;
}
.search-popover .MuiPaper-root .fallback-wrapper {
  text-align: center;
}
.search-popover .MuiPaper-root .fallback-wrapper img {
  max-width: 299px;
  height: auto;
}
.search-popover .MuiPaper-root .fallback-wrapper p {
  font-weight: 500;
  color: #212121;
}

.notification-menu .MuiPaper-root {
  padding: 18px 22px 23px 22px !important;
  border-radius: 17px !important;
  max-width: 591px;
  min-width: 350px;
  width: max-content;
  box-shadow: 0px 7px 30px 7px rgba(33, 33, 33, 0.03) !important;
  height: max-content;
}
.notification-menu .MuiPaper-root .MuiList-root {
  padding: 0 !important;
}
.notification-menu .MuiPaper-root .heading {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 9px;
}
.notification-menu .MuiPaper-root .heading h4 {
  color: #212121;
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
}
.notification-menu .MuiPaper-root .heading p {
  font-size: 10px;
  font-weight: 800;
  line-height: 16px;
  background: #0034bb;
  padding: 1px 5px;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  color: #fff;
}
.notification-menu .MuiPaper-root .container {
  overflow: visible;
}
.notification-menu .MuiPaper-root .container .notification-wrapper {
  margin-bottom: 6px;
  padding: 12px 18px;
  margin-left: -5px;
  position: relative;
}
.notification-menu .MuiPaper-root .container .notification-wrapper:hover {
  border-radius: 10px;
  background: rgba(0, 52, 187, 0.03);
}
.notification-menu .MuiPaper-root .container .notification-wrapper .message {
  color: #212121;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 4px;
}
.notification-menu .MuiPaper-root .container .notification-wrapper .time {
  color: #212121;
  font-size: 12px;
  line-height: 18px;
}
.notification-menu .MuiPaper-root .container .notification-wrapper::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  background: transparent;
  border-radius: 8px;
  left: 0;
}
.notification-menu .MuiPaper-root .container .unread {
  margin-left: 0;
  padding-left: 28px;
}
.notification-menu .MuiPaper-root .container .unread .message {
  font-weight: 500;
}
.notification-menu .MuiPaper-root .container .unread::before {
  content: "";
  position: absolute;
  width: 3px;
  height: calc(100% - 24px);
  background: #0034bb;
  border-radius: 8px;
  left: 12px;
  top: 12px;
  bottom: 12px;
}
.notification-menu .MuiPaper-root .container .fallback {
  text-align: center;
}
.notification-menu .MuiPaper-root .container .fallback img {
  max-height: 250px;
}
.notification-menu .MuiPaper-root .container .fallback p {
  color: #212121;
  font-weight: 500;
}
.notification-menu .MuiPaper-root .notification-cta {
  text-decoration: underline;
  font-weight: 400 !important;
  color: #212121;
  font-size: 13px !important;
  margin-bottom: 0;
}

.settings-drawer .MuiPaper-root {
  border-radius: 0;
  padding: 25px 27px 40px 40px;
  min-width: 460px;
  justify-content: flex-start;
  background-color: #fff;
}
.settings-drawer .MuiPaper-root .wrapper .icon-container {
  text-align: right;
}
.settings-drawer .MuiPaper-root .wrapper h3 {
  margin-top: 10px;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 600;
  color: #212121;
}
.settings-drawer .MuiPaper-root .wrapper .field {
  margin-bottom: 30px;
}
.settings-drawer .MuiPaper-root .wrapper .field p {
  font-weight: 500;
  margin-bottom: -3px;
}
.settings-drawer .MuiPaper-root .wrapper .field .select {
  margin-top: 4px;
  outline: none;
}
.settings-drawer .MuiPaper-root .wrapper .field .select .MuiFormControl-root .MuiInputBase-root {
  border-radius: 8px;
  padding: 10px 11px 10px 7px;
  outline: none;
}
.settings-drawer .MuiPaper-root .wrapper .field .select .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  padding: 0;
}
.settings-drawer .MuiPaper-root .wrapper .field .select .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
}
.settings-drawer .MuiPaper-root .wrapper .field a {
  color: #0034bb;
  font-size: 12px;
  font-weight: 400;
  margin-top: -30px;
}
.settings-drawer .MuiPaper-root .wrapper .field .sub {
  font-size: 12px;
  font-weight: 400;
  margin-top: 3px;
}
.settings-drawer .MuiPaper-root .wrapper .field .sub span {
  color: #0034bb;
  text-decoration: underline;
  cursor: pointer;
}
.settings-drawer .MuiPaper-root .wrapper .start-tour-btn {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 15px;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 21px 20px;
  margin-bottom: 30px;
}
.settings-drawer .MuiPaper-root .wrapper .start-tour-btn .cont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 39px;
  border-radius: 999px;
  box-shadow: 0 4px 22px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(33, 33, 33, 0.1);
}
.settings-drawer .MuiPaper-root .wrapper .start-tour-btn .cont svg {
  padding-left: 2px;
}
.settings-drawer .MuiPaper-root .wrapper .start-tour-btn .content {
  height: fit-content;
}
.settings-drawer .MuiPaper-root .wrapper .start-tour-btn .content h3 {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 6px;
  margin: 0;
}

.MuiAutocomplete-popper .MuiPaper-root {
  margin-top: 9px;
  border-radius: 6px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  box-shadow: -7px 7px 40px 0px rgba(33, 33, 33, 0.1);
}
.MuiAutocomplete-popper .MuiPaper-root .MuiAutocomplete-option {
  margin-bottom: 12px;
}

.product-menu .MuiPaper-root {
  padding: 24px 20px !important;
  border-radius: 12px !important;
  min-width: max-content;
  box-shadow: 0px 7px 30px 7px rgba(33, 33, 33, 0.03) !important;
}
.product-menu .MuiPaper-root .MuiList-root {
  padding: 0 !important;
}
.product-menu .MuiPaper-root h5 {
  font-size: 20px;
  color: #212121;
  font-weight: 600;
  line-height: 1;
}
.product-menu .MuiPaper-root .products-wrapper {
  margin-top: 12px;
}
.product-menu .MuiPaper-root .products-wrapper .product {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: all 400ms ease-in;
}
.product-menu .MuiPaper-root .products-wrapper .product:hover .icon-wrapper .icon {
  transform: scale(1.1);
}
.product-menu .MuiPaper-root .products-wrapper .product:last-child {
  margin-bottom: 0;
}
.product-menu .MuiPaper-root .products-wrapper .product .icon-wrapper {
  border-radius: 50%;
  background: rgba(33, 33, 33, 0.06);
  padding: 12px;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.product-menu .MuiPaper-root .products-wrapper .product .selected {
  color: #0034bb;
  background: rgba(0, 52, 187, 0.11);
  cursor: default;
}
.product-menu .MuiPaper-root .products-wrapper .product .text-wrapper .name {
  font-size: 16px;
  color: #212121;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 1.5;
}
.product-menu .MuiPaper-root .products-wrapper .product .text-wrapper .selected-name {
  font-weight: 600;
}
.product-menu .MuiPaper-root .products-wrapper .product .text-wrapper .desc {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.8);
  line-height: 20px;
}

.signout-menu .MuiPaper-root {
  padding: 0 !important;
  border-radius: 6px !important;
  min-width: max-content;
  box-shadow: 0px 7px 30px 7px rgba(33, 33, 33, 0.03) !important;
}
.signout-menu .MuiPaper-root .MuiList-padding {
  padding: 0 !important;
}
.signout-menu .MuiPaper-root .user-menu .user {
  display: flex;
  align-items: center;
  column-gap: 11px;
  padding: 12px 16px;
  margin-left: 0;
}
.signout-menu .MuiPaper-root .user-menu .user .right {
  text-align: left;
}
.signout-menu .MuiPaper-root .user-menu .user .right .name {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  line-height: 20px;
}
.signout-menu .MuiPaper-root .user-menu .user .right .email {
  font-size: 14px;
  color: #212121;
  line-height: 20px;
}
.signout-menu .MuiPaper-root .user-menu .divider {
  height: 3px;
  background: rgba(33, 33, 33, 0.1);
  cursor: default;
}
.signout-menu .MuiPaper-root .user-menu .btn-wrapper {
  text-align: left;
}
.signout-menu .MuiPaper-root .user-menu .btn-wrapper:hover {
  border-radius: 6px;
  background: rgba(0, 52, 187, 0.03);
}
.signout-menu .MuiPaper-root .user-menu .btn-wrapper .signout-btn {
  margin: 10px 16px !important;
  color: #212121;
  font-size: 14px;
  line-height: 20px;
  padding: 0 !important;
}
.signout-menu .MuiPaper-root .user-menu .btn-wrapper .signout-btn:hover {
  background: transparent !important;
}

.no-access {
  text-align: center;
}
.no-access .bg_circle-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 50px;
  position: absolute;
  z-index: -1;
  opacity: 0.2;
  -moz-opacity: 0.07;
  right: 7.75rem;
  left: 7.75rem;
}
.no-access .bg_circle-wrapper > .circle {
  width: 320px;
  height: 325px;
  border-radius: 325px;
  filter: blur(200px);
  flex: 1 1 30%;
  overflow: visible;
}
.no-access .bg_circle-wrapper .circle-one {
  background: #0034bb;
}
.no-access .bg_circle-wrapper .circle-two {
  background: #0034bb;
}
.no-access .bg_circle-wrapper .circle-three {
  background: #2bba1d;
}
.no-access .bg_circle-wrapper .circle-four {
  background: #2bba1d;
}
.no-access .bg_circle-wrapper .circle-five {
  background: #ba0000;
}
.no-access .bg_circle-wrapper .circle-six {
  background: #fe9800;
}
.no-access .content {
  margin-top: 70px;
}
.no-access .content .image {
  width: 430px;
  margin: auto;
}
.no-access .content .image img {
  max-width: 100%;
}
.no-access .content h1 {
  font-size: 38px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  margin-top: 10px;
}
.no-access .content h2 {
  margin-top: 16px;
  font-size: 21px;
  font-weight: 400;
  color: rgb(33, 33, 33);
}
.no-access .content h2 span {
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  padding: 4px 10px;
  border-radius: 8px;
  margin-right: -5px;
}
.no-access .content h2 span:hover {
  background: rgba(0, 52, 187, 0.1);
  padding: 4px 10px;
  border-radius: 8px;
}
.no-access footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 40px;
  border-top: 1px solid rgba(33, 33, 33, 0.1);
}
.no-access footer .footer-btn {
  border-bottom: 1px solid !important;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1.12);
  }
  25% {
    transform: scale(1.17);
  }
  45% {
    transform: scale(1.17);
  }
  80% {
    transform: scale(1.12);
  }
  100% {
    transform: scale(1.12);
  }
}
@keyframes zoomImageTwoInOut {
  0% {
    transform: scale(1.05);
  }
  25% {
    transform: scale(1.1);
  }
  45% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1.05);
  }
}
.free-dialog .MuiPaper-root {
  margin: 0px !important;
}
.free-dialog .dialog-container {
  overflow-y: auto;
  display: flex;
  border-radius: 12px;
  background: #f8fafc;
  padding: 42px 62px 22px 62px;
}
.free-dialog .dialog-container .dialog-left {
  width: 60%;
  padding-right: 40px;
}
.free-dialog .dialog-container .dialog-left .title {
  font-size: 23px;
  font-weight: 600;
  color: #000;
  width: 80%;
}
.free-dialog .dialog-container .dialog-left .title span {
  background: var(--Blue-gradient, linear-gradient(90deg, #0034bb 0%, #477aff 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Poppins;
  font-size: 42.588px;
  font-style: italic;
  font-weight: 600;
  line-height: 52.348px;
  padding-right: 10px;
}
.free-dialog .dialog-container .dialog-left .para {
  font-size: 17px;
  font-weight: 400;
  color: rgb(0, 0, 0);
  margin-top: 6px;
  width: 80%;
}
.free-dialog .dialog-container .dialog-left .list {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-top: 15px;
}
.free-dialog .dialog-container .dialog-left .list .li {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  background: #fff;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.06);
  width: 50px;
  height: 50px;
}
.free-dialog .dialog-container .dialog-left .list .li h1 {
  color: #c4a447;
  font-size: 14px;
  font-style: italic;
  font-weight: 700;
}
.free-dialog .dialog-container .dialog-left .list .text {
  width: 80%;
}
.free-dialog .dialog-container .dialog-left .list .text h3 {
  font-size: 15px;
  font-weight: 500;
  color: rgb(0, 0, 0);
}
.free-dialog .dialog-container .dialog-left .list .text p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}
.free-dialog .dialog-container .dialog-right {
  border-radius: 16px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  background: #fff;
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.06);
  width: 40%;
  padding: 25px;
  padding-top: 20px;
  margin-bottom: 20px;
  position: relative;
}
.free-dialog .dialog-container .dialog-right .dialog-top {
  text-align: center;
}
.free-dialog .dialog-container .dialog-right .title h1 {
  font-size: 22px;
  font-weight: 700;
  color: rgb(0, 0, 0);
  margin-bottom: 15px;
  margin-top: 10px;
  text-align: center;
}
.free-dialog .dialog-container .dialog-right .switch-tab {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.02);
  background: rgba(33, 33, 33, 0.05);
  padding: 6px 10px;
  margin-bottom: 10px;
  width: 80%;
  margin: auto;
}
.free-dialog .dialog-container .dialog-right .switch-tab .button {
  border: none;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 0, 0);
  width: 50%;
  cursor: pointer;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  gap: 5px;
}
.free-dialog .dialog-container .dialog-right .switch-tab .button span {
  font-size: 9px;
  font-weight: 600;
  border-radius: 2.311px;
  background: #daffe1;
  color: rgb(12, 193, 66);
  padding: 2px;
}
.free-dialog .dialog-container .dialog-right .switch-tab .active {
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #fff;
  padding: 8px 0px;
}
.free-dialog .dialog-container .dialog-right .pro-plan {
  border-radius: 8px;
  background: rgba(33, 33, 33, 0.05);
  padding: 16px 20px;
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}
.free-dialog .dialog-container .dialog-right .pro-plan .plan-left {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.free-dialog .dialog-container .dialog-right .pro-plan .plan-left p {
  font-size: 16px;
  font-size: 400;
  color: rgb(0, 0, 0);
}
.free-dialog .dialog-container .dialog-right .pro-plan .plan-left h1 {
  font-size: 30px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  font-style: italic;
}
.free-dialog .dialog-container .dialog-right .pro-plan .plan-left h1 span {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: 400;
}
.free-dialog .dialog-container .dialog-right .pro-plan .plan-left h3 {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 52, 187);
  cursor: pointer;
}
.free-dialog .dialog-container .dialog-right .pro-plan .plan-left h3:hover {
  text-decoration: underline;
}
.free-dialog .dialog-container .dialog-right .pro-plan .plan {
  position: relative;
  width: 45%;
}
.free-dialog .dialog-container .dialog-right .pro-plan .plan .svg1 {
  position: absolute;
  left: 0;
  right: 30px;
  bottom: 0;
  top: 0;
}
.free-dialog .dialog-container .dialog-right .pro-plan .plan .svg-base {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1;
}
.free-dialog .dialog-container .dialog-right .pro-plan .plan .svg-overlay {
  position: absolute;
  top: 26px;
  right: 34px;
  z-index: 2;
}
.free-dialog .dialog-container .dialog-right .prorated {
  margin-top: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
}
.free-dialog .dialog-container .dialog-right .prorated .title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.free-dialog .dialog-container .dialog-right .prorated .title .title-left h1 {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 600;
  color: rgb(0, 0, 0);
}
.free-dialog .dialog-container .dialog-right .prorated .title p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 5px;
}
.free-dialog .dialog-container .dialog-right .debit-card {
  margin-top: 15px;
  padding-left: 5px;
  max-height: 160px;
  overflow-y: auto;
}
.free-dialog .dialog-container .dialog-right .debit-card h1 {
  font-size: 14px;
  font-weight: 600;
  color: rgb(0, 0, 0);
  margin-bottom: 0px;
}
.free-dialog .dialog-container .dialog-right .debit-card .card h2 {
  display: flex;
  align-items: center;
  gap: 0px;
  font-size: 14px;
  font-weight: 600;
  color: rgb(33, 33, 33);
}
.free-dialog .dialog-container .dialog-right .debit-card .card p {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: rgb(33, 33, 33);
  padding-left: 43px;
  margin-top: -6px;
}
.free-dialog .dialog-container .dialog-right .debit-card .card p .card-brand-icon {
  max-height: 14px;
  scale: 0.9;
  margin-left: 8px;
}
.free-dialog .dialog-container .dialog-right .button-checkout {
  margin-top: 30px;
  position: absolute;
  bottom: 20px;
  width: 87%;
}
.free-dialog .dialog-container .dialog-right .button-checkout .create {
  width: 100%;
  border-radius: 8px;
}
.free-dialog .dialog-container .dialog-right .button-checkout p {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: rgba(0, 0, 0, 0.2);
  font-size: 12px;
  font-family: 400;
  margin-top: 15px;
}

.free-button {
  display: flex;
  align-items: center;
  border-radius: 2479.516px;
  border: 0.299px solid #ded8b9;
  background: #fff;
  box-shadow: 0px 0.992px 0px 0px #000;
  width: 70px;
  height: 30px;
  padding: 5px 10px;
  padding-top: 8px;
}
.free-button h1 {
  background: linear-gradient(90deg, #e8d1a2 -32.13%, #ad9515 97.5%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  font-weight: 400;
}

.num-icon {
  padding: 1px;
  width: 20px;
  font-size: 14px;
  font-weight: 600;
  background: blue;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  color: #ffffff;
}
.num-icon:hover {
  background: #7f00bb !important;
}

.num-icon .active {
  background: red;
}

.loader {
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #f8f9fa;
  width: 90%;
  max-width: 75vw;
  max-height: 90vh;
  min-height: 20vh;
  border-radius: 10px;
  padding: 30px 40px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.modal h2 {
  font-size: 25px;
  font-weight: 600;
  color: #0034bb;
}
.modal .chips {
  margin: 20px 0;
}
.modal .chips .chip {
  border-radius: 6px;
  margin: 5px;
  text-transform: capitalize;
  color: #000000;
  font-weight: 500;
}
.modal .chips .outlined {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.modal .chips .filled {
  background: #313131;
  color: #ffffff;
}
.modal .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 50px;
}
.modal .container .left {
  width: 100%;
  flex: 0.9;
}
.modal .container .right {
  height: 300px;
  width: 320px;
  background: #0034bb;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 20px;
}
.modal .container .right div p {
  color: #ffffff;
  font-size: 12px;
}
.modal .container .right div h2 {
  color: #0034bb;
  font-size: 28px;
}
.modal .container .right .illus {
  height: 280px;
  margin-right: -50px;
  position: relative;
  right: -50px;
}
.modal .btns {
  text-align: right;
}
.modal .btns button {
  margin: 5px 15px;
  font-weight: 500;
}

/* icons */
@font-face {
  font-family: "icomoon";
  src: url("/src/assets/fonts/icomoon.eot?dagg2b");
  src: url("/src/assets/fonts/icomoon.eot?dagg2b#iefix") format("embedded-opentype"), url("/src/assets/fonts/icomoon.ttf?dagg2b") format("truetype"), url("/src/assets/fonts/icomoon.woff?dagg2b") format("woff"), url("/src/assets/fonts/icomoon.svg?dagg2b#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-num_1,
.icon-num_2,
.icon-num_3,
.icon-num_4,
.icon-num_5,
.icon-num_6,
.icon-num_7,
.icon-num_8,
.icon-num_9,
.icon-num_10 {
  height: auto;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 12px;
  padding-top: 7px;
  font-size: 14px;
  background: transparent;
  border-radius: 7px;
  font-weight: 500;
  font-family: "Poppins";
  width: 32px;
}